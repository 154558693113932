import moment from "moment";
import React, { useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { ExcelIcon, ExportIcon, PDFIcon } from "../icons";
import { useDownloadExcel } from "../useDownloadExcel";
import { URLS } from "../urls";
import { toast } from "react-toastify";
import { stringifyQueryString, useCookie } from "../../utils";
import currency from "currency.js";
import ModalLoader from "../ModalLoader";

export const EmployeeExports = ({ showSalary, queryParams }) => {
  const branch_id = useCookie("branch_id");

  const [excelData, setExcelData] = useState([]);

  const CSVLinkRef = useRef(null);

  // fetch excel hook
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(
    excelData,
    CSVLinkRef
  );

  const { limit, page, ...rest } = queryParams;

  const onDownloadExcelData = async () => {
    const data = await fetchExcelData(
      `${URLS.backendEmployees}/get/${branch_id}?${stringifyQueryString(rest)}`
    );
    const Employees = data?.employees;

    if (!Employees || !Employees?.length > 0) {
      return toast.error("No Employee to download", { position: "top-right" });
    }

    let exData = Employees.filter(
      (emp) => !emp.hasResigned && !emp.isTerminated
    )?.map((employee, index) => [
      index + 1,
      `${
        employee.personalInfo?.name?.first +
        " " +
        employee.personalInfo?.name?.last
      }`,
      showSalary
        ? currency(employee.jobInfo?.salary || 0, {
            symbol: "",
          }).format()
        : "",
      employee.jobInfo?.employeeId,
      employee.jobInfo?.grade?.name,
      employee.personalInfo?.email,
      employee.personalInfo?.phone?.home,
      employee.jobInfo?.joinDate,
      employee.jobInfo?.startDate,
    ]);

    const normalHead = [
      "No",
      "Name",
      showSalary ? "Salary" : "",
      "EmployeeId",
      "Job Grade",
      "Email",
      "Mobile",
      "Join Date",
      "Start Date",
    ];

    const date = `Date Prepared: ${moment(new Date()).format(
      "MMMM DD yyyy k:mm:ss z"
    )}`;

    exData = [
      // [company],
      ["Employee Details"],
      [date],
      [""],
      normalHead,
      ...exData,
      [""],
    ];

    // console.log(exData);
    setExcelData(exData);
  };

  const onDownloadFullExcelData = async () => {
    const data = await fetchExcelData(
      `${URLS.backendEmployees}/get/${branch_id}?${stringifyQueryString(rest)}`
    );
    const Employees = data?.employees;

    if (!Employees || !Employees?.length > 0) {
      return toast.error("No Employee to download", {
        position: "top-right",
      });
    }

    let exData = Employees.filter(
      (emp) => !emp.hasResigned && !emp.isTerminated
    )?.map((employee, index) => [
      index + 1,
      `${
        employee.personalInfo?.name?.first +
        " " +
        employee.personalInfo?.name?.last
      }`,
      showSalary
        ? currency(employee.jobInfo?.salary || 0, {
            symbol: "",
          }).format()
        : "",
      employee.jobInfo?.employeeId,
      employee.jobInfo?.grade?.name,
      employee.personalInfo?.email,
      employee.personalInfo?.phone?.home,
      employee.jobInfo?.joinDate,
      employee.jobInfo?.startDate,
      employee.personalInfo?.phone?.home,
      `${employee.personalInfo?.address?.apartment || ""} ${
        employee.personalInfo?.address?.street || ""
      } ${employee.personalInfo?.address?.city || ""} ${
        employee.personalInfo?.address?.state || ""
      } `,
      employee.personalInfo?.address?.zip,
      employee.personalInfo?.birth,
      employee.personalInfo?.gender,
      employee.personalInfo?.govtId,
      employee.personalInfo?.govtType,
      employee.personalInfo?.isAdmin ? "Yes" : "No",
      employee.personalInfo?.martial,
      employee.personalInfo?.prevEmployee?.employee,
      employee.personalInfo?.prevEmployee?.phone,
      employee.jobInfo?.confirmDate,
      employee.jobInfo?.department?.name,
      employee.jobInfo?.salaryBasis,
      employee.jobInfo?.sfa_id,
      `${employee.emergencyInfo?.address?.apartment || ""} ${
        employee.emergencyInfo?.address?.street || ""
      } ${employee.emergencyInfo?.address?.city || ""} ${
        employee.emergencyInfo?.address?.state || ""
      } `,
      `${employee.emergencyInfo?.name?.first || ""} ${
        employee.emergencyInfo?.name?.last || ""
      }`,
      `${employee.emergencyInfo?.phone?.primary || ""} ${
        employee.emergencyInfo?.phone?.secondary || ""
      }`,
      `${employee.emergencyInfo?.guarantor?.title} ${employee.emergencyInfo?.guarantor?.name}`,
      employee.emergencyInfo?.guarantor?.phone,
      employee.emergencyInfo?.guarantor?.address,
      employee.emergencyInfo?.relation,
      employee.bankDetails?.accountNumber,
      employee.bankDetails?.accountOfficer,
      employee.bankDetails?.accountType,
      employee.bankDetails?.bankCode,
      employee.bankDetails?.bankName,
      employee.bankDetails?.pensionAdmin,
      employee.bankDetails?.pensionNumber,
    ]);

    const normalHead = [
      "No",
      "Name",
      showSalary ? "Salary" : "",
      "EmployeeId",
      "Job Grade",
      "Email",
      "Mobile",
      "Join Date",
      "Start Date",
      "Personal Phone",
      "Personal Address",
      "Zip",
      "Personal Birthday",
      "Gender",
      "Government ID",
      "Government ID Type",
      "Admin",
      "Martial Status",
      "Previous Employer Name",
      "Previous Employer phone",
      "Confirm Date",
      "Department",
      "Salary Basis",
      "SFA ID",
      "Emergency Address",
      "Emergency Contact Name",
      "Emergency Contact Phone",
      "Guarantor Name",
      "Guarantor Phone",
      "Guarantor Address",
      "Relation",
      "Account Number",
      "Account Officer",
      "Account Type",
      "Bank Code",
      "Bank Name",
      "Pension Admin",
      "Pension Number",
    ];

    const date = `Date Prepared: ${moment(new Date()).format(
      "MMMM DD yyyy k:mm:ss z"
    )}`;

    exData = [
      // [company],
      ["Employee Details"],
      [date],
      [""],
      normalHead,
      ...exData,
      [""],
    ];

    // console.log(exData);
    setExcelData(exData);
  };

  return (
    <div>
      <CSVLink
        className="btn print d-none"
        filename={`Employee Details(${moment(new Date()).format(
          "DD-MMM-yyyy hh:mm:ss a"
        )}).csv`}
        data={excelData}
        ref={CSVLinkRef}
      />

      <Dropdown>
        <Dropdown.Toggle
          variant=""
          className="btn btn-outline-primary"
          disabled={isfetchingExcel}
          bsPrefix=""
        >
          <div className="d-flex">
            <ExportIcon lineColor="#2463ae" />
            <p className="mx-2">Export</p>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu
          popperConfig={{
            strategy: "fixed",
          }}
          renderOnMount
          className="text-center"
        >
          <Dropdown.Item
            as="div"
            onClick={onDownloadExcelData}
            className="p-cursor"
          >
            Export summary report <ExcelIcon color="#008000" />
          </Dropdown.Item>
          <Dropdown.Item
            as="div"
            onClick={onDownloadFullExcelData}
            className="p-cursor"
          >
            Export full report <ExcelIcon color="#008000" />
          </Dropdown.Item>
          <Dropdown.Item
            href={`${URLS.backendEmployees}/PDF/${branch_id}?imageUrl=${URLS.backendStatic}&showSalary=${showSalary}`}
            target="blank"
            className="p-cursor"
          >
            PDF <PDFIcon color="#ff0000" />
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <ModalLoader show={isfetchingExcel} title="Exporting..." />
    </div>
  );
};
