import React, { useEffect, useState } from "react";
import {
  branch_id,
  fetchActionsUtil,
  stringifyQueryString,
  useCookie,
} from "../../utils";
import moment from "moment";
import { URLS } from "../urls";
import ModalLoader from "../ModalLoader";
import {
  CalendarCheck2,
  CalendarDays,
  CalendarMinus,
  SummaryChartIconUp,
  SummaryCloseIcon,
} from "../icons";
import { UseQueryData } from "../UseQueryData";

const LeaveHistory = ({ eId }) => {
  const token = useCookie("token");

  // -------------- Query Data ----------------
  const initialQueryParams = {
    year: "",
  };
  const { queryParams, theQueryParams, onChangeInput } = UseQueryData({
    initialQueryParams,
    timer: 1500,
  });
  // -------------- Query Data ----------------

  const [state, setState] = useState([]);

  const [userLeaveBalance, setUserLeaveBalance] = useState({});

  const [totals, setTotals] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  const [btnSummryActive, setBtnSummaryActive] = useState(false);

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const leavesData = fetchActionsUtil(
        `${URLS.backendApplyLeaves}/getById/${eId}?${stringifyQueryString(
          queryParams
        )}`,
        "GET",
        token
      );
      const leavesTotalData = fetchActionsUtil(
        `${
          URLS.backendApplyLeaves
        }/total-applied-leaves/?Id=${eId}&${stringifyQueryString(queryParams)}`,
        "GET",
        token
      );

      const leavesBalanceData = fetchActionsUtil(
        `${
          URLS.backendLeaveBalances
        }/getByEmployeeId/${eId}?${stringifyQueryString(queryParams)}`,
        "GET",
        token
      );

      const [leavesResult, leavesTotalResult, leavesBalanceResult] =
        await Promise.all([leavesData, leavesTotalData, leavesBalanceData]);

      setTotals({ ...leavesTotalResult });
      setState(leavesResult.applyLeaves);
      setUserLeaveBalance(leavesBalanceResult?.leaveBalance);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      fetchData();
    }

    return () => {
      isMounted = false;
    };
  }, [branch_id, queryParams]);

  const calculateDays = (days) => {
    return days > 1 ? `${days} days ` : `${days} day`;
  };

  const checkIfLeaveFinished = (date, status) => {
    let data;

    const check = moment(new Date()).isAfter(moment(date));

    if (status === "New" || status === "Pending") {
      data = (
        <p className="dropdown-item pending" href="#">
          {" "}
          Pending
        </p>
      );
    } else if (status === "Declined") {
      data = (
        <p className="dropdown-item rejected" href="#">
          {" "}
          Inactive
        </p>
      );
    } else {
      if (!check) {
        data = (
          <p className="dropdown-item open-active" href="#">
            {" "}
            Active
          </p>
        );
      } else {
        data = (
          <p className="dropdown-item shortlisted" href="#">
            {" "}
            Finished
          </p>
        );
      }
    }
    return data;
  };

  return (
    <div>
      {/*<!-- Leave Statistics -->*/}
      <section className="stats">
        <div className="stat-alt">
          <div className="icon icon-alt icon-1">
            <CalendarDays />
          </div>
          <div className="details details-alt ">
            <h1 className="dark-text text-20 font-500 m-0">
              {userLeaveBalance ? userLeaveBalance.totalAvailable : 0}
            </h1>

            <p className="text-gray-400 font-400 text-14">Total Leave</p>
          </div>
        </div>
        <div className="stat-alt">
          <div className="icon icon-alt icon-1">
            <CalendarCheck2 />
          </div>
          <div className="details details-alt ">
            <h1 className="dark-text text-20 font-500 m-0">
              {userLeaveBalance
                ? userLeaveBalance.totalAvailable ||
                  0 - userLeaveBalance.totalRemaining ||
                  0
                : 0}
            </h1>

            <p className="text-gray-400 font-400 text-14">Taken Leave</p>
          </div>
        </div>
        <div className="stat-alt">
          <div className="icon icon-alt icon-1">
            <CalendarMinus />
          </div>
          <div className="details details-alt ">
            <h1 className="dark-text text-20 font-500 m-0">
              {userLeaveBalance ? userLeaveBalance.totalRemaining : 0}
            </h1>

            <p className="text-gray-400 font-400 text-14">Remaining Leaves</p>
          </div>
        </div>
      </section>
      {/*<!-- /Leave Statistics -->*/}

      <div className="d-flex justify-content-between my-4">
        <div className="d-flex">
          <div className="form-inline ml-3">
            <label>Year</label>
            <input
              type="number"
              style={{ width: "100px" }}
              className="form-control mx-2"
              value={theQueryParams.year}
              name="year"
              onChange={onChangeInput}
            />
          </div>
        </div>
        <div className="d-flex">
          <button
            onClick={() => setBtnSummaryActive(!btnSummryActive)}
            className="btn summaryBtn "
          >
            Show Summary
          </button>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="table-responsive">
            {state?.length > 0 ? (
              <table className="table table-striped custom-table mb-0 datatable">
                <thead>
                  <tr>
                    <th>Leave Type</th>
                    <th>From</th>
                    <th>To</th>
                    <th>Total no of days</th>
                    <th>No of days taken</th>
                    <th>No of days left</th>
                    <th>Active</th>
                    <th>Reason</th>
                    <th className="text-center">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {state.map((appliedLeave) => {
                    return (
                      <tr>
                        <td>{appliedLeave?.name}</td>
                        <td>
                          {moment(appliedLeave?.from).format("DD/MM/YYYY")}
                          {/* {appliedLeave?.from} */}
                        </td>
                        <td>
                          {moment(appliedLeave?.to).format("DD/MM/YYYY")}
                          {/* {appliedLeave?.to} */}
                        </td>
                        <td>
                          {appliedLeave?.totalDays
                            ? calculateDays(appliedLeave?.totalDays)
                            : "---"}
                        </td>
                        <td>{calculateDays(appliedLeave?.days)}</td>
                        <td>{calculateDays(appliedLeave?.remaining)}</td>
                        <td>
                          {checkIfLeaveFinished(
                            appliedLeave?.to,
                            appliedLeave?.status
                          )}
                        </td>
                        <td>{appliedLeave?.reason}</td>
                        <td className="text-center">
                          <div className="dropdown action-label">
                            <a
                              className="btn btn-white btn-sm btn-rounded dropdown-toggle"
                              href="javaScript:void(0);"
                              aria-expanded="false"
                            >
                              {appliedLeave?.status === "New" ? (
                                <i className="fa fa-dot-circle-o text-purple"></i>
                              ) : appliedLeave?.status === "Pending" ? (
                                <i className="fa fa-dot-circle-o text-info"></i>
                              ) : appliedLeave?.status === "Approved" ? (
                                <i className="fa fa-dot-circle-o text-success"></i>
                              ) : (
                                <i className="fa fa-dot-circle-o text-danger"></i>
                              )}
                              {appliedLeave?.status}
                            </a>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div className="d-flex justify-content-center my-4">
                <div>
                  <h2>No leave yet</h2>
                </div>
              </div>
            )}
          </div>

          <div
            className={
              btnSummryActive
                ? `summaryNew border-top open`
                : `summaryNew border-top`
            }
          >
            <div className="row justify-content-between mt-4">
              <div className="col">
                <div className="my-3">
                  <div className="d-flex gap-3 align-items-center mb-4">
                    <h4 className="m-0">
                      Total leave applied for{" "}
                      {theQueryParams.year || "all years"}
                    </h4>{" "}
                    <hr className="flex-grow-1 m-0" />
                    {btnSummryActive && (
                      <button
                        onClick={() => setBtnSummaryActive(!btnSummryActive)}
                        className="btn p-0"
                      >
                        <SummaryCloseIcon />
                      </button>
                    )}
                  </div>

                  <div
                    className="gridCont mb-3 three-col-grid"
                    style={{ gridTemplateRows: "unset" }}
                  >
                    {/*  */}
                    <div className="gridChild gridChildBorderLeftDarkGreen">
                      <div className="gridChildLeft">
                        <p className="gridChld1">{totals?.total || 0}</p>
                        <p className="gridChld2">Total Approved days</p>
                      </div>

                      <div className="gridChildRight">
                        <SummaryChartIconUp />
                        <p>5%</p>
                      </div>
                    </div>
                    {/*  */}
                    <div className="gridChild gridChildBorderLeftOrange">
                      <div className="gridChildLeft">
                        <p className="gridChld1">{totals?.totalPending || 0}</p>
                        <p className="gridChld2">Total Pending days</p>
                      </div>

                      <div className="gridChildRight">
                        <SummaryChartIconUp />
                        <p>5%</p>
                      </div>
                    </div>

                    {/*  */}

                    <div className="gridChild gridChildBorderLeftBlue">
                      <div className="gridChildLeft">
                        <p className="gridChld1">
                          {totals?.totalDeclined || 0}
                        </p>
                        <p className="gridChld2">Total Decline days</p>
                      </div>

                      <div className="gridChildRight">
                        <SummaryChartIconUp />
                        <p>5%</p>
                      </div>
                    </div>

                    {/*  */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ModalLoader show={isLoading} />
        </div>
      </div>
    </div>
  );
};

export default LeaveHistory;
