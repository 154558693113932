import React from "react";
import { format } from "date-fns";
import { NoSelectedItemIcon } from "./icons";

export default function NoTableItem({
  queryParams,
  title = "No Records found",
}) {
  return (
    <div className="no-item">
      <div className="d-flex justify-content-center align-items-center">
        <div className="p-3 pt-4 text-center">
          <div className="non-info">
            <NoSelectedItemIcon />
            <h2 className="mb-1 mt-3 h5">{title}</h2>
            {queryParams?.startDate && queryParams?.endDate ? (
              <p>
                {queryParams.startDate
                  ? format(new Date(queryParams.startDate), "dd MMM, yyy")
                  : ""}
                {" - "}
                {queryParams.endDate
                  ? format(new Date(queryParams.endDate), "dd MMM, yyy")
                  : ""}
              </p>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
