import React, { Fragment, useState, useEffect } from "react";
import { URLS } from "../../urls";
import moment from "moment";
import { Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { dateWithNowTime, fetchActionsUtil, useCookie } from "../../../utils";
import RsDateRangePicker from "../../RsDateRangePicker";

export default function ApplyPermissionModal({
  setOpenApplyPermission,
  getPermission,
  employee,
}) {
  const branch_id = useCookie("branch_id");

  const [state, setState] = useState({
    buttonText: "Submit",
    userId: "",
    applyDate: moment(new Date()).format("DD/MM/YYYY"),
    staff: "",
    users: [],
    comment: "",
    description: "",
    startDate: moment(new Date()).format("MM/DD/YYYY"),
    endDate: moment(new Date()).format("MM/DD/YYYY"),
    type: "",
    mode: "Single",
    requester: "",
    supervisor: null,
  });

  const [departments, setDepartments] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      fetchData();
      getSupervisors(employee.employeeId);
    }
    return () => {
      isMounted = false;
    };
  }, [employee]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const departmentData = fetchActionsUtil(
        `${URLS.mainSiteBackend}/users/departments`
      );

      const employeeData = fetchActionsUtil(
        `${URLS.backendEmployees}/get-employee-from-invex-details/${employee.employeeId}`
      );

      const [departmentResult, employeeResult] = await Promise.all([
        departmentData,
        employeeData,
      ]);
      setDepartments(departmentResult?.data?.departments);

      if (employeeResult.success) {
        setState((el) => ({
          ...el,
          requester: employeeResult?.invexStaff?.Staff_ID,
        }));
      }
    } catch (error) {
      console.log({ error });
      toast.error("Error!!!");
    } finally {
      setLoading(false);
    }
  };

  const getSupervisors = async (id) => {
    try {
      setLoading(true);

      const response = await fetchActionsUtil(
        `${URLS.backendEmployees}/getOneEmployeeSupervisor/${id}`
      );

      if (response.success) {
        const { foundSupervisor } = response;

        if (foundSupervisor) {
          if (process.env.REACT_APP_HAS_INVEX === "true") {
            const res = await fetchActionsUtil(
              `${URLS.mainSiteBackend}/users/${foundSupervisor?.Staff_ID}`
            );

            if (res?.data) {
              setState((el) => ({
                ...el,
                supervisor: res?.data,
                staff: res?.data?.Staff_ID,
              }));
            }
          }
        }
      }
    } catch (error) {
      console.log({ error });
      toast.error("Error!!!");
    } finally {
      setLoading(false);
    }
  };

  const getUsersInDepartments = async (department) => {
    let response = await fetch(
      `${URLS.mainSiteBackend}/users/by-department/${department}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        // credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    setState({
      ...state,
      users: data?.users,
    });
  };

  const onChangeInputs = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const filterByDateRange = (event, picker) => {
    setState({
      ...state,
      startDate: picker.startDate.format("MM/DD/YYYY"),
      endDate: picker.endDate.format("MM/DD/YYYY"),
    });
    picker.element.val(
      picker.startDate.format("MM/DD/YYYY") +
        " - " +
        picker.endDate.format("MM/DD/YYYY")
    );
  };

  const clearDateRange = (event, picker) => {
    setState({
      ...state,
      startDate: "",
      endDate: "",
    });

    picker.element.val("");
  };

  const submit = async (e) => {
    e.preventDefault();
    setState({ ...state, buttonText: "Submitting ..." });
    setLoading(true);

    const {
      applyDate,
      description,
      comment,
      staff,
      startDate,
      endDate,
      type,
      mode,
      requester,
    } = state;

    const employeeName = employee?.employeeName;
    const employeeId = employee?.employeeId;

    const requisition = {
      title: `${employeeName}'s Permission Request`,
      description,
      type: "Permission",
      requestto: staff,
      amount: 0,
      status: "Pending",
      regdate: moment(new Date()).format("MM/DD/YYYY"),
      requestby: requester ? requester : staff,
      vendor: "",
      comment,
      filledFormData: "",
      loanDetails: JSON.stringify({
        employeeName,
        employeeId,
        startDate,
        endDate,
        type,
      }),
    };

    const sendBody = {
      applyDate,
      status: "Pending",
      userID: employeeId,
      staff,
      description,
      comment,
      branch_id,
      endDate: dateWithNowTime(endDate, "MM/DD/YYYY"),
      startDate: dateWithNowTime(startDate, "MM/DD/YYYY"),
      type,
      mode,
    };

    const formData = new FormData();
    Object.entries(requisition).forEach(([key, value]) => {
      formData.append(key, value);
    });

    const token = "dummy token";

    try {
      if (process.env.REACT_APP_HAS_INVEX === "true") {
        const res = await fetch(`${URLS.mainSiteBackend}/requisition/create`, {
          method: "POST",
          headers: {
            Authorization: `Token ${token}`,
          },
          body: formData,
        });

        if (res.ok) {
          const result = await res.json();
          const sendResult = await fetchActionsUtil(
            `${URLS.backendpermmission}`,
            "POST",
            token,
            {
              ...sendBody,
              requestid: result?.data?.requisition?.requestid,
            }
          );

          if (sendResult.success) {
            toast.success("Permission Successfully Applied.", {
              position: "top-right",
            });
            getPermission();
            setState({
              ...state,
              buttonText: "Submit",
              staff: "",
              users: [],
              comment: "",
              description: "",
            });
            setLoading(false);
            setOpenApplyPermission(false);
          }
        }
      } else {
        toast.error("Please Connect to InvexERP.", {
          position: "top-right",
        });
      }
    } catch (error) {
      console.error(error);
      toast.error("An unexpected error occurred.", {
        position: "top-right",
      });
    } finally {
      setState({ ...state, buttonText: "Submit" });
      setLoading(false);
    }
  };

  return (
    <Modal
      centered
      show={true}
      onHide={() => {
        setOpenApplyPermission(false);
      }}
      className="custom-modal"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h3>Apply For Permission</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div>
            <form onSubmit={submit}>
              <div className="row">
                <Form.Group className="col-md-6">
                  <Form.Label>Employee name</Form.Label>
                  <input
                    className="form-control"
                    disabled
                    type="text"
                    value={employee?.employeeName}
                  />
                </Form.Group>

                <Form.Group className="col-md-6">
                  <Form.Label>
                    Choose Date <span className="text-danger">*</span>
                  </Form.Label>

                  <RsDateRangePicker
                    placement="topEnd"
                    onClean={(event, picker) => clearDateRange(event, picker)}
                    onOk={(event, picker) => filterByDateRange(event, picker)}
                  />
                </Form.Group>
              </div>
              <Form.Group className="mb-3">
                <Form.Label>
                  Description <span className="text-danger">*</span> :
                </Form.Label>
                <label className="border p-0 rounded pb-2 message-area d-block">
                  <Form.Control
                    name="description"
                    value={state?.description}
                    onChange={onChangeInputs}
                    as="textarea"
                    className="w-100 border-0 p-3"
                    placeholder="Enter your detail description"
                    rows={4}
                    required
                  />
                </label>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  Permission Type <span className="text-danger">*</span> :
                </Form.Label>
                <select
                  className="form-control"
                  value={state?.type}
                  name="type"
                  onChange={onChangeInputs}
                  required
                >
                  <option value=""></option>
                  <option value="NoSignOut">No SignOut</option>
                  <option value="Lateness">Lateness</option>
                  <option value="Absent">Absent</option>
                  <option value="EarlyClosure">Early Closure</option>
                </select>
              </Form.Group>
              <div className="d-flex align-items-center my-4">
                <h4 className="pr-2">Send Request To</h4>{" "}
                <hr className="flex-grow-1 m-0" />
              </div>

              {!state?.supervisor ? (
                <div className="row gap-3">
                  <Form.Group className="col mb-3">
                    <Form.Label>
                      Department <span className="text-danger">*</span> :
                    </Form.Label>
                    <select
                      className="form-control"
                      onChange={(e) => getUsersInDepartments(e.target.value)}
                      required
                    >
                      <option value="">Select Recipient's Department</option>
                      {departments &&
                        departments.map((el, index) => (
                          <option key={index} value={el.Department}>
                            {el.Department}
                          </option>
                        ))}
                    </select>
                  </Form.Group>

                  {state?.users.length > 0 && (
                    <Form.Group className="col mb-3">
                      <Form.Label>
                        Recipient <span className="text-danger">*</span> :
                      </Form.Label>
                      <select
                        name="staff"
                        className="form-control"
                        value={state?.staff}
                        onChange={onChangeInputs}
                        required
                      >
                        <option value="">Select Recipient </option>
                        {state?.users.map((el, index) => (
                          <Fragment key={index}>
                            <option value={el.Staff_ID}>{el.Name}</option>
                          </Fragment>
                        ))}
                      </select>
                    </Form.Group>
                  )}
                </div>
              ) : (
                <div className="my-3">
                  <input
                    className="form-control"
                    disabled
                    type="text"
                    value={state?.supervisor?.Name}
                  />
                </div>
              )}

              <Form.Group className="mb-3">
                <Form.Label>Comment :</Form.Label>
                <label className="border p-0 rounded pb-2 message-area d-block">
                  <Form.Control
                    name="comment"
                    value={state?.comment}
                    onChange={onChangeInputs}
                    as="textarea"
                    className="w-100 border-0 p-3"
                    placeholder="Enter a comment"
                    rows={4}
                  />
                </label>
              </Form.Group>
              <div className="submit-section">
                <button
                  className="btn btn-primary submit-btn"
                  disabled={isLoading}
                >
                  {state?.buttonText}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
