import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row, Table } from "react-bootstrap";
import Select from "react-select";
import { read, utils } from "xlsx";
import { ImportIcon } from "../icons";
import Cookies from "universal-cookie";
import { fetchActionsUtil, useCookie } from "../../utils";
import { URLS } from "../urls";
import { toast } from "react-toastify";
import ModalLoader from "../ModalLoader";
import moment from "moment";

export const ImportEmployees = ({ show, handleClose, refresh }) => {
  const cookies = new Cookies();
  const company_id = cookies.get("company_id");
  const branch_id = useCookie("branch_id");
  const token = "dummy token";

  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    grades: [],
    department: [],
    category: [],
  });

  // const [gradeId, setGradeId] = useState("");
  // const [departmentId, setDepartmentId] = useState("");
  // const [categoryId, setCategoryId] = useState("");
  const [employees, setEmployees] = useState([]);
  const [employeeErrors, setEmployeeErrors] = useState([]);
  // const [departmentName, setDepartmentName] = useState("");

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const token = "dummy token";

      const gradesData = fetchActionsUtil(
        `${URLS.backendJobGrades}/get/${branch_id}`,
        "GET",
        token
      );

      const departmentsData = fetchActionsUtil(
        `${URLS.backendDepartments}/get/${branch_id}`,
        "GET",
        token
      );

      const categoryData = fetchActionsUtil(
        `${URLS.backendJobCategory}/get/${branch_id}`,
        "GET",
        token
      );

      const [gradesResult, departmentsResult, categoryResult] =
        await Promise.all([gradesData, departmentsData, categoryData]);

      const grades = gradesResult?.jobGrades?.map((el) => ({
        value: el?._id,
        label: el?.name,
      }));
      const department = departmentsResult?.departments?.map((el) => ({
        value: el?._id,
        label: el?.name,
      }));

      const category = categoryResult?.jobCategories?.map((el) => ({
        value: el?._id,
        label: el?.name,
      }));

      setState({
        grades,
        department,
        category,
      });
    } catch (error) {
      console.log(error);
      toast.error("Fetching Error !!!");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      fetchData();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  async function handleFile({ eventData: e }) {
    setIsLoading(true);

    const file = e?.target?.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = async function (e) {
        try {
          const workbook = read(e.target.result, {
            cellDates: true,
            dateNF: "yyyy-MM-dd HH:mm:ss.SSS",
          });

          let rows = utils.sheet_to_json(
            workbook.Sheets[workbook.SheetNames[0]],
            {
              defval: "",
              dateNF: "yyyy-MM-dd HH:mm:ss.SSS",
            }
          );
          // console.log("excel", rows);

          const departments = state.department;
          const jobGrades = state.grades;
          const categories = state.category;

          if (rows?.length > 0) {
            const employees = rows?.map((el) => {
              // Remove space from excels
              let newObject = {};

              Object.keys(el).forEach((key) => {
                let newKey = key.replace(/\s+/g, ""); // Remove spaces
                newObject[newKey] = el[key];
              });

              // Map to get the supervisors
              let supers = `${newObject?.supervisor_employeeID}`
                .split(",")
                .filter((el) => el !== "");

              const min = 2;
              const max = 4;
              const random = Math.floor(Math.random() * (max - min + 1)) + min;

              // Get the username from the email and add number at end of it
              const username = newObject?.jobInfo_email
                ? `${newObject?.jobInfo_email?.split("@")[0]}${random}`
                : "";

              // Get the department from the excel
              const employeeDept = newObject?.department?.trim()
                ? departments.find(
                    (dept) => dept?.label === newObject?.department
                  )
                : null;
              const departmentName = employeeDept ? employeeDept?.label : "";
              const departmentId = employeeDept ? employeeDept?.value : null;

              // Get the job grade from the excel
              const gradeId = newObject?.job_grade
                ? jobGrades.find(
                    (grade) => grade?.label === newObject?.job_grade
                  )?.value
                : null;

              // Get the category from the excel
              const categoryId = newObject?.category
                ? categories.find(
                    (category) => category?.label === newObject?.category
                  )?.value
                : null;

              return {
                branch: branch_id,
                supervisor: supers?.length > 0 ? supers : [],
                department: departmentName,
                isLineManager: newObject?.isLineManager == "1" ? true : false,
                personalInfo: {
                  name: {
                    first: newObject?.personalInfo_name_first,
                    last:
                      newObject?.personalInfo_name_last ||
                      newObject?.personalInfo_name_middle,
                    middle: newObject?.personalInfo_name_middle,
                  },
                  address: {
                    apartment: newObject?.personalInfo_address_apartment,
                    street: newObject?.personalInfo_address_street,
                    city: newObject?.personalInfo_address_city,
                    state: newObject?.personalInfo_address_state,
                    zip: newObject?.personalInfo_address_zip,
                  },
                  phone: {
                    home: newObject?.personalInfo_phone_home,
                    alternate: newObject?.personalInfo_phone_alternate,
                  },
                  email: newObject?.personalInfo_email,
                  birth: moment(newObject?.personalInfo_birth, "yyyy-MM-dd")
                    .add(1, "hours")
                    .format("DD/MM/YYYY"),
                  isAdmin: newObject?.admin == "1" ? true : false,
                  isSuperAdmin: newObject?.admin == "1" ? true : false,
                  // grantAccess: newObject?.admin == "1" ? true : false,
                  grantAccess: true,
                  govtId: newObject?.personalInfo_govtId,
                  gender:
                    newObject?.personalInfo_gender?.charAt(0).toUpperCase() ===
                    "M"
                      ? "Male"
                      : newObject?.personalInfo_gender
                          ?.charAt(0)
                          .toUpperCase() === "F"
                      ? "Female"
                      : "",
                  martial: newObject?.personalInfo_martial,
                  govtType: newObject?.personalInfo_govtType,
                  spouse: {
                    name: newObject?.personalInfo_spouse_name,
                    employer: newObject?.personalInfo_spouse_employer,
                    phone: newObject?.personalInfo_spouse_phone,
                  },
                  prevEmployee: {
                    employee: newObject?.personalInfo_prevEmployee_employee,
                    phone: newObject?.personalInfo_prevEmployee_phone,
                  },
                },
                jobInfo: {
                  title: newObject?.jobInfo_title,
                  category: categoryId,
                  employeeId: newObject?.jobInfo_employeeId,
                  changeableId: newObject?.jobInfo_employeeId,
                  grade: gradeId,
                  company_id: company_id ? company_id : "",
                  supervisor: [],
                  department: departmentId,
                  workLocation: null,
                  email: newObject?.jobInfo_email,
                  // password: `${newObject?.jobInfo_password}`,
                  workPhone: newObject?.jobInfo_workPhone,
                  cellPhone: newObject?.jobInfo_cellPhone,
                  startDate: moment(newObject?.jobInfo_startDate, "yyyy-MM-dd")
                    .add(1, "hours")
                    .format("DD/MM/YYYY"),
                  joinDate: moment(newObject?.jobInfo_confirmDate, "yyyy-MM-dd")
                    .add(1, "hours")
                    .format("DD/MM/YYYY"),
                  salary: newObject?.jobInfo_salary,
                  status: newObject?.jobInfo_confirmDate ? "Confirmed" : "",
                  salaryBasis: "",
                  contract: false,
                  contractType: "",
                  sfa_id: newObject?.jobInfo_sfa_id,
                  workLocationBranch: null,
                  confirmDate: moment(
                    newObject?.jobInfo_confirmDate,
                    // "yyyy-MM-dd HH:mm:ss.SSS"
                    "yyyy-MM-dd"
                  )
                    .add(1, "hours")
                    .format("DD/MM/YYYY"),
                  state: newObject?.jobInfo_state,
                },
                emergencyInfo: {
                  name: {
                    first: newObject?.emergencyInfo_name_first,
                    last: newObject?.emergencyInfo_name_last,
                    middle: newObject?.emergencyInfo_name_middle,
                  },
                  address: {
                    apartment: newObject?.emergencyInfo_address_apartment,
                    street: newObject?.emergencyInfo_address_street,
                    city: newObject?.emergencyInfo_address_city,
                    state: newObject?.emergencyInfo_address_state,
                    zip: newObject?.emergencyInfo_address_zip,
                  },
                  phone: {
                    primary: newObject?.emergencyInfo_phone_primary,
                    secondary: newObject?.emergencyInfo_phone_secondary,
                  },
                  relation: newObject?.emergencyInfo_relation,
                  guarantor: {
                    title: newObject?.emergencyInfo_guarantor_title,
                    name: newObject?.emergencyInfo_guarantor_name,
                    phone: newObject?.emergencyInfo_guarantor_phone,
                    address: newObject?.emergencyInfo_guarantor_address,
                  },
                  referee: {
                    title: newObject?.emergencyInfo_referee_title,
                    name: newObject?.emergencyInfo_referee_name,
                    phone: newObject?.emergencyInfo_referee_phone,
                    address: newObject?.emergencyInfo_referee_address,
                  },
                  employeeHMO: null,
                  lifeInsurance: null,
                  image: "",
                  resume: "",
                  certificate: "",
                },
                educationInfo: [
                  {
                    institution: "",
                    subject: "",
                    startDate: "",
                    completeDate: "",
                    degree: "",
                    grade: "",
                    certificate: "",
                  },
                ],
                educationCertificateFiles: [
                  {
                    file: null,
                  },
                ],
                user: {
                  name: {
                    first: newObject?.personalInfo_name_first,
                    last:
                      newObject?.personalInfo_name_last ||
                      newObject?.personalInfo_name_middle,
                    middle: newObject?.personalInfo_name_middle,
                  },
                  username: username,
                  email: newObject?.jobInfo_email || "",
                  password: `${newObject?.jobInfo_password}` || "",
                  isAdmin: newObject?.admin == "1" ? true : false,
                  isSuperAdmin: newObject?.admin == "1" ? true : false,
                  company_id: company_id,
                  department_id: departmentId,
                  grantAccess: newObject?.admin == "1" ? true : false,
                  branch: branch_id,
                  workEmail: newObject?.jobInfo_email,
                  gender:
                    newObject?.personalInfo_gender?.charAt(0).toUpperCase() ===
                    "M"
                      ? "Male"
                      : newObject?.personalInfo_gender
                          ?.charAt(0)
                          .toUpperCase() === "F"
                      ? "Female"
                      : "",
                  Staff_ID: newObject?.jobInfo_employeeId,
                },

                bankDetails: {
                  bankName: newObject?.bankDetails_bankName,
                  accountNumber: newObject?.bankDetails_accountNumber,
                  accountType: newObject?.bankDetails_accountType,
                  accountOfficer: newObject?.bankDetails_accountOfficer,
                  bankCode: newObject?.bankDetails_bankCode,
                  pensionNumber: newObject?.bankDetails_pensionNumber,
                  pensionAdmin: newObject?.bankDetails_pensionAdmin,
                },
              };
            });
            // console.log({ employees });

            setEmployees(employees);

            const missingFields = employees
              ?.map((item) => {
                const missing = {};
                const employeeName = `${item.personalInfo.name.first} ${item.personalInfo.name.last}`;
                const employeeId = item.jobInfo.employeeId;

                if (!item.user.email)
                  missing["user.email"] = {
                    employeeName,
                    employeeId,
                    error: "Please enter the personal email",
                  };
                if (!item.user.password)
                  missing["user.password"] = {
                    employeeName,
                    employeeId,
                    error: "Please enter the password",
                  };
                if (!item.jobInfo.employeeId)
                  missing["jobInfo.employeeId"] = {
                    employeeName,
                    employeeId,
                    error: "Please enter the employee Id",
                  };
                if (!item.jobInfo.email)
                  missing["jobInfo.email"] = {
                    employeeName,
                    employeeId,
                    error: "Please enter the job email",
                  };
                if (!item.jobInfo.department)
                  missing["jobInfo.department"] = {
                    employeeName,
                    employeeId,
                    error:
                      "Department not found in excel OR It is missing from HR database",
                  };
                if (!item.jobInfo.grade)
                  missing["jobInfo.grade"] = {
                    employeeName,
                    employeeId,
                    error:
                      "Job grade not found in excel OR It is missing from HR database",
                  };
                return {
                  missingFields: missing,
                };
              })
              .filter((item) => Object.keys(item.missingFields).length > 0)
              .flatMap((item) => Object.values(item.missingFields));

            // console.log({ missingFields });

            setEmployeeErrors(missingFields);
          }
        } catch (err) {
          console.log(err);
        } finally {
          setIsLoading(false);
        }
      };

      reader.readAsArrayBuffer(file);
      e.target.value = "";
    }
  }

  const saveEmployees = async () => {
    try {
      if (employeeErrors.length > 0) {
        return toast.error("Please rectify the errors before you continue");
      }

      setIsLoading(true);
      const { success } = await fetchActionsUtil(
        `${URLS.backendEmployees}/multiple-add`,
        "POST",
        token,
        {
          employees,
        }
      );
      if (success) {
        refresh();
        toast.success("Added successfully");
        handleClose();
      }
    } catch (error) {
      toast.error(error?.message);

      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size={employeeErrors.length > 0 ? "lg" : ""}
    >
      <Modal.Header closeButton>
        <Modal.Title>Import Employees</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-4">
          <div className="d-flex justify-content-center mt-3">
            <label className="btn btn-outline-primary mx-3 p-cursor">
              <ImportIcon />
              <span className="mx-2" style={{ color: "#2463AE" }}>
                Import
              </span>
              <input
                type="file"
                className="d-none"
                onChange={(e) => {
                  handleFile({
                    eventData: e,
                  });
                }}
                accept=".xlsx"
              />
            </label>
          </div>
        </div>
        {/*  */}
        <div className="my-3">
          {employees.length > 0 ? (
            <div className="d-flex justify-content-center">
              <div>
                <div>
                  <p>{employees.length} employees detected</p>
                </div>
                <div>
                  {employeeErrors.length !== employees.length ? (
                    <>
                      {employeeErrors.length > 0 ? (
                        <p className="text-danger">
                          {employeeErrors.length} employee does not have the
                          required fields
                        </p>
                      ) : null}
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          ) : null}
        </div>

        {/*  */}
        {employeeErrors.length > 0 ? (
          <div className="my-4">
            <Row className="table-height">
              <Table responsive striped sticky className="table-custom">
                <thead className="position-sticky top-0">
                  <tr>
                    <th>NO</th>
                    <th>Employee name</th>
                    <th>Employee ID</th>
                    <th>Errors</th>
                  </tr>
                </thead>
                <tbody>
                  {employeeErrors.map((el, i) => (
                    <tr>
                      <td>{i + 1}</td>
                      <td>{el?.employeeName}</td>
                      <td>{el?.employeeId}</td>
                      <td>
                        <span className="text-danger"> {el?.error}</span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Row>
          </div>
        ) : null}
      </Modal.Body>

      {employees.length > 0 ? (
        <>
          {employeeErrors.length > 0 ? null : (
            <div className="d-flex justify-content-between my-3 mx-4">
              <div>
                <button className="btn btn-secondary" onClick={handleClose}>
                  Cancel
                </button>
              </div>
              <div>
                <button className="btn btn-primary" onClick={saveEmployees}>
                  Save
                </button>
              </div>
            </div>
          )}
        </>
      ) : null}
      <ModalLoader show={isLoading} />
    </Modal>
  );
};
