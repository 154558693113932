import React, { useEffect, useState } from "react";
import moment from "moment";
import Select from "react-select";

import { URLS } from "../urls.js";
import { Helmet } from "react-helmet";
import Topbar from "../topbar/Topbar.js";
import Sidebar from "../sidebar/Sidebar.js";

import RsDateRangePicker from "../RsDateRangePicker.jsx";
import {
  arrangeEventObject,
  chooseAttendanceStatusColor,
  fetchActionsUtil,
  stringifyQueryString,
  useCookie,
} from "../../utils.js";
import { toast } from "react-toastify";
import ApplyDayOffModal from "./ApplyDayOffModal.jsx";
import { Form } from "react-bootstrap";
import ModalLoader from "../ModalLoader.js";
import "./dayOff.css";
import { Add } from "@mui/icons-material";
import { UseQueryData } from "../UseQueryData.jsx";
import { DownArrowIcon } from "../icons.js";
import CustomPagination from "../Paginations.jsx";
import NoTableItem from "../NoTableItem.jsx";

const DayOff = () => {
  const branch_id = useCookie("branch_id");

  // ------------------ Filter Search -------------------//
  const initialQueryParams = {
    limit: 20,
    page: 1,
    type: "",
    status: "",
    endDate: "",
    startDate: "",
  };
  const { queryParams, theQueryParams, onChangeInput, setQueryParams } =
    UseQueryData({
      initialQueryParams,
      timer: 1500,
    });
  // ------------------ Filter Search -------------------//

  const [returnParams, setReturnParams] = useState({
    count: 0,
    endIndex: 0,
    startIndex: 0,
  });

  const [loading, setLoading] = useState(false);
  const [openApplyPermission, setOpenApplyDayOff] = useState(false);
  const [allGetDayOff, setAllGetDayOff] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [openDetails, setOpenDetails] = useState({});

  const fetchData = async () => {
    try {
      setLoading(true);
      const dayOffData = fetchActionsUtil(
        `${URLS.backendDayOff}/by-branch/${branch_id}?${stringifyQueryString(
          queryParams
        )}`
      );

      const employeeData = fetchActionsUtil(
        `${URLS.backendEmployees}/get/${branch_id}`
      );

      const [dayOffResult, employeeResult] = await Promise.all([
        dayOffData,
        employeeData,
      ]);

      setAllGetDayOff(dayOffResult?.dayOff);
      setEmployees(employeeResult.employees);
      setOpenDetails({
        uniqueStatus: dayOffResult?.uniqueStatus,
        uniqueNames: dayOffResult?.uniqueNames,
      });
      setReturnParams({
        count: dayOffResult?.count,
        endIndex: dayOffResult?.endIndex,
        startIndex: dayOffResult?.startIndex,
      });
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };

  const filterByDateRange = (event, picker) => {
    onChangeInput(
      arrangeEventObject({
        name: "startDate",
        value: picker.startDate.add(1, "hours"),
      })
    );

    onChangeInput(
      arrangeEventObject({
        name: "endDate",
        value: picker.endDate,
      })
    );

    picker.element.val(
      picker.startDate.format("YYYY-MM-DD") +
        " - " +
        picker.endDate.format("YYYY-MM-DD")
    );
  };

  const clearDateRange = (event, picker) => {
    onChangeInput(
      arrangeEventObject({
        name: "endDate",
        value: "",
      })
    );

    onChangeInput(
      arrangeEventObject({
        name: "startDate",
        value: "",
      })
    );

    picker.element.val("");
  };
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      fetchData();
    }

    return () => {
      isMounted = false;
    };
  }, [queryParams]);

  const getUserDayOff = (id, selectedItems) => {
    let userSelected = [];

    selectedItems.forEach((el) => {
      if (el["applySelected"] === "Employees") {
        const user = employees.find((el) => el?.userId === id);
        user && userSelected.push(user);
      } else if (el["applySelected"] === "Job Grade") {
        const user = employees.find((el) => el?.jobInfo?.grade?._id === id);
        user && userSelected.push(user);
      } else if (el["applySelected"] === "Department") {
        const user = employees.find(
          (el) => el?.jobInfo?.department?._id === id
        );
        user && userSelected.push(user);
      }
    });

    if (userSelected.length > 0) {
      userSelected = userSelected?.map(
        (el) =>
          `${el?.personalInfo?.name?.first || ""} ${
            el?.personalInfo?.name?.middle || ""
          } ${el?.personalInfo?.name?.last || ""}`
      );
    }

    return userSelected;
  };

  const dropDownFunction = (qty) => {
    setQueryParams((el) => ({ ...el, limit: qty }));
  };

  return (
    <body>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex, nofollow"></meta>
        <title>InvexERP</title>
      </Helmet>
      <div className="main-wrapper">
        <Topbar />
        <Sidebar />

        <section className="bg-white page-header position-relative w-100 page-header-2">
          <div className="page-wrapper">
            <div className="d-flex w-100  m-0 p-0 align-items-center justify-content-between gap-5">
              <div className="">
                <h4 className="page-title page-title-alt fw-bold">Day off</h4>
                <p className="page-paragraph">All employees day off</p>
              </div>
              <div className="d-flex">
                <button
                  className="btn add-btn"
                  onClick={() => setOpenApplyDayOff(true)}
                >
                  <Add /> Apply
                </button>
              </div>
            </div>
          </div>
        </section>

        {/*<!-- Page Wrapper -->*/}
        <div className="page-wrapper">
          {/*<!-- Page Content -->*/}
          <div className="content container-fluid">
            {/*<!-- Search Filter -->*/}
            <div className="grid-cat-1 filter-row">
              <div className="">
                <div className="form-group form-focus text-gray-400 d-flex align-items-center justify-content-between">
                  <select
                    className="form-control floating"
                    value={theQueryParams.type}
                    name="type"
                    onChange={(e) => onChangeInput(e)}
                  >
                    <option selected value="">
                      Select apply to
                    </option>
                    {openDetails?.uniqueNames?.map((name) => (
                      <option value={name}>{name}</option>
                    ))}
                  </select>
                  <div className=" pr-2">
                    <DownArrowIcon color="#6c757d" />
                  </div>
                </div>
              </div>
              <div className="">
                <div className="form-group form-focus text-gray-400 d-flex align-items-center justify-content-between">
                  <select
                    className="form-control floating"
                    value={theQueryParams.status}
                    name="status"
                    onChange={(e) => onChangeInput(e)}
                  >
                    <option selected value="">
                      Select Status
                    </option>
                    {openDetails?.uniqueStatus?.map((name) => (
                      <option value={name}>{name}</option>
                    ))}
                  </select>
                  <div className=" pr-2">
                    <DownArrowIcon color="#6c757d" />
                  </div>
                </div>
              </div>

              <div className="search-date cal-icon">
                <RsDateRangePicker
                  placement="bottomEnd"
                  onClean={(event, picker) => clearDateRange(event, picker)}
                  onOk={(event, picker) => filterByDateRange(event, picker)}
                  placeholder="Select From and To Date"
                />
              </div>
            </div>
            {/*<!-- /Search Filter -->*/}

            <div className="row">
              <div className="col-md-12">
                <div className="">
                  {allGetDayOff?.length > 0 ? (
                    <table className="table table-striped custom-table mb-0 datatable">
                      <thead>
                        <tr>
                          <th>S/N</th>
                          <th className="text-center">Apply to</th>
                          <th className="text-center">Employees</th>
                          <th className="text-center">Dates</th>
                          <th className="text-center">Status</th>
                          <th className="text-center">Description</th>
                          <th className="text-center">created on</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allGetDayOff?.length > 0
                          ? allGetDayOff?.map((permit, i) => {
                              return (
                                <tr key={i}>
                                  <td>{i + 1}</td>
                                  <td className="text-center">
                                    {permit?.applySelected}
                                  </td>
                                  <td className="text-center">
                                    {permit?.applySelected === "All"
                                      ? "All employee"
                                      : permit?.selectedData
                                          ?.map((el) =>
                                            getUserDayOff(el, [permit])
                                          )
                                          .join(" // ")}
                                  </td>
                                  <td className="text-center">
                                    {permit?.dateRanges
                                      ?.map(
                                        (date) =>
                                          ` ${moment(date?.startDate).format(
                                            "MMMM Do YYYY"
                                          )} -
                                        ${moment(date?.endDate).format(
                                          "MMMM Do YYYY"
                                        )}`
                                      )
                                      .join(" // ")}
                                  </td>

                                  <td className="text-center fw-bold">
                                    <p
                                      className={`tw-status ${chooseAttendanceStatusColor(
                                        permit.status === "Approved"
                                          ? "Good"
                                          : permit.status === "Pending"
                                          ? "OnLeave"
                                          : "Late"
                                      )}`}
                                    >
                                      {permit.status}
                                    </p>
                                  </td>
                                  <td className="text-center">
                                    {permit?.description}
                                  </td>
                                  <td className="text-center">
                                    {`${moment(permit?.createdAt)?.format(
                                      "MMMM Do YYYY"
                                    )}`}
                                  </td>
                                </tr>
                              );
                            })
                          : null}
                      </tbody>
                    </table>
                  ) : (
                    <NoTableItem />
                  )}
                </div>
              </div>
            </div>
          </div>
          {/*<!-- /Page Content -->*/}

          <div className="my-4">
            <CustomPagination
              setQueryParams={setQueryParams}
              startIndex={returnParams.startIndex}
              endIndex={returnParams.endIndex}
              count={returnParams.count}
              limit={queryParams.limit}
              page={queryParams.page}
              dropDownFunction={dropDownFunction}
            />
          </div>

          {/*<!-- Add Leave Modal -->*/}
          {openApplyPermission && (
            <ApplyDayOffModal
              setOpenApplyDayOff={setOpenApplyDayOff}
              getDayOff={fetchData}
            />
          )}
          {/*<!-- /Add Leave Modal -->*/}

          {/*<!-- Edit Leave Modal -->*/}
          {/* <EditLeaveModal leaveTypes={leaveTypes} editLeave={selectedLeave} /> */}
          {/*<!-- /Edit Leave Modal -->*/}
        </div>
        {/*<!-- /Page Wrapper -->*/}
      </div>
      {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
      <div className="sidebar-overlay" data-reff=""></div>
      <ModalLoader show={loading} />
    </body>
  );
};

export default DayOff;
