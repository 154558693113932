import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import { Button, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import ModalLoader from "../ModalLoader";
import { toast } from "react-toastify";
import { URLS } from "../urls";

import ImportPaylistFromExcel from "../editEmployeeJobGrade/ImportPaylistFromExcel";
import { addAllSettings } from "../settings/settingsAction";
import { fetchActionsUtil, useCookie } from "../../utils";
import TimePicker from "../TimePicker";
import moment from "moment";
import CurrencyInput from "react-currency-input-field";
import { isEmpty } from "lodash";

const monthCycleOptions = [
  {
    label: "Previous Month",
    value: "Previous Month",
  },
  {
    label: "Current Month",
    value: "Current Month",
  },
];

const salaryParcentage = {
  basic: 25,
  housing: 15,
  medical: 25,
  transport: 15,
  utility: 5,
  dressing: 10,
  entertainment: 5,
};

const attendanceInitials = {
  Lateness: 2,
  NoSignOut: 2,
  Absent: 2,
  EarlyClosure: 2,
  resumeTime: moment(new Date()).format("HH:mm:ss"),
  closingTime: moment(new Date()).format("HH:mm:ss"),
  deductType: "Net Pay",
};

function PayrollSettings(props) {
  const branch_id = useCookie("branch_id");
  const defaultSettings = {
    startDay: 1,
    startMonth: "Current Month",
    endDay: 30,
    endMonth: "Current Month",
    exemptionDeductions: [],
    taxRateAsPerMonthlyComputation: [{ ratePercentage: "", rateAmount: "" }],
    grossForCraPurposePercentage: 0,
    personalAllowance: 0,
  };

  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState({
    ...defaultSettings,
  });

  const [salarySettings, setSalarySettings] = useState({
    ...salaryParcentage,
  });

  const [addNewSalary, setAddNewSalary] = useState([]);

  const [getNewSalary, setGetNewSalary] = useState([]);

  const [typeOfSalary, setTypeOfSalary] = useState("");

  const [paySetting, setPaySetting] = useState({
    payType: "Gross Pay",
    workingDays: 0,
    extras: false,
    customWorkingDays: false,
  });
  const [leaveSetting, setLeaveSetting] = useState({
    useLeaveWorkingDays: false,
    applyAllowanceImmediately: false,
  });

  const [attendanceSetting, setAttendanceSetting] = useState({
    ...attendanceInitials,
  });

  const [btnText, setBtnText] = useState("Save Pay Settings");

  const [attenbtnText, setAttenBtnText] = useState("Save Attendance Settings");

  const [salarybtnText, setSalaryBtnText] = useState("Save Salary Settings");

  const [deductionSettingsOptions, setDeductionSettingsOptions] = useState([]);

  const token = "dummy token";

  const getSalaryByBranch = async (id) => {
    const data = await fetchActionsUtil(
      `${URLS.backendsalarySetting}/get/${id}`,
      "GET",
      token
    );

    if (data?.jobGrades?.length > 0) {
      const {
        basic,
        housing,
        medical,
        transport,
        utility,
        dressing,
        entertainment,
        custom,
        typeOfSalary,
      } = data?.jobGrades[0];

      setSalarySettings({
        basic,
        housing,
        medical,
        transport,
        utility,
        dressing,
        entertainment,
      });

      setGetNewSalary(custom);
      setTypeOfSalary(typeOfSalary);
    }
  };

  useEffect(() => {
    getSettings();
    getAttendanceSettings();
    getLeaveSettings();
  }, []);

  useEffect(() => {
    setPaySetting({
      ...props.settings,
      extras: props.settings.extras === "true" ? true : false,
      customWorkingDays:
        props.settings.customWorkingDays === "true" ? true : false,
    });
    getSalaryByBranch(branch_id);
    return () => {};
  }, [props]);

  const getLeaveSettings = async () => {
    // await waitFor(5000);

    let response = await fetch(
      `${URLS.backendLeaves}/get-settings/${branch_id}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        // credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      console.log(response.message);
      throw new Error(response.message);
    }

    const { leaveSettings } = await response.json();

    setLeaveSetting({
      useLeaveWorkingDays: leaveSettings?.useLeaveWorkingDays,
      applyAllowanceImmediately: leaveSettings?.applyAllowanceImmediately,
    });
  };

  const getSettings = () => {
    setLoading(true);
    fetch(`${URLS.backendPayroll}/get-settings/${branch_id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        // cleanup for the form
        if (result?.deductionSettings) {
          result.deductionSettings = result.deductionSettings.map((el) => ({
            ...el,
            label: el.name,
            value: el._id,
          }));
          setDeductionSettingsOptions(result.deductionSettings);
        }

        if (result?.settings) {
          if (isEmpty(result?.settings.taxRateAsPerMonthlyComputation)) {
            delete result.settings.taxRateAsPerMonthlyComputation;
          }

          if (
            !isEmpty(result?.settings.exemptionDeductions) &&
            result?.deductionSettings
          ) {
            result.settings.exemptionDeductions =
              result?.settings.exemptionDeductions.map((el) =>
                result?.deductionSettings.find((setting) => setting._id === el)
              );
          }

          setSettings({ ...defaultSettings, ...result.settings });
        }

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast("Unable to get Settings", {
          type: "error",
        });
      });
  };

  const getAttendanceSettings = () => {
    setLoading(true);
    fetch(`${URLS.backendattendance}/get-settings/${branch_id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result?.attendance) {
          setAttendanceSetting({
            ...attendanceInitials,
            ...result.attendance,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast("Unable to get Settings", {
          type: "error",
        });
      });
  };

  const saveSettings = (e) => {
    e.preventDefault(e);
    setLoading(true);

    fetch(`${URLS.backendPayroll}/save-settings`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({ settings, branch_id }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          toast("Successs", {
            type: "success",
          });
        } else {
          throw Error();
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast("Unable to save", {
          type: "error",
        });
      });
  };

  const saveLeaveSettings = (e) => {
    e.preventDefault(e);
    setLoading(true);

    fetch(`${URLS.backendLeaves}/save-settings`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({ ...leaveSetting, branch_id }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          toast("SuccessFully", {
            type: "success",
          });
        } else {
          throw Error();
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast("Unable to save", {
          type: "error",
        });
      });
  };

  const saveSalarySettings = (salarySettings, e) => {
    e.preventDefault(e);
    setLoading(true);
    const token = "dummy token";

    fetch(`${URLS.backendsalarySetting}/add`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({ ...salarySettings, branch_id, typeOfSalary }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          toast("Successs", {
            type: "success",
          });
        } else {
          throw Error();
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast("Unable to save", {
          type: "error",
        });
      });
  };

  const savePaySetting = async (e) => {
    e.preventDefault();
    try {
      setBtnText("please wait...");
      const formData = new FormData();
      for (let setting in paySetting) {
        formData.append(setting, paySetting[setting]);
      }

      const response = await fetch(
        `${URLS.backendCompanySettings}/save-settings`,
        {
          method: "POST",
          body: formData,
          headers: {
            Accept: "application/json",
            // "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );

      const result = await response.json();
      if (result.success) {
        props.addAllSettings(result.settings);
        toast("Settings Saved", {
          type: "success",
        });
      }
    } catch (e) {
      console.log(e);
      toast("Unable to save settings", {
        type: "error",
      });
    } finally {
      setBtnText("Save Pay settings");
    }
  };

  const saveAttendance = (e) => {
    e.preventDefault(e);

    if (
      attendanceSetting.closingTime === "Invalid date" ||
      attendanceSetting.resumeTime === "Invalid date"
    ) {
      return toast.error("Select a valid time");
    }

    setLoading(true);
    setAttenBtnText("Please wait...");
    //  console.log({ ...attendanceSetting, branch_id });
    fetch(`${URLS.backendattendance}/save-settings`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({ ...attendanceSetting, branch_id }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          toast("Successs", {
            type: "success",
          });
        } else {
          throw Error();
        }
        setAttenBtnText("Save Attendance Settings");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setAttenBtnText("Save Attendance Settings");
        toast("Unable to save", {
          type: "error",
        });
      });
  };

  const onSalarySettings = (e) => {
    const { name, value } = e.target;
    setSalarySettings({ ...salarySettings, [name]: value });
  };

  const onAttendanceSettings = (e) => {
    const { name, value } = e.target;
    setAttendanceSetting({ ...attendanceSetting, [name]: value });
  };

  const selectTime = (date, name) => {
    setAttendanceSetting({
      ...attendanceSetting,
      [name]: moment(date).format("HH:mm:ss"),
    });
  };

  const onSaveSalarySetting = (e) => {
    setSalaryBtnText("Save Salary Settings");

    const removeEmptyAdded = addNewSalary.filter((el) => el.name !== "");

    const addedSalary = removeEmptyAdded.reduce(
      (obj, item) => ((obj[item.name] = parseFloat(item.percentage)), obj),
      {}
    );

    const getOldSalary = getNewSalary.reduce(
      (obj, item) => ((obj[item.name] = parseFloat(item.percentage)), obj),
      {}
    );

    const merger = { ...addedSalary, ...salarySettings, ...getOldSalary };

    const total = Object.values(merger).reduce(
      (c, p) => parseFloat(c) + parseFloat(p, 0)
    );

    if (total < 100) {
      return toast(`Please add ${100 - total}% to the salary Percentage`, {
        type: "error",
        position: "top-right",
      });
    } else if (total > 100) {
      return toast(
        `Please subtract ${total - 100}% from the salary Percentage`,
        {
          type: "error",
          position: "top-right",
        }
      );
    }

    saveSalarySettings(
      { salarySettings, addNewSalary: removeEmptyAdded.concat(getNewSalary) },
      e
    );
    setSalaryBtnText("Save Salary Settings");
  };

  const onChangeNewOnes = (e, i) => {
    const all = addNewSalary.map((el, id) => {
      if (i !== id) return el;
      const { name, value } = e.target;
      return { ...el, [name]: value };
    });
    setAddNewSalary(all);
  };

  const onChangeOldAdded = (name, price) => {
    setGetNewSalary((oldata) =>
      oldata.map((item) => {
        if (item.name === name) {
          return { ...item, percentage: price };
        } else {
          return item;
        }
      })
    );
  };

  const removeOldAdded = (idx) => {
    // if (getNewSalary.length === 1) return;
    const removeOnes = getNewSalary.filter((r, i) => i !== idx);
    setGetNewSalary(removeOnes);
  };

  const removeNewOne = (idx) => {
    // if (addNewSalary.length === 1) return;
    const removeOnes = addNewSalary.filter((r, i) => i !== idx);
    setAddNewSalary(removeOnes);
  };

  return (
    <body>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex, nofollow"></meta>
        <title>InvexERP</title>
      </Helmet>
      <div className="main-wrapper">
        <Topbar />
        <Sidebar />
        {/*<!-- Page Wrapper -->*/}
        <div className="page-wrapper">
          {/*<!-- Page Content -->*/}
          <div className="content container-fluid">
            {/*<!-- Page Title -->*/}
            <div className="row">
              <div className="col-sm-12">
                <h4 className="page-title">Application Settings</h4>
              </div>
            </div>
            {/*<!-- /Page Title -->*/}
            {/*<!-- Content Starts -->*/}
            <div className="p-3">
              <Form onSubmit={(e) => saveSettings(e)}>
                <Form.Group>
                  <fieldset className="html-fieldset my-4">
                    <legend className="html-legend fw-bold">
                      Payroll Cycle:
                    </legend>

                    <Row>
                      <Col>
                        <Form.Label>Start Day</Form.Label>
                        <Form.Control
                          type="number"
                          className="mb-2"
                          placeholder=""
                          name="startDay"
                          value={settings.startDay}
                          onChange={(e) => {
                            setSettings({
                              ...settings,
                              [e.target.name]: e.target.value,
                            });
                          }}
                          required
                        />
                        <Select
                          name="startMonth"
                          options={monthCycleOptions}
                          value={monthCycleOptions.find(
                            (el) => el.value == settings.startMonth
                          )}
                          onChange={(selected) => {
                            if (!selected.value) return;
                            setSettings({
                              ...settings,
                              startMonth: selected.value,
                            });
                          }}
                        />
                      </Col>

                      <Col>
                        <Form.Label>End Day</Form.Label>
                        <Form.Control
                          type="number"
                          className="mb-2"
                          placeholder=""
                          name="endDay"
                          value={settings.endDay}
                          onChange={(e) => {
                            setSettings({
                              ...settings,
                              [e.target.name]: e.target.value,
                            });
                          }}
                          required
                        />
                        <Select
                          name="endMonth"
                          options={monthCycleOptions}
                          value={monthCycleOptions.find(
                            (el) => el.value == settings.endMonth
                          )}
                          onChange={(selected) => {
                            if (!selected.value) return;
                            setSettings({
                              ...settings,
                              endMonth: selected.value,
                            });
                          }}
                        />
                      </Col>
                    </Row>

                    <div className="d-flex justify-content-end mt-4">
                      <Button disabled={loading} type="submit">
                        Save
                      </Button>
                    </div>
                  </fieldset>
                </Form.Group>

                <fieldset className="html-fieldset my-4">
                  <legend className="html-legend fw-bold">
                    Tax Rates As Per Monthly Computation:
                  </legend>

                  <Form.Group>
                    <Form.Label>Exemption</Form.Label>
                    <Select
                      options={deductionSettingsOptions}
                      value={settings.exemptionDeductions}
                      onChange={(selected) => {
                        setSettings({
                          ...settings,
                          exemptionDeductions: selected,
                        });
                      }}
                      isMulti
                      isLoading={loading}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>
                      Tax Rates as Per Monthly Computation
                    </Form.Label>
                    <div className="row">
                      {settings.taxRateAsPerMonthlyComputation.map((el, i) => (
                        <div className="col-md-5" key={i}>
                          <div className="d-flex mb-3">
                            <span
                              className="position-absolute top-0 start-100 translate-middle badge text-white rounded bg-danger p-cursor"
                              onClick={() =>
                                setSettings((oldata) => {
                                  return {
                                    ...oldata,
                                    taxRateAsPerMonthlyComputation:
                                      oldata.taxRateAsPerMonthlyComputation.filter(
                                        (el, index) => index !== i
                                      ),
                                  };
                                })
                              }
                            >
                              X
                            </span>
                            <input
                              type="number"
                              className="input-group-text px-2"
                              placeholder="Percentage (%)"
                              aria-label="ratePercentage"
                              aria-describedby="ratePercentage"
                              name="ratePercentage"
                              value={el?.ratePercentage}
                              onChange={(e) => {
                                const { name: eventName, value: eventValue } =
                                  e.target;

                                setSettings((oldata) => {
                                  return {
                                    ...oldata,
                                    taxRateAsPerMonthlyComputation:
                                      oldata.taxRateAsPerMonthlyComputation.map(
                                        (el, index) => {
                                          if (i !== index) {
                                            return el;
                                          } else {
                                            return {
                                              ...el,
                                              [eventName]: eventValue,
                                            };
                                          }
                                        }
                                      ),
                                  };
                                });
                              }}
                            />
                            <CurrencyInput
                              className="input-group-text bg-white"
                              placeholder="Amount"
                              aria-label="rateAmount"
                              aria-describedby="rateAmount"
                              name="rateAmount"
                              value={el?.rateAmount}
                              onValueChange={(value, name) =>
                                setSettings((oldata) => {
                                  return {
                                    ...oldata,
                                    taxRateAsPerMonthlyComputation:
                                      oldata.taxRateAsPerMonthlyComputation.map(
                                        (el, index) => {
                                          if (i !== index) {
                                            return el;
                                          } else {
                                            return {
                                              ...el,
                                              [name]: value,
                                            };
                                          }
                                        }
                                      ),
                                  };
                                })
                              }
                            />
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className="d-flex justify-content-end">
                      <button
                        className="btn btn-secondary"
                        onClick={() =>
                          setSettings((oldata) => {
                            return {
                              ...oldata,
                              taxRateAsPerMonthlyComputation: [
                                ...oldata.taxRateAsPerMonthlyComputation,
                                defaultSettings
                                  .taxRateAsPerMonthlyComputation[0],
                              ],
                            };
                          })
                        }
                        type="button"
                      >
                        Add More+
                      </button>
                    </div>
                  </Form.Group>

                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Gross for CRA Purpose (%)</Form.Label>
                        <Form.Control
                          type="number"
                          name={"grossForCraPurposePercentage"}
                          value={settings.grossForCraPurposePercentage}
                          onChange={(e) => {
                            const { name: eventName, value: eventValue } =
                              e.target;
                            setSettings((oldata) => ({
                              ...oldata,
                              [eventName]: eventValue,
                            }));
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label>Personal Allowance</Form.Label>
                        <CurrencyInput
                          className="form-control"
                          name={"personalAllowance"}
                          value={settings?.personalAllowance}
                          onValueChange={(value, name) => {
                            setSettings((oldata) => ({
                              ...oldata,
                              [name]: value ? value : "",
                            }));
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <div className="d-flex justify-content-end mt-4">
                    <Button disabled={loading} type="submit">
                      Save
                    </Button>
                  </div>
                </fieldset>
              </Form>
            </div>

            {/*  */}
            <fieldset className="html-fieldset my-4">
              <legend className="html-legend fw-bold">
                Proration Settings:
              </legend>
              <div className="p-3 pt-0">
                <div className="row">
                  <div className="col-sm-3">
                    <label for="Prorated">Prorated By: </label>
                    <select
                      className="form-control"
                      aria-label="Default select example"
                      name="payType"
                      value={paySetting.payType}
                      onChange={(e) =>
                        setPaySetting({
                          ...paySetting,
                          [e.target.name]: e.target.value,
                        })
                      }
                    >
                      {["Net Pay", "Gross Pay"].map((a, i) => (
                        <option key={i} value={a}>
                          {a}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label>Custom Working Days</label>
                      <input
                        onChange={(e) =>
                          setPaySetting({
                            ...paySetting,
                            [e.target.name]: e.target.value,
                          })
                        }
                        className="form-control"
                        name="workingDays"
                        value={paySetting.workingDays || ""}
                        type="number"
                      />
                    </div>
                  </div>

                  <div className="col-sm-3">
                    <div className="form-group">
                      <label>Use Custom Working Days</label>
                      <div
                        className="onoffswitch ml-0"
                        onClick={(e) => {
                          setPaySetting({
                            ...paySetting,
                            customWorkingDays: !paySetting.customWorkingDays,
                          });
                        }}
                      >
                        <input
                          type="checkbox"
                          name="onoffswitch"
                          className="onoffswitch-checkbox"
                          checked={paySetting.customWorkingDays}
                        />
                        <label className="onoffswitch-label">
                          <span className="onoffswitch-inner"></span>
                          <span className="onoffswitch-switch"></span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-3">
                    <div className="form-group">
                      <label>Show Extras</label>
                      <div
                        className="onoffswitch ml-0"
                        onClick={(e) => {
                          setPaySetting({
                            ...paySetting,
                            extras: !paySetting.extras,
                          });
                        }}
                      >
                        <input
                          type="checkbox"
                          name="onoffswitch"
                          className="onoffswitch-checkbox"
                          checked={paySetting.extras}
                        />
                        <label className="onoffswitch-label">
                          <span className="onoffswitch-inner"></span>
                          <span className="onoffswitch-switch"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <Button
                    className="d-flex justify-content-end mt-4"
                    onClick={savePaySetting}
                    type="submit"
                  >
                    {btnText}
                  </Button>
                </div>
              </div>
            </fieldset>

            {/*  */}
            <fieldset className="html-fieldset my-4">
              <legend className="html-legend fw-bold">
                Set Percentage for salary:
              </legend>
              <div className="p-3 pt-0">
                <div className="text-center my-4 mt-0 ">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="input-group mt-2 mb-4">
                        <label className="input-group-text">Calculate By</label>
                        <select
                          className="form-control"
                          aria-label="Default select example"
                          value={typeOfSalary}
                          onChange={(e) => setTypeOfSalary(e.target.value)}
                        >
                          <option value=""> -- Select -- </option>
                          <option value="Percentage"> Use Percentage </option>
                          <option value="Fixed"> Use Fixed </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-3">
                    <div className="input-group mb-3">
                      <span className="input-group-text" id="basic">
                        Basic
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="basic"
                        aria-label="basic"
                        aria-describedby="basic"
                        name="basic"
                        value={salarySettings.basic}
                        onChange={onSalarySettings}
                      />
                    </div>
                  </div>
                  {/*  */}
                  <div className="col-md-3">
                    <div className="input-group mb-3">
                      <span className="input-group-text" id="housing">
                        Housing
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="housing"
                        aria-label="housing"
                        aria-describedby="housing"
                        name="housing"
                        value={salarySettings.housing}
                        onChange={onSalarySettings}
                      />
                    </div>
                  </div>
                  {/*  */}
                  <div className="col-md-3">
                    <div className="input-group mb-3">
                      <span className="input-group-text" id="medical">
                        Medical
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="medical"
                        aria-label="medical"
                        aria-describedby="medical"
                        name="medical"
                        value={salarySettings.medical}
                        onChange={onSalarySettings}
                      />
                    </div>
                  </div>
                  {/*  */}
                  <div className="col-md-3">
                    <div className="input-group mb-3">
                      <span className="input-group-text" id="transport">
                        Transport
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="transport"
                        aria-label="transport"
                        aria-describedby="transport"
                        name="transport"
                        value={salarySettings.transport}
                        onChange={onSalarySettings}
                      />
                    </div>
                  </div>
                  {/*  */}
                  <div className="col-md-3">
                    <div className="input-group mb-3">
                      <span className="input-group-text" id="utility">
                        Utility
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="utility"
                        aria-label="utility"
                        aria-describedby="utility"
                        name="utility"
                        value={salarySettings.utility}
                        onChange={onSalarySettings}
                      />
                    </div>
                  </div>
                  {/*  */}
                  <div className="col-md-3">
                    <div className="input-group mb-3">
                      <span className="input-group-text" id="dressing">
                        Dressing
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="dressing"
                        aria-label="dressing"
                        aria-describedby="dressing"
                        name="dressing"
                        value={salarySettings.dressing}
                        onChange={onSalarySettings}
                      />
                    </div>
                  </div>
                  {/*  */}
                  <div className="col-md-3">
                    <div className="input-group mb-3">
                      <span className="input-group-text" id="entertainment">
                        Entertainment
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="entertainment"
                        aria-label="entertainment"
                        aria-describedby="entertainment"
                        name="entertainment"
                        value={salarySettings.entertainment}
                        onChange={onSalarySettings}
                      />
                    </div>
                  </div>

                  {getNewSalary?.map((el, i) => (
                    <div className="col-md-3" key={i}>
                      <div className="input-group mb-3 position-relative">
                        <span
                          className="position-absolute top-0 start-100 translate-middle badge text-white rounded bg-danger p-cursor"
                          onClick={() => removeOldAdded(i)}
                        >
                          X
                        </span>
                        <span className="input-group-text" id={el?.name}>
                          {el?.name}
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          placeholder={el?.name}
                          aria-label={el?.name}
                          aria-describedby={el?.name}
                          name={el?.name}
                          value={el?.percentage}
                          onChange={(e) =>
                            onChangeOldAdded(el?.name, e.target.value)
                          }
                        />
                      </div>
                    </div>
                  ))}
                </div>

                <div className="row">
                  {addNewSalary.map((el, i) => (
                    <div className="col-md-4" key={i}>
                      <div className="d-flex mb-3">
                        <span
                          className="position-absolute top-0 start-100 translate-middle badge text-white rounded bg-danger p-cursor"
                          onClick={() => removeNewOne(i)}
                        >
                          X
                        </span>
                        <input
                          type="text"
                          className="input-group-text"
                          placeholder="Enter name"
                          aria-label="name"
                          aria-describedby="name"
                          name="name"
                          value={el?.name}
                          onChange={(e) => onChangeNewOnes(e, i)}
                        />
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter percentage"
                          aria-label="percentage"
                          aria-describedby="percentage"
                          name="percentage"
                          value={el?.percentage}
                          onChange={(e) => onChangeNewOnes(e, i)}
                        />
                      </div>
                    </div>
                  ))}
                </div>

                <div className="d-flex justify-content-end">
                  <button
                    className="btn btn-secondary"
                    onClick={() =>
                      setAddNewSalary((e) =>
                        e.concat({ name: "", percentage: 0 })
                      )
                    }
                  >
                    Add More+
                  </button>
                </div>
                <div className="mt3">
                  <Button onClick={onSaveSalarySetting} type="submit">
                    {salarybtnText}
                  </Button>
                </div>
                {/*    <ImportPaylistFromExcel /> */}
              </div>
            </fieldset>

            {/* Attendance Settings */}
            <fieldset className="html-fieldset my-4">
              <legend className="html-legend fw-bold">
                Attendance Settings:
              </legend>
              <div className="p-3 pt-0">
                <div className="text-center my-3 mt-0"></div>
                <div className="fw-bold">
                  <p>Enter Percentage for deduction</p>
                </div>
                <div className="row">
                  {/*  */}

                  <div className="col-md-3">
                    <div className="input-group mb-3">
                      <span className="input-group-text" id="Lateness">
                        Lateness
                      </span>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="0"
                        aria-label="Lateness"
                        aria-describedby="Lateness"
                        name="Lateness"
                        value={attendanceSetting.Lateness}
                        onChange={onAttendanceSettings}
                      />
                    </div>
                  </div>
                  {/*  */}
                  <div className="col-md-3">
                    <div className="input-group mb-3">
                      <span className="input-group-text" id="NoSignOut">
                        No SignOut
                      </span>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="0"
                        aria-label="NoSignOut"
                        aria-describedby="NoSignOut"
                        name="NoSignOut"
                        value={attendanceSetting.NoSignOut}
                        onChange={onAttendanceSettings}
                      />
                    </div>
                  </div>
                  {/*  */}
                  <div className="col-md-3">
                    <div className="input-group mb-3">
                      <span className="input-group-text" id="Absent">
                        Absent
                      </span>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="0"
                        aria-label="Absent"
                        aria-describedby="Absent"
                        name="Absent"
                        value={attendanceSetting.Absent}
                        onChange={onAttendanceSettings}
                      />
                    </div>
                  </div>
                  {/*  */}
                  <div className="col-md-3">
                    <div className="input-group mb-3">
                      <span className="input-group-text" id="EarlyClosure">
                        Early Closure
                      </span>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="0"
                        aria-label="EarlyClosure"
                        aria-describedby="EarlyClosure"
                        name="EarlyClosure"
                        value={attendanceSetting.EarlyClosure}
                        onChange={onAttendanceSettings}
                      />
                    </div>
                  </div>
                </div>

                <div className="my-3">
                  <div className="fw-bold">
                    <p>Enter Clocking</p>
                  </div>
                  <div className="row">
                    {/*  */}
                    <div className="col-md-6">
                      <div className="input-group mb-3 d-flex">
                        <span className="input-group-text" id="resumeHour">
                          Resumption Time
                        </span>

                        <TimePicker
                          ok={(time) => selectTime(time, "resumeTime")}
                          className="time-format"
                          value={attendanceSetting.resumeTime}
                        />
                      </div>
                    </div>
                    {/*  */}
                    <div className="col-md-6">
                      <div className="input-group mb-3">
                        <span className="input-group-text" id="closingHour">
                          Closing Time
                        </span>

                        <TimePicker
                          ok={(time) => selectTime(time, "closingTime")}
                          className="time-format"
                          value={attendanceSetting.closingTime}
                        />
                      </div>
                    </div>
                    {/*  */}
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <label className="fw-bold">Deducted By: </label>
                      <select
                        className="form-control"
                        aria-label="Default select example"
                        name="deductType"
                        value={attendanceSetting.deductType}
                        onChange={onAttendanceSettings}
                      >
                        {["", "Net Pay", "Gross Pay"].map((a, i) => (
                          <option key={i} value={a}>
                            {a}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {/*  */}
                </div>
                <div>
                  <Button onClick={saveAttendance} type="submit">
                    {attenbtnText}
                  </Button>
                </div>
                {/*    <ImportPaylistFromExcel /> */}
              </div>
            </fieldset>
            {/* Attendance Settings */}

            {/* Leave Settings */}
            <fieldset className="html-fieldset my-4">
              <legend className="html-legend fw-bold">Leave Settings:</legend>
              <div className="p-3 pt-0">
                <div className="row">
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label>Exclude Weekends</label>
                      <div
                        className="onoffswitch ml-0"
                        onClick={(e) => {
                          setLeaveSetting({
                            ...leaveSetting,
                            useLeaveWorkingDays:
                              !leaveSetting.useLeaveWorkingDays,
                          });
                        }}
                      >
                        <input
                          type="checkbox"
                          name="onoffswitch"
                          className="onoffswitch-checkbox"
                          checked={leaveSetting.useLeaveWorkingDays}
                        />
                        <label className="onoffswitch-label">
                          <span className="onoffswitch-inner"></span>
                          <span className="onoffswitch-switch"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* applyAllowanceImmediately */}
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label>Apply Allowance Immediately</label>
                      <div
                        className="onoffswitch ml-0"
                        onClick={(e) => {
                          setLeaveSetting({
                            ...leaveSetting,
                            applyAllowanceImmediately:
                              !leaveSetting.applyAllowanceImmediately,
                          });
                        }}
                      >
                        <input
                          type="checkbox"
                          name="onoffswitch"
                          className="onoffswitch-checkbox"
                          checked={leaveSetting.applyAllowanceImmediately}
                        />
                        <label className="onoffswitch-label">
                          <span className="onoffswitch-inner"></span>
                          <span className="onoffswitch-switch"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <Button
                    className="d-flex justify-content-end mt-4"
                    onClick={saveLeaveSettings}
                    type="submit"
                  >
                    save Leave Setting
                  </Button>
                </div>
              </div>
            </fieldset>

            {/* Leave Settings */}

            {/*<!-- /Content End -->*/}
          </div>
          {/*<!-- /Page Content -->*/}
        </div>
        {/*<!-- /Page Wrapper -->*/}
      </div>
      {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
      <div className="sidebar-overlay" data-reff=""></div>
      <ModalLoader show={loading} />
    </body>
  );
}

const mapStateToProps = (state) => {
  return {
    User: state.api.User,
    settings: state.settingsReducer.settings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addAllSettings: (settings) => dispatch(addAllSettings(settings)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PayrollSettings));
