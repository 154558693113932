/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { Dropdown, Form } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import AddEmployeeModal from "./AddEmployeeModal";
import EditEmployeeModal from "./EditEmployeeModal";
import Employee from "./Employee";
import { URLS } from "../urls";
// import PieChartModel from "./PieChartModel";
import { fetchActionsUtil, stringifyQueryString, useCookie } from "../../utils";
import ModalLoader from "../ModalLoader";
import { AddTerminationModal } from "../termination/AddTerminationModal";
import { EditEmployeeSalaryModal } from "./EditEmployeeSalaryModal";
import {
  DownloadIcon,
  GridIcon,
  ImportIcon,
  ListIcon,
  SearchIcon,
} from "../icons";
import { EmployeeExports } from "./EmployeeExports";
import EmployeeTable from "./EmployeeTable";
import DeleteEmployee from "./DeleteEmployee";
import { toast } from "react-toastify";
import { useDebounceQueryString, useQueryString } from "../UseQueryStrings";
import CustomPagination from "../Paginations";
import { ImportEmployees } from "./ImportEmployees";
import AllEmployeeTable from "./AllEmployeeTable";
import ChangeUserPassword from "./ChangePassword";
import { AddMultipleBranchToUser } from "./AddMultipleBranchToUser";
import { AddUserToBranches } from "./AddUserToBranches";
import { UseQueryData } from "../UseQueryData";

const Employees = (props) => {
  const initialQueryParams = {
    limit: 20,
    page: 1,
    id: "",
    name: "",
    department: "",
    sortBy: "id",
    sortOrder: "asc",
  };

  const branch_id = useCookie("branch_id");

  const token = "dummy token";

  const { queryParams, theQueryParams, onChangeInput, setQueryParams } =
    UseQueryData({
      initialQueryParams,
      timer: 1500,
    });

  const [openDelete, setOpenDelete] = useState(false);
  const [openPassword, setOpenPassword] = useState(false);
  const [openAddUserToBranches, setOpenAddUserToBranches] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tableMode, seTableMode] = useState(true);
  const [showSalary, setShowSalary] = useState(false);
  const [returnParams, setReturnParams] = useState({
    count: 0,
    endIndex: 0,
    startIndex: 0,
  });
  const [showEdit, setShowEdit] = useState(false);
  const [showDisplay, setShowDisplay] = useState("Summary");
  const [state, setState] = useState({
    employees: [], //update this whenever you add/update employee in backend.
    editEmployee: "", // id or index of the employee which is to be edited
    editUser: "",
    deleteEmployee: "",
    searchEmployeeList: [],
    departments: [],
    employeesStatistics: {
      confirmed: 0,
      trainee: 0,
      probation: 0,
      internship: 0,
      gender: {
        Male: 0,
        Female: 0,
        Others: 0,
      },
    },
    addEmployee: false,
    openEditSalary: false,
    openTermination: false,
    employeeUserId: "",
    fullName: "",
    employee_id: "",
    jobgradeid: "",
  });

  const [openImport, setOpenImport] = useState(false);
  const [openAddMultipleBranch, setOpenAddMultipleBranch] = useState(false);

  const onEditEmployee = (_id, userId) => {
    setState((el) => ({ ...el, editEmployee: _id, editUser: userId }));
  };

  const deleteEmployee = (_id) => {
    setState((el) => ({ ...el, deleteEmployee: _id }));
  };

  const getEditEmployeeDataFromId = (id) => {
    const { employees } = state;
    let employee = {};
    if (employees && employees.length)
      employees.forEach((e) => {
        if (e && e._id === id) {
          employee = e;
          // employee.jobInfo.grade =
          //   employee.jobInfo.grade && employee.jobInfo.grade._id;
          // employee.jobInfo.workLocation =
          //   employee.jobInfo.workLocation && employee.jobInfo.workLocation._id;
          // employee.jobInfo.department =
          //   employee.jobInfo.department && employee.jobInfo.department._id;
        }
      });
    return employee;
  };

  const getEditUserDataFromId = (id) => {
    const { users } = state;
    let user = {};
    if (users && users.length) {
      user = users.find((user) => user._id === id);
    }
    return user;
  };

  const updateEmployeesList = (employee) => {
    let { employees } = state;

    const id = employee && employee._id;
    let isExists = false;
    for (let index = employees.length - 1; index >= 0; --index) {
      if (employees[index]._id === id) {
        employees.splice(index, 1);
        employees.splice(index, 0, employee);
        isExists = true;
        break;
      }
    }
    if (isExists) {
      setState((el) => ({
        ...el,
        employees: employees,
        searchEmployeeList: employees,
      }));
    } else {
      const updatedEmployees = employees.concat(employee);
      setState((el) => ({
        ...el,
        employees: updatedEmployees,
        searchEmployeeList: updatedEmployees,
      }));
    }
  };

  const componentWillMount = async () => {
    try {
      setIsLoading(true);

      const { employeesStatistics } = state;

      const userData = fetchActionsUtil(
        `${URLS.backendUsers}/getAllUsers/${branch_id}`,
        "POST",
        token
      );
      const employeeData = fetchActionsUtil(
        `${URLS.backendEmployees}/get/${branch_id}?${stringifyQueryString(
          queryParams
        )}`,
        "GET",
        token
      );
      const departmentData = fetchActionsUtil(
        `${URLS.backendDepartments}/get/${branch_id}`,
        "GET",
        token
      );

      const [departmentResult, userResult, employeeResult] = await Promise.all([
        departmentData,
        userData,
        employeeData,
      ]);

      const changeEmployeesStatistics = {
        confirmed: 0,
        trainee: 0,
        probation: 0,
        internship: 0,
        gender: {
          Male: 0,
          Female: 0,
          Others: 0,
        },
      };
      if (employeeResult?.allEmployee?.length > 0) {
        setState((el) => ({
          ...el,
          employeesStatistics,
        }));
        employeeResult.allEmployee.forEach((employee) => {
          if (employee?.jobInfo?.status === "Confirmed")
            changeEmployeesStatistics.confirmed += 1;
          else if (employee?.jobInfo?.status === "Trainee")
            changeEmployeesStatistics.trainee += 1;
          else if (employee?.jobInfo?.status === "Probation")
            changeEmployeesStatistics.probation += 1;
          else if (employee?.jobInfo?.status === "Internship")
            changeEmployeesStatistics.internship += 1;

          if (employee?.personalInfo?.gender)
            changeEmployeesStatistics.gender[
              employee?.personalInfo?.gender
            ] += 1;
        });
      }

      setState((el) => ({
        ...el,
        users: userResult.users,
        employees: employeeResult.employees,
        searchEmployeeList: employeeResult.employees,
        departments: departmentResult.departments,
        employeesStatistics: changeEmployeesStatistics,
      }));
      setReturnParams({
        count: employeeResult.count,
        endIndex: employeeResult.endIndex,
        startIndex: employeeResult.startIndex,
      });
    } catch (error) {
      console.log(error);
      toast.error("Fetching Error !!!");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      componentWillMount();
    }

    return () => {
      isMounted = false;
    };
  }, [branch_id, queryParams]);

  const handleDeleteEmployee = () => {
    const employeeId = state.deleteEmployee;
    const token = "dummy token";

    fetch(`${URLS.backendEmployees}/delete`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ employeeId }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result?.success) {
          toast.success("Deleted Successfully", {
            position: "top-right",
          });
          componentWillMount();
        }
      });

    setOpenDelete(false);
  };

  const OnFullName = (employee) => {
    const name =
      employee?.personalInfo?.name?.first +
      " " +
      employee?.personalInfo?.name?.last;
    setState((el) => ({ ...el, fullName: name }));
  };

  const searchEmployee = async () => {
    try {
      setIsLoading(true);

      const employeeData = await fetchActionsUtil(
        `${URLS.backendEmployees}/get/${branch_id}?${stringifyQueryString(
          queryParams
        )}`,
        "GET",
        token
      );

      setState((el) => ({
        ...el,
        employees: employeeData.employees,
        searchEmployeeList: employeeData.employees,
      }));

      setReturnParams({
        count: employeeData.count,
        endIndex: employeeData.endIndex,
        startIndex: employeeData.startIndex,
      });
    } catch (error) {
      console.log(error);
      toast.error("Fetching Error !!!");
    } finally {
      setIsLoading(false);
    }
  };

  const returnDropDownItems = () => {
    const dropsItems = [
      {
        action: ({ employee }) => {
          setShowEdit(true);
          onEditEmployee(employee._id, employee.userId);
        },
        icon: <i className="fa fa-pencil m-r-5"></i>,
        name: "Edit",
      },
      {
        action: ({ employee }) => {
          setOpenPassword(true);
          onEditEmployee(employee._id, employee.userId);
        },
        icon: <i className="fa fa-unlock-alt m-r-5"></i>,
        name: "Change Password",
      },
      {
        action: ({ employee }) => {
          setOpenAddUserToBranches(true);
          onEditEmployee(employee._id, employee.userId);
        },
        icon: <i className="fa fa-pencil-square m-r-5"></i>,
        name: "Allow attendance approval",
      },
      {
        action: ({ employee }) => {
          setOpenDelete(true);
          deleteEmployee(employee._id);
        },
        icon: <i className="fa fa-trash-o m-r-5"></i>,
        name: "Delete",
      },
      {
        action: ({ employee }) => {
          setState((el) => ({
            ...el,
            openEditSalary: true,
            employee_id: employee?._id,
            jobgradeid: employee.jobInfo?.grade?._id,
          }));
          OnFullName(employee);
        },
        icon: <i className="fa fa-money m-r-5"></i>,
        name: "Edit Salary",
      },
      {
        action: ({ employee }) => {
          setState((el) => ({
            ...el,
            employeeUserId: employee.userId,
            openTermination: true,
          }));

          OnFullName(employee);
        },
        icon: <i className="fa fa-minus-circle m-r-5"></i>,
        name: "Terminate",
      },
    ];

    return dropsItems;
  };

  const DropDowns = ({ action, icon, name }) => {
    return (
      <Dropdown.Item
        as="div"
        className="p-cursor"
        onClick={() => {
          action();
        }}
      >
        {icon} {name}
      </Dropdown.Item>
    );
  };

  const dropDownFunction = (qty) => {
    setQueryParams((el) => ({ ...el, limit: qty }));
  };

  const {
    editEmployee,
    editUser,
    searchEmployeeList,
    departments,
    employeesStatistics,
    openEditSalary,
    openTermination,
    employeeUserId,
    fullName,
    employee_id,
    jobgradeid,
  } = state;
  const editEmployeeData = getEditEmployeeDataFromId(editEmployee);
  const editUserData = getEditUserDataFromId(editUser);

  return (
    <body>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex, nofollow" />
        <title>InvexERP</title>
      </Helmet>
      <div className="main-wrapper">
        <Topbar />
        <Sidebar />
        {/*<!-- Page Wrapper -->*/}
        <div className="page-wrapper">
          {/*<!-- Page Content -->*/}
          <div>
            {/*<!-- Page Title -->*/}

            <div className="add_vacancy  mb-3 d-flex border bg-white align-items-center justify-content-between">
              <div className="w-100 d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column container ">
                  <h3 className="mt-1">Employee</h3>
                  <p className="pb-0 secondary-text">
                    All Employee status and dates
                  </p>
                </div>

                <div className="d-flex justify-content-end align-items-baseline w-100 mr-3">
                  <div className="d-flex align-items-center">
                    <div
                      className={`mx-2 p-cursor ${
                        tableMode
                          ? "text-ash-main"
                          : "blue-color text-primary-main"
                      }`}
                      onClick={() => seTableMode(false)}
                    >
                      <GridIcon color={tableMode ? null : "#2463ae"} />{" "}
                      <b>Grid</b>
                    </div>
                    <div
                      className={`mx-2 p-cursor ${
                        tableMode
                          ? "blue-color text-primary-main"
                          : "text-ash-main"
                      }`}
                      onClick={() => seTableMode(true)}
                    >
                      <ListIcon color={tableMode ? "#2463ae" : null} />{" "}
                      <b>List</b>
                    </div>
                  </div>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant=""
                      className="btn secondary"
                      bsPrefix=""
                    >
                      Template <DownloadIcon />
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      popperConfig={{
                        strategy: "fixed",
                      }}
                      renderOnMount
                      className="text-center"
                    >
                      <Dropdown.Item as="div">
                        <a
                          href={`/excel_templates/template_employees.xlsx`}
                          target="blank"
                          title="Download"
                          download=""
                        >
                          Template to import employees
                        </a>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  <div className="mx-3">
                    <EmployeeExports
                      showSalary={showSalary}
                      queryParams={queryParams}
                    />
                  </div>

                  <div>
                    <label
                      className="btn btn-outline-primary w-100 p-cursor"
                      onClick={() => setOpenImport(true)}
                    >
                      <ImportIcon />
                      <span style={{ color: "#2463AE" }}>Import</span>
                    </label>
                  </div>

                  <button
                    className="btn add-btn mx-3"
                    onClick={() =>
                      setState((el) => ({ ...el, addEmployee: true }))
                    }
                  >
                    <i className="fa fa-plus"></i>Add Employee
                  </button>
                </div>
              </div>
            </div>

            <div className="my-4">
              <div className="d-flex justify-content-start mx-4">
                <div>
                  <Link to={`${URLS.dashboard}`}>
                    <b>Dashboard</b>
                  </Link>
                </div>
                <span className="mx-2">/</span>
                <div>
                  <p className="text-muted">Employees</p>
                </div>
              </div>
            </div>

            {/*<!-- /Page Title -->*/}
            {/*<!-- Leave Statistics -->*/}
            <section className="stats mx-4">
              <div className="stat">
                <div className="icon icon-1">
                  <i class="fa fa-cubes"></i>
                </div>
                <div className="details">
                  <p>{employeesStatistics?.confirmed}</p>
                  <span className="dashboard-links">Confirmed </span>
                </div>
              </div>

              <div className="stat">
                <div className="icon icon-3">
                  <i class="fa fa-cubes"></i>
                </div>
                <div className="details">
                  <p>{employeesStatistics?.trainee}</p>
                  <span className="dashboard-links">Trainee </span>
                </div>
              </div>

              <div className="stat">
                <div className="icon">
                  <i class="fa fa-cubes"></i>
                </div>
                <div className="details">
                  <p>{employeesStatistics?.probation}</p>
                  <span className="dashboard-links">Probation</span>
                </div>
              </div>

              <div className="stat">
                <div className="icon">
                  <i class="fa fa-cubes"></i>
                </div>
                <div className="details">
                  <p>{employeesStatistics?.internship}</p>
                  <span className="dashboard-links">Internship</span>
                </div>
              </div>
            </section>
            {/*<!-- /Leave Statistics -->*/}

            {/*<!-- Search Filter -->*/}
            <div className="row m-3 mb-0">
              <div className="col-sm-6 col-md-4">
                <div className="search-area">
                  <SearchIcon color="#94A3B8" />
                  <Form.Control
                    placeholder="Search employee ID"
                    aria-label="Search employee ID"
                    value={theQueryParams.id}
                    name="id"
                    onChange={onChangeInput}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="search-area">
                  <SearchIcon color="#94A3B8" />
                  <Form.Control
                    placeholder="Search employee name"
                    aria-label="Search employee name"
                    value={theQueryParams.name}
                    onChange={onChangeInput}
                    name="name"
                  />
                </div>
              </div>
              <div className="col-sm-6 col-md-3">
                <Form.Control
                  as="select"
                  value={theQueryParams.department}
                  onChange={onChangeInput}
                  name="department"
                >
                  <option value="">Select Department</option>
                  {departments && departments.length
                    ? departments.map((department) => {
                        return (
                          <option value={department._id}>
                            {department.name}
                          </option>
                        );
                      })
                    : null}
                </Form.Control>
              </div>
              <button
                className="btn btn-primary col-sm-6 col-md-1"
                onClick={searchEmployee}
              >
                Search
              </button>
            </div>

            <div className="mx-4 w-100">
              <div className="d-flex justify-content-between align-items-baseline">
                <div className="d-flex w-100">
                  <div>
                    <label>Sort By : </label>
                    <Form.Control
                      as="select"
                      value={theQueryParams.sortBy}
                      onChange={onChangeInput}
                      name="sortBy"
                    >
                      <option value="">Sort By</option>
                      {[
                        { value: "id", label: "Employee ID" },
                        { value: "first", label: "First Name" },
                        { value: "last", label: "Last Name" },
                      ].map((sort) => {
                        return <option value={sort.value}>{sort.label}</option>;
                      })}
                    </Form.Control>
                  </div>

                  <div className="mx-4">
                    <label>Order By : </label>
                    <Form.Control
                      as="select"
                      value={theQueryParams.sortOrder}
                      onChange={onChangeInput}
                      name="sortOrder"
                    >
                      <option value="">Order By</option>
                      {[
                        { value: "asc", label: "Ascending Order" },
                        { value: "desc", label: "Descending Order" },
                      ].map((sort) => {
                        return <option value={sort.value}>{sort.label}</option>;
                      })}
                    </Form.Control>
                  </div>
                  <div className="mx-4">
                    <label>Report Type: </label>
                    <Form.Control
                      as="select"
                      value={showDisplay}
                      onChange={(e) => setShowDisplay(e.target.value)}
                      name="showDisplay"
                    >
                      {[
                        { value: "Summary", label: "Summary" },
                        { value: "Full", label: "Full" },
                      ].map((display) => {
                        return (
                          <option value={display.value}>{display.label}</option>
                        );
                      })}
                    </Form.Control>
                  </div>
                </div>

                <div className="d-flex justify-content-end  m-4 w-100">
                  {/* <div className="">
                    <label>{null}</label>
                    <div>
                      <button
                        className="btn btn-primary"
                        onClick={() => setOpenAddMultipleBranch(true)}
                      >
                        Add multiple branch to user
                      </button>
                    </div>
                  </div> */}
                  {tableMode ? (
                    <div className="mx-4">
                      <label>Show Salary</label>
                      <div
                        className="onoffswitch ml-0"
                        onClick={(e) => {
                          setShowSalary(!showSalary);
                        }}
                      >
                        <input
                          type="checkbox"
                          name="onoffswitch"
                          className="onoffswitch-checkbox"
                          checked={showSalary}
                        />
                        <label className="onoffswitch-label">
                          <span className="onoffswitch-inner"></span>
                          <span className="onoffswitch-switch"></span>
                        </label>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            {searchEmployeeList?.length > 0 ? (
              <div>
                {tableMode ? (
                  <div className="m-4">
                    {showDisplay === "Summary" ? (
                      <EmployeeTable
                        showSalary={showSalary}
                        searchEmployeeList={searchEmployeeList}
                        startIndex={returnParams.startIndex}
                        returnDropDownItems={returnDropDownItems}
                        DropDowns={DropDowns}
                      />
                    ) : (
                      <AllEmployeeTable
                        showSalary={showSalary}
                        searchEmployeeList={searchEmployeeList}
                        startIndex={returnParams.startIndex}
                        returnDropDownItems={returnDropDownItems}
                        DropDowns={DropDowns}
                      />
                    )}
                  </div>
                ) : (
                  <div className="grid-4-1 m-4">
                    {searchEmployeeList?.map((employee) => (
                      <Employee
                        key={employee._id}
                        employee={employee}
                        returnDropDownItems={returnDropDownItems}
                        DropDowns={DropDowns}
                      />
                    ))}
                  </div>
                )}
              </div>
            ) : (
              <h3 className="text-center w-100">No employee found</h3>
            )}
            <div className="my-4">
              <CustomPagination
                setQueryParams={setQueryParams}
                startIndex={returnParams.startIndex}
                endIndex={returnParams.endIndex}
                count={returnParams.count}
                limit={queryParams.limit}
                page={queryParams.page}
                dropDownFunction={dropDownFunction}
              />
            </div>
          </div>
          {/*<!-- /Page Content -->*/}
          <ModalLoader show={isLoading} />
          {/*<!-- Add Employee Modal -->*/}
          {state?.addEmployee ? (
            <AddEmployeeModal
              mode="add"
              show={state.addEmployee}
              updateEmployeesList={updateEmployeesList}
              hindeIt={() => setState((el) => ({ ...el, addEmployee: false }))}
            />
          ) : null}
          {/*<!-- /Add Employee Modal -->*/}
          {/*<!-- Edit Employee Modal -->*/}
          {showEdit ? (
            <EditEmployeeModal
              showEdit={showEdit}
              employee={editEmployeeData}
              user={editUserData}
              editUser={editUser}
              updateEmployeesList={updateEmployeesList}
              employee_id={editEmployee}
              hindeIt={() => {
                setShowEdit(false);
              }}
            />
          ) : null}
          {openPassword ? (
            <ChangeUserPassword
              employee={editEmployeeData}
              show={openPassword}
              handleClose={setOpenPassword}
            />
          ) : null}
          {/*<!-- /Edit Employee Modal -->*/}
          {openTermination && (
            <AddTerminationModal
              show={openTermination}
              handleClose={() =>
                setState((el) => ({
                  ...el,
                  openTermination: false,
                }))
              }
              setReload={() => componentWillMount()}
              userId={employeeUserId}
            />
          )}
          {openEditSalary && (
            <EditEmployeeSalaryModal
              show={openEditSalary}
              handleClose={() =>
                setState((el) => ({ ...el, openEditSalary: false }))
              }
              user_id={employee_id}
              name={fullName}
              jobgradeid={jobgradeid}
            />
          )}
          {/*<!-- Delete Employee Modal -->*/}
          {openDelete && (
            <DeleteEmployee
              handleDeleteEmployee={handleDeleteEmployee}
              show={openDelete}
              handleClose={setOpenDelete}
            />
          )}
          {/*<!-- /Delete Employee Modal -->*/}
          {openImport && (
            <ImportEmployees
              show={openImport}
              handleClose={() => setOpenImport(false)}
              refresh={() => componentWillMount()}
            />
          )}
          {/*  */}
          {openAddMultipleBranch ? (
            <AddMultipleBranchToUser
              show={openAddMultipleBranch}
              handleClose={() => setOpenAddMultipleBranch(false)}
              refresh={() => componentWillMount()}
            />
          ) : null}

          {openAddUserToBranches ? (
            <AddUserToBranches
              show={openAddUserToBranches}
              handleClose={() => setOpenAddUserToBranches(false)}
              refresh={() => componentWillMount()}
              user_id={employee_id}
              name={fullName}
              employee={editEmployeeData}
            />
          ) : null}
        </div>
        {/*<!-- /Page Wrapper -->*/}
      </div>
      {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
      <div className="sidebar-overlay" data-reff=""></div>
    </body>
  );
};

export default Employees;
