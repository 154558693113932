import React from "react";
import { URLS } from "../urls";
import moment from "moment";
import DatePicker from "react-datetime";
import { Form, Col, Row, Image, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
export default class DeleteLeaveModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonText: "Delete",
      isWaiting: false,
      deleteLeave: null,
    };
  }

  static getDerivedStateFromProps(nextProps, nextState) {
    if (nextProps.deleteLeave !== nextState.deleteLeave) {
      return { deleteLeave: nextProps.deleteLeave };
    }
    return null;
  }

  deleteSelectedLeave = (e) => {
    e.preventDefault();
    this.setState({ buttonText: "Deleting ...", isWaiting: true });
    const { deleteLeave } = this.state;
    const token = "dummy token";
    fetch(`${URLS.backendApplyLeaves}/delete/${deleteLeave?._id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    }).then((res) => {
      if (res.ok) {
        res.json().then((result) => {
          setTimeout(() => {
            this.props.handleClose();
            this.setState({ buttonText: "Delete", isWaiting: false });
            this.props.removeLeaveFormAppliedLeavesList(result.applyLeave);
          }, 500);
          toast("Deleted Successfully.", {
            type: "success",
            position: "top-right",
          });
        });
      } else {
        res.json().then((responseJson) => {
          console.log(responseJson);
          toast.error("Error occurred, Check the form again.", {
            position: "top-right",
          });
          this.setState({ buttonText: "Delete", isWaiting: false });
        });
      }
    });
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="md"
      >
        <Modal.Body>
          <div className="form-header">
            <h3>Delete Leave</h3>
            <p>Are you sure want to delete this leave?</p>
          </div>
          <div className="modal-btn delete-action">
            <div className="row d-flex align-items-center justify-content-center">
              <div className="">
                <button
                  className="btn dlt-btn continue-btn"
                  onClick={this.deleteSelectedLeave}
                >
                  Delete
                </button>
              </div>
              <div className="">
                <button
                  onClick={this.props.handleClose}
                  className="text-white cncl-btn cancel-btn"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
