import React from "react";
import { Link } from "react-router-dom";
import { URLS } from "../urls";
import currency from "currency.js";
import { Dropdown, Table } from "react-bootstrap";

const AllEmployeeTable = ({
  showSalary,
  searchEmployeeList,
  startIndex,
  returnDropDownItems,
  DropDowns,
}) => {
  return (
    <div className="row">
      <div className="col-md-12">
        <Table responsive className="table-custom">
          <thead>
            <tr className="text-nowrap">
              <th></th>
              <th>No</th>
              <th className="text-left">Name</th>
              <th>EmployeeId</th>
              {showSalary && <th>Salary</th>}
              <th>Job Grade</th>
              <th>Email</th>
              <th>Mobile</th>
              <th>Join Date</th>
              <th>Start Date</th>
              <th>Personal Phone</th>
              <th>Personal Address</th>
              <th>Zip</th>
              <th>Personal Birthday</th>
              <th>Gender</th>
              <th>Government ID</th>
              <th>Government ID Type</th>
              <th>Admin</th>
              <th>Martial Status</th>
              <th>Previous Employer Name</th>
              <th>Previous Employer phone</th>
              <th>Confirm Date</th>
              <th>Department</th>
              <th>Salary Basis</th>
              <th>SFA ID</th>
              <th>Emergency Address</th>
              <th>Emergency Contact Name</th>
              <th>Emergency Contact Phone</th>
              <th>Guarantor Name</th>
              <th>Guarantor Phone</th>
              <th>Guarantor Address</th>
              <th>Relation</th>
              <th>Account Number</th>
              <th>Account Officer</th>
              <th>Account Type</th>
              <th>Bank Code</th>
              <th>Bank Name</th>
              <th>Pension Admin</th>
              <th>Pension Number</th>
            </tr>
          </thead>

          <tbody>
            {searchEmployeeList.map((employee, index) => {
              const displayImage =
                employee?.emergencyInfo && employee?.emergencyInfo?.image;

              const profileImage = displayImage
                ? URLS.backendStatic + "/" + displayImage
                : "/assets/img/profiles/avatar-03.jpg";
              return (
                <tr key={index}>
                  <td className="text-right">
                    <Dropdown>
                      <Dropdown.Toggle variant="" bsPrefix="">
                        <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                      </Dropdown.Toggle>

                      <Dropdown.Menu
                        popperConfig={{
                          strategy: "fixed",
                        }}
                        renderOnMount
                      >
                        {returnDropDownItems()?.map((el) => (
                          <DropDowns
                            action={() => el.action({ employee })}
                            icon={el.icon}
                            name={el.name}
                          />
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                  <td>{startIndex + index + 1}</td>
                  <td className="w-100">
                    <div className="d-flex justify-content-start align-items-center">
                      <div className="table-avatar blue-link">
                        <Link to={`profile/${employee._id}`} className="avatar">
                          <img alt="" src={profileImage} />
                        </Link>
                      </div>
                      <div className="table-avatar blue-link">
                        <h2 className="text-nowrap text-capitalize">
                          <Link to={`profile/${employee._id}`}>
                            {employee.personalInfo?.name?.first +
                              " " +
                              employee.personalInfo?.name?.last}
                          </Link>
                        </h2>
                      </div>
                      {employee?.onLeave ? (
                        <div className="mx-2">
                          <p className="dropdown-item open-active">
                            {employee.onLeave}
                          </p>
                        </div>
                      ) : null}
                    </div>
                  </td>
                  <td>{employee.jobInfo?.employeeId}</td>
                  {showSalary && (
                    <td>
                      {currency(employee.jobInfo?.salary || 0, {
                        symbol: "",
                      }).format()}
                    </td>
                  )}
                  <td>{employee.jobInfo?.grade?.name}</td>
                  <td>{employee.jobInfo?.email}</td>
                  <td>{employee.personalInfo?.phone?.home}</td>
                  <td>{employee.jobInfo?.joinDate}</td>
                  <td>{employee.jobInfo?.startDate}</td>
                  <td>{employee.personalInfo?.phone?.home}</td>
                  <td>
                    {employee.personalInfo?.address?.apartment}{" "}
                    {employee.personalInfo?.address?.street}{" "}
                    {employee.personalInfo?.address?.city}{" "}
                    {employee.personalInfo?.address?.state}{" "}
                  </td>
                  <td>{employee.personalInfo?.address?.zip} </td>
                  <td>{employee.personalInfo?.birth} </td>
                  <td>{employee.personalInfo?.gender} </td>
                  <td>{employee.personalInfo?.govtId} </td>
                  <td>{employee.personalInfo?.govtType} </td>
                  <td>{employee.personalInfo?.isAdmin ? "Yes" : "No"} </td>
                  <td>{employee.personalInfo?.martial} </td>
                  <td>{employee.personalInfo?.prevEmployee?.employee}</td>
                  <td>{employee.personalInfo?.prevEmployee?.phone}</td>
                  <td>{employee.jobInfo?.confirmDate}</td>
                  <td>{employee.jobInfo?.department?.name}</td>
                  <td>{employee.jobInfo?.salaryBasis}</td>
                  <td>{employee.jobInfo?.sfa_id}</td>
                  <td>
                    {employee.emergencyInfo?.address?.apartment}{" "}
                    {employee.emergencyInfo?.address?.street}{" "}
                    {employee.emergencyInfo?.address?.city}{" "}
                    {employee.emergencyInfo?.address?.state}{" "}
                  </td>
                  <td>
                    {employee.emergencyInfo?.name?.first}{" "}
                    {employee.emergencyInfo?.name?.last}
                  </td>
                  <td>
                    {employee.emergencyInfo?.phone?.primary}
                    {" , "}
                    {employee.emergencyInfo?.phone?.secondary}
                  </td>
                  <td>
                    {employee.emergencyInfo?.guarantor?.title}{" "}
                    {employee.emergencyInfo?.guarantor?.name}
                  </td>
                  <td>{employee.emergencyInfo?.guarantor?.phone} </td>
                  <td>{employee.emergencyInfo?.guarantor?.address} </td>
                  <td>{employee.emergencyInfo?.relation} </td>
                  <td>{employee.bankDetails?.accountNumber}</td>
                  <td>{employee.bankDetails?.accountOfficer}</td>
                  <td>{employee.bankDetails?.accountType}</td>
                  <td>{employee.bankDetails?.bankCode}</td>
                  <td>{employee.bankDetails?.bankName}</td>
                  <td>{employee.bankDetails?.pensionAdmin}</td>
                  <td>{employee.bankDetails?.pensionNumber}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default React.memo(AllEmployeeTable);
