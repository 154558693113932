import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import reload from "../../assets/img/Reload.svg";
import scrollup from "../../assets/img/scrollup.svg";
import { URLS } from "../urls";
import { fetchActionsUtil, useCookie } from "../../utils";

export {
  scrollup
}

export const RecentApplications = ({ data }) => {
  const [state, setState] = useState([]);

  const branch_id = useCookie("branch_id");

  const fetchDetails = async () => {
    const { recentApplications } = await fetchActionsUtil(
      `${URLS.backendApplications}/get-recent/${branch_id}`
    );

    setState(recentApplications);
  };

  useEffect(() => {
    setState(data);
  }, [data]);

  const statusClass = ({ status }) => {
    let detail = "";

    switch (status) {
      case "shortlisted":
        detail = "shortlisted";
        break;

      case "rejected":
        detail = "rejected";
        break;

      case "offered":
        detail = "offered";
        break;
      case "onboarding":
        detail = "onboarding";
        break;

      default:
        detail = "pending";
        break;
    }

    return detail;
  };

  const DisplayApplications = ({ status, name, phone, _id }) => {
    return (
      <div className="d-flex mt-3 flex-row applicants justify-content-between align-items-center pb-0">
        <div className="row-details">
          <div className="row-details_image_holder">
            <img
              src="/assets/img/profiles/avatar-03.jpg"
              alt={name}
              className="row-details_image"
            />
          </div>
          <div className="">
            <Link to={`${URLS.applicantProfile}/${_id}`}>
              <h4 className="other_det_xs pb-0">{name}</h4>
            </Link>

            <p className="pb-0 grey-xs">({phone})</p>
          </div>
        </div>
        <div className="applicant__positon">
          <div className={statusClass({ status })}>
            {status === "New" ? "pending" : status}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="recent-applications border h-auto rounded">
      <div className="top_header border-bottom ">
        <h4 className="mr-3 other_det-2 pb-0">Recent Applications</h4>
        <div>
          <img
            src={reload}
            alt="employee"
            className="reload-svg p-cursor"
            onClick={() => fetchDetails()}
          />
        </div>
      </div>
      <section className="border-bottom">
        <div className="section_wrapper">
          <div className="d-flex detail_box">
            <p className="pb-0">Name</p>
            <img src={scrollup} alt="employee" className="scroll-svg" />
          </div>
          <div className="">
            <p className="pb-0">Position</p>
          </div>
        </div>
      </section>
      {state?.length > 0 ? (
        <main>
          {state?.map((el) => (
            <DisplayApplications
              status={el?.status}
              name={el?.name}
              phone={el?.phone}
              _id={el?._id}
            />
          ))}
        </main>
      ) : null}
    </div>
  );
};
