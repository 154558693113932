import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import currency from "currency.js";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import { URLS } from "../urls";
import { Col, Form, Row } from "react-bootstrap";
import { Document, Page, pdfjs } from "react-pdf";
import DocumentModal from "../employees/DocumentModal";
import AddJobGradeModal from "../employees/AddJobGradeModal";
import AddLocationModal from "../employees/AddLocationModal";
import AddEmployeeHMOModal from "../employees/AddEmployeeHMOModal";
import AddLifeInsuranceModal from "../employees/AddLifeInsuranceModal";
import "../../assets/css/date-picker.css";
import Datepicker from "react-datetime";
import moment from "moment";
import EmployeeSkills from "../employees/EmployeeSkills";
import EmployeeTraining from "../employees/EmployeeTraining";
import Multiselect from "../employees/Multiselect";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import Project from "../projects/Project";
import EditProjectModal from "../projects/EditProjectModal";

import { toast } from "react-toastify";
import { useCookie } from "../../utils";
import CurrencyInput from "react-currency-input-field";
import EmpProfile from "./Emp-Profile";
import EmpProject from "./emp-Projects";
import DeleteModal from "../projects/DeleteModal";
import BankStatutory from "./bank-Statutory";
import Task from "./task";
import ProfileModal from "./ProfileModal";
import PersonalInfoModal from "./PersonalInfoModal";
import FamilyInfoModal from "./FamilyInforModal";
import EmergencyContactModal from "./EmergencyContactModal";
import EducationModal from "./EducationModal";
import ExperienceModal from "./ExperienceModal";
import LeaveHistory from "./leaveHistory";
import LoanHistory from "./LoanHistory";
import SalaryHistory from "./SalaryHistory";
import AllowanceHistory from "./AllowanceHistory";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Profile = (props) => {
  const branch_id = useCookie("branch_id");

  const [section, setSection] = useState("emp_profile");

  const [state, setState] = useState({
    show_add_job: false,
    show_add_location: false,
    show_add_employeeHMO: false,
    show_add_lifeInsurance: false,
    _id: null,
    displayImageSrc: null,
    displayResumeSrc: null,
    pdfPageNumber: 1,
    personalInfo: {
      name: {
        first: "",
        last: "",
        middle: "",
      },
      address: {
        apartment: "",
        street: "",
        city: "",
        state: "",
        zip: "",
      },
      phone: {
        home: "",
        alternate: "",
      },
      email: "",
      birth: "",
      govtId: "",
      martial: "",
      spouse: {
        name: "",
        employer: "",
        phone: "",
      },
      prevEmployee: {
        employee: "",
        phone: "",
      },
    },
    jobInfo: {
      title: "",
      employeeId: "",
      changeableId: "",
      grade: "",
      supervisor: [],
      department: "",
      workLocation: "",
      email: "",
      workPhone: "",
      cellPhone: "",
      joinDate: "",
      startDate: "",
      salary: "",
      status: "",
      salaryBasis: "",
      contract: "",
      contractType: "",
      workLocationBranch: "",
    },
    emergencyInfo: {
      name: {
        first: "",
        last: "",
        middle: "",
      },
      address: {
        apartment: "",
        street: "",
        city: "",
        state: "",
        zip: "",
      },
      phone: {
        primary: "",
        secondary: "",
      },
      relation: "",
      guarantor: {
        title: "",
        name: "",
        phone: "",
        address: "",
      },
      referee: {
        title: "",
        name: "",
        phone: "",
        address: "",
      },
      employeeHMO: null,
      lifeInsurance: null,
      image: "",
      resume: "",
      certificate: "",
    },
    educationInfo: [
      {
        institution: "",
        subject: "",
        startDate: "",
        completeDate: "",
        degree: "",
        grade: "",
        certificate: "",
      },
    ],
    jobGrades: [],
    contracts: [],
    locations: [],
    departments: [],
    employeeHMOs: [],
    lifeInsurances: [],
    educationCertificateFiles: [
      {
        file: null,
      },
    ],
    supervisors: [],
    projects: [],
    editProject: "",
    deleteProject: "",
    clientList: [],
    employeeList: [],
    projectTasks: [],
    collaborationUrl: process.env.REACT_APP_COLLABORATION_FRONTEND,
    typeOfSalary: "",
    branchesLocations: [],
  });

  const componentWillMount = () => {
    const id = window.location.pathname.split("/")[3];
    const token = "dummy token";

    fetch(`${URLS.backendEmployees}/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        //    console.log("result => ", result.employee?._id, result.employee);
        setState((el) => ({
          ...el,
          _id: result.employee?._id,
          personalInfo: {
            ...el?.personalInfo,
            ...result.employee?.personalInfo,
          },
          emergencyInfo: result?.employee?.emergencyInfo
            ? result?.employee?.emergencyInfo
            : el?.state?.emergencyInfo,
          educationInfo: result?.employee?.educationInfo,
        }));
        let jobInfoTemp = result?.employee?.jobInfo;

        if (jobInfoTemp) {
          jobInfoTemp.grade = jobInfoTemp?.grade && jobInfoTemp?.grade?._id;
          jobInfoTemp.workLocation =
            jobInfoTemp?.workLocation && jobInfoTemp?.workLocation?._id;
          jobInfoTemp.department =
            jobInfoTemp?.department && jobInfoTemp?.department?._id;
          jobInfoTemp.changeableId =
            jobInfoTemp.changeableId || jobInfoTemp.employeeId;

          setState((el) => ({
            ...el,
            jobInfo: jobInfoTemp,
          }));
        }

        const educationInfo = result?.employee?.educationInfo;
        const educationInfoLength = educationInfo?.length;
        let newEducationCertificateFiles = [];
        for (let index = 0; index < educationInfoLength; index++) {
          newEducationCertificateFiles.push({ file: null });
        }
        setState((el) => ({
          ...el,
          educationCertificateFiles: newEducationCertificateFiles,
        }));
      });
    fetch(`${URLS.backendJobGrades}/get`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setState((el) => ({
          ...el,
          jobGrades: result?.jobGrades || [],
        }));
      });

    fetch(`${URLS.backendContracts}/get`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setState((el) => ({
          ...el,
          contracts: result?.contracts || [],
        }));
      });

    fetch(`${URLS.backendDepartments}/get`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setState((el) => ({
          ...el,
          departments: result?.departments || [],
        }));
      });

    fetch(`${URLS.backendLocations}/get`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setState((el) => ({
          ...el,
          locations: result?.locations || [],
        }));
      });
    fetch(`${URLS.backendEmployeeHMOs}/get`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setState((el) => ({
          ...el,
          employeeHMOs: result?.employeeHMOs || [],
        }));
      });

    fetch(`${URLS.backendLifeInsurances}/get`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setState((el) => ({
          ...el,
          lifeInsurances: result?.lifeInsurances || [],
        }));
      });
    fetch(`${URLS.backendEmployees}/supervisors`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.supervisors) {
          setState((el) => ({
            ...el,
            supervisors: result?.supervisors || [],
          }));
        }
      });

    fetch(`${URLS.backendProjects}/getProjectsByEmployeeId/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        // console.log("result.projects", result.projects);
        setState((el) => ({
          ...el,
          projects: result?.projects || [],
        }));
      });
    fetch(`${URLS.backendClients}/get`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setState((el) => ({
          ...el,
          clientList: result?.clients || [],
        }));
      });

    fetch(`${URLS.backendEmployees}/get`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setState((el) => ({
          ...el,
          employeeList: result?.employees || [],
        }));
      });
    fetch(`${URLS.backendTasks}/tasks/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setState((el) => ({
          ...el,
          projectTasks: result?.tasks || [],
        }));
      });

    fetch(`${URLS.backendsalarySetting}/get/${branch_id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        const data = result?.jobGrades[0];

        setState((el) => ({
          ...el,
          typeOfSalary: data?.typeOfSalary || "",
        }));
      });
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      componentWillMount();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const eduRef = useRef();
  const emergencyRef = useRef();
  const resumeRef = useRef();

  const getAddressValue = (apartment, street, city, state, zip) => {
    let result = "";
    if (apartment && apartment !== "") result = result.concat(apartment);
    if (street && street !== "") result = result.concat(" " + street);
    if (city && city !== "") result = result.concat(", " + city);
    if (state && state !== "") result = result.concat(", " + state);
    if (zip && zip !== "") result = result.concat(" " + zip);
    return result;
  };
  const getPhoneValue = (phone1, phone2) => {
    let result = "";
    if (phone1 && phone1 !== "") result = result.concat(phone1);
    if (phone2 && phone2 !== "") {
      if (result !== "") result = result.concat(", " + phone2);
      else result = result.concat(phone2);
    }
    return result;
  };
  const changeShowJobState = () => {
    const { show_add_job } = state;
    setState((el) => ({
      ...el,
      show_add_job: !show_add_job,
    }));
  };
  const changeShowLocationState = () => {
    const { show_add_location } = state;
    setState((el) => ({
      ...el,
      show_add_location: !show_add_location,
    }));
  };
  const changeShowEmployeeHMOState = () => {
    const { show_add_employeeHMO } = state;
    setState((el) => ({
      ...el,
      show_add_employeeHMO: !show_add_employeeHMO,
    }));
  };
  const changeShowLifeInsuranceState = () => {
    const { show_add_lifeInsurance } = state;
    setState((el) => ({
      ...el,
      show_add_lifeInsurance: !show_add_lifeInsurance,
    }));
  };
  const updateJobGradesList = (item) => {
    const { jobGrades } = state;
    jobGrades.push(item);
    setState((el) => ({
      ...el,
      jobGrades,
    }));
  };
  const updateLocationsList = (item) => {
    const { locations } = state;
    locations.push(item);
    setState((el) => ({
      ...el,
      locations,
    }));
  };
  const updateEmployeeHMOsList = (item) => {
    const { employeeHMOs } = state;
    employeeHMOs.push(item);
    setState((el) => ({
      ...el,
      employeeHMOs,
    }));
  };
  const updateLifeInsurancesList = (item) => {
    const { lifeInsurances } = state;
    lifeInsurances.push(item);
    setState((el) => ({
      ...el,
      lifeInsurances,
    }));
  };
  const handleEducationInfoField = (idx) => (evt) => {
    const newEducationInfo = state.educationInfo.map((info, eIdx) => {
      if (idx !== eIdx) return info;
      let tName = evt.target.name;
      const value = evt.target.value;
      return { ...info, [tName]: value };
    });
    setState((el) => ({ ...el, educationInfo: newEducationInfo }));
  };

  const handleEducationInfoCompleteField = (idx) => (evt) => {
    const newEducationInfo = state.educationInfo.map((info, eIdx) => {
      if (idx !== eIdx) return info;
      let tName = "completeDate";
      const value = evt.target
        ? evt.target.value
        : moment(evt).format("DD/MM/YYYY");
      return { ...info, [tName]: value };
    });
    setState((el) => ({ ...el, educationInfo: newEducationInfo }));
  };

  const handleEducationInfoStartField = (idx) => (evt) => {
    const newEducationInfo = state.educationInfo.map((info, eIdx) => {
      if (idx !== eIdx) return info;
      let tName = "startDate";
      const value = evt.target
        ? evt.target.value
        : moment(evt).format("DD/MM/YYYY");
      return { ...info, [tName]: value };
    });
    setState((el) => ({ ...el, educationInfo: newEducationInfo }));
  };
  const handleEducationInfoFileField = (idx) => {
    // const refName = "certificate_" + idx;
    // const file = eduRef[refName].files[0] ? eduRef[refName].files[0] : null;
    const file = eduRef.current?.files[0] ? eduRef.current?.files[0] : null;
    if (file !== null) {
      const { educationInfo, educationCertificateFiles } = state;
      let currentDate = new Date();
      currentDate = currentDate.getTime();
      educationInfo[idx].certificate = currentDate + ".pdf";
      educationCertificateFiles[idx].file = file;
      setState((el) => ({
        ...el,
        educationInfo: educationInfo,
        educationCertificateFiles: educationCertificateFiles,
      }));
    }
  };

  const addMoreEducationInfo = () => {
    const { educationInfo, educationCertificateFiles } = state;
    setState((el) => ({
      ...el,
      educationInfo: educationInfo?.concat({
        institution: "",
        subject: "",
        startDate: "",
        completeDate: "",
        degree: "",
        grade: "",
        certificate: "",
      }),
    }));
    setState((el) => ({
      ...el,
      educationCertificateFiles: educationCertificateFiles?.concat({
        file: null,
      }),
    }));
  };
  const updatePersonalInfoFirstName = (event) => {
    const { personalInfo } = state;
    personalInfo.name.first = event.target.value;
    setState((el) => ({
      ...el,
      personalInfo,
    }));
  };

  const updateJobInfoJoinDate = (event) => {
    const { jobInfo } = state;
    jobInfo.joinDate = event.target
      ? event.target.value
      : moment(event).format("DD/MM/YYYY");
    setState((el) => ({
      ...el,
      jobInfo,
    }));
  };

  const updatePersonalInfoLastName = (event) => {
    const { personalInfo } = state;
    personalInfo.name.last = event.target.value;
    setState((el) => ({
      ...el,
      personalInfo,
    }));
  };
  const updatePersonalInfoMiddleName = (event) => {
    const { personalInfo } = state;
    personalInfo.name.middle = event.target.value;
    setState((el) => ({
      ...el,
      personalInfo,
    }));
  };
  const updatePersonalInfoAddressApartment = (event) => {
    const { personalInfo } = state;
    personalInfo.address.apartment = event.target.value;
    setState((el) => ({
      ...el,
      personalInfo,
    }));
  };
  const updatePersonalInfoAddressStreet = (event) => {
    const { personalInfo } = state;
    personalInfo.address.street = event.target.value;
    setState((el) => ({
      ...el,
      personalInfo,
    }));
  };
  const updatePersonalInfoAddressCity = (event) => {
    const { personalInfo } = state;
    personalInfo.address.city = event.target.value;
    setState((el) => ({
      ...el,
      personalInfo,
    }));
  };
  const updatePersonalInfoAddressState = (event) => {
    const { personalInfo } = state;
    personalInfo.address.state = event.target.value;
    setState((el) => ({
      ...el,
      personalInfo,
    }));
  };
  const updatePersonalInfoAddressZip = (event) => {
    const { personalInfo } = state;
    personalInfo.address.zip = event.target.value;
    setState((el) => ({
      ...el,
      personalInfo,
    }));
  };
  const updatePersonalInfoPhoneHome = (event) => {
    const { personalInfo } = state;
    personalInfo.phone.home = event.target.value;
    setState((el) => ({
      ...el,
      personalInfo,
    }));
  };
  const updatePersonalInfoPhoneAlternate = (event) => {
    const { personalInfo } = state;
    personalInfo.phone.alternate = event.target.value;
    setState((el) => ({
      ...el,
      personalInfo,
    }));
  };
  const updatePersonalInfoEmail = (event) => {
    const { personalInfo } = state;
    personalInfo.email = event.target.value;
    setState((el) => ({
      ...el,
      personalInfo,
    }));
  };
  const updatePersonalInfoGovtId = (event) => {
    const { personalInfo } = state;
    personalInfo.govtId = event.target.value;
    setState((el) => ({
      ...el,
      personalInfo,
    }));
  };
  const updatePersonalGender = (event) => {
    const { personalInfo } = state;
    personalInfo.gender = event.target.value;
    setState((el) => ({
      ...el,
      personalInfo,
    }));
  };
  const updatePersonalInfoBirth = (event) => {
    try {
      const { personalInfo } = state;
      personalInfo.birth = event.target
        ? event.target.value
        : moment(event).format("DD/MM/YYYY");
      setState((el) => ({
        ...el,
        personalInfo,
      }));
    } catch (e) {
      console.log("there was an error :: ", e);
    }
  };
  const updatePersonalInfoMartial = (event) => {
    const { personalInfo } = state;
    personalInfo.martial = event.target.value;
    setState((el) => ({
      ...el,
      personalInfo,
    }));
  };
  const updatePersonalInfoSpouseName = (event) => {
    const { personalInfo } = state;
    personalInfo.spouse.name = event.target.value;
    setState((el) => ({
      ...el,
      personalInfo,
    }));
  };
  const updatePersonalInfoSpouseEmployer = (event) => {
    const { personalInfo } = state;
    personalInfo.spouse.employer = event.target.value;
    setState((el) => ({
      ...el,
      personalInfo,
    }));
  };
  const updatePersonalInfoSpousePhone = (event) => {
    const { personalInfo } = state;
    personalInfo.spouse.phone = event.target.value;
    setState((el) => ({
      ...el,
      personalInfo,
    }));
  };
  const updatePersonalInfoPrevEmployeeEmployee = (event) => {
    const { personalInfo } = state;
    personalInfo.prevEmployee.employee = event.target.value;
    setState((el) => ({
      ...el,
      personalInfo,
    }));
  };
  const updatePersonalInfoPrevEmployeePhone = (event) => {
    const { personalInfo } = state;
    personalInfo.prevEmployee.phone = event.target.value;
    setState((el) => ({
      ...el,
      personalInfo,
    }));
  };
  const updateJobInfoTitle = (event) => {
    const { jobInfo } = state;
    jobInfo.title = event.target.value;
    setState((el) => ({
      jobInfo,
    }));
  };
  const updateJobInfoEmployeeId = (event) => {
    const { jobInfo } = state;
    // jobInfo.employeeId = event.target.value;
    jobInfo.changeableId = event.target.value;
    setState((el) => ({
      jobInfo,
    }));
  };
  const updateJobInfoGrade = (event) => {
    const { jobInfo, jobGrades } = state;
    const value = event.target.value;
    if (value === "add_job") {
      const { show_add_job } = state;
      setState((el) => ({
        show_add_job: !show_add_job,
      }));
    } else {
      const grade = jobGrades.filter((grades) => grades._id === value);
      const gradeTotal = Object.entries(grade[0].salary).reduce(
        (c, p) => c + p[1],
        0
      );
      const totalCustom =
        grade[0]?.custom?.length > 0
          ? grade[0]?.custom?.reduce(
              (c, p) => parseFloat(c) + parseFloat(p?.value),
              0
            )
          : 0;

      jobInfo.salary = gradeTotal + totalCustom;
      jobInfo.grade = value;
      setState((el) => ({
        jobInfo,
      }));
    }
  };
  const updateJobInfoSupervisor = (params) => {
    const { jobInfo } = state;
    jobInfo.supervisor = params.map((data) => {
      return data.id;
    });
    setState((el) => ({
      ...el,
      jobInfo,
    }));
    console.log(state.jobInfo);
  };
  const updateJobInfoDepartment = (event) => {
    const { jobInfo } = state;
    jobInfo.department = event.target.value;
    setState((el) => ({
      ...el,
      jobInfo,
    }));
  };
  const updateJobInfoWorkLocation = (event) => {
    const { jobInfo, locations } = state;
    const value = event.target.value;
    if (value === "add_location") {
      const { show_add_location } = state;
      setState((el) => ({
        ...el,
        show_add_location: !show_add_location,
      }));
    } else {
      let branchesLocations = locations.find((el) => el._id === value);
      branchesLocations = branchesLocations?.branches;
      jobInfo.workLocation = value;
      jobInfo.workLocationBranch = "";
      setState((el) => ({
        ...el,
        jobInfo,
        branchesLocations,
      }));
    }
  };
  const updateJobInfoEmail = (event) => {
    const { jobInfo } = state;
    jobInfo.email = event.target.value;
    setState((el) => ({
      ...el,
      jobInfo,
    }));
  };
  const updateJobInfoWorkPhone = (event) => {
    const { jobInfo } = state;
    jobInfo.workPhone = event.target.value;
    setState((el) => ({
      ...el,
      jobInfo,
    }));
  };
  const updateJobInfoCellPhone = (event) => {
    const { jobInfo } = state;
    jobInfo.cellPhone = event.target.value;
    setState((el) => ({
      ...el,
      jobInfo,
    }));
  };
  const updateJobInfoStartDate = (event) => {
    const { jobInfo } = state;
    jobInfo.startDate = event.target
      ? event.target.value
      : moment(event).format("DD/MM/YYYY");
    setState((el) => ({
      ...el,
      jobInfo,
    }));
  };
  const updateJobInfoSalary = (event) => {
    const { jobInfo } = state;
    jobInfo.salary = event.target.value;
    setState((el) => ({
      ...el,
      jobInfo,
    }));
  };
  const updateJobInfoStatus = (event) => {
    const { jobInfo } = state;
    jobInfo.status = event.target.value;
    setState((el) => ({
      ...el,
      jobInfo,
    }));
  };
  const updateJobInfoSalaryBasis = (event) => {
    const { jobInfo } = state;
    jobInfo.salaryBasis = event.target.value;
    setState((el) => ({
      ...el,
      jobInfo,
    }));
  };
  const updateJobInfoContract = (event) => {
    const { jobInfo } = state;
    jobInfo.contract = event.target.checked;
    setState((el) => ({
      ...el,
      jobInfo,
    }));
  };
  const updateJobInfoContractType = (event) => {
    const { jobInfo } = state;
    jobInfo.contractType = event.target.value;
    setState((el) => ({
      ...el,
      jobInfo,
    }));
  };
  const updateEmergencyInfoNameFirst = (event) => {
    const { emergencyInfo } = state;
    emergencyInfo.name.first = event.target.value;
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };
  const updateEmergencyInfoNameLast = (event) => {
    const { emergencyInfo } = state;
    emergencyInfo.name.last = event.target.value;
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };
  const updateEmergencyInfoNameMiddle = (event) => {
    const { emergencyInfo } = state;
    emergencyInfo.name.middle = event.target.value;
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };
  const updateEmergencyInfoAddressApartment = (event) => {
    const { emergencyInfo } = state;
    emergencyInfo.address.apartment = event.target.value;
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };
  const updateEmergencyInfoAddressStreet = (event) => {
    const { emergencyInfo } = state;
    emergencyInfo.address.street = event.target.value;
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };
  const updateEmergencyInfoAddressCity = (event) => {
    const { emergencyInfo } = state;
    emergencyInfo.address.city = event.target.value;
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };
  const updateEmergencyInfoAddressState = (event) => {
    const { emergencyInfo } = state;
    emergencyInfo.address.state = event.target.value;
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };
  const updateEmergencyInfoAddressZip = (event) => {
    const { emergencyInfo } = state;
    emergencyInfo.address.zip = event.target.value;
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };
  const updateEmergencyInfoPhonePrimary = (event) => {
    const { emergencyInfo } = state;
    emergencyInfo.phone.primary = event.target.value;
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };
  const updateEmergencyInfoPhoneSecondary = (event) => {
    const { emergencyInfo } = state;
    emergencyInfo.phone.secondary = event.target.value;
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };
  const updateEmergencyInfoRelation = (event) => {
    const { emergencyInfo } = state;
    emergencyInfo.relation = event.target.value;
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };
  const updateEmergencyInfoGuarantorTitle = (event) => {
    const { emergencyInfo } = state;
    emergencyInfo.guarantor.title = event.target.value;
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };
  const updateEmergencyInfoGuarantorName = (event) => {
    const { emergencyInfo } = state;
    emergencyInfo.guarantor.name = event.target.value;
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };
  const updateEmergencyInfoGuarantorPhone = (event) => {
    const { emergencyInfo } = state;
    emergencyInfo.guarantor.phone = event.target.value;
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };
  const updateEmergencyInfoGuarantorAddress = (event) => {
    const { emergencyInfo } = state;
    emergencyInfo.guarantor.address = event.target.value;
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };
  const updateEmergencyInfoRefereeTitle = (event) => {
    const { emergencyInfo } = state;
    emergencyInfo.referee.title = event.target.value;
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };
  const updateEmergencyInfoRefereeName = (event) => {
    const { emergencyInfo } = state;
    emergencyInfo.referee.name = event.target.value;
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };
  const updateEmergencyInfoRefereePhone = (event) => {
    const { emergencyInfo } = state;
    emergencyInfo.referee.phone = event.target.value;
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };
  const updateEmergencyInfoRefereeAddress = (event) => {
    const { emergencyInfo } = state;
    emergencyInfo.referee.address = event.target.value;
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };
  const updateEmergencyInfoEmployeeHMO = (event) => {
    const { emergencyInfo } = state;
    const value = event.target.value;
    if (value === "add_employeeHMO") {
      const { show_add_employeeHMO } = state;
      setState((el) => ({
        ...el,
        show_add_employeeHMO: !show_add_employeeHMO,
      }));
    } else {
      emergencyInfo.employeeHMO = value;
      setState((el) => ({
        ...el,
        emergencyInfo,
      }));
    }
  };
  const updateEmergencyInfoLifeInsurance = (event) => {
    const { emergencyInfo } = state;
    const value = event.target.value;
    if (value === "add_lifeInsurance") {
      const { show_add_lifeInsurance } = state;
      setState((el) => ({
        ...el,
        show_add_lifeInsurance: !show_add_lifeInsurance,
      }));
    } else {
      emergencyInfo.lifeInsurance = value;
      setState((el) => ({
        ...el,
        emergencyInfo,
      }));
    }
  };
  const updateEmergencyInfoImage = (event) => {
    const { emergencyInfo } = state;
    let currentDate = new Date();
    currentDate = currentDate.getTime();
    emergencyInfo.image = currentDate + ".png";
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
    let file = emergencyRef.current?.emergency_image.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function () {
      setState((el) => ({
        ...el,
        displayImageSrc: [reader.result],
      }));
    };
  };
  const updateEmergencyInfoResume = (event) => {
    const { emergencyInfo } = state;
    let currentDate = new Date();
    currentDate = currentDate.getTime();
    emergencyInfo.resume = currentDate + ".pdf";
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
    setState((el) => ({
      ...el,
      displayResumeSrc: event.target.files[0],
    }));
  };
  const onDocumentLoadSuccess = (e) => {
    // console.log("success", e);
  };

  const updateJobInfoWorkLocationBranch = (event) => {
    const { jobInfo, branchesLocations } = state;
    const value = event.target.value;
    jobInfo.workLocationBranch = value;
    let theState = branchesLocations.find((el) => el.name === value);

    theState = state?.state;
    jobInfo.state = theState;
    setState((el) => ({
      ...el,
      jobInfo,
    }));
  };

  const savePersonalInfo = (e) => {
    e.preventDefault();
    const { _id, personalInfo, jobInfo, educationInfo, emergencyInfo } = state;

    let formData = new FormData();
    const employee = {
      _id,
      personalInfo,
      jobInfo,
      emergencyInfo,
      educationInfo,
    };
    formData.append("employee", JSON.stringify(employee));
    const token = "dummy token";
    fetch(`${URLS.backendEmployees}/update`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Token ${token}`,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        toast.success("Updated Successful", {
          position: "top-right",
        });
        // console.log(result);
        // props.updateEmployeesList(result.employee);
      });
  };

  const saveJobInfo = (e) => {
    // console.log({ ...state, _id: state._id });
    e.preventDefault();
    const { _id, personalInfo, jobInfo, educationInfo, emergencyInfo } = state;
    let formData = new FormData();
    const employee = {
      _id,
      personalInfo,
      jobInfo,
      emergencyInfo,
      educationInfo,
    };

    formData.append("employee", JSON.stringify(employee));
    const token = "dummy token";
    fetch(`${URLS.backendEmployees}/update`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Token ${token}`,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        toast.success("Updated Successful", {
          position: "top-right",
        });
        // props.updateEmployeesList(result.employee);
      });
  };

  const saveEmergencyInfo = (e) => {
    e.preventDefault();
    const { _id, personalInfo, jobInfo, educationInfo, emergencyInfo } = state;
    let formData = new FormData();
    const employee = {
      _id,
      personalInfo,
      jobInfo,
      emergencyInfo,
      educationInfo,
    };
    formData.append("employee", JSON.stringify(employee));
    if (
      emergencyRef?.current?.emergency_image?.files &&
      emergencyRef?.current?.emergency_image?.files[0]
    )
      formData.append(
        "file",
        emergencyRef?.current?.emergency_image.files[0],
        emergencyInfo?.image
      );
    if (
      resumeRef?.current?.emergency_resume?.files &&
      resumeRef.current?.emergency_resume?.files[0]
    )
      formData.append(
        "file",
        resumeRef?.current?.emergency_resume?.files[0],
        emergencyInfo?.resume
      );
    const token = "dummy token";
    fetch(`${URLS.backendEmployees}/update`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Token ${token}`,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        toast.success("Updated Successful", {
          position: "top-right",
        });
        setState((el) => ({
          ...el,
          emergencyInfo: result?.employee?.emergencyInfo,
        }));
        // props.updateEmployeesList(result.employee);
      });
  };
  const saveEducationInfo = (e) => {
    e.preventDefault();
    const {
      _id,
      personalInfo,
      jobInfo,
      educationInfo,
      emergencyInfo,
      educationCertificateFiles,
    } = state;
    let formData = new FormData();
    const employee = {
      _id,
      personalInfo,
      jobInfo,
      emergencyInfo,
      educationInfo,
    };
    formData.append("employee", JSON.stringify(employee));
    for (let i = 0; i < educationInfo.length; i++) {
      if (
        educationCertificateFiles[i]?.file &&
        educationCertificateFiles[i]?.file !== ""
      )
        formData.append(
          "file",
          educationCertificateFiles[i]?.file,
          educationInfo[i]?.certificate
        );
    }
    const token = "dummy token";
    fetch(`${URLS.backendEmployees}/update`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Token ${token}`,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        toast.success("Updated Successful", {
          position: "top-right",
        });
        // props.updateEmployeesList(result.employee);
      });
  };
  const editTheProject = (_id) => {
    setState((el) => ({ ...el, editProject: _id }));
  };

  // const deleteProject = (_id) => {
  //   setState((el) => ({ ...el, deleteProject: _id }));
  // };

  const getEditProjectDataFromId = (id) => {
    const { projects } = state;
    return projects?.find((item) => id === item?._id);
  };

  const handleDeleteProject = () => {
    let projects = state?.projects;
    const projectId = state?.deleteProject;
    const token = "dummy token";

    fetch(`${URLS.backendProjects}/delete`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ projectId }),
    })
      .then((res) => res.json())
      .then((result) => {
        projects = projects.filter((project) => project?._id !== projectId);
        setState((el) => ({ ...el, projects }));
      });

    window.$("#delete_project").modal("toggle");
  };

  const updateProjectsList = () => {};

  const {
    personalInfo,
    jobInfo,
    emergencyInfo,
    educationInfo,
    jobGrades,
    contracts,
    departments,
    locations,
    educationCertificateFiles,
    displayImageSrc,
    displayResumeSrc,
    pdfPageNumber,
    employeeHMOs,
    lifeInsurances,
    supervisors,
    projects,
    editProject,
    employeesList,
    clientList,
    projectTasks,
    // collaborationUrl,
    branchesLocations,
  } = state;
  const editProjectData = getEditProjectDataFromId(editProject);
  // const profileImage =
  //   emergencyInfo && emergencyInfo.image
  //     ? URLS.backendStatic + "/" + emergencyInfo.image
  //     : "/assets/img/profiles/avatar-02.jpg";
  // const supervisorProfileImage =
  //   supervisors.length && jobInfo
  //     ? supervisors.map((item) => {
  //         return item.id === jobInfo.supervisor
  //           ? URLS.backendStatic + "/" + item?.image
  //           : "/assets/img/profiles/avatar-02.jpg";
  //       })
  //     : "/assets/img/profiles/avatar-02.jpg";
  const displayImage = displayImageSrc
    ? displayImageSrc
    : URLS?.backendStatic + "/" + emergencyInfo?.image;
  const displayResume = displayResumeSrc
    ? displayResumeSrc
    : URLS?.backendStatic + "/" + emergencyInfo?.resume;
  let displayEducationCertificates = [];
  for (let i = 0; i < educationInfo?.length; i++) {
    let sFileUrl = URLS.backendStatic + "/" + educationInfo[i]?.certificate;
    let fff =
      educationCertificateFiles[i] && educationCertificateFiles[i]?.file;
    if (fff) {
      displayEducationCertificates.push(fff);
    } else {
      displayEducationCertificates.push(sFileUrl);
    }
  }
  return (
    <body>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex, nofollow" />
        <title>InvexERP</title>
      </Helmet>
      <div className="main-wrapper">
        <Topbar />
        <Sidebar />
        <div className="page-wrapper">
          {/*<!-- Page Content -->*/}
          <div className="content container-fluid">
            {/*<!-- Page Title -->*/}
            <div className="row">
              <div className="col-sm-12">
                <Breadcrumb>
                  <BreadcrumbItem>
                    <Link to={`${URLS.dashboard}`}>Dashboard</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <Link to={`${URLS.employees}`}>Employees</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>My Profile</BreadcrumbItem>
                </Breadcrumb>
                {/* <h4 className='page-title'>My Profile</h4> */}
              </div>
            </div>
            {/*<!-- /Page Title -->*/}

            <div className="card-box mb-0">
              <div className="row">
                <div className="col-md-12">
                  <div className="profile-view">
                    <div className="profile-img-wrap">
                      <div className="profile-img">
                        <img
                          alt=""
                          src={
                            emergencyInfo && emergencyInfo.image
                              ? URLS.backendStatic + "/" + emergencyInfo.image
                              : "/assets/img/profiles/avatar-02.jpg"
                          }
                        />
                      </div>
                    </div>
                    <div className="profile-basic">
                      <div className="row">
                        <div className="col-md-5">
                          <div className="profile-info-left">
                            <h3 className="user-name m-t-0 mb-0">
                              {(personalInfo?.name?.first
                                ? personalInfo?.name?.first
                                : "") +
                                " " +
                                (personalInfo?.name?.middle
                                  ? personalInfo?.name?.middle
                                  : "") +
                                " " +
                                (personalInfo?.name?.last
                                  ? personalInfo?.name?.last
                                  : "")}
                            </h3>
                            <h6 className="text-muted">
                              {departments?.length &&
                                departments?.map((item) => {
                                  return item._id === jobInfo.department
                                    ? item.name
                                    : "";
                                })}
                            </h6>
                            <small className="text-muted">
                              {jobInfo.title}
                            </small>
                            <div className="staff-id">
                              {jobInfo.changeableId}
                            </div>
                            <div className="small doj text-muted">
                              {jobInfo.startDate}
                            </div>
                            {/* <div className="staff-msg">
                                <a
                                  className="btn btn-custom"
                                  href={collaborationUrl + "/messenger"}
                                >
                                  Send Message
                                </a>
                              </div> */}
                          </div>
                        </div>
                        <div className="col-md-7">
                          <ul className="personal-info">
                            <div
                              style={{ display: "flex", padding: "4px 0px" }}
                            >
                              <div className="title" style={{ flex: 1 }}>
                                Phone:{" "}
                              </div>
                              <div className="text" style={{ flex: 1 }}>
                                <span className="p-cursor">
                                  {" "}
                                  {personalInfo?.phone?.home}{" "}
                                </span>
                              </div>
                            </div>
                            <div
                              style={{ display: "flex", padding: "4px 0px" }}
                            >
                              <div className="title" style={{ flex: 1 }}>
                                Email:{" "}
                              </div>
                              <div className="text" style={{ flex: 1 }}>
                                <span className="p-cursor">
                                  {" "}
                                  {personalInfo?.email}{" "}
                                </span>
                              </div>
                            </div>
                            <div
                              style={{ display: "flex", padding: "4px 0px" }}
                            >
                              <div className="title" style={{ flex: 1 }}>
                                Birthday:{" "}
                              </div>
                              <div className="text" style={{ flex: 1 }}>
                                {" "}
                                {personalInfo?.birth}{" "}
                              </div>
                            </div>
                            <div
                              style={{ display: "flex", padding: "4px 0px" }}
                            >
                              <div className="title" style={{ flex: 1 }}>
                                Address:{" "}
                              </div>
                              <div className="text" style={{ flex: 1 }}>
                                {" "}
                                {getAddressValue(
                                  personalInfo?.address?.apartment,
                                  personalInfo?.address?.street,
                                  personalInfo?.address?.city,
                                  personalInfo?.address?.state,
                                  personalInfo?.address?.zip
                                )}{" "}
                              </div>
                            </div>
                            {/* <div style={{ display: 'flex', padding: '4px 0px' }}>
																<div className='title' style={{ flex: 1 }}>Reports to: </div>
																<div className='text' style={{ flex: 1 }}>
																	<div className='avatar-box'>
																		<div className='avatar avatar-xs'>
																			<img src={supervisorProfileImage} alt='' />
																		</div>
																	</div>
																	<a href={`${jobInfo.supervisor}`}>{supervisors.length && supervisors.map((item) => { return item.id === jobInfo.supervisor ? item.name : '' })}</a>
																</div>
															</div> */}
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* <div className='pro-edit'><a data-target='#profile_info' data-toggle='modal' className='edit-icon' href='Javascript:void(0);'><i className='fa fa-pencil'></i></a></div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="card-box tab-box">
              <div className="row user-tabs">
                <div className="col-lg-12 col-md-12 col-sm-12 line-tabs">
                  <ul className="nav nav-tabs nav-tabs-bottom flex-row fw-bold">
                    <li className="nav-item">
                      <a
                        href="#emp_profile"
                        data-toggle="tab"
                        className="nav-link active"
                        onClick={() => setSection("emp_profile")}
                      >
                        Profile
                      </a>
                    </li>
                    {/* <li className='nav-item'><a href='#bank_statutory' data-toggle='tab' className='nav-link'>Bank & Statutory <small className='text-danger'>(Admin Only)</small></a></li> */}
                    <li className="nav-item">
                      <a
                        href="#emp_skills"
                        data-toggle="tab"
                        className="nav-link"
                        onClick={() => setSection("emp_skills")}
                      >
                        Skills/Certification
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#emp_trainings"
                        data-toggle="tab"
                        className="nav-link"
                        onClick={() => setSection("emp_trainings")}
                      >
                        Training
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#emp_documents"
                        data-toggle="tab"
                        className="nav-link"
                        onClick={() => setSection("emp_documents")}
                      >
                        Documents
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#leave_history"
                        data-toggle="tab"
                        className="nav-link"
                        onClick={() => setSection("leave_history")}
                      >
                        Leave history
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#loan_history"
                        data-toggle="tab"
                        className="nav-link"
                        onClick={() => setSection("loan_history")}
                      >
                        Loan history
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#salary_history"
                        data-toggle="tab"
                        className="nav-link"
                        onClick={() => setSection("salary_history")}
                      >
                        Salary history
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#allowance_history"
                        data-toggle="tab"
                        className="nav-link"
                        onClick={() => setSection("allowance_history")}
                      >
                        Allowance history
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="tab-content">
              {/*<!-- Profile Info Tab -->*/}

              <div
                id="emp_profile"
                className="pro-overview tab-pane fade show active"
              >
                {section === "emp_profile" ? (
                  <EmpProfile
                    emergencyInfo={emergencyInfo}
                    personalInfo={personalInfo}
                    getAddressValue={getAddressValue}
                    getPhoneValue={getPhoneValue}
                    employeeHMOs={employeeHMOs}
                    lifeInsurances={lifeInsurances}
                    jobInfo={jobInfo}
                    jobGrades={jobGrades}
                    contracts={contracts}
                    supervisors={supervisors}
                    departments={departments}
                    locations={locations}
                    educationInfo={educationInfo}
                  />
                ) : null}
              </div>

              {/*<!-- /Profile Info Tab -->*/}
              {/*<!-- Projects Tab -->*/}

              <div className="tab-pane fade" id="emp_projects">
                {section === "emp_projects" ? (
                  <EmpProject
                    projects={projects}
                    editTheProject={editTheProject}
                  />
                ) : null}
              </div>

              {/*<!-- /Projects Tab -->*/}
              {/*<!-- Bank Statutory Tab -->*/}

              <div className="tab-pane fade" id="bank_statutory">
                {section === "bank_statutory" ? <BankStatutory /> : null}
              </div>

              {/*<!-- /Bank Statutory Tab -->*/}

              <div className="tab-pane fade" id="emp_skills">
                {section === "emp_skills" ? (
                  <EmployeeSkills eId={state._id} />
                ) : null}
              </div>

              <div className="tab-pane fade" id="emp_trainings">
                {section === "emp_trainings" ? (
                  <EmployeeTraining eId={state._id} />
                ) : null}
              </div>

              <div className="tab-pane fade" id="emp_documents">
                {section === "emp_documents" ? <DocumentModal /> : null}
              </div>

              <div id="tasks" className="tab-pane fade">
                {section === "tasks" ? (
                  <Task projectTasks={projectTasks} />
                ) : null}
              </div>

              <div id="loan_history" className="tab-pane fade">
                {section === "loan_history" ? (
                  <LoanHistory eId={state._id} />
                ) : null}
              </div>

              <div className="tab-pane fade" id="leave_history">
                {section === "leave_history" ? (
                  <LeaveHistory eId={state._id} />
                ) : null}
              </div>

              <div className="tab-pane fade" id="salary_history">
                {section === "salary_history" ? (
                  <SalaryHistory eId={state._id} />
                ) : null}
              </div>

              <div className="tab-pane fade" id="allowance_history">
                {section === "allowance_history" ? (
                  <AllowanceHistory eId={state._id} />
                ) : null}
              </div>
            </div>
          </div>
          {/*<!-- /Page Content -->*/}

          {/* EditProjectModal */}
          <EditProjectModal
            mode="edit"
            project={editProjectData}
            updateProjectsList={updateProjectsList}
            clientList={clientList}
            employeesList={employeesList}
          />
          {/* EditProjectModal */}

          {/*<!-- Delete Project Modal -->*/}
          <DeleteModal handleDeleteProject={handleDeleteProject} />
          {/*<!-- /Delete Project Modal -->*/}

          {/*<!-- Profile Modal -->*/}
          <ProfileModal />
          {/*<!-- /Profile Modal -->*/}

          {/*<!-- Personal Info Modal -->*/}
          <PersonalInfoModal
            savePersonalInfo={savePersonalInfo}
            personalInfo={personalInfo}
            updatePersonalInfoFirstName={updatePersonalInfoFirstName}
            updatePersonalInfoLastName={updatePersonalInfoLastName}
            updatePersonalInfoMiddleName={updatePersonalInfoMiddleName}
            updatePersonalInfoAddressApartment={
              updatePersonalInfoAddressApartment
            }
            updatePersonalInfoAddressStreet={updatePersonalInfoAddressStreet}
            updatePersonalInfoAddressCity={updatePersonalInfoAddressCity}
            updatePersonalInfoAddressState={updatePersonalInfoAddressState}
            updatePersonalInfoAddressZip={updatePersonalInfoAddressZip}
            updatePersonalInfoPhoneHome={updatePersonalInfoPhoneHome}
            updatePersonalInfoPhoneAlternate={updatePersonalInfoPhoneAlternate}
            updatePersonalInfoEmail={updatePersonalInfoEmail}
            updatePersonalInfoGovtId={updatePersonalInfoGovtId}
            updatePersonalGender={updatePersonalGender}
            updatePersonalInfoBirth={updatePersonalInfoBirth}
            updatePersonalInfoMartial={updatePersonalInfoMartial}
            updatePersonalInfoSpouseName={updatePersonalInfoSpouseName}
            updatePersonalInfoSpouseEmployer={updatePersonalInfoSpouseEmployer}
            updatePersonalInfoSpousePhone={updatePersonalInfoSpousePhone}
            updatePersonalInfoPrevEmployeeEmployee={
              updatePersonalInfoPrevEmployeeEmployee
            }
            updatePersonalInfoPrevEmployeePhone={
              updatePersonalInfoPrevEmployeePhone
            }
          />
          <div
            id="job_information_modal"
            className="modal custom-modal fade"
            role="dialog"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-lg"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Job Information</h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <Form onSubmit={saveJobInfo}>
                    <Form.Group as={Row}>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Job Title
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control
                          value={jobInfo.title}
                          onChange={updateJobInfoTitle}
                        />
                      </Col>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Employee ID
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control
                          value={jobInfo.changeableId}
                          onChange={updateJobInfoEmployeeId}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Job Grade
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control
                          as="select"
                          value={jobInfo.grade}
                          onChange={updateJobInfoGrade}
                        >
                          <option>Select</option>
                          <option value="add_job">Add New</option>
                          <option disabled>__________________</option>
                          {jobGrades?.map((jobGrade) => {
                            return (
                              <option value={jobGrade._id}>
                                {jobGrade.name}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Supervisor
                      </Form.Label>
                      <Col sm={4}>
                        {supervisors?.length && (
                          <Multiselect
                            options={supervisors}
                            onSelectOptions={updateJobInfoSupervisor}
                            selectedOptions={jobInfo?.supervisor}
                          />
                        )}
                      </Col>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Department
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control
                          as="select"
                          value={jobInfo.department}
                          onChange={updateJobInfoDepartment}
                        >
                          <option>Select</option>
                          {departments?.map((dep) => {
                            return <option value={dep._id}>{dep.name}</option>;
                          })}
                        </Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Work Location
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control
                          as="select"
                          value={jobInfo.workLocation}
                          onChange={updateJobInfoWorkLocation}
                        >
                          <option>Select</option>
                          <option value="add_location">Add New</option>
                          <option disabled>__________________</option>
                          {locations?.map((location) => {
                            return (
                              <option value={location._id}>
                                {location.location}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Col>

                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Email
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control
                          value={jobInfo.email}
                          onChange={updateJobInfoEmail}
                        />
                      </Col>
                    </Form.Group>

                    {/* {branchesLocations?.length > 0 && ( */}
                    <Form.Group as={Row}>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Select Company
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control
                          as="select"
                          value={jobInfo.workLocationBranch}
                          onChange={updateJobInfoWorkLocationBranch}
                        >
                          <option value="">Select</option>
                          {branchesLocations?.map((location) => {
                            return (
                              <option value={location.name}>
                                {location.name}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Col>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        State
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control value={jobInfo.state} type="text" />
                      </Col>
                    </Form.Group>
                    {/* )} */}

                    <Form.Group as={Row}>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Work Phone
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control
                          value={jobInfo.workPhone}
                          onChange={updateJobInfoWorkPhone}
                        />
                      </Col>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Cell Phone
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control
                          value={jobInfo.cellPhone}
                          onChange={updateJobInfoCellPhone}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Join Date <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Col sm={4}>
                        <div classname="datePicker">
                          <i
                            class="fa fa-calendar fa-xs calendar-icon"
                            aria-hidden="true"
                          ></i>
                          <Datepicker
                            value={jobInfo.joinDate}
                            closeOnSelect={true}
                            onChange={updateJobInfoJoinDate}
                          />
                        </div>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Start Date
                      </Form.Label>
                      <Col sm={4}>
                        <div classname="datePicker">
                          <i
                            class="fa fa-calendar fa-xs calendar-icon"
                            aria-hidden="true"
                          ></i>
                          <Datepicker
                            value={jobInfo.startDate}
                            closeOnSelect={true}
                            onChange={updateJobInfoStartDate}
                          />
                        </div>
                      </Col>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Salary
                      </Form.Label>
                      <Col sm={4}>
                        <CurrencyInput
                          className="form-control"
                          value={jobInfo.salary}
                          allowNegativeValue={false}
                          onValueChange={(value, name) =>
                            updateJobInfoSalary({
                              target: { value },
                            })
                          }
                          disabled={state.typeOfSalary !== "Percentage"}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Status
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control
                          as="select"
                          value={jobInfo.status}
                          onChange={updateJobInfoStatus}
                        >
                          <option>Choose...</option>
                          <option value="Internship">Internship</option>
                          <option value="Probation">Probation</option>
                          <option value="Trainee">Trainee</option>
                          <option value="Confirmed">Confirmed</option>
                        </Form.Control>
                      </Col>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Salary Basis
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control
                          as="select"
                          value={jobInfo.salaryBasis}
                          onChange={updateJobInfoSalaryBasis}
                        >
                          <option>Choose...</option>
                          <option value="Hourly">Hourly</option>
                          <option value="Daily">Daily</option>
                          <option value="Daily">Weekly</option>
                          <option value="Monthly">Monthly</option>
                        </Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Contractor
                      </Form.Label>
                      <Col sm={4}>
                        <div
                          className="form-check"
                          style={{
                            height: "44px",
                            padding: "6px 0",
                            display: "inline-block",
                          }}
                        >
                          <input
                            style={{ height: "30px", width: "30px" }}
                            type="checkbox"
                            checked={jobInfo.contract}
                            onChange={updateJobInfoContract}
                          />
                          <label
                            style={{
                              margin: "10px",
                              verticalAlign: "bottom",
                            }}
                          >
                            Check if contract based
                          </label>
                        </div>
                      </Col>
                      {jobInfo.contract && (
                        <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                          Contract Type
                        </Form.Label>
                      )}
                      {jobInfo.contract && (
                        <Col sm={4}>
                          <Form.Control
                            as="select"
                            value={jobInfo.contractType}
                            onChange={updateJobInfoContractType}
                          >
                            <option value="">Choose...</option>
                            {contracts.length ? (
                              contracts.map((project) => {
                                return (
                                  <option value={project._id}>
                                    {project.contractName}
                                  </option>
                                );
                              })
                            ) : (
                              <option value="">Choose...</option>
                            )}
                          </Form.Control>
                        </Col>
                      )}
                    </Form.Group>
                    <div className="submit-section">
                      <button
                        className="btn btn-primary submit-btn"
                        data-toggle="modal"
                        data-target="#job_information_modal"
                      >
                        Save
                      </button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
            <AddJobGradeModal
              updateJobGradesList={updateJobGradesList}
              isShow={state.show_add_job}
              changeShowJobState={changeShowJobState}
            />
            <AddLocationModal
              updateLocationsList={updateLocationsList}
              isShow={state.show_add_location}
              changeShowLocationState={changeShowLocationState}
            />
          </div>
          {/*<!-- /Personal Info Modal -->*/}

          {/*<!-- Family Info Modal -->*/}
          <FamilyInfoModal />
          {/*<!-- /Family Info Modal -->*/}

          {/*<!-- Emergency Contact Modal -->*/}
          <EmergencyContactModal
            saveEmergencyInfo={saveEmergencyInfo}
            emergencyInfo={emergencyInfo}
            updateEmergencyInfoNameFirst={updateEmergencyInfoNameFirst}
            updateEmergencyInfoNameLast={updateEmergencyInfoNameLast}
            updateEmergencyInfoNameMiddle={updateEmergencyInfoNameMiddle}
            updateEmergencyInfoAddressApartment={
              updateEmergencyInfoAddressApartment
            }
            updateEmergencyInfoAddressStreet={updateEmergencyInfoAddressStreet}
            updateEmergencyInfoAddressCity={updateEmergencyInfoAddressCity}
            updateEmergencyInfoAddressState={updateEmergencyInfoAddressState}
            updateEmergencyInfoAddressZip={updateEmergencyInfoAddressZip}
            updateEmergencyInfoPhonePrimary={updateEmergencyInfoPhonePrimary}
            updateEmergencyInfoPhoneSecondary={
              updateEmergencyInfoPhoneSecondary
            }
            updateEmergencyInfoRelation={updateEmergencyInfoRelation}
            updateEmergencyInfoGuarantorTitle={
              updateEmergencyInfoGuarantorTitle
            }
            updateEmergencyInfoGuarantorName={updateEmergencyInfoGuarantorName}
            updateEmergencyInfoGuarantorPhone={
              updateEmergencyInfoGuarantorPhone
            }
            updateEmergencyInfoGuarantorAddress={
              updateEmergencyInfoGuarantorAddress
            }
            updateEmergencyInfoRefereeTitle={updateEmergencyInfoRefereeTitle}
            updateEmergencyInfoRefereeName={updateEmergencyInfoRefereeName}
            updateEmergencyInfoRefereePhone={updateEmergencyInfoRefereePhone}
            updateEmergencyInfoRefereeAddress={
              updateEmergencyInfoRefereeAddress
            }
            updateEmergencyInfoEmployeeHMO={updateEmergencyInfoEmployeeHMO}
            employeeHMOs={employeeHMOs}
            updateEmergencyInfoLifeInsurance={updateEmergencyInfoLifeInsurance}
            lifeInsurances={lifeInsurances}
            displayImage={displayImage}
            emergencyRef={emergencyRef}
            updateEmergencyInfoImage={updateEmergencyInfoImage}
            displayResume={displayResume}
            onDocumentLoadSuccess={onDocumentLoadSuccess}
            pdfPageNumber={pdfPageNumber}
            resumeRef={resumeRef}
            updateEmergencyInfoResume={updateEmergencyInfoResume}
            updateEmployeeHMOsList={updateEmployeeHMOsList}
            state={state}
            changeShowEmployeeHMOState={changeShowEmployeeHMOState}
            updateLifeInsurancesList={updateLifeInsurancesList}
            changeShowLifeInsuranceState={changeShowLifeInsuranceState}
          />
          {/*<!-- /Emergency Contact Modal -->*/}

          {/*<!-- Education Modal -->*/}
          <EducationModal
            saveEducationInfo={saveEducationInfo}
            educationInfo={educationInfo}
            handleEducationInfoField={handleEducationInfoField}
            handleEducationInfoStartField={handleEducationInfoStartField}
            handleEducationInfoCompleteField={handleEducationInfoCompleteField}
            displayEducationCertificates={displayEducationCertificates}
            onDocumentLoadSuccess={onDocumentLoadSuccess}
            pdfPageNumber={pdfPageNumber}
            eduRef={eduRef}
            handleEducationInfoFileField={handleEducationInfoFileField}
            addMoreEducationInfo={addMoreEducationInfo}
          />
          {/*<!-- /Education Modal -->*/}

          {/*<!-- Experience Modal -->*/}
          <ExperienceModal />
          {/*<!-- /Experience Modal -->*/}
        </div>
        {/*<!-- /Page Wrapper -->*/}
      </div>
      {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
      <div className="sidebar-overlay" data-reff=""></div>
    </body>
  );
};

export default Profile;
