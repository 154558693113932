import React from "react";
import { SalarySetting } from "../create-salary/CreateSalary";
import { URLS } from "../urls";

const DisplayLeaveAllowance = ({
  allowance,
  leave,
  showMoreAssigneesAllowance,
  getallowanceUsers,
  usersStructures,
  showLessAssigneesAllowance,
  showAllAssigneesAllowance,
  updateAllowanceSlug,
}) => {
  return (
    <div className="custom-policy mt-4">
      <div className="leave-header">
        <div className="title">
          <b>Leave Allowance</b>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-hover table-nowrap leave-table">
          <thead>
            <tr>
              <th className="">Name</th>
              <th className="">Payment Type</th>
              <th className="l-assignee">Assignee</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {allowance
              .filter((el) => el?.leaveId === leave?._id)
              .map((al) => {
                const users = getallowanceUsers(
                  al["DeductType"],
                  al["selectedDeducts"]
                );

                return (
                  <tr>
                    <td>{al?.name}</td>
                    <td>
                      {al?.paymentType} ({al?.percentage}){" "}
                      <SalarySetting setting={al} placement="top" />
                    </td>
                    {al["DeductType"] === "All" ? (
                      <td>All Employee</td>
                    ) : (
                      <>
                        {users.length < 3 ? (
                          <td>
                            {users?.map((assignee) => {
                              return usersStructures(assignee);
                            })}
                          </td>
                        ) : showMoreAssigneesAllowance[al?._id] ? (
                          <td
                            style={{
                              whiteSpace: "normal",
                            }}
                          >
                            {users?.map((assignee) => {
                              return usersStructures(assignee);
                            })}
                            <div
                              style={{
                                display: "inline-block",
                                textAlign: "center",
                                marginRight: "10px",
                                verticalAlign: "inherit",
                              }}
                              onClick={showLessAssigneesAllowance(al?._id)}
                            >
                              <div
                                style={{
                                  display: "inline",
                                }}
                              >
                                <a
                                  href="Javascript:void(0);"
                                  className="avatar"
                                  style={{
                                    backgroundColor: "#eaeaea",
                                  }}
                                >
                                  <span
                                    style={{
                                      color: "#000",
                                      fontSize: "11px",
                                    }}
                                  >
                                    Less
                                  </span>
                                </a>
                              </div>
                              <div>
                                {/* <a href="Javascript:void(0);" style={{color:'#00ff00'}}>Less</a> */}
                              </div>
                            </div>
                          </td>
                        ) : (
                          <td>
                            <div
                              style={{
                                display: "inline-block",
                                textAlign: "center",
                                marginRight: "10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "inline",
                                }}
                              >
                                <a
                                  href="Javascript:void(0);"
                                  className="avatar"
                                >
                                  <img
                                    alt=""
                                    src={
                                      users[0]?.emergencyInfo?.image
                                        ? URLS.backendStatic +
                                          "/" +
                                          users[0]?.emergencyInfo?.image
                                        : "assets/img/profiles/avatar-02.jpg"
                                    }
                                  />
                                </a>
                              </div>
                              <div>
                                <a href="Javascript:void(0);">
                                  {users[0]?.personalInfo?.name?.first +
                                    users[0]?.personalInfo?.name?.last}
                                </a>
                              </div>
                            </div>

                            <div
                              style={{
                                display: "inline-block",
                                textAlign: "center",
                                marginRight: "10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "inline",
                                }}
                              >
                                <a
                                  href="Javascript:void(0);"
                                  className="avatar"
                                >
                                  <img
                                    alt=""
                                    src={
                                      users[1]?.emergencyInfo?.image
                                        ? URLS.backendStatic +
                                          "/" +
                                          users[1]?.emergencyInfo?.image
                                        : "assets/img/profiles/avatar-02.jpg"
                                    }
                                  />
                                </a>
                              </div>
                              <div>
                                <a href="Javascript:void(0);">
                                  {users[1]?.personalInfo?.name?.first +
                                    users[1]?.personalInfo?.name?.last}
                                </a>
                              </div>
                            </div>
                            <div
                              style={{
                                display: "inline-block",
                                textAlign: "center",
                                marginRight: "10px",
                                verticalAlign: "inherit",
                              }}
                              onClick={showAllAssigneesAllowance(al._id)}
                            >
                              <div
                                style={{
                                  display: "inline",
                                }}
                              >
                                <a
                                  href="Javascript:void(0);"
                                  className="avatar"
                                  style={{
                                    backgroundColor: "#ff5e3a",
                                  }}
                                >
                                  <span>+{users.length - 2}</span>
                                </a>
                              </div>
                              <div>
                                {/* <a href="Javascript:void(0);" style={{color:'#ff5e3a'}}>More</a> */}
                              </div>
                            </div>
                          </td>
                        )}
                      </>
                    )}
                    <td className="text-right">
                      <div className="dropdown dropdown-action">
                        <span
                          aria-expanded="false"
                          data-toggle="dropdown"
                          className="action-icon dropdown-toggle p-cursor"
                        >
                          <i className="material-icons">more_vert</i>
                        </span>
                        <div className="dropdown-menu dropdown-menu-right">
                          <span
                            className="dropdown-item p-cursor"
                            data-toggle="modal"
                            data-target="#edit_allowance"
                            onClick={() => updateAllowanceSlug(al)}
                          >
                            <i className="fa fa-pencil m-r-5"></i> Edit
                          </span>
                          <span
                            className="dropdown-item p-cursor"
                            data-toggle="modal"
                            data-target="#delete_allowance"
                            onClick={() => updateAllowanceSlug(al)}
                          >
                            <i className="fa fa-trash-o m-r-5"></i> Delete
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DisplayLeaveAllowance;
