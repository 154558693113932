import React from "react";
import { Form } from "react-bootstrap";

const EditCustomPolicyModal = ({
  editCustomPolicy,
  assigneesGroup,
  departments,
  jobGrades,
  employeesLeft,
  selectedEmployeesLeft,
  selectedEmployeesRight,
  employeesRight,
  editButtonText,
  saveEditCustomPolicy,
  updateEditCustomPolicyField,
  updateSearchFilterDepartment,
  updateSearchFilterJobGrade,
  updateSelectedEmployeesLeft,
  customleaveShiftToRightAll,
  shiftLeftSelectedToRightEdit,
  shiftRightSelectedToLeftEdit,
  customleaveShiftToLeftAll,
  updateSelectedEmployeesRight,
}) => {
  return (
    <div
      id="edit_custom_policy"
      className="modal custom-modal fade"
      role="dialog"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-lg"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Edit Custom Policy</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={saveEditCustomPolicy}>
              <div className="form-group">
                <label>
                  Policy Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={editCustomPolicy.name}
                  onChange={updateEditCustomPolicyField}
                />
              </div>
              <div className="form-group">
                <label>
                  Days <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="days"
                  value={editCustomPolicy.days}
                  onChange={updateEditCustomPolicyField}
                />
              </div>
              <div className="form-group leave-duallist">
                <label>Add employee</label>

                {/*<!-- Search Filter -->*/}
                <div className="row filter-row">
                  <div className="col-sm-6 col-md-4">
                    <div className="form-group form-focus">
                      <Form.Control
                        as="select"
                        value={assigneesGroup.department}
                        onChange={updateSearchFilterDepartment}
                      >
                        <option value="">Select Department</option>
                        {departments.length
                          ? departments.map((department) => {
                              return (
                                <option value={department._id}>
                                  {department.name}
                                </option>
                              );
                            })
                          : null}
                      </Form.Control>
                      <label className="focus-label">Department</label>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4">
                    <div className="form-group form-focus">
                      <Form.Control
                        as="select"
                        value={assigneesGroup.grade}
                        onChange={updateSearchFilterJobGrade}
                      >
                        <option value="">Select Job Grade</option>
                        {jobGrades.length
                          ? jobGrades.map((jobGrade) => {
                              return (
                                <option value={jobGrade._id}>
                                  {jobGrade.name}
                                </option>
                              );
                            })
                          : null}
                      </Form.Control>
                      <label className="focus-label">Job Grade</label>
                    </div>
                  </div>
                </div>
                {/*<!-- /Search Filter -->*/}

                <div className="row">
                  <div className="col-lg-5 col-sm-5">
                    <select
                      name="edit_customleave_from"
                      id="edit_customleave_select_from"
                      className="form-control"
                      size="5"
                      multiple="multiple"
                      onChange={updateSelectedEmployeesLeft}
                    >
                      {employeesLeft.length
                        ? employeesLeft.map((emp) => {
                            return <option value={emp._id}>{emp.name}</option>;
                          })
                        : null}
                    </select>
                  </div>
                  <div className="multiselect-controls col-lg-2 col-sm-2">
                    <button
                      type="button"
                      id="edit_customleave_select_rightAll"
                      className="btn btn-block btn-white"
                      onClick={customleaveShiftToRightAll}
                      disabled={employeesLeft.length ? false : true}
                    >
                      <i className="fa fa-forward"></i>
                    </button>
                    <button
                      type="button"
                      id="edit_customleave_select_rightSelected"
                      className="btn btn-block btn-white"
                      onClick={shiftLeftSelectedToRightEdit}
                      disabled={selectedEmployeesLeft.length ? false : true}
                    >
                      <i className="fa fa-chevron-right"></i>
                    </button>
                    <button
                      type="button"
                      id="edit_customleave_select_leftSelected"
                      className="btn btn-block btn-white"
                      onClick={shiftRightSelectedToLeftEdit}
                      disabled={selectedEmployeesRight.length ? false : true}
                    >
                      <i className="fa fa-chevron-left"></i>
                    </button>
                    <button
                      type="button"
                      id="edit_customleave_select_leftAll"
                      className="btn btn-block btn-white"
                      onClick={customleaveShiftToLeftAll}
                      disabled={employeesRight.length ? false : true}
                    >
                      <i className="fa fa-backward"></i>
                    </button>
                  </div>
                  <div className="col-lg-5 col-sm-5">
                    <select
                      name="customleave_to"
                      id="edit_customleave_select_to"
                      className="form-control"
                      size="8"
                      multiple="multiple"
                      onChange={updateSelectedEmployeesRight}
                    >
                      {employeesRight.length
                        ? employeesRight.map((emp) => {
                            return <option value={emp._id}>{emp.name}</option>;
                          })
                        : null}
                    </select>
                  </div>
                </div>
              </div>

              <div className="submit-section">
                <button className="btn btn-primary submit-btn">
                  {editButtonText}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCustomPolicyModal;
