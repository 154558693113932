import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row, Table } from "react-bootstrap";
import Select from "react-select";
import { read, utils } from "xlsx";
import { ImportIcon } from "../icons";
import Cookies from "universal-cookie";
import { fetchActionsUtil, useCookie } from "../../utils";
import { URLS } from "../urls";
import { toast } from "react-toastify";
import ModalLoader from "../ModalLoader";
import moment from "moment";

export const AddMultipleBranchToUser = ({ show, handleClose, refresh }) => {
  const branch_id = useCookie("branch_id");
  const token = "dummy token";

  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    grades: [],
    department: [],
    category: [],
  });

  // const [gradeId, setGradeId] = useState("");
  // const [departmentId, setDepartmentId] = useState("");
  const [branches, setBranches] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [employeesDetails, setEmployeesDetails] = useState([]);
  const [empBranches, setEmpBranches] = useState([]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const token = "dummy token";

      const gradesData = fetchActionsUtil(
        `${URLS.backendJobGrades}/get/${branch_id}`,
        "GET",
        token
      );

      const departmentsData = fetchActionsUtil(
        `${URLS.backendDepartments}/get/${branch_id}`,
        "GET",
        token
      );

      const categoryData = fetchActionsUtil(
        `${URLS.backendJobCategory}/get/${branch_id}`,
        "GET",
        token
      );

      const employeeData = fetchActionsUtil(
        `${URLS.backendUsers}/getAllUsers/${branch_id}`,
        "POST",
        token
      );

      const branchesData = fetchActionsUtil(`${URLS.backendBranch}/get`);

      const [
        gradesResult,
        departmentsResult,
        categoryResult,
        employeeResult,
        branchesResult,
      ] = await Promise.all([
        gradesData,
        departmentsData,
        categoryData,
        employeeData,
        branchesData,
      ]);

      const mapBranches = branchesResult?.branches.map((el) => ({
        value: el._id,
        label: el.name,
      }));

      const grades = gradesResult?.jobGrades?.map((el) => ({
        value: el?._id,
        label: el?.name,
      }));
      const department = departmentsResult?.departments?.map((el) => ({
        value: el?._id,
        label: el?.name,
      }));

      const category = categoryResult?.jobCategories?.map((el) => ({
        value: el?._id,
        label: el?.name,
      }));

      const employee = employeeResult.users?.map((el) => ({
        value: el?._id,
        label: `${el?.name?.first || ""} ${el?.name?.last || ""}`,
      }));

      setEmployees(employee || []);
      setBranches(mapBranches || []);
      setState({
        grades,
        department,
        category,
      });
    } catch (error) {
      console.log(error);
      toast.error("Fetching Error !!!");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      fetchData();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const saveUsers = async () => {
    if (!employeesDetails?.length > 0) {
      return toast.error("Please select employees", {
        position: "top-right",
      });
    }
    if (!empBranches?.length > 0) {
      return toast.error("Please select branches", {
        position: "top-right",
      });
    }

    const result = await fetchActionsUtil(
      `${URLS.backendUsers}/add-Multiple-Branches`,
      "POST",
      "token",
      {
        users: employeesDetails,
        branches: empBranches,
      }
    );

    if (result.success) {
      toast.success("Branches saved successfully", {
        position: "top-right",
      });
      handleClose();
      refresh();
    } else {
      toast.error("Error!!!", {
        position: "top-right",
      });
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="md"
    >
      <Modal.Header closeButton>
        <Modal.Title>Add multiple branch to user</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-4">
          <div className="my-3">
            <div className="col-md-12">
              <div className="form-group">
                <label className="col-form-label">Select Employees</label>
                <Select
                  closeMenuOnSelect={true}
                  isMulti
                  isSearchable={true}
                  value={
                    employees?.length > 0
                      ? employees?.filter((d) =>
                          employeesDetails?.find((e) => e.value === d.value)
                        )
                      : []
                  }
                  onChange={(selected) => {
                    setEmployeesDetails(selected);
                  }}
                  options={employees}
                />
              </div>
            </div>
          </div>
          {/*  */}

          <div className="my-3">
            <div className="col-md-12">
              <div className="form-group">
                <label className="col-form-label">Select Branches</label>
                <Select
                  closeMenuOnSelect={true}
                  isMulti
                  isSearchable={true}
                  value={
                    branches?.length > 0
                      ? branches?.filter((d) =>
                          empBranches?.find((e) => e.value === d.value)
                        )
                      : []
                  }
                  onChange={(selected) => {
                    setEmpBranches(selected);
                  }}
                  options={branches}
                />
              </div>
            </div>
          </div>
          {/*  */}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-between my-3 mx-4">
          <div className="mx-4">
            <button className="btn btn-secondary" onClick={handleClose}>
              Cancel
            </button>
          </div>
          <div>
            <button className="btn btn-primary" onClick={saveUsers}>
              Save
            </button>
          </div>
        </div>
      </Modal.Footer>

      <ModalLoader show={isLoading} />
    </Modal>
  );
};
