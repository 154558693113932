import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import { Form, Modal, Dropdown } from "react-bootstrap";
import ModalLoader from "../ModalLoader";
import { toast } from "react-toastify";
import { URLS } from "../urls";
import {
  chooseAttendanceStatusColor,
  fetchActionsUtil,
  stringifyQueryString,
  useCookie,
} from "../../utils";
import moment from "moment";
import TableComponent from "../TableComponent";
import DisplaySingleModal from "./DisplaySingleModal";
import AddAttendanceModal from "./AddAttendance";
import { DownloadIcon } from "../icons";
import "./attendance.css";
import { Add, CalendarTodayOutlined } from "@mui/icons-material";
import NoTableItem from "../NoTableItem";
import { UseQueryData } from "../UseQueryData";
import CustomPagination from "../Paginations";

const chooseStatus = [
  "No SignOut",
  "Early Closure",
  "Late",
  "Absent",
  "Good",
  "OnLeave",
  "Permitted",
  "Not a staff",
  "Weekend",
];

function NewTimeSheet() {
  // ------------------ Filter Search -------------------//
  const initialQueryParams = {
    limit: 20,
    page: 1,
    id: "",
    name: "",
    payrollYear: "",
    status: "",
    payrollMonth: "",
  };
  const { queryParams, theQueryParams, onChangeInput, setQueryParams } =
    UseQueryData({
      initialQueryParams,
      timer: 1500,
    });
  // ------------------ Filter Search -------------------//

  const [returnParams, setReturnParams] = useState({
    count: 0,
    endIndex: 0,
    startIndex: 0,
  });

  const branch_id = useCookie("branch_id");
  const [loading, setLoading] = useState(false);
  const [UsersID, setUsersID] = useState("");
  const [showSaveButton, setshowSaveButton] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showDisplaySingle, setShowDisplaySingle] = useState(false);
  const [timeSheetToDelete, setTimeSheetToDelete] = useState();
  const [showAttendance, setShowAttendence] = useState({});
  const [payrollDate, setPayrollDate] = useState({
    payrollYear: "",
    payrollMonth: "",
  });
  const [noneSaveEntries, setNoneSaveEntries] = useState([]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getAttendance();
    }

    return () => {
      isMounted = false;
    };
  }, [queryParams]);

  const getAttendance = async () => {
    try {
      setLoading(true);

      const result = await fetchActionsUtil(
        `${URLS.backendattendance}/get/${branch_id}?${stringifyQueryString(
          queryParams
        )}`
      );

      setShowAttendence({ excellDetails: result?.attendance });

      setReturnParams({
        count: result?.count,
        endIndex: result?.endIndex,
        startIndex: result?.startIndex,
      });
    } catch (error) {
      console.log(error);

      toast("Unable to get TimeSheet", {
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const SaveAttendance = () => {
    setLoading(true);
    const token = "dummy token";
    fetch(`${URLS.backendattendance}/add`, {
      method: "POST",
      body: JSON.stringify({
        showAttendance,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        toast("Save Successfully", {
          type: "success",
        });
        setLoading(false);
        setshowSaveButton(false);
      })
      .catch((err) => {
        setLoading(false);
        setshowSaveButton(false);
        toast("Unable to get TimeSheet", {
          type: "error",
        });
      });
  };

  const deleteTimeSheet = (data) => {
    setTimeSheetToDelete();
    setLoading(true);
    const token = "dummy token";
    fetch(`${URLS.backendPayroll}/delete-time-sheet`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          toast("Deleted successfully", {
            type: "success",
          });
          getAttendance();
        } else {
          throw Error();
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast("Unable to delete", {
          type: "error",
        });
      });
  };

  const onRowClick = (attendance) => {
    setUsersID(attendance.ID);
    setShowDisplaySingle(true);
  };

  const tableHead = () => {
    return (
      <thead className="table">
        <tr>
          <th>No</th>
          <th>Employee Name </th>
          <th>Employee ID </th>
          <th>Date </th>
          <th>In-Time</th>
          <th>Out-Time</th>
          <th>Result</th>
        </tr>
      </thead>
    );
  };

  const tableBodyData = (attendance, index) => {
    return (
      <>
        <td>{index + 1}</td>
        <td>{attendance.Name}</td>
        <td>{attendance.ID}</td>
        <td className="d-flex gap-3 align-items-center">
          {" "}
          <span>
            {" "}
            <CalendarTodayOutlined fontSize="small" />{" "}
          </span>{" "}
          <p>{moment(attendance?.Date)?.format("MMMM Do YYYY")}</p>{" "}
        </td>
        <td>{attendance.in}</td>
        <td>{attendance.out}</td>
        <td>
          <p
            className={`tw-status ${chooseAttendanceStatusColor(
              attendance.result
            )}`}
          >
            {attendance.result}
          </p>
        </td>
      </>
    );
  };

  const dropDownFunction = (qty) => {
    setQueryParams((el) => ({ ...el, limit: qty }));
  };

  return (
    <body>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex, nofollow"></meta>
        <title>InvexERP</title>
      </Helmet>
      <div className="main-wrapper">
        <Topbar />
        <Sidebar />
        <section className="bg-white page-header position-relative w-100 page-header-2">
          <div className="page-wrapper">
            <div className="d-flex w-100  m-0 p-0 align-items-center justify-content-between gap-5">
              <div className="">
                <h4 className="page-title page-title-alt">Attendance</h4>
                <p className="page-paragraph">All employees attendance</p>
              </div>
              <div className="d-flex">
                <div className="mx-3">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant=""
                      className="btn secondary"
                      bsPrefix=""
                    >
                      Template <DownloadIcon />
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      popperConfig={{
                        strategy: "fixed",
                      }}
                      renderOnMount
                      className="text-center"
                    >
                      <Dropdown.Item as="div">
                        <a
                          href={`/excel_templates/Attendance_Sample.csv`}
                          target="blank"
                          title="Download"
                          download=""
                        >
                          Template to import attendance
                        </a>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <button
                  className="btn add-btn"
                  onClick={() => setShowUploadModal(true)}
                >
                  <Add />
                  Upload Attendance
                </button>
              </div>
            </div>
          </div>
        </section>
        {/*<!-- Page Wrapper -->*/}
        <div className="page-wrapper  mt-n10">
          {/*<!-- Page Content -->*/}
          <div className="content container-fluid">
            {/*<!-- Page Title -->*/}

            {/*<!-- /Page Title -->*/}
            {/*<!-- Content Starts -->*/}

            <div className="p-3">
              <div className="">
                <div className="form-inline allowance-grid-5-2">
                  <div className="">
                    <Form.Group className="">
                      <Form.Control
                        className="form-control-alt"
                        name="id"
                        type="text"
                        placeholder="Employee ID"
                        value={theQueryParams.id}
                        onChange={onChangeInput}
                      />
                    </Form.Group>
                  </div>
                  <div className="">
                    <Form.Group className="">
                      <Form.Control
                        className="form-control-alt"
                        name="name"
                        placeholder="Employee name"
                        type="text"
                        value={theQueryParams.name}
                        onChange={onChangeInput}
                      />
                    </Form.Group>
                  </div>

                  <div>
                    <select
                      className="form-control form-control-alt"
                      name="payrollMonth"
                      placeholder="Select month"
                      value={payrollDate.payrollMonth}
                      onChange={(e) => {
                        setPayrollDate({
                          ...payrollDate,
                          payrollMonth: e.target.value,
                        });
                        onChangeInput(e);
                      }}
                    >
                      <option>Select month</option>
                      {[...Array(12)].map((e, index) => (
                        <option key={index}>{index + 1}</option>
                      ))}
                    </select>
                  </div>

                  <div>
                    <input
                      type="number"
                      className="form-control form-control-alt"
                      placeholder="Enter search year"
                      name="payrollYear"
                      value={payrollDate.payrollYear}
                      onChange={(e) => {
                        setPayrollDate({
                          ...payrollDate,
                          payrollYear: e.target.value,
                        });
                        onChangeInput(e);
                      }}
                    />
                  </div>
                  <div>
                    <select
                      className="form-control form-control-alt"
                      onChange={onChangeInput}
                      name="status"
                      value={theQueryParams.status}
                    >
                      <option selected value="">
                        Select status
                      </option>
                      {chooseStatus?.map((el) => (
                        <option value={el}>{el}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-end">
                {showAttendance?.excellDetails?.length > 0 &&
                  showSaveButton && (
                    <button
                      onClick={SaveAttendance}
                      className="btn btn-primary"
                    >
                      Save Attendance
                    </button>
                  )}
              </div>

              <div className="mt-4">
                {/*<!-- JobGradeHistory Table -->*/}
                <div className="info">
                  <div className="table-height">
                    {showAttendance?.excellDetails?.length > 0 ? (
                      <TableComponent
                        responsive
                        borderless
                        // striped
                        sticky
                        // hover
                        tableHeadsFunction={tableHead}
                        mainDataArray={showAttendance?.excellDetails}
                        tableDataRowFunction={tableBodyData}
                        className="product-table text-nowrap"
                        onDoubleClickRow={onRowClick}
                      />
                    ) : (
                      <NoTableItem />
                    )}
                  </div>
                </div>
                {/*<!-- Table --> */}
              </div>
            </div>

            {/*<!-- /Content End -->*/}

            {!showSaveButton ? (
              <div className="my-4">
                <CustomPagination
                  setQueryParams={setQueryParams}
                  startIndex={returnParams.startIndex}
                  endIndex={returnParams.endIndex}
                  count={returnParams.count}
                  limit={queryParams.limit}
                  page={queryParams.page}
                  dropDownFunction={dropDownFunction}
                />
              </div>
            ) : null}
          </div>
          {/*<!-- /Page Content -->*/}
        </div>
        {/*<!-- /Page Wrapper -->*/}
      </div>
      {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
      <div className="sidebar-overlay" data-reff=""></div>
      <ModalLoader show={loading} />
      {showUploadModal && (
        <AddAttendanceModal
          setShowUploadModal={setShowUploadModal}
          showAttendance={setShowAttendence}
          updatesetPayrollDate={setPayrollDate}
          setshowSaveButton={setshowSaveButton}
          setNoneSaveEntries={setNoneSaveEntries}
        />
      )}
      {timeSheetToDelete && (
        <DeleteTimeSheetModal
          setTimeSheetToDelete={setTimeSheetToDelete}
          deleteTimeSheet={deleteTimeSheet}
          timeSheetToDelete={timeSheetToDelete}
        />
      )}
      {showDisplaySingle && (
        <DisplaySingleModal
          setShowDisplaySingle={setShowDisplaySingle}
          UserId={UsersID}
          selectedTimes={payrollDate}
          noneSaveEntries={noneSaveEntries}
        />
      )}
    </body>
  );
}

function DeleteTimeSheetModal({
  timeSheetToDelete,
  setTimeSheetToDelete,
  deleteTimeSheet,
}) {
  return (
    <Modal
      centered
      show={true}
      onHide={() => {
        setTimeSheetToDelete();
      }}
      className="custom-modal"
    >
      <Modal.Body>
        <div className="form-header">
          <h3>Delete Allowance</h3>
          <p>Are you sure want to delete?</p>
        </div>
        <div className="modal-btn delete-action p-cursor">
          <div className="row">
            <div className="col-6">
              <div
                className="btn  continue-btn btn-danger "
                onClick={() => deleteTimeSheet(timeSheetToDelete)}
              >
                Delete
              </div>
            </div>
            <div className="col-6">
              <div
                data-dismiss="modal"
                className="btn btn-primary cancel-btn"
                onClick={() => {
                  setTimeSheetToDelete();
                }}
              >
                Cancel
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    User: state.api.User,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NewTimeSheet));
