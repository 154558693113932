import React from "react";
import { URLS } from "../urls";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { MessageIcon, PhoneIcon } from "../icons";

const Employee = ({ employee, returnDropDownItems, DropDowns }) => {
  const {
    Staff_ID,
    jobInfo,
    personalInfo,
    _id,
    emergencyInfo,
    hasResigned,
    onLeave,
  } = employee;

  const href = `${URLS.profile}/${_id}`;

  const fullName =
    (personalInfo?.name?.first ?? "") +
    " " +
    (personalInfo?.name?.middle ?? "") +
    " " +
    (personalInfo?.name?.last ?? "");

  const displayImage = emergencyInfo && emergencyInfo.image;

  const profileImage = displayImage
    ? URLS.backendStatic + "/" + displayImage
    : "/assets/img/profiles/avatar-03.jpg";
  return (
    <div className="mb-3">
      <div className="new-employee-card">
        <div className="d-flex justify-content-between align-items-baseline">
          <div className="">
            <Link to={href} className="avatar">
              <img src={profileImage} alt={fullName} />
            </Link>
          </div>

          <div className="d-flex justify-content-between align-items-baseline">
            <div className="">
              <p className="card-active">{onLeave || "Active"}</p>
            </div>

            <Dropdown>
              <Dropdown.Toggle variant="" bsPrefix="">
                <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
              </Dropdown.Toggle>

              <Dropdown.Menu
                popperConfig={{
                  strategy: "fixed",
                }}
                renderOnMount
              >
                {returnDropDownItems()?.map((el) => (
                  <DropDowns
                    action={() => el.action({ employee })}
                    icon={el.icon}
                    name={el.name}
                  />
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        <h4 className="user-name m-t-10 mb-0 text-ellipsis">
          <Link className="text-dark" to={href}>
            {fullName}
          </Link>
          {"  "}
          <small
            className="text-muted small p-cursor"
            title={jobInfo?.employeeId}
          >
            (#{jobInfo?.employeeId?.substring(0, 5) + "..."})
          </small>
        </h4>
        <div className="text-muted">
          <p>{jobInfo?.grade?.name}</p>
        </div>
        {hasResigned && <div className="text-danger">Resigned</div>}

        <div className="d-flex justify-content-between align-items-baseline my-3">
          <div>
            <p className="text-muted">Department</p>
            <p>{jobInfo?.department?.name}</p>
          </div>
          <div>
            <p className="text-muted">Hired Date</p>
            <p>{jobInfo?.joinDate}</p>
          </div>
        </div>
        <div>
          <div className="d-flex align-items-baseline">
            <p className="mr-3 mb-2">
              <MessageIcon />{" "}
            </p>
            <p>{jobInfo?.email}</p>
          </div>
          <div className="d-flex align-items-baseline">
            <p className="mr-3">
              <PhoneIcon />
            </p>
            <p>{personalInfo?.phone?.home}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Employee;
