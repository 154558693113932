import { useEffect, useState } from "react";
import { useDebounceQueryString, useQueryString } from "./UseQueryStrings";

export const UseQueryData = ({ initialQueryParams = {}, timer = 1000 }) => {
  const { queryParams, updateQueryString } = useQueryString();
  const [theQueryParams, setQueryParams] = useState({ ...initialQueryParams });
  const debouncedFilterParams = useDebounceQueryString(theQueryParams, timer);

  useEffect(() => {
    updateQueryString(theQueryParams);
  }, [debouncedFilterParams]);

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    setQueryParams((el) => ({ ...el, page: 1, [name]: value }));
  };

  return { queryParams, theQueryParams, onChangeInput, setQueryParams };
};
