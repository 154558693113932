import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Modal, Row, Col, Form } from "react-bootstrap";

import { URLS } from "../urls";

import DatePicker from "react-datetime";
import moment from "moment";
import "../../../src/assets/css/ga-styles.css";
import { toast } from "react-toastify";
import { useCookie } from "../../utils";

export function AddTerminationModal({ show, handleClose, setReload, userId }) {
  const branch_id = useCookie("branch_id");
  const date = new Date();
  const initialTerminationData = {
    employeeId: "",
    departmentId: "",
    reason: "",
    noticeDate: moment(date).format("DD/MM/YYYY"),
    terminationDate: moment(date).format("DD/MM/YYYY"),
    terminationType: ["misconduct"],
    branch_id,
  };

  const [employees, setEmployees] = useState([]);
  const [terminationData, setTerminationData] = useState(
    initialTerminationData
  );
  const [mapEmployees, setMapEmployees] = useState([]);

  const [terminations, setTerminations] = useState([]);

  useEffect(() => {
    getEmployees();
    return () => {};
  }, []);

  const setEmployeeId = (userId) => {
    const employee = employees.find((employee) => employee.userId === userId);
    setTerminationData({
      ...terminationData,
      employeeId: userId,
      departmentId: employee?.jobInfo?.department?._id,
    });
  };

  useEffect(() => {
    if (userId && employees?.length > 0) {
      setEmployeeId(userId);
    }
  }, [userId, employees]);

  const getEmployees = async () => {
    const token = "dummy token";

    const resTer = await fetch(
      `${URLS.backendTermination}/get-terminations/${branch_id}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );

    const res = await fetch(`${URLS.backendEmployees}/get/${branch_id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    });

    const { employees } = await res.json();

    const terminate = await resTer.json();

    setTerminations(terminate?.terminations);
    setEmployees(employees);
    if (employees) {
      const em = employees
        .filter(
          (el) =>
            el.hasResigned !== true &&
            el.isTerminated !== true &&
            el.hasSuspended !== true
        )
        .map((d) => ({
          ...d,
          label: `${
            d?.personalInfo?.name?.first ? d?.personalInfo?.name?.first : ""
          } ${
            d?.personalInfo?.name?.middle ? d?.personalInfo?.name?.middle : ""
          } ${d?.personalInfo?.name?.last ? d?.personalInfo?.name?.last : ""}`,
          value: d.userId,
        }));
      setMapEmployees(em);
    }
  };

  const saveTermination = (e) => {
    e.preventDefault();

    const token = "dummy token";
    fetch(`${URLS.backendTermination}/save`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({ terminationData }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          setTerminations([...terminations, result.termination]);
          setTerminationData({ ...initialTerminationData });
          setReload && setReload();
          handleClose && handleClose();
          toast("Employee terminated", {
            type: "success",
          });
        } else {
          if (result.exists) {
            toast("Employee has already been terminated", {
              type: "error",
            });
            return;
          }
          throw Error();
        }
      })
      .catch((err) => {
        console.log(err);
        toast("Unable to submit", {
          type: "error",
        });
      });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby=""
      centered
      size="md"
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Termination</Modal.Title>
      </Modal.Header>
      <div>
        <div className="">
          <div className="">
            <div className="modal-body">
              <form onSubmit={(e) => saveTermination(e)}>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <span className="text-danger">*</span>Employee
                      </Form.Label>
                      {employees && (
                        <Select
                          closeMenuOnSelect={true}
                          isSearchable={true}
                          value={mapEmployees.filter(
                            (el) => el.value === terminationData.employeeId
                          )}
                          onChange={(selected) => {
                            setEmployeeId(selected?.value);
                          }}
                          options={mapEmployees}
                          isDisabled={userId}
                        />
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group>
                  <Form.Label>
                    Termination Type <span className="text-danger">*</span>
                  </Form.Label>
                  <Row>
                    {terminationData.terminationType.map((type, index) => {
                      return (
                        <Col md="6" key={index} className="mb-2 d-flex">
                          <select
                            value={type}
                            className="form-control"
                            onChange={(e) => {
                              const terminationType =
                                terminationData.terminationType;
                              terminationType[index] = e.target.value;
                              setTerminationData({
                                ...terminationData,
                                terminationType,
                              });
                            }}
                          >
                            <option value="">Select Termination type</option>
                            <option value="misconduct">Misconduct</option>
                            <option value="abuse">Abuse</option>
                            <option value="others">Others</option>
                          </select>
                          <span
                            className="m-3"
                            title="remove"
                            onClick={() => {
                              if (terminationData.terminationType.length > 1) {
                                const terminationType =
                                  terminationData.terminationType;
                                terminationType.splice(index, 1);
                                setTerminationData({
                                  ...terminationData,
                                  terminationType,
                                });
                              }
                            }}
                          >
                            <i className="fa fa-times"></i>
                          </span>
                        </Col>
                      );
                    })}
                  </Row>
                  <div className="mb-3">
                    <div
                      className="btn btn-primary p-cursor"
                      onClick={() =>
                        setTerminationData({
                          ...terminationData,
                          terminationType: [
                            ...terminationData.terminationType,
                            "",
                          ],
                        })
                      }
                    >
                      <i className="fa fa-plus"></i> Add Type
                    </div>
                  </div>
                </Form.Group>

                {/*  */}

                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Termination Date <span className="text-danger">*</span>
                      </Form.Label>
                      <DatePicker
                        id={"terminationDate"}
                        value={terminationData.terminationDate}
                        onChange={(e) => {
                          setTerminationData({
                            ...terminationData,
                            terminationDate: moment(e._d).format("DD/MM/YYYY"),
                          });
                        }}
                        closeOnSelect={true}
                      />
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Notice Date <span className="text-danger">*</span>
                      </Form.Label>
                      <DatePicker
                        id={"noticeDate"}
                        value={terminationData.noticeDate}
                        onChange={(e) => {
                          setTerminationData({
                            ...terminationData,
                            noticeDate: moment(e._d).format("DD/MM/YYYY"),
                          });
                        }}
                        closeOnSelect={true}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group className="mb-3">
                  <Form.Label>
                    <span className="text-danger">*</span> Reason
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    name="reason"
                    value={terminationData.reason}
                    onChange={(e) => {
                      setTerminationData({
                        ...terminationData,
                        reason: e.target.value,
                      });
                    }}
                    rows={4}
                    placeholder="..."
                    required
                  />
                </Form.Group>

                <div className="submit-section">
                  <button className="btn btn-primary submit-btn">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
