import React from "react";
import Select from "react-select";

const DisplayMainLeave = ({
  leave,
  editLeaveFields,
  idx,
  updateLeaveDays,
  updateLeaveDuration,
  editLeaveDays,
  editLeaveDuration,
  cancelSaveLeaveDays,
  cancelSaveLeaveDuration,
  saveLeaveDays,
  updateLeaveCarryNo,
  updateLeaveCarryYes,
  updateLeaveCarryMax,
  editLeaveCarry,
  cancelSaveLeaveCarry,
  branches,
  updateLeaveBranch,
  editLeaveBranches,
  cancelSaveLeaveBranch,
  saveLeaveDaysBranches,
}) => {
  return (
    <div className="leave-item">
      <div className="leave-row">
        <div className="leave-left">
          <div className="input-box">
            <div className="form-group">
              <label>Days</label>
              <input
                type="text"
                className="form-control"
                disabled={!editLeaveFields[leave._id]["days"]}
                value={leave.days}
                onChange={updateLeaveDays(leave)}
              />
            </div>
          </div>
        </div>
        <div className="leave-right-custom">
          <button
            hidden={editLeaveFields[leave._id]["days"]}
            className="leave-edit-btn-custom"
            disabled={leave.isActive ? true : false}
            onClick={editLeaveDays(leave)}
          >
            Edit
          </button>
          <button
            hidden={!editLeaveFields[leave._id]["days"]}
            className="btn btn-white "
            onClick={cancelSaveLeaveDays(leave)}
          >
            Cancel
          </button>
          <button
            hidden={!editLeaveFields[leave._id]["days"]}
            className="btn btn-primary leave-save-btn"
            type="submit"
            onClick={saveLeaveDays(leave, "days")}
          >
            Save
          </button>
        </div>
      </div>
      <div className="leave-row">
        <div className="leave-left">
          <div className="input-box">
            <label className="d-block">Carry forward</label>
            <div className="leave-inline-form">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="carryForward"
                  id={"carry_no_" + idx}
                  value="option1"
                  disabled={!editLeaveFields[leave._id]["carry"]}
                  checked={!leave.carry}
                  onChange={updateLeaveCarryNo(leave)}
                />
                <label className="form-check-label" for={"carry_no_" + idx}>
                  No
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="carryForward"
                  id={"carry_yes_" + idx}
                  value="option2"
                  disabled={!editLeaveFields[leave._id]["carry"]}
                  checked={leave.carry}
                  onChange={updateLeaveCarryYes(leave)}
                />
                <label className="form-check-label" for={"carry_yes_" + idx}>
                  Yes
                </label>
              </div>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">Max</span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  disabled={
                    !editLeaveFields[leave._id]["carry"] || !leave.carry
                  }
                  value={leave.carryMax}
                  onChange={updateLeaveCarryMax(leave)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="leave-right-custom">
          <button
            hidden={editLeaveFields[leave._id]["carry"]}
            className="leave-edit-btn-custom"
            disabled={leave.isActive ? true : false}
            onClick={editLeaveCarry(leave)}
          >
            Edit
          </button>
          <button
            hidden={!editLeaveFields[leave._id]["carry"]}
            className="btn btn-white "
            onClick={cancelSaveLeaveCarry(leave)}
          >
            Cancel
          </button>
          <button
            hidden={!editLeaveFields[leave._id]["carry"]}
            className="btn btn-primary leave-save-btn"
            type="submit"
            onClick={saveLeaveDays(leave, "carry")}
          >
            Save
          </button>
        </div>
      </div>

      <div className="leave-row">
        <div className="leave-left">
          <div className="input-box">
            <label className="d-block">Earned leave</label>
            <div className="leave-inline-form">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id={"inlineRadio1" + idx}
                  value="option1"
                  disabled
                  checked={!leave.earned}
                />
                <label className="form-check-label" for={"inlineRadio1" + idx}>
                  No
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id={"inlineRadio2" + idx}
                  value="option2"
                  disabled
                  checked={leave.earned}
                />
                <label className="form-check-label" for={"inlineRadio2" + idx}>
                  Yes
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="leave-right-custom">
          <button
            className="leave-edit-btn-custom"
            disabled={leave.isActive ? true : false}
          >
            Edit
          </button>
        </div>
      </div>
      {/* ----------------- Branch */}
      <div className="leave-row">
        <div className="leave-left">
          <div className="input-box">
            <div className="form-group">
              <label>Select Branch</label>

              <Select
                closeMenuOnSelect={true}
                isMulti
                isSearchable={true}
                value={branches?.filter((d) =>
                  leave?.branches?.find((e) => e.value === d.value)
                )}
                onChange={(selected) => {
                  updateLeaveBranch(leave, selected);
                }}
                isDisabled={!editLeaveFields[leave._id]["branches"]}
                options={branches}
              />
            </div>
          </div>
        </div>
        <div className="leave-right-custom">
          <button
            hidden={editLeaveFields[leave._id]["branches"]}
            className="leave-edit-btn-custom"
            disabled={leave.isActive ? true : false}
            onClick={editLeaveBranches(leave)}
          >
            Edit
          </button>
          <button
            hidden={!editLeaveFields[leave._id]["branches"]}
            className="btn btn-white "
            onClick={cancelSaveLeaveBranch(leave)}
          >
            Cancel
          </button>
          <button
            hidden={!editLeaveFields[leave._id]["branches"]}
            className="btn btn-primary leave-save-btn"
            type="submit"
            onClick={saveLeaveDaysBranches(leave, "branches")}
          >
            Save
          </button>
        </div>
      </div>
      {/*  */}
      <div className="leave-row">
        <div className="leave-left">
          <div className="input-box">
            <div className="form-group">
              <label>Duration</label>
              <input
                type="text"
                className="form-control"
                disabled={!editLeaveFields[leave._id]["duration"]}
                value={leave.duration}
                onChange={updateLeaveDuration(leave)}
              />
            </div>
          </div>
        </div>
        <div className="leave-right-custom">
          <button
            hidden={editLeaveFields[leave._id]["duration"]}
            className="leave-edit-btn-custom"
            disabled={leave.isActive ? true : false}
            onClick={editLeaveDuration(leave)}
          >
            Edit
          </button>
          <button
            hidden={!editLeaveFields[leave._id]["duration"]}
            className="btn btn-white "
            onClick={cancelSaveLeaveDuration(leave)}
          >
            Cancel
          </button>
          <button
            hidden={!editLeaveFields[leave._id]["duration"]}
            className="btn btn-primary leave-save-btn"
            type="submit"
            onClick={saveLeaveDays(leave, "duration")}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default DisplayMainLeave;
