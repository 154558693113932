import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useState } from "react";
import { toast } from "react-toastify";
import { fetchActionsUtil } from "../../utils";
import { URLS } from "../urls";

const ChangeUserPassword = ({ employee, show, handleClose }) => {
  const [password, setPassword] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  const [user, setUser] = useState({});

  useEffect(() => {
    setUser(employee);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setPassword((prevPassword) => ({
      ...prevPassword,
      [name]: value,
    }));
  };

  const changePasswordAction = async () => {
    const { newPassword, confirmPassword } = password;
    if (newPassword !== confirmPassword) {
      return toast.error("The passwords doesn't match", {
        position: "top-right",
      });
    }

    const result = await fetchActionsUtil(
      `${URLS.backendUsers}/change-password`,
      "POST",
      "token",
      {
        password: newPassword,
        Staff_ID: user?.Staff_ID,
      }
    );

    if (result.success) {
      toast.success("Password changed successfully", {
        position: "top-right",
      });
      handleClose(false);
    } else {
      toast.error("Error!!!", {
        position: "top-right",
      });
    }
  };

  return (
    <Modal show={show} onHide={() => handleClose(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          Change {user?.personalInfo?.name?.first || ""}{" "}
          {user?.personalInfo?.name?.last || ""} Password
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <div>
          <div className="container">
            <div className="form-group">
              <label htmlFor="newPassword">New Password</label>
              <input
                type="password"
                className="form-control"
                name="newPassword"
                placeholder="Enter new password"
                required
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="confirmPassword">Confirm Password</label>
              <input
                type="password"
                className="form-control"
                name="confirmPassword"
                placeholder="Confirm new password"
                required
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
        <div className="modal-btn delete-action">
          <div className="d-flex justify-content-between align-items-baseline">
            <div className="mx-4">
              <button
                data-dismiss="modal"
                className="btn btn-secondary"
                onClick={() => handleClose(false)}
              >
                Cancel
              </button>
            </div>
            <div className="mx-4">
              <button
                className="btn btn-primary"
                onClick={changePasswordAction}
              >
                Create
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ChangeUserPassword;
