import React, { Component } from "react";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Cookies from "universal-cookie";
import CheckAPI from "./components/CheckAPI.js";
import asyncComponent from "./components/AsyncComponent";

import { URLS } from "./components/urls.js";
import "@fontsource/open-sauce-sans";
import Employees from "./components/employees/Employees";
import Holidays from "./components/holidays/Holidays";
import Leaves from "./components/leaves/Leaves";
import ScheduleInterview from "./components/schedule interview/schedule-interview.jsx";
import LeavesEmployee from "./components/leaves-employee/LeavesEmployee";
import LeaveSettings from "./components/leave-settings/LeaveSettings";
import Attendance from "./components/attendance/Attendance.jsx";
import AttendanceEmployee from "./components/attendance-employee/AttendanceEmployee";
import Departments from "./components/departments/Departments";
import Designations from "./components/designations/Designations";
import Timesheet from "./components/timesheet/Timesheet";
import Promotion from "./components/promotion/Promotion";
import Resignation from "./components/resignation/Resignation";
import Termination from "./components/termination/Termination";

import Projects from "./components/projects/Projects";
import Teams from "./components/teams/Teams";
import Tasks from "./components/tasks/Tasks";
import Contracts from "./components/contracts/Contracts";
import Approvals from "./components/approvals/Approvals";
// import VoiceCall from './components/voiceCall/VoiceCall';
// import VideoCall from './components/videoCall/VideoCall';
// import OutgoingCall from './components/outgoingCall/OutgoingCall';
// import IncomingCall from './components/incomingCall/IncomingCall';
import Contacts from "./components/contacts/Contacts";
import Leads from "./components/leads/Leads";
import Estimates from "./components/estimates/Estimates";
import Invoices from "./components/invoices/Invoices";
import Payments from "./components/payments/Payments";
import Expenses from "./components/expenses/Expenses";
// import ProvidentFund from './components/providentFund/ProvidentFund';
import Taxes from "./components/taxes/Taxes";
import Salary from "./components/salary/Salary";
// import SalaryView from './components/salaryView/SalaryView';
// import PayrollItems from './components/payrollItems/PayrollItems';
import Jobs from "./components/jobs/Jobs";
import JobApplicants from "./components/job-applicants/JobApplicants";
import Tickets from "./components/tickets/Tickets";
import Events from "./components/events/Events";
import Inbox from "./components/inbox/Inbox";
import Chat from "./components/chat/Chat";
import Assets from "./components/assets/Assets";
import Policies from "./components/policies/Policies";
import Users from "./components/users/Users";
import ExpenseReports from "./components/expense-reports/ExpenseReports";
import InvoiceReports from "./components/invoice-reports/InvoiceReports";
import Settings from "./components/settings/Settings";
// import Register from './components/register/Register';
// import ForgotPassword from './components/forgotPassword/ForgotPassword';
import Profile from "./components/profile/Profile";
// import ClientProfile from './components/clientProfile/ClientProfile';
import Error404 from "./components/error404/Error404";
// import Error500 from './components/error500/Error500';
// import BlankPage from './components/blankPage/BlankPage';
import Components from "./components/components/Components";
import ClientProfile from "./components/client-profile/ClientProfile.js";
import ProjectView from "./components/project-view/ProjectView.js";
import ContractView from "./components/contract-view/ContractView.js";
import TeamView from "./components/team-view/TeamView.js";
import JobDetails from "./components/job-details/JobDetails";
import JobList from "./components/job-list/JobList";
import jobView from "./components/job-view/JobView";
import Performance from "./components/performance/Performance";
// import './assets/css/style.css';
import Loan from "./components/loan/Loan.js";

import UserHome from "./components/user-dashboard/user-home/UserHome";
import UserDashboardProfile from "./components/user-dashboard/user-profile/UserDashboardProfile";
import UserDashboardProjects from "./components/user-dashboard/user-project/UserDashboardProjects";
import UserProjectView from "./components/user-dashboard/user-project/UserProjectView";
import UserTasks from "./components/user-dashboard/user-task/UserTasks";
import UserPerformance from "./components/user-dashboard/user-performance/UserPerformance";
import UserDashboardPolicy from "./components/user-dashboard/user-policy/UserDashboardPolicy";
import UserDashboardLeaves from "./components/user-dashboard/user-leave/UserDashboardLeaves";
import UserDashboardTeams from "./components/user-dashboard/user-team/UserDashboardTeams";
import UserTeamView from "./components/user-dashboard/user-team/TeamView";
import UserDashboardContracts from "./components/user-dashboard/user-contract/UserDashboardContracts";
import UserDashboardLoan from "./components/user-dashboard/user-loan/UserDashboardLoan.js";

import DeductionSettings from "./components/deduction-settings/DeductionSettings";
import { ToastContainer } from "react-toastify";
import CreateSalary from "./components/create-salary/CreateSalary.js";
import JobGrade from "./components/job-grade/JobGrade.js";
import DeductionReports from "./components/deduction-reports/DeductionReports.js";
import PayHistory from "./components/pay-history/PayHistory.js";
import SalarySummary from "./components/salary-summary/SalarySummary.js";
import EmployeeDashboard from "./components/dashboard/EmployeeDashboard.js";
import BonusSettings from "./components/bonus-settings/BonusSettings.js";
import AllowanceSettings from "./components/allowance/AllowanceSettings.js";
import BenefitSettings from "./components/benefit/BenefitSettings.js";
import EditEmployeeJobGrade from "./components/editEmployeeJobGrade/EditEmployeeJobGrade.jsx";
import PayrollSettings from "./components/salary-settings/PayrollSettings.jsx";
import BonusReports from "./components/Reports/BonusReports.js";
import AllowanceReports from "./components/Reports/AllowanceReports.js";
import BenefitsReports from "./components/Reports/BenefitsReports.js";
import NewTimeSheet from "./components/timesheet/NewTimeSheet.js";
import Overtime from "./components/salary-settings/Overtime.jsx";
import UserAllowance from "./components/user-dashboard/user-allowance/UserAllowance.jsx";
import Permission from "./components/permission/Permission.jsx";
import Suspension from "./components/suspension/Suspension.jsx";
import Calendar from "./components/calendar/Calendar";
import Applicantprofile from "./components/applicant profile/applicantprofile.js";
import JobCategory from "./components/job-category/JobCategory.jsx";
import DayOff from "./components/DayOff/DayOff.jsx";
import userPermission from "./components/user-dashboard/user-permission/Permission.jsx";

const AsyncLandingPage = asyncComponent(() =>
  import("./components/landingPage/LandingPage.js")
);
const AsyncRoutes = asyncComponent(() => import("./components/Routes.js"));
const AsyncClients = asyncComponent(() =>
  import("./components/clients/Clients.js")
);
const AsyncActivities = asyncComponent(() =>
  import("./components/activities/Activities.js")
);

// const renderMergedProps = (component, ...rest) => {
//   const finalProps = Object.assign({}, ...rest);
//   return React.createElement(component, finalProps);
// };

const PropsRoute = ({ component: Component, path: path, ...rest }) => {
  const cookies = new Cookies();
  const token = cookies.get("token");

  return (
    <Route
      {...rest}
      render={(routeProps) => {
        const userId = cookies.get("userId");
        const subUrl = path?.split("/")[1];

        console.log(subUrl);
        if (["job-list", "job-view"].includes(subUrl)) {
          return <Component {...routeProps} />;
        }

        if (!token || !userId) {
          return <Redirect to="/login" />;
        }

        //  const subUrl = path.split("/")[1];
        const isAdmin = cookies.get("isAdmin");
        const isSuperAdmin = cookies.get("isSuperAdmin");

        if (!subUrl) {
          if (!isSuperAdmin && !isAdmin) {
            return <Redirect to="/user/profile" />;
          } else {
            return <Redirect to="/dashboard" />;
          }
        }

        if (subUrl === "dashboard" && !isSuperAdmin && !isAdmin) {
          return <Redirect to="/user/profile" />;
        } else if (subUrl === "user" && (isSuperAdmin || isAdmin)) {
          return <Redirect to="/dashboard" />;
        }

        return <Component {...routeProps} />;
      }}
    />
  );
};

class Main extends Component {
  render() {
    const url = window.location.pathname;
    console.log("check url", url);

    return (
      <div>
        <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Router>
          <div>
            <Switch>
              <Route exact path={URLS.login} component={AsyncLandingPage} />
              <PropsRoute exact path="/" component={AsyncRoutes} />
              <PropsRoute exact path={URLS.dashboard} component={AsyncRoutes} />
              <PropsRoute exact path={URLS.clients} component={AsyncClients} />
              <PropsRoute
                exact
                path={URLS.activities}
                component={AsyncActivities}
              />
              <PropsRoute exact path={URLS.employees} component={Employees} />
              <PropsRoute
                path={URLS.employeeDashboard}
                component={EmployeeDashboard}
              />
              <PropsRoute exact path={URLS.holidays} component={Holidays} />
              <PropsRoute
                exact
                path={URLS.leavesEmployee}
                component={LeavesEmployee}
              />
              <PropsRoute exact path={URLS.leaves} component={Leaves} />
              <PropsRoute
                exact
                path={URLS.leaveSettings}
                component={LeaveSettings}
              />
              <PropsRoute
                exact
                path={URLS.ScheduleInterview}
                component={ScheduleInterview}
              />
              <PropsRoute exact path={URLS.attendance} component={Attendance} />
              <PropsRoute
                exact
                path={URLS.attendanceEmployee}
                component={AttendanceEmployee}
              />
              <PropsRoute
                exact
                path={URLS.departments}
                component={Departments}
              />
              <PropsRoute
                exact
                path={URLS.designations}
                component={Designations}
              />
              <PropsRoute
                exact
                path={URLS.timesheet}
                component={NewTimeSheet}
              />
              <PropsRoute exact path={URLS.promotion} component={Promotion} />
              <PropsRoute
                exact
                path={URLS.editEmployeeJobGrade}
                component={EditEmployeeJobGrade}
              />
              <PropsRoute
                exact
                path={URLS.resignation}
                component={Resignation}
              />
              <PropsRoute
                exact
                path={URLS.termination}
                component={Termination}
              />
              <PropsRoute exact path={URLS.overtime} component={Overtime} />
              <PropsRoute exact path={URLS.projects} component={Projects} />
              <PropsRoute exact path={URLS.contracts} component={Contracts} />
              <PropsRoute exact path={URLS.approvals} component={Approvals} />
              <PropsRoute exact path={URLS.teams} component={Teams} />
              <PropsRoute exact path={URLS.tasks} component={Tasks} />
              <PropsRoute
                exact
                path={URLS.performance}
                component={Performance}
              />
              <PropsRoute exact path={URLS.contacts} component={Contacts} />
              <PropsRoute exact path={URLS.leads} component={Leads} />
              <PropsRoute exact path={URLS.estimates} component={Estimates} />
              <PropsRoute exact path={URLS.invoices} component={Invoices} />
              <PropsRoute exact path={URLS.expenses} component={Expenses} />
              <PropsRoute exact path={URLS.taxes} component={Taxes} />
              <PropsRoute exact path={URLS.salary} component={Salary} />
              <PropsRoute exact path={URLS.jobs} component={Jobs} />
              <PropsRoute exact path={URLS.tickets} component={Tickets} />
              <PropsRoute exact path={URLS.events} component={Events} />
              <PropsRoute exact path={URLS.inbox} component={Inbox} />
              <PropsRoute exact path={URLS.chat} component={Chat} />
              <PropsRoute exact path={URLS.assets} component={Assets} />
              <PropsRoute exact path={URLS.policies} component={Policies} />
              <PropsRoute exact path={URLS.users} component={Users} />
              <PropsRoute exact path={URLS.settings} component={Settings} />
              <PropsRoute path={URLS.profile} component={Profile} />
              <PropsRoute path={URLS.clientProfile} component={ClientProfile} />
              <PropsRoute path={URLS.projectView} component={ProjectView} />
              <PropsRoute path={URLS.contractView} component={ContractView} />
              <PropsRoute path={URLS.teamView} component={TeamView} />
              <PropsRoute path={URLS.jobDetail} component={JobDetails} />
              <PropsRoute exact path={URLS.jobList} component={JobList} />
              <PropsRoute
                exact
                path={URLS.expenseReports}
                component={ExpenseReports}
              />
              <PropsRoute
                exact
                path={URLS.invoiceReports}
                component={InvoiceReports}
              />
              <PropsRoute path={URLS.jobView} component={jobView} />
              <PropsRoute path={URLS.jobApplicants} component={JobApplicants} />
              <PropsRoute
                path={URLS.applicantProfile}
                component={Applicantprofile}
              />
              <PropsRoute exact path={URLS.components} component={Components} />
              <PropsRoute
                path={URLS.deductionSettings}
                component={DeductionSettings}
              />
              <PropsRoute path={URLS.bonusSettings} component={BonusSettings} />
              <PropsRoute path={URLS.allowance} component={AllowanceSettings} />
              <PropsRoute path={URLS.benefit} component={BenefitSettings} />
              <PropsRoute path={URLS.createSalary} component={CreateSalary} />
              <PropsRoute path={URLS.jobGrade} component={JobGrade} />
              <PropsRoute
                path={URLS.deductionReports}
                component={DeductionReports}
              />
              <PropsRoute path={URLS.payHistory} component={PayHistory} />
              <PropsRoute path={URLS.salarySummary} component={SalarySummary} />
              <PropsRoute path={URLS.loan} component={Loan} />
              <PropsRoute path={URLS.paySettings} component={PayrollSettings} />
              <PropsRoute path={URLS.bonusReport} component={BonusReports} />
              <PropsRoute
                path={URLS.allowanceReport}
                component={AllowanceReports}
              />
              <PropsRoute
                path={URLS.benefitReport}
                component={BenefitsReports}
              />
              <PropsRoute path={URLS.permmission} component={Permission} />
              <PropsRoute path={URLS.suspension} component={Suspension} />
              <PropsRoute path={URLS.jobCategory} component={JobCategory} />
              <PropsRoute path={URLS.dayOff} component={DayOff} />
              {/* Calendar */}
              <PropsRoute path={URLS.calendar} component={Calendar} />
              {/* */}
              {/* User Routes */}
              <PropsRoute
                exact
                path={URLS.userDashboardProfile}
                component={UserDashboardProfile}
              />
              <PropsRoute
                exact
                path={URLS.userDashboardProjects}
                component={UserDashboardProjects}
              />
              <PropsRoute
                path={URLS.userDashboardProjectView}
                component={UserProjectView}
              />
              <PropsRoute
                exact
                path={URLS.userDashboardTasks}
                component={UserTasks}
              />
              <PropsRoute
                path={URLS.userDashboardPerformance}
                component={UserPerformance}
              />
              <PropsRoute
                exact
                path={URLS.userDashboardPolicy}
                component={UserDashboardPolicy}
              />
              <PropsRoute
                exact
                path={URLS.userDashboardLeaves}
                component={UserDashboardLeaves}
              />
              <PropsRoute
                exact
                path={URLS.userDashboardTeams}
                component={UserDashboardTeams}
              />
              <PropsRoute
                path={URLS.userDashboardTeamView}
                component={UserTeamView}
              />
              <PropsRoute
                path={URLS.userDashboardLoan}
                component={UserDashboardLoan}
              />
              <PropsRoute
                path={URLS.userDashboardAllowance}
                component={UserAllowance}
              />
              <PropsRoute
                path={URLS.userDashboardPermission}
                component={userPermission}
              />

              <PropsRoute path="*" component={Error404} />
            </Switch>
          </div>
        </Router>
      </div>
    );
  }
}

//<CheckAPI />

const mapStateToProps = (state) => {
  return {
    UserData: state.api.UserData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
