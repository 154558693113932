import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import moment from "moment";
import { Helmet } from "react-helmet";
import Cookies from "universal-cookie";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import ApplyLeaveModal from "./ApplyLeaveModal";
import { URLS } from "../urls";
import EditLeaveModal from "./EditLeaveModal";
import DeleteLeaveModal from "./DeleteLeaveModal";
import {
  arrangeEventObject,
  fetchActionsUtil,
  stringifyQueryString,
  useCookie,
} from "../../utils";
import RsDateRangePicker from "../RsDateRangePicker";
import ModalLoader from "../ModalLoader";
import "./newleaves.css";
import {
  CalendarCheck2,
  CalendarClock,
  CalendarDays,
  CalendarMinus,
  DownArrowIcon,
} from "../icons";
import { Add } from "@mui/icons-material";
import { UseQueryData } from "../UseQueryData";
import CustomPagination from "../Paginations";

const allMonths = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const Leaves = (props) => {
  const branch_id = useCookie("branch_id");

  // ------------------ Filter Search -------------------//
  const initialQueryParams = {
    limit: 20,
    page: 1,
    id: "",
    name: "",
    type: "",
    status: "",
    from: "",
    to: "",
    branch_id,
  };
  const { queryParams, theQueryParams, onChangeInput, setQueryParams } =
    UseQueryData({
      initialQueryParams,
      timer: 1500,
    });
  // ------------------ Filter Search -------------------//

  const [returnParams, setReturnParams] = useState({
    count: 0,
    endIndex: 0,
    startIndex: 0,
  });

  const [state, setState] = useState({
    appliedLeaves: [],
    employees: [],
    leaveTypes: [],
    leaveBalances: null,
    searchFilter: {},
    selectedLeave: null,
    loggedEmployee: null,
    isLoading: false,
  });
  const [openAddNew, setOpenAddNew] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openDetails, setOpenDetails] = useState({});
  const [openStatics, setOpenStatics] = useState({});

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      componentDidMount();
    }

    return () => {
      isMounted = true;
    };
  }, [branch_id, queryParams]);

  const componentDidMount = async () => {
    const token = "dummy token";
    const cookies = new Cookies();
    const id = cookies.get("userId");
    setState((el) => ({ ...el, isLoading: true }));

    const ApplyLeavesData = fetchActionsUtil(
      `${URLS.backendApplyLeaves}/get/${branch_id}?${stringifyQueryString(
        queryParams
      )}`,
      "GET",
      token
    );

    const EmployeesData = fetchActionsUtil(
      `${URLS.backendEmployees}/getShortInfo/${branch_id}`,
      "GET",
      token
    );

    const LeavesData = fetchActionsUtil(
      `${URLS.backendLeaves}/get?branch_id=${branch_id}`,
      "GET",
      token
    );

    const InfoByUserIdData = fetchActionsUtil(
      `${URLS.backendEmployees}/getShortInfoByUserId/${id}`,
      "GET",
      token
    );

    const LeaveBalancesData = fetchActionsUtil(
      `${URLS.backendLeaveBalances}/get?branch_id=${branch_id}`,
      "GET",
      token
    );

    const checkLeavesYearlyData = fetchActionsUtil(
      `${URLS.backendLeaves}/checkLeaveYearly?branch_id=${branch_id}`,
      "GET",
      token
    );

    const leavesBalanceStaticsData = fetchActionsUtil(
      `${URLS.backendLeaveBalances}/getByStatics?${stringifyQueryString(
        queryParams
      )}`,
      "GET",
      token
    );

    const [
      applyLeavesResult,
      EmployeesResult,
      LeavesResult,
      InfoByUserIdResult,
      LeaveBalancesResult,
      checkLeavesYearlyResult,
      leavesBalanceStaticsResult,
    ] = await Promise.all([
      ApplyLeavesData,
      EmployeesData,
      LeavesData,
      InfoByUserIdData,
      LeaveBalancesData,
      checkLeavesYearlyData,
      leavesBalanceStaticsData,
    ]);

    let leaveBalancesTemp = {};

    if (LeaveBalancesResult.leaveBalances) {
      LeaveBalancesResult.leaveBalances.forEach((leaveType) => {
        leaveBalancesTemp[leaveType._id] = leaveType.leavesAvailable;
      });
    }
    console.log({ checkLeavesYearlyResult, leavesBalanceStaticsResult });

    setState((el) => ({
      ...el,
      appliedLeaves: applyLeavesResult.applyLeaves,
      employees: EmployeesResult.employees,
      leaveTypes: LeavesResult.leaves,
      loggedEmployee: InfoByUserIdResult.employee,
      leaveBalances: leaveBalancesTemp,
      isLoading: false,
    }));

    setOpenDetails({
      uniqueLeaves: applyLeavesResult.uniqueLeaves || [],
      uniqueNames: applyLeavesResult.uniqueNames || [],
    });
    setOpenStatics({
      totalAvailable: leavesBalanceStaticsResult.totalAvailable,
      totalRemaining: leavesBalanceStaticsResult.totalRemaining,
      takenLeave: leavesBalanceStaticsResult.takenLeave,
      totalApproved: leavesBalanceStaticsResult.totalApproved,
      totalPending: leavesBalanceStaticsResult.totalPending,
      totalDeclined: leavesBalanceStaticsResult.totalDeclined,
    });
    setReturnParams({
      count: applyLeavesResult.count,
      endIndex: applyLeavesResult.endIndex,
      startIndex: applyLeavesResult.startIndex,
    });
  };

  const updateAppliedLeavesList = (appliedLeave) => {
    let { appliedLeaves } = state;
    const id = appliedLeave && appliedLeave._id;
    let isExists = false;
    for (let index = appliedLeaves.length - 1; index >= 0; --index) {
      if (appliedLeaves[index]._id === id) {
        appliedLeaves.splice(index, 1);
        appliedLeaves.splice(index, 0, appliedLeave);
        isExists = true;
        break;
      }
    }
    if (isExists) {
      setState((el) => ({ ...el, appliedLeaves: appliedLeaves }));
    } else {
      setState((el) => ({
        ...el,
        appliedLeaves: [appliedLeave].concat(appliedLeaves),
      }));
    }
  };

  const removeLeaveFormAppliedLeavesList = (deletedLeave) => {
    let { appliedLeaves } = state;
    const id = deletedLeave && deletedLeave._id;
    for (let index = appliedLeaves.length - 1; index >= 0; --index) {
      if (appliedLeaves[index]._id === id) {
        appliedLeaves.splice(index, 1);
        break;
      }
    }
    setState((el) => ({ ...el, appliedLeaves: appliedLeaves }));
  };

  const showDateInFormat = (date, normal) => {
    date = date.split("/");

    let newDate;

    if (date[0] === "Invalid date") {
      newDate = normal;
    } else {
      newDate =
        ("0" + date[0]).slice(-2) +
        " " +
        allMonths[date[1] - 1] +
        " " +
        date[2];
    }

    return newDate;
  };

  const updateSelectedLeave = (appliedLeave) => {
    setState((el) => ({
      ...el,
      selectedLeave: appliedLeave,
    }));
  };

  const updateStatusAsDeclined = (leave) => (evt) => {
    leave.status = "Declined";
    updateLeaveInDatabase(leave);
  };
  const updateLeaveInDatabase = (leave) => {
    const token = "dummy token";
    fetch(`${URLS.backendApplyLeaves}/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({ applyLeave: leave }),
    }).then((res) => {
      if (res.ok) {
        res.json().then((result) => {
          console.log(result);
          updateAppliedLeavesList(result.applyLeave);
        });
      } else {
        res.json().then((responseJson) => {
          console.log(responseJson);
          alert("Error occurred, Check the form again.");
        });
      }
    });
  };

  const calculateDays = (days) => {
    return days > 1 ? `${days} days ` : `${days} day`;
  };

  const checkIfLeaveFinished = (date, status) => {
    let data;

    const check = moment(new Date()).isAfter(moment(date));

    if (status === "New" || status === "Pending") {
      data = (
        <p className="dropdown-item pending" href="#">
          {" "}
          Pending
        </p>
      );
    } else if (status === "Declined") {
      data = (
        <p className="dropdown-item rejected" href="#">
          {" "}
          Inactive
        </p>
      );
    } else {
      if (!check) {
        data = (
          <p className="dropdown-item open-active" href="#">
            {" "}
            Active
          </p>
        );
      } else {
        data = (
          <p className="dropdown-item shortlisted" href="#">
            {" "}
            Finished
          </p>
        );
      }
    }
    return data;
  };

  const filterByDateRange = (event, picker) => {
    onChangeInput(
      arrangeEventObject({
        name: "from",
        value: picker.startDate,
      })
    );

    onChangeInput(
      arrangeEventObject({
        name: "to",
        value: picker.endDate,
      })
    );

    picker.element.val(
      picker.startDate.format("DD-MM-yyyy") +
        " - " +
        picker.endDate.format("DD-MM-yyyy")
    );
  };

  const clearDateRange = (event, picker) => {
    onChangeInput(
      arrangeEventObject({
        name: "from",
        value: "",
      })
    );

    onChangeInput(
      arrangeEventObject({
        name: "to",
        value: "",
      })
    );

    picker.element.val("");
  };

  const dropDownFunction = (qty) => {
    setQueryParams((el) => ({ ...el, limit: qty }));
  };

  const {
    appliedLeaves,
    employees,
    leaveTypes,
    searchFilter,
    selectedLeave,
    loggedEmployee,
    leaveBalances,
    isLoading,
  } = state;
  return (
    <body>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex, nofollow"></meta>
        <title>InvexERP</title>
      </Helmet>
      <div className="main-wrapper">
        <Topbar />
        <Sidebar />
        <section className="bg-white page-header w-100 page-header-2">
          <div className="page-wrapper">
            <div className="d-flex w-100  m-0 p-0 align-items-center justify-content-between gap-5">
              <div className="">
                <h4 className="page-title page-title-alt">Leaves</h4>
                <p className="page-paragraph">All Employee leaves and data</p>
              </div>
              <div className="">
                <button
                  className="btn add-btn"
                  data-toggle="modal"
                  onClick={() => setOpenAddNew(true)}
                >
                  <Add /> Apply Leave
                </button>
              </div>
            </div>
          </div>
        </section>
        {/*<!-- Page Wrapper -->*/}
        <div className="page-wrapper page-wrapper-alt position-relative">
          {/*<!-- Page Content -->*/}
          <div className="content position-relative container-fluid">
            {/*<!-- Leave Statistics -->*/}
            <section className="stats">
              <div className="stat-alt">
                <div className="icon icon-alt icon-1">
                  <CalendarDays />
                </div>
                <div className="details details-alt ">
                  <h1 className="dark-text text-20 font-500 m-0">
                    {openStatics?.totalAvailable} days
                  </h1>

                  <p className="text-gray-400 font-400 text-14">Total Leave</p>
                </div>
              </div>
              <div className="stat-alt">
                <div className="icon icon-alt icon-1">
                  <CalendarCheck2 />
                </div>
                <div className="details details-alt ">
                  <h1 className="dark-text text-20 font-500 m-0">
                    {openStatics?.takenLeave} days
                  </h1>

                  <p className="text-gray-400 font-400 text-14">Taken Leave</p>
                </div>
              </div>
              <div className="stat-alt">
                <div className="icon icon-alt icon-1">
                  <CalendarMinus />
                </div>
                <div className="details details-alt ">
                  <h1 className="dark-text text-20 font-500 m-0">
                    {openStatics?.totalRemaining} days
                  </h1>

                  <p className="text-gray-400 font-400 text-14">
                    Remaining Leaves
                  </p>
                </div>
              </div>
              <div className="stat-alt">
                <div className="icon icon-alt icon-1">
                  <CalendarClock />
                </div>
                <div className="details details-alt ">
                  <h1 className="dark-text text-20 font-500 m-0">
                    {openStatics?.totalPending} days
                  </h1>

                  <p className="text-gray-400 font-400 text-14">
                    Pending requests
                  </p>
                </div>
              </div>
            </section>
            {/*<!-- /Leave Statistics -->*/}

            {/*<!-- Search Filter -->*/}
            <div className="grid-cat-1 filter-row">
              <div className="">
                <div className="form-group form-focus">
                  <input
                    type="text"
                    placeholder="Employee name"
                    className="form-control floating"
                    value={theQueryParams.name}
                    name="name"
                    onChange={(e) => onChangeInput(e)}
                  />
                </div>
              </div>
              <div className="">
                <div className="form-group form-focus">
                  <input
                    type="text"
                    placeholder="Employee Id"
                    className="form-control floating"
                    value={theQueryParams.id}
                    name="id"
                    onChange={(e) => onChangeInput(e)}
                  />
                </div>
              </div>
              <div className="">
                <div className="form-group form-focus text-gray-400 d-flex align-items-center justify-content-between">
                  <select
                    className="form-control floating"
                    value={theQueryParams.type}
                    name="type"
                    onChange={(e) => onChangeInput(e)}
                  >
                    <option selected value="">
                      Leaves type
                    </option>
                    {openDetails?.uniqueNames?.map((name) => (
                      <option value={name}>{name}</option>
                    ))}
                  </select>
                  <div className=" pr-2">
                    <DownArrowIcon color="#6c757d" />
                  </div>
                </div>
              </div>
              <div className="">
                <div className="form-group form-focus text-gray-400 d-flex align-items-center justify-content-between">
                  <select
                    className="form-control floating"
                    value={theQueryParams.status}
                    name="status"
                    onChange={(e) => onChangeInput(e)}
                  >
                    <option selected value="">
                      Leave Status
                    </option>
                    {openDetails?.uniqueLeaves?.map((name) => (
                      <option value={name}>{name}</option>
                    ))}
                  </select>
                  <div className=" pr-2">
                    <DownArrowIcon color="#6c757d" />
                  </div>
                </div>
              </div>

              <div className="search-date cal-icon">
                <RsDateRangePicker
                  placement="bottomEnd"
                  onClean={(event, picker) => clearDateRange(event, picker)}
                  onOk={(event, picker) => filterByDateRange(event, picker)}
                  placeholder="Select From and To Date"
                />
              </div>
            </div>
            {/*<!-- /Search Filter -->*/}

            <div className="row">
              <div className="col-md-12">
                <div className="table-responsive">
                  <table className="table table-striped border-alt custom-table mb-0 datatable">
                    <thead className="rounded-alt-t bg-light-grey">
                      <tr className="bg-light-grey">
                        <th className=" text-nowrap">Employee</th>
                        <th className=" text-nowrap">Leave Type</th>
                        <th className=" text-nowrap">From</th>
                        <th className=" text-nowrap">To</th>
                        <th className=" text-nowrap">Total no of days</th>
                        <th className=" text-nowrap">No of days taken</th>
                        <th className=" text-nowrap">No of days left</th>
                        <th className=" text-nowrap">Active</th>
                        <th className=" text-nowrap">Reason</th>
                        <th className="text-center text-nowrap">Status</th>
                        <th className="text-right text-nowrap">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {appliedLeaves?.length > 0
                        ? appliedLeaves.map((appliedLeave) => {
                            return (
                              <tr
                                onClick={(e) =>
                                  updateSelectedLeave(appliedLeave)
                                }
                              >
                                <td>
                                  <h2 className="table-avatar">
                                    <Link
                                      to={
                                        URLS.profile +
                                        "/" +
                                        appliedLeave?.employee?.id
                                      }
                                      className="avatar"
                                    >
                                      <img
                                        alt=""
                                        src={
                                          appliedLeave?.employee?.image
                                            ? URLS.backendStatic +
                                              "/" +
                                              appliedLeave?.employee?.image
                                            : "assets/img/profiles/avatar-09.jpg"
                                        }
                                      />
                                    </Link>
                                    <Link
                                      to={
                                        URLS.profile +
                                        "/" +
                                        appliedLeave?.employee?.id
                                      }
                                    >
                                      {appliedLeave?.employee?.name}{" "}
                                      <span>
                                        {appliedLeave?.employee?.designation}
                                      </span>
                                    </Link>
                                  </h2>
                                </td>
                                <td>{appliedLeave?.name}</td>
                                <td>
                                  {showDateInFormat(
                                    moment(appliedLeave?.from).format(
                                      "DD/MM/YYYY"
                                    ),
                                    appliedLeave?.from
                                  )}
                                </td>
                                <td>
                                  {showDateInFormat(
                                    moment(appliedLeave?.to).format(
                                      "DD/MM/YYYY"
                                    ),
                                    appliedLeave?.to
                                  )}
                                </td>
                                <td>
                                  {appliedLeave?.totalDays
                                    ? calculateDays(appliedLeave?.totalDays)
                                    : "---"}
                                </td>
                                <td>{calculateDays(appliedLeave?.days)}</td>
                                <td>
                                  {calculateDays(appliedLeave?.remaining)}
                                </td>
                                <td>
                                  {checkIfLeaveFinished(
                                    appliedLeave?.to,
                                    appliedLeave?.status
                                  )}
                                </td>
                                <td>{appliedLeave?.reason}</td>
                                <td className="text-center">
                                  <div className="dropdown action-label">
                                    <span
                                      className="btn btn-white btn-sm btn-rounded"
                                      aria-expanded="false"
                                    >
                                      {appliedLeave?.status === "New" ? (
                                        <i className="fa fa-dot-circle-o text-purple"></i>
                                      ) : appliedLeave?.status === "Pending" ? (
                                        <i className="fa fa-dot-circle-o text-info"></i>
                                      ) : appliedLeave?.status ===
                                        "Approved" ? (
                                        <i className="fa fa-dot-circle-o text-success"></i>
                                      ) : (
                                        <i className="fa fa-dot-circle-o text-danger"></i>
                                      )}
                                      {appliedLeave?.status}
                                    </span>
                                  </div>
                                </td>
                                <td className="text-right">
                                  <div className="dropdown dropdown-action">
                                    <span
                                      className="action-icon dropdown-toggle p-cursor"
                                      data-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <i className="material-icons">
                                        more_vert
                                      </i>
                                    </span>

                                    <div className="dropdown-menu dropdown-menu-right">
                                      {/* {appliedLeave?.status === "New" && (
                                        <span
                                          className="dropdown-item p-cursor"
                                          data-toggle="modal"
                                          onClick={() => setOpenEdit(true)}
                                        >
                                          <i className="fa fa-pencil m-r-5"></i>{" "}
                                          Edit
                                        </span>
                                      )} */}

                                      {(appliedLeave?.status === "New" ||
                                        appliedLeave?.status ===
                                          "Declined") && (
                                        <span
                                          className="dropdown-item p-cursor"
                                          data-toggle="modal"
                                          onClick={() => {
                                            updateSelectedLeave(appliedLeave);
                                            setOpenDelete(true);
                                          }}
                                        >
                                          <i className="fa fa-trash-o m-r-5"></i>{" "}
                                          Delete
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        : null}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="my-4">
              <CustomPagination
                setQueryParams={setQueryParams}
                startIndex={returnParams.startIndex}
                endIndex={returnParams.endIndex}
                count={returnParams.count}
                limit={queryParams.limit}
                page={queryParams.page}
                dropDownFunction={dropDownFunction}
              />
            </div>
          </div>
          {/*<!-- /Page Content -->*/}

          {/*<!-- Add Leave Modal -->*/}
          {openAddNew ? (
            <ApplyLeaveModal
              updateAppliedLeavesList={updateAppliedLeavesList}
              employees={employees}
              leaveBalances={leaveBalances}
              show={openAddNew}
              handleClose={() => setOpenAddNew(false)}
            />
          ) : null}
          {/*<!-- /Add Leave Modal -->*/}

          {/*<!-- Edit Leave Modal -->*/}
          {openEdit ? (
            <EditLeaveModal
              updateAppliedLeavesList={updateAppliedLeavesList}
              employees={employees}
              employeeAvailableLeaves={
                selectedLeave && leaveBalances
                  ? leaveBalances[selectedLeave.employee.id]
                  : null
              }
              editLeave={selectedLeave}
              show={openEdit}
              handleClose={() => setOpenEdit(false)}
            />
          ) : null}
          {/*<!-- /Edit Leave Modal -->*/}

          {/*<!-- Approve Leave Modal -->*/}
          {/* <ApproveLeaveModal
            updateAppliedLeavesList={updateAppliedLeavesList}
            appliedLeave={selectedLeave}
            employee={loggedEmployee}
          /> */}
          {/*<!-- /Approve Leave Modal -->*/}

          {/*<!-- Decline Leave Modal -->*/}
          {/* <DeclineLeaveModal
            updateAppliedLeavesList={updateAppliedLeavesList}
            appliedLeave={selectedLeave}
            employee={loggedEmployee}
          /> */}
          {/*<!-- /Decline Leave Modal -->*/}

          {/*<!-- Delete Leave Modal -->*/}
          {openDelete ? (
            <DeleteLeaveModal
              removeLeaveFormAppliedLeavesList={
                removeLeaveFormAppliedLeavesList
              }
              deleteLeave={selectedLeave}
              show={openDelete}
              handleClose={() => setOpenDelete(false)}
            />
          ) : null}
          {/*<!-- /Delete Leave Modal -->*/}
        </div>
        {/*<!-- /Page Wrapper -->*/}
      </div>
      {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
      <ModalLoader show={isLoading} />
      <div className="sidebar-overlay" data-reff=""></div>
    </body>
  );
};

const mapStateToProps = (state) => {
  return {
    User: state.api.User,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Leaves));
