import React from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Helmet } from "react-helmet";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import { URLS } from "../urls";
import AddLeaveModal from "./AddLeaveModal";
// import Leave from "./Leave";
import { Form } from "react-bootstrap";
import { LeaveAllowance } from "./AddLeaveAllowance";
import { branch_id } from "../../utils";
import { EditLeaveAllowance } from "./EditLeaveAllowance";
import { toast } from "react-toastify";
import { SalarySetting } from "../create-salary/CreateSalary";
import Select from "react-select";
import AddCustomPolicyModal from "./AddCustomPolicyModal";
import EditCustomPolicyModal from "./EditCustomPolicyModal";
import DisplayLeaveAllowance from "./DisplayLeaveAllowance";
import DisplayCustomPolicy from "./DisplayCustomPolicy";
import DisplayMainLeave from "./DisplayMainLeave";
class LeaveSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addButtonText: "Submit",
      editButtonText: "Submit",
      leaves: [],
      rsvLeavesSet: {},
      showMoreAssignees: {},
      showMoreAssigneesAllowance: {},
      editLeaveFields: {},
      employees: [],
      deleteLeave: null,
      newCustomPolicy: {
        leaveId: null,
        name: "",
        days: 0,
        assignees: [],
      },
      editCustomPolicy: {
        leaveId: null,
        name: "",
        days: 0,
        assignees: [],
      },
      deleteCustomPolicy: null,
      employeesSet: {},
      employeesLeft: [],
      employeesRight: [],
      selectedEmployeesLeft: [],
      selectedEmployeesRight: [],
      departments: [],
      jobGrades: [],
      assigneesGroup: {
        department: "",
        grade: "",
      },
      leaveId: "",
      allowance: [],
      mainEmployes: [],
      slug: "",
      assignees: [],
      leaveName: "",
      branches: [],
      policyId: "",
    };
  }
  componentDidMount() {
    const token = "dummy token";
    fetch(`${URLS.backendLeaves}/get?branch_id=${branch_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.leaves.length) {
          let showMoreAssignees = {},
            editLeaveFields = {},
            rsvLeavesSet = {};
          result.leaves.forEach((element) => {
            editLeaveFields[element._id] = {
              days: false,
              carry: false,
              earned: false,
              branches: false,
            };
            rsvLeavesSet[element._id] = JSON.parse(JSON.stringify(element));
            if (element.customPolicies.length) {
              showMoreAssignees[element._id] = {};
              element.customPolicies.forEach((customPolicy) => {
                showMoreAssignees[element._id][customPolicy._id] = false;
              });
            } else {
              showMoreAssignees[element._id] = null;
            }
          });
          // console.log({ result, editLeaveFields });
          this.setState({
            rsvLeavesSet: rsvLeavesSet,
            leaves: result.leaves,
            showMoreAssignees: showMoreAssignees,
            editLeaveFields: editLeaveFields,
          });
        }
      });

    fetch(
      `${URLS.backendEmployees}/getCustomPolicyInfo?branch_id=${branch_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        if (result.employees.length) {
          let employeesSet = {};
          result.employees.forEach((element) => {
            employeesSet[element._id] = element;
          });
          this.setState({
            employees: result.employees,
            employeesSet: employeesSet,
            employeesLeft: result.employees,
          });
        }
      });

    fetch(`${URLS.backendEmployees}/get/${branch_id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          mainEmployes: result.employees,
        });
      });
    fetch(`${URLS.backendDepartments}/get/${branch_id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          departments: result.departments,
        });
      });
    fetch(`${URLS.backendJobGrades}/get/${branch_id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          jobGrades: result.jobGrades,
        });
      });

    fetch(`${URLS.backendAllowance}/leave/${branch_id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        let showMoreAssigneesAllowance = {};
        if (result?.allowance?.length > 0) {
          result.allowance.forEach((allow) => {
            showMoreAssigneesAllowance[allow._id] = false;
          });
        } else {
          showMoreAssigneesAllowance = null;
        }
        this.setState({
          allowance: result.allowance,
          showMoreAssigneesAllowance,
        });
      });

    fetch(`${URLS.backendBranch}/get`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        const branches = result?.branches?.map((branch) => {
          return {
            value: branch._id,
            label: branch.name,
          };
        });

        this.setState({
          branches: [
            {
              value: "all",
              label: "All Branches",
            },
            ...branches,
          ],
        });
      });
  }
  updateLeavesList = (leave) => {
    let { leaves, showMoreAssignees, editLeaveFields, rsvLeavesSet } =
      this.state;
    const id = leave && leave._id;
    let isExists = false;
    for (let index = leaves.length - 1; index >= 0; --index) {
      if (leaves[index]._id === id) {
        leaves.splice(index, 1);
        leaves.splice(index, 0, leave);
        rsvLeavesSet[leave._id] = JSON.parse(JSON.stringify(leave));
        isExists = true;
        break;
      }
    }
    if (isExists) {
      this.setState({ leaves: leaves, rsvLeavesSet });
    } else {
      showMoreAssignees[leave._id] = null;
      editLeaveFields[leave._id] = { days: false, carry: false, earned: false };
      rsvLeavesSet[leave._id] = JSON.parse(JSON.stringify(leave));
      this.setState({
        leaves: leaves?.concat(leave),
        showMoreAssignees,
        editLeaveFields,
        rsvLeavesSet,
      });
    }
  };
  changeLiveStatus = (leave) => (event) => {
    leave.isActive = !leave.isActive;
    const token = "dummy token";
    fetch(`${URLS.backendLeaves}/update/details`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({ leave }),
    }).then((res) => {
      if (res.ok) {
        res.json().then((result) => {
          let updatedLeave = result.leave;
          updatedLeave.customPolicies = leave.customPolicies;
          this.updateLeavesList(updatedLeave);
        });
      } else {
        res.json().then((responseJson) => {
          console.log(responseJson);
          alert("Error occurred, Check the form again.");
        });
      }
    });
  };

  reloadPage = () => {
    window.location.reload();
  };

  updateDeleteLeave = (leave) => (evt) => {
    this.setState({
      deleteLeave: leave._id,
    });
    window.$("#delete_leave").modal("toggle");
  };
  confirmDeleteLeave = (evt) => {
    const { deleteLeave } = this.state;
    console.log("deleteLeave", deleteLeave);
    const token = "dummy token";
    fetch(`${URLS.backendLeaves}/delete/${deleteLeave}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    }).then((res) => {
      if (res.ok) {
        res.json().then((result) => {
          window.$("#delete_leave").modal("toggle");
          this.removeLeaveFromList(result.leave);
        });
        if (this.reloadPage) {
          this.reloadPage();
        }
      } else {
        res.json().then((responseJson) => {
          console.log(responseJson);
          alert("Error occurred, Check the form again.");
        });
      }
    });
  };
  removeLeaveFromList(leave) {
    let { leaves, rsvLeavesSet } = this.state;
    const id = leave && leave._id;
    for (let index = leaves.length - 1; index >= 0; --index) {
      if (leaves[index]._id === id) {
        leaves.splice(index, 1);
        delete rsvLeavesSet[leave._id];
        break;
      }
    }
    this.setState({ leaves: leaves, rsvLeavesSet });
  }
  updateCustomPolicySelectedLeave = (leave) => (evt) => {
    const { newCustomPolicy, employees } = this.state;
    newCustomPolicy.leaveId = leave._id;
    this.setState({
      newCustomPolicy,
      employeesLeft: employees,
      employeesRight: [],
      selectedEmployeesLeft: [],
      selectedEmployeesRight: [],
    });
  };
  updateNewCustomPolicyField = (evt) => {
    const { newCustomPolicy } = this.state;
    newCustomPolicy[evt.target.name] = evt.target.value;
    this.setState({
      newCustomPolicy,
    });
  };
  updateCustomPoliciesOfLeave(customPolicy) {
    let { leaves, rsvLeavesSet } = this.state;
    const leavesLength = leaves.length;
    for (let leaveIndex = 0; leaveIndex < leavesLength; ++leaveIndex) {
      let leave = leaves[leaveIndex];
      if (leave._id === customPolicy.leaveId) {
        let customPolicies = leave.customPolicies;
        const customPoliciesLength = customPolicies.length;
        let isExistCustomPolicy = false;
        for (
          let customPoliciesIndex = 0;
          customPoliciesIndex < customPoliciesLength;
          customPoliciesIndex++
        ) {
          let customPolicy_ = customPolicies[customPoliciesIndex];
          if (customPolicy_._id === customPolicy._id) {
            customPolicies.splice(customPoliciesIndex, 1);
            customPolicies.splice(customPoliciesIndex, 0, customPolicy);
            isExistCustomPolicy = true;
            break;
          }
        }
        if (!isExistCustomPolicy) {
          customPolicies.push(customPolicy);
        }
        leave.customPolicies = customPolicies;
        leaves.splice(leaveIndex, 1);
        leaves.splice(leaveIndex, 0, leave);
        rsvLeavesSet[leave._id] = JSON.parse(JSON.stringify(leave));
        break;
      }
    }
    this.setState({
      leaves,
      rsvLeavesSet,
    });
  }
  updateCustomPoliciesOfLeaveOnDelete(customPolicy) {
    let { leaves, rsvLeavesSet } = this.state;
    const leavesLength = leaves.length;
    for (let leaveIndex = 0; leaveIndex < leavesLength; ++leaveIndex) {
      let leave = leaves[leaveIndex];
      if (leave._id === customPolicy.leaveId) {
        let customPolicies = leave.customPolicies;
        const customPoliciesLength = customPolicies.length;
        for (
          let customPoliciesIndex = 0;
          customPoliciesIndex < customPoliciesLength;
          customPoliciesIndex++
        ) {
          let customPolicy_ = customPolicies[customPoliciesIndex];
          if (customPolicy_._id === customPolicy._id) {
            customPolicies.splice(customPoliciesIndex, 1);
            break;
          }
        }
        leave.customPolicies = customPolicies;
        leaves.splice(leaveIndex, 1);
        leaves.splice(leaveIndex, 0, leave);
        rsvLeavesSet[leave._id] = JSON.parse(JSON.stringify(leave));
        break;
      }
    }
    this.setState({
      leaves,
      rsvLeavesSet,
    });
  }
  saveNewCustomPolicy = (evt) => {
    evt.preventDefault();
    this.setState({ addButtonText: "Submitting ..." });
    const token = "dummy token";
    const { newCustomPolicy, employeesRight, assigneesGroup } = this.state;
    newCustomPolicy.assignees = employeesRight;
    newCustomPolicy.department = assigneesGroup.department;
    newCustomPolicy.grade = assigneesGroup.grade;
    fetch(`${URLS.backendLeaveCustomPolicies}/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({ customPolicy: newCustomPolicy }),
    })
      .then((res) => res.json())
      .then((result) => {
        let { showMoreAssignees } = this.state;
        showMoreAssignees[result.customPolicy.leaveId] = {};
        showMoreAssignees[result.customPolicy.leaveId][
          result.customPolicy._id
        ] = false;
        this.updateCustomPoliciesOfLeave(result.customPolicy);
        window.$("#add_custom_policy").modal("toggle");
        this.setState({
          addButtonText: "Submit",
          newCustomPolicy: {
            leaveId: null,
            name: "",
            days: 0,
            assignees: [],
            showMoreAssignees,
          },
        });
      });
  };
  updateEditCustomPolicy = (lcp) => (evt) => {
    let { employees } = this.state;
    let employeesLeft = employees.filter(
      (ar) => !lcp.assignees.find((rm) => rm._id === ar._id)
    );
    let employeesRight = lcp.assignees;
    this.setState({
      editCustomPolicy: lcp,
      employeesLeft: employeesLeft,
      employeesRight: employeesRight,
      selectedEmployeesLeft: [],
      selectedEmployeesRight: [],
    });
  };
  updateEditCustomPolicyField = (evt) => {
    const { editCustomPolicy } = this.state;
    editCustomPolicy[evt.target.name] = evt.target.value;
    this.setState({
      editCustomPolicy,
    });
  };
  saveEditCustomPolicy = (evt) => {
    evt.preventDefault();
    this.setState({ editButtonText: "Submitting ..." });
    const token = "dummy token";
    const { editCustomPolicy, employeesRight, assigneesGroup } = this.state;
    editCustomPolicy.assignees = employeesRight;
    editCustomPolicy.department = assigneesGroup.department;
    editCustomPolicy.grade = assigneesGroup.grade;
    fetch(`${URLS.backendLeaveCustomPolicies}/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({ customPolicy: editCustomPolicy }),
    })
      .then((res) => res.json())
      .then((result) => {
        console.log("COCO", result);
        window.$("#edit_custom_policy").modal("toggle");
        this.setState({ editButtonText: "Submit" });
      });
  };
  updateDeleteCustomPolicy = (lcp) => (evt) => {
    this.setState({
      deleteCustomPolicy: lcp._id,
    });
  };
  confirmDeleteCustomPolicy = (evt) => {
    const { deleteCustomPolicy } = this.state;
    const token = "dummy token";
    fetch(`${URLS.backendLeaveCustomPolicies}/delete/${deleteCustomPolicy}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    }).then((res) => {
      if (res.ok) {
        res.json().then((result) => {
          this.updateCustomPoliciesOfLeaveOnDelete(result.customPolicy);
          window.$("#delete_custom_policy").modal("toggle");
        });

        if (this.reloadPage) {
          this.reloadPage();
        }
      } else {
        res.json().then((responseJson) => {
          console.log(responseJson);
          alert("Error occurred, Check the form again.");
        });
      }
    });
  };
  updateSelectedEmployeesLeft = (evt) => {
    const { employeesSet } = this.state;
    var options = evt.target.options;
    var selectedEmployeesLeft = [];
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        var value = options[i].value;
        var employee = employeesSet[value];
        selectedEmployeesLeft.push(employee);
      }
    }
    this.setState({
      selectedEmployeesLeft: selectedEmployeesLeft,
    });
  };
  updateSelectedEmployeesRight = (evt) => {
    const { employeesSet } = this.state;
    var options = evt.target.options;
    var selectedEmployeesRight = [];
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        var value = options[i].value;
        var employee = employeesSet[value];
        selectedEmployeesRight.push(employee);
      }
    }
    this.setState({
      selectedEmployeesRight: selectedEmployeesRight,
    });
  };
  customleaveShiftToRightAll = (evt) => {
    const { employeesLeft, employeesRight } = this.state;
    this.setState({
      employeesRight: employeesRight.concat(employeesLeft),
      employeesLeft: [],
    });
  };
  shiftLeftSelectedToRight = (evt) => {
    let { employeesLeft, employeesRight, selectedEmployeesLeft } = this.state;
    let employeesLeftTemp = employeesLeft.filter(
      (ar) => !selectedEmployeesLeft.find((rm) => rm._id === ar._id)
    );
    var elements = document.getElementById("customleave_select_from").options;
    for (var i = 0; i < elements.length; i++) {
      elements[i].selected = false;
    }
    this.setState({
      employeesRight: employeesRight.concat(selectedEmployeesLeft),
      employeesLeft: employeesLeftTemp,
      selectedEmployeesLeft: [],
      selectedEmployeesRight: [],
    });
  };
  shiftRightSelectedToLeft = (evt) => {
    let { employeesLeft, employeesRight, selectedEmployeesRight } = this.state;
    let employeesRightTemp = employeesRight.filter(
      (ar) => !selectedEmployeesRight.find((rm) => rm._id === ar._id)
    );
    var elements = document.getElementById("customleave_select_to").options;
    for (var i = 0; i < elements.length; i++) {
      elements[i].selected = false;
    }
    this.setState({
      employeesRight: employeesRightTemp,
      employeesLeft: employeesLeft.concat(selectedEmployeesRight),
      selectedEmployeesLeft: [],
      selectedEmployeesRight: [],
    });
  };
  shiftLeftSelectedToRightEdit = (evt) => {
    let { employeesLeft, employeesRight, selectedEmployeesLeft } = this.state;
    let employeesLeftTemp = employeesLeft.filter(
      (ar) => !selectedEmployeesLeft.find((rm) => rm._id === ar._id)
    );
    var elements = document.getElementById(
      "edit_customleave_select_from"
    ).options;
    for (var i = 0; i < elements.length; i++) {
      elements[i].selected = false;
    }
    this.setState({
      employeesRight: employeesRight.concat(selectedEmployeesLeft),
      employeesLeft: employeesLeftTemp,
      selectedEmployeesLeft: [],
      selectedEmployeesRight: [],
    });
  };
  shiftRightSelectedToLeftEdit = (evt) => {
    let { employeesLeft, employeesRight, selectedEmployeesRight } = this.state;
    let employeesRightTemp = employeesRight.filter(
      (ar) => !selectedEmployeesRight.find((rm) => rm._id === ar._id)
    );
    var elements = document.getElementById(
      "edit_customleave_select_to"
    ).options;
    for (var i = 0; i < elements.length; i++) {
      elements[i].selected = false;
    }
    this.setState({
      employeesRight: employeesRightTemp,
      employeesLeft: employeesLeft.concat(selectedEmployeesRight),
      selectedEmployeesLeft: [],
      selectedEmployeesRight: [],
    });
  };
  customleaveShiftToLeftAll = (evt) => {
    const { employeesRight, employeesLeft } = this.state;
    this.setState({
      employeesLeft: employeesLeft.concat(employeesRight),
      employeesRight: [],
    });
  };
  updateSearchFilterDepartment = (evt) => {
    const { assigneesGroup, employees } = this.state;
    assigneesGroup.department = evt.target.value;
    // assigneesGroup.grade = '';
    let employeesRight = [];
    if (assigneesGroup.department && assigneesGroup.grade) {
      employeesRight = employees.filter(
        (emp) => emp.department === assigneesGroup.department
      );
      employeesRight = employeesRight.filter(
        (emp) => emp.grade === assigneesGroup.grade
      );
    } else if (assigneesGroup.department && !assigneesGroup.grade) {
      employeesRight = employees.filter(
        (emp) => emp.department === assigneesGroup.department
      );
    } else if (!assigneesGroup.department && assigneesGroup.grade) {
      employeesRight = employees.filter(
        (emp) => emp.grade === assigneesGroup.grade
      );
    }
    let employeesLeft = employees.filter(
      (ar) => !employeesRight.find((rm) => rm._id === ar._id)
    );
    this.setState({
      assigneesGroup,
      employeesLeft: employeesLeft,
      employeesRight: employeesRight,
      selectedEmployeesLeft: [],
      selectedEmployeesRight: [],
    });
  };
  updateSearchFilterJobGrade = (evt) => {
    const { assigneesGroup, employees } = this.state;
    assigneesGroup.grade = evt.target.value;
    // assigneesGroup.department = '';
    let employeesRight = [];
    if (assigneesGroup.department && assigneesGroup.grade) {
      employeesRight = employees.filter(
        (emp) => emp.department === assigneesGroup.department
      );
      employeesRight = employeesRight.filter(
        (emp) => emp.grade === assigneesGroup.grade
      );
    } else if (assigneesGroup.department && !assigneesGroup.grade) {
      employeesRight = employees.filter(
        (emp) => emp.department === assigneesGroup.department
      );
    } else if (!assigneesGroup.department && assigneesGroup.grade) {
      employeesRight = employees.filter(
        (emp) => emp.grade === assigneesGroup.grade
      );
    }
    // let  employeesRight = employees.filter(emp => emp.grade===assigneesGroup.grade)
    let employeesLeft = employees.filter(
      (ar) => !employeesRight.find((rm) => rm._id === ar._id)
    );
    this.setState({
      assigneesGroup,
      employeesLeft: employeesLeft,
      employeesRight: employeesRight,
      selectedEmployeesLeft: [],
      selectedEmployeesRight: [],
    });
  };
  showAllAssignees = (leaveId, customPolicyId) => (evt) => {
    const { showMoreAssignees } = this.state;
    showMoreAssignees[leaveId][customPolicyId] = true;
    this.setState({
      showMoreAssignees,
    });
  };
  showLessAssignees = (leaveId, customPolicyId) => (evt) => {
    const { showMoreAssignees } = this.state;
    showMoreAssignees[leaveId][customPolicyId] = false;
    this.setState({
      showMoreAssignees,
    });
  };

  showAllAssigneesAllowance = (allowanceId) => (evt) => {
    const { showMoreAssigneesAllowance } = this.state;
    showMoreAssigneesAllowance[allowanceId] = true;
    this.setState({
      showMoreAssigneesAllowance,
    });
  };
  showLessAssigneesAllowance = (allowanceId) => (evt) => {
    const { showMoreAssigneesAllowance } = this.state;
    showMoreAssigneesAllowance[allowanceId] = false;
    this.setState({
      showMoreAssigneesAllowance,
    });
  };

  usersStructures = (assignee) => {
    return (
      <div
        style={{
          display: "inline-block",
          textAlign: "center",
          marginRight: "10px",
        }}
      >
        <div
          style={{
            display: "inline",
          }}
        >
          <a href="Javascript:void(0);" className="avatar">
            <img
              alt=""
              src={
                assignee?.emergencyInfo?.image
                  ? URLS.backendStatic + "/" + assignee?.emergencyInfo?.image
                  : "assets/img/profiles/avatar-02.jpg"
              }
            />
          </a>
        </div>
        <div>
          <a href="Javascript:void(0);">
            {assignee?.personalInfo?.name?.first +
              " " +
              assignee?.personalInfo?.name?.last}
          </a>
        </div>
      </div>
    );
  };

  editLeaveDays = (leave) => (evt) => {
    const { editLeaveFields } = this.state;
    editLeaveFields[leave._id]["days"] = true;
    this.setState({
      editLeaveFields,
    });
  };
  editLeaveDuration = (leave) => (evt) => {
    const { editLeaveFields } = this.state;
    editLeaveFields[leave._id]["duration"] = true;
    this.setState({
      editLeaveFields,
    });
  };

  editLeaveBranches = (leave) => (evt) => {
    const { editLeaveFields } = this.state;
    editLeaveFields[leave._id]["branches"] = true;
    this.setState({
      editLeaveFields,
    });
  };

  //

  updateLeaveDays = (leave) => (evt) => {
    const { leaves } = this.state;
    for (let i = 0; i < leaves.length; i++) {
      if (leaves[i]._id === leave._id) {
        leaves[i].days = evt.target.value;
        break;
      }
    }
    this.setState({ leaves });
  };

  updateLeaveDuration = (leave) => (evt) => {
    const { leaves } = this.state;
    for (let i = 0; i < leaves.length; i++) {
      if (leaves[i]._id === leave._id) {
        leaves[i].duration = evt.target.value;
        break;
      }
    }
    this.setState({ leaves });
  };

  updateLeaveBranch = (leave, selected) => {
    const { leaves } = this.state;

    for (let i = 0; i < leaves.length; i++) {
      if (leaves[i]._id === leave._id) {
        leaves[i].branches = selected;
        break;
      }
    }
    this.setState({ leaves });
  };

  cancelSaveLeaveDays = (leave) => (evt) => {
    const { editLeaveFields, leaves, rsvLeavesSet } = this.state;
    editLeaveFields[leave._id]["days"] = false;
    for (let i = 0; i < leaves.length; i++) {
      if (leaves[i]._id === leave._id) {
        leaves[i].days = rsvLeavesSet[leave._id].days;
        break;
      }
    }
    this.setState({ leaves, editLeaveFields });
  };

  cancelSaveLeaveDuration = (leave) => (evt) => {
    const { editLeaveFields, leaves, rsvLeavesSet } = this.state;
    editLeaveFields[leave._id]["duration"] = false;
    for (let i = 0; i < leaves.length; i++) {
      if (leaves[i]._id === leave._id) {
        leaves[i].duration = rsvLeavesSet[leave._id].duration;
        break;
      }
    }
    this.setState({ leaves, editLeaveFields });
  };

  cancelSaveLeaveBranch = (leave) => (evt) => {
    const { editLeaveFields, leaves, rsvLeavesSet } = this.state;
    editLeaveFields[leave._id]["branches"] = false;
    for (let i = 0; i < leaves.length; i++) {
      if (leaves[i]._id === leave._id) {
        leaves[i].branches = rsvLeavesSet[leave._id].branches;
        break;
      }
    }
    this.setState({ leaves, editLeaveFields });
  };

  saveLeaveDays = (leave, fieldType) => (evt) => {
    const { editLeaveFields } = this.state;
    const token = "dummy token";

    fetch(`${URLS.backendLeaves}/update/details`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({ leave }),
    }).then((res) => {
      if (res.ok) {
        res.json().then((result) => {
          let updatedLeave = result.leave;
          updatedLeave.customPolicies = leave.customPolicies;
          this.updateLeavesList(updatedLeave);
          editLeaveFields[leave._id][fieldType] = false;
          this.setState({ editLeaveFields });
        });
      } else {
        res.json().then((responseJson) => {
          console.log(responseJson);
          alert("Error occurred, Check the form again.");
        });
      }
    });
  };

  saveLeaveDaysBranches = (leave, fieldType) => (evt) => {
    const { editLeaveFields } = this.state;
    const token = "dummy token";

    fetch(`${URLS.backendLeaves}/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({ leave }),
    }).then((res) => {
      if (res.ok) {
        res.json().then((result) => {
          let updatedLeave = result.leave;
          updatedLeave.customPolicies = leave.customPolicies;
          this.updateLeavesList(updatedLeave);
          editLeaveFields[leave._id][fieldType] = false;
          this.setState({ editLeaveFields });
        });
      } else {
        res.json().then((responseJson) => {
          console.log(responseJson);
          alert("Error occurred, Check the form again.");
        });
      }
    });
  };

  editLeaveCarry = (leave) => (evt) => {
    const { editLeaveFields } = this.state;
    editLeaveFields[leave._id]["carry"] = true;
    this.setState({
      editLeaveFields,
    });
  };
  updateLeaveCarryNo = (leave) => (evt) => {
    const { leaves } = this.state;
    for (let i = 0; i < leaves.length; i++) {
      if (leaves[i]._id === leave._id) {
        leaves[i].carry = false;
        leaves[i].carryMax = 0;
        break;
      }
    }
    this.setState({ leaves });
  };
  updateLeaveCarryYes = (leave) => (evt) => {
    const { leaves } = this.state;
    for (let i = 0; i < leaves.length; i++) {
      if (leaves[i]._id === leave._id) {
        leaves[i].carry = true;
        break;
      }
    }
    this.setState({ leaves });
  };
  updateLeaveCarryMax = (leave) => (evt) => {
    const { leaves } = this.state;
    for (let i = 0; i < leaves.length; i++) {
      if (leaves[i]._id === leave._id) {
        leaves[i].carryMax = evt.target.value;
        break;
      }
    }
    this.setState({ leaves });
  };
  cancelSaveLeaveCarry = (leave) => (evt) => {
    const { editLeaveFields, leaves, rsvLeavesSet } = this.state;
    editLeaveFields[leave._id]["carry"] = false;
    for (let i = 0; i < leaves.length; i++) {
      if (leaves[i]._id === leave._id) {
        leaves[i].carry = rsvLeavesSet[leave._id].carry;
        leaves[i].carryMax = rsvLeavesSet[leave._id].carryMax;
        break;
      }
    }
    this.setState({ leaves, editLeaveFields });
  };

  getallowanceUsers = (DeductType, selectedDeducts) => {
    const { mainEmployes } = this.state;
    let users = [];
    if (DeductType === "Job Grade") {
      users = mainEmployes.filter((el) =>
        selectedDeducts.includes(el?.jobInfo?.grade?._id)
      );
    } else if (DeductType === "Employees") {
      users = mainEmployes.filter((el) => selectedDeducts.includes(el?.userId));
    } else if (DeductType === "Contract") {
      users = mainEmployes.filter((el) =>
        selectedDeducts.includes(el?.jobInfo?.contractType)
      );
    } else if (DeductType === "Permanent") {
      users = mainEmployes.filter(
        (el) => !selectedDeducts.includes(el?.jobInfo?.contractType)
      );
    }

    return users;
  };

  deleteAllowance = async () => {
    try {
      const { slug } = this.state;
      const token = "dummy token";
      const res = await fetch(`${URLS.backendAllowance}/delete/${slug}`, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      });

      const data = await res.json();
      if (!res.ok) {
        window.$("#delete_allowance").modal("toggle");

        throw Error(data);
      } else {
        toast("Settings deleted", {
          type: "success",
        });
        window.$("#delete_allowance").modal("toggle");
        if (this.reloadPage) {
          this.reloadPage();
        }
      }
    } catch (err) {
      console.log(err);
      toast("Unable to get settings, please try again", {
        type: "error",
      });
      window.$("#delete_allowance").modal("toggle");
    }
  };

  updateAllowanceSlug = (allowance) => {
    this.setState({ slug: allowance?.slug });
  };

  updateLeaveAndCustom = ({ leave, lcp }) => {
    this.setState({
      leaveId: leave._id,
      assignees: lcp.assignees,
      leaveName: lcp.name,
      policyId: lcp?._id,
    });
  };

  render() {
    const {
      assigneesGroup,
      departments,
      jobGrades,
      leaves,
      showMoreAssignees,
      editLeaveFields,
      employeesLeft,
      employeesRight,
      selectedEmployeesLeft,
      selectedEmployeesRight,
      newCustomPolicy,
      addButtonText,
      editCustomPolicy,
      editButtonText,
      leaveId,
      allowance,
      slug,
      showMoreAssigneesAllowance,
      branches,
      policyId,
    } = this.state;
    // console.log("AS", leaves);
    return (
      <body>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="robots" content="noindex, nofollow"></meta>
          <title>InvexERP</title>
        </Helmet>
        <div className="main-wrapper">
          <Topbar />
          <Sidebar />
          {/*<!-- Page Wrapper -->*/}
          <div className="page-wrapper">
            {/*<!-- Page Content -->*/}
            <div className="content container-fluid">
              {/*<!-- Page Title -->*/}
              <div className="row">
                <div className="col">
                  <Breadcrumb>
                    <BreadcrumbItem>
                      <Link to={`${URLS.dashboard}`}>Dashboard</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>Leave Settings</BreadcrumbItem>
                  </Breadcrumb>
                  {/* <h4 className="page-title">Leave Settings</h4> */}
                </div>
                <div className="col-auto text-right float-right ml-auto m-b-30">
                  <button
                    className="btn add-btn"
                    data-toggle="modal"
                    data-target="#add_leave"
                  >
                    <i className="fa fa-plus"></i>Add Leave
                  </button>
                </div>
              </div>
              {/*<!-- /Page Title -->*/}

              <div className="row">
                <div className="col-md-12">
                  {leaves?.length > 0
                    ? leaves?.map((leave, idx) => {
                        // return <Leave leave={leave}/>
                        return (
                          <>
                            <div
                              className="card-box leave-box"
                              id={"leave_custom" + idx}
                            >
                              <div className="h3 card-title with-switch">
                                {leave.name}
                                {leave.forGender === "FEMALE" ? (
                                  <span class="subtitle">
                                    Assigned to female only
                                  </span>
                                ) : leave.forGender === "MALE" ? (
                                  <span class="subtitle">
                                    Assigned to male only
                                  </span>
                                ) : (
                                  ""
                                )}
                                <div className="onoffswitch">
                                  <input
                                    type="checkbox"
                                    name="onoffswitch"
                                    className="onoffswitch-checkbox"
                                    id={"switch_custom" + idx}
                                    checked={leave.isActive}
                                    onChange={this.changeLiveStatus(leave)}
                                  />
                                  <label
                                    className="onoffswitch-label"
                                    for={"switch_custom" + idx}
                                  >
                                    <span className="onoffswitch-inner"></span>
                                    <span className="onoffswitch-switch"></span>
                                  </label>
                                </div>
                                <button
                                  className="btn btn-danger leave-delete-btn"
                                  type="button"
                                  onClick={this.updateDeleteLeave(leave)}
                                  disabled={leave.isActive ? true : false}
                                >
                                  Delete
                                </button>
                              </div>
                              <DisplayMainLeave
                                leave={leave}
                                editLeaveFields={editLeaveFields}
                                idx={idx}
                                updateLeaveDays={this.updateLeaveDays}
                                updateLeaveDuration={this.updateLeaveDuration}
                                editLeaveDays={this.editLeaveDays}
                                editLeaveDuration={this.editLeaveDuration}
                                cancelSaveLeaveDays={this.cancelSaveLeaveDays}
                                cancelSaveLeaveDuration={
                                  this.cancelSaveLeaveDuration
                                }
                                saveLeaveDays={this.saveLeaveDays}
                                updateLeaveCarryNo={this.updateLeaveCarryNo}
                                updateLeaveCarryYes={this.updateLeaveCarryYes}
                                updateLeaveCarryMax={this.updateLeaveCarryMax}
                                editLeaveCarry={this.editLeaveCarry}
                                cancelSaveLeaveCarry={this.cancelSaveLeaveCarry}
                                branches={this.state.branches}
                                updateLeaveBranch={this.updateLeaveBranch}
                                editLeaveBranches={this.editLeaveBranches}
                                cancelSaveLeaveBranch={
                                  this.cancelSaveLeaveBranch
                                }
                                saveLeaveDaysBranches={
                                  this.saveLeaveDaysBranches
                                }
                              />

                              {/* -------------------/ Branch */}

                              <DisplayCustomPolicy
                                leave={leave}
                                showMoreAssignees={showMoreAssignees}
                                updateCustomPolicySelectedLeave={
                                  this.updateCustomPolicySelectedLeave
                                }
                                showLessAssignees={this.showLessAssignees}
                                showAllAssignees={this.showAllAssignees}
                                updateEditCustomPolicy={
                                  this.updateEditCustomPolicy
                                }
                                updateDeleteCustomPolicy={
                                  this.updateDeleteCustomPolicy
                                }
                                updateLeaveAndCustom={this.updateLeaveAndCustom}
                              />
                              {/* // Allowance */}
                              <DisplayLeaveAllowance
                                allowance={allowance}
                                leave={leave}
                                showMoreAssigneesAllowance={
                                  showMoreAssigneesAllowance
                                }
                                getallowanceUsers={this.getallowanceUsers}
                                usersStructures={this.usersStructures}
                                showLessAssigneesAllowance={
                                  this.showLessAssigneesAllowance
                                }
                                showAllAssigneesAllowance={
                                  this.showAllAssigneesAllowance
                                }
                                updateAllowanceSlug={this.updateAllowanceSlug}
                              />
                            </div>
                          </>
                        );
                      })
                    : null}
                </div>
              </div>
            </div>
            {/*<!-- /Page Content -->*/}

            {/*<!-- Add Custom Policy Modal -->*/}
            <AddCustomPolicyModal
              newCustomPolicy={newCustomPolicy}
              assigneesGroup={assigneesGroup}
              departments={departments}
              jobGrades={jobGrades}
              employeesLeft={employeesLeft}
              selectedEmployeesLeft={selectedEmployeesLeft}
              selectedEmployeesRight={selectedEmployeesRight}
              employeesRight={employeesRight}
              addButtonText={addButtonText}
              saveNewCustomPolicy={this.saveNewCustomPolicy}
              updateNewCustomPolicyField={this.updateNewCustomPolicyField.bind(
                this
              )}
              updateSearchFilterDepartment={this.updateSearchFilterDepartment}
              updateSearchFilterJobGrade={this.updateSearchFilterJobGrade}
              updateSelectedEmployeesLeft={this.updateSelectedEmployeesLeft.bind(
                this
              )}
              customleaveShiftToRightAll={this.customleaveShiftToRightAll.bind(
                this
              )}
              shiftLeftSelectedToRight={this.shiftLeftSelectedToRight.bind(
                this
              )}
              shiftRightSelectedToLeft={this.shiftRightSelectedToLeft.bind(
                this
              )}
              customleaveShiftToLeftAll={this.customleaveShiftToLeftAll.bind(
                this
              )}
              updateSelectedEmployeesRight={this.updateSelectedEmployeesRight.bind(
                this
              )}
            />
            {/*<!-- /Add Custom Policy Modal -->*/}
            <EditCustomPolicyModal
              editCustomPolicy={editCustomPolicy}
              assigneesGroup={assigneesGroup}
              departments={departments}
              jobGrades={jobGrades}
              employeesLeft={employeesLeft}
              selectedEmployeesLeft={selectedEmployeesLeft}
              selectedEmployeesRight={selectedEmployeesRight}
              employeesRight={employeesRight}
              editButtonText={editButtonText}
              saveEditCustomPolicy={this.saveEditCustomPolicy}
              updateEditCustomPolicyField={this.updateEditCustomPolicyField.bind(
                this
              )}
              updateSearchFilterDepartment={this.updateSearchFilterDepartment}
              updateSearchFilterJobGrade={this.updateSearchFilterJobGrade}
              updateSelectedEmployeesLeft={this.updateSelectedEmployeesLeft.bind(
                this
              )}
              customleaveShiftToRightAll={this.customleaveShiftToRightAll.bind(
                this
              )}
              shiftLeftSelectedToRightEdit={this.shiftLeftSelectedToRightEdit.bind(
                this
              )}
              shiftRightSelectedToLeftEdit={this.shiftRightSelectedToLeftEdit.bind(
                this
              )}
              customleaveShiftToLeftAll={this.customleaveShiftToLeftAll.bind(
                this
              )}
              updateSelectedEmployeesRight={this.updateSelectedEmployeesRight.bind(
                this
              )}
            />
            {/*<!-- Edit Custom Policy Modal -->*/}

            {/*<!-- /Edit Custom Policy Modal -->*/}

            {/*<!-- Delete Custom Policy Modal -->*/}
            <div
              className="modal custom-modal fade"
              id="delete_custom_policy"
              role="dialog"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="form-header">
                      <h3>Delete Custom Policy</h3>
                      <p>Are you sure want to delete?</p>
                    </div>
                    <div className="modal-btn delete-action">
                      <div className="row">
                        <div className="col-6">
                          <a
                            href="Javascript:void(0);"
                            className="btn btn-primary continue-btn"
                            onClick={this.confirmDeleteCustomPolicy.bind(this)}
                          >
                            Delete
                          </a>
                        </div>
                        <div className="col-6">
                          <a
                            href="Javascript:void(0);"
                            data-dismiss="modal"
                            className="btn btn-primary cancel-btn"
                          >
                            Cancel
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- /Delete Custom Policy Modal -->*/}
            {/*<!-- /Add Leave Modal -->*/}
            <AddLeaveModal
              updateLeavesList={this.updateLeavesList.bind(this)}
              reloadPage={this.reloadPage}
              branches={branches}
            />
            {/*<!-- /Add Leave Modal -->*/}

            {/*<!-- /Leave Allowance Modal -->*/}
            <LeaveAllowance
              leaveId={leaveId}
              assignees={this.state.assignees}
              leaveName={this.state.leaveName}
              reloadPage={this.reloadPage}
              policyId={policyId}
            />
            {/*<!-- /Leave Allowance Modal-->*/}

            <EditLeaveAllowance slug={slug} reloadPage={this.reloadPage} />

            {/*<!-- Delete Leave Modal -->*/}
            <div
              className="modal custom-modal fade"
              id="delete_leave"
              role="dialog"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="form-header">
                      <h3>Delete Leave</h3>
                      <p>Are you sure want to delete?</p>
                    </div>
                    <div className="modal-btn delete-action">
                      <div className="row">
                        <div className="col-6">
                          <a
                            href="Javascript:void(0);"
                            className="btn btn-primary continue-btn"
                            onClick={this.confirmDeleteLeave}
                          >
                            Delete
                          </a>
                        </div>
                        <div className="col-6">
                          <a
                            href="Javascript:void(0);"
                            data-dismiss="modal"
                            className="btn btn-primary cancel-btn"
                            onClick={this.cancelDeleteLeave}
                          >
                            Cancel
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- /Delete Leave Modal -->*/}

            {/* Delete Allowance */}
            <div
              className="modal custom-modal fade"
              id="delete_allowance"
              role="dialog"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="form-header">
                      <h3>Delete Allowance</h3>
                      <p>Are you sure want to delete?</p>
                    </div>
                    <div className="modal-btn delete-action">
                      <div className="row">
                        <div className="col-6">
                          <a
                            href="Javascript:void(0);"
                            className="btn btn-primary continue-btn"
                            onClick={() => this.deleteAllowance()}
                          >
                            Delete
                          </a>
                        </div>
                        <div className="col-6">
                          <a
                            href="Javascript:void(0);"
                            data-dismiss="modal"
                            className="btn btn-primary cancel-btn"
                            onClick={this.cancelDeleteLeave}
                          >
                            Cancel
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*<!-- /Page Wrapper -->*/}
        </div>
        {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
        <div className="sidebar-overlay" data-reff=""></div>
      </body>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    User: state.api.User,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LeaveSettings));
