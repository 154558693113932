import React, { useEffect, useState } from "react";
import moment from "moment";
import { URLS } from "../../urls";
import { Helmet } from "react-helmet";
import Topbar from "../user-topbar/UserTopbar";
import Sidebar from "../user-sidebar/UserSidebar";
import RsDateRangePicker from "../../RsDateRangePicker.jsx";
import {
  arrangeEventObject,
  chooseAttendanceStatusColor,
  fetchActionsUtil,
  stringifyQueryString,
} from "../../../utils.js";
import ApplyPermissionModal from "./ApplyPermisssionModal.jsx";
import ModalLoader from "../../ModalLoader";
import { UseQueryData } from "../../UseQueryData.jsx";
import CustomPagination from "../../Paginations.jsx";
import { Add, CalendarTodayOutlined } from "@mui/icons-material";
import { DownArrowIcon } from "../../icons.js";
import TableComponent from "../../TableComponent.js";
import NoTableItem from "../../NoTableItem.jsx";
import Cookies from "universal-cookie";

const Permission = () => {
  const cookies = new Cookies();

  // ------------------ Filter Search -------------------//
  const initialQueryParams = {
    limit: 20,
    page: 1,
    status: "",
    endDate: "",
    startDate: "",
  };
  const { queryParams, theQueryParams, onChangeInput, setQueryParams } =
    UseQueryData({
      initialQueryParams,
      timer: 1500,
    });
  // ------------------ Filter Search -------------------//

  const [returnParams, setReturnParams] = useState({
    count: 0,
    endIndex: 0,
    startIndex: 0,
  });

  const [loading, setLoading] = useState(false);
  const [openApplyPermission, setOpenApplyPermission] = useState(false);

  const [allPermission, setAllPermission] = useState([]);
  const [openDetails, setOpenDetails] = useState({});
  const [employee, setEmployee] = useState({});

  const fetchData = async () => {
    try {
      setLoading(true);

      const employee = cookies.get("employee");
      const employeeName =
        employee.info.first +
        " " +
        employee.info.middle +
        " " +
        employee.info.last;

      const permissionData = fetchActionsUtil(
        `${URLS.backendpermmission}/${employee.id}?${stringifyQueryString(
          queryParams
        )}`
      );

      const [permissionResult] = await Promise.all([permissionData]);

      setAllPermission(permissionResult?.permission);
      setOpenDetails({
        uniqueStatus: permissionResult?.uniqueStatus,
        uniqueNames: permissionResult?.uniqueNames,
      });
      setReturnParams({
        count: permissionResult?.count,
        endIndex: permissionResult?.endIndex,
        startIndex: permissionResult?.startIndex,
      });
      setEmployee({ employeeName, employeeId: employee.id });
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };

  const filterByDateRange = (event, picker) => {
    onChangeInput(
      arrangeEventObject({
        name: "startDate",
        value: picker.startDate.add(1, "hours"),
      })
    );

    onChangeInput(
      arrangeEventObject({
        name: "endDate",
        value: picker.endDate,
      })
    );

    picker.element.val(
      picker.startDate.format("YYYY-MM-DD") +
        " - " +
        picker.endDate.format("YYYY-MM-DD")
    );
  };

  const clearDateRange = (event, picker) => {
    onChangeInput(
      arrangeEventObject({
        name: "endDate",
        value: "",
      })
    );

    onChangeInput(
      arrangeEventObject({
        name: "startDate",
        value: "",
      })
    );

    picker.element.val("");
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      fetchData();
    }

    return () => {
      isMounted = false;
    };
  }, [queryParams]);

  const dropDownFunction = (qty) => {
    setQueryParams((el) => ({ ...el, limit: qty }));
  };

  const permissionTableHead = () => {
    return (
      <thead className="table">
        <tr>
          <th className="text-center">S/N</th>
          <th className="text-center">Staff Name</th>
          <th className="text-center">Employee Id</th>
          <th className="text-center">Permit</th>
          <th>Status</th>
          <th>Date</th>
          <th className="text-center">Description</th>
          <th className="text-center">Permit type</th>
        </tr>
      </thead>
    );
  };

  const permissionTableBodyData = (permit, index) => {
    return (
      <>
        <td className="text-center">{index + 1}</td>

        <td className="text-center">
          {`${
            permit?.mode === "Single"
              ? permit?.userID?.personalInfo?.name?.first
              : "All"
          } ${
            permit?.mode === "Single"
              ? permit?.userID?.personalInfo?.name?.last
              : "Employee"
          }`}
        </td>

        <td className="text-center">
          {permit?.mode === "Single"
            ? permit?.userID?.jobInfo?.changeableId ||
              permit?.userID?.jobInfo?.employeeId
            : "-- --"}
        </td>

        <td className="text-center">{permit?.mode}</td>
        <td className="text-center fw-bold">
          <p
            className={`tw-status ${chooseAttendanceStatusColor(
              permit.status === "Approved"
                ? "Good"
                : permit.status === "Pending"
                ? "OnLeave"
                : "Late"
            )}`}
          >
            {permit.status}
          </p>
        </td>

        <td className="text-center d-flex gap-3 align-items-center">
          {" "}
          <span>
            {" "}
            <CalendarTodayOutlined fontSize="small" />{" "}
          </span>{" "}
          <p>
            {" "}
            {`${moment(permit?.startDate)?.format("MM/DD/YYYY")} - ${moment(
              permit?.endDate
            )?.format("MM/DD/YYYY")}`}
          </p>
        </td>

        <td className="text-center">{permit?.description}</td>

        <td className="text-center">{permit?.type}</td>
      </>
    );
  };

  return (
    <body>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex, nofollow"></meta>
        <title>InvexERP</title>
      </Helmet>
      <div className="main-wrapper">
        <Topbar />
        <Sidebar />
        <section className="bg-white page-header position-relative w-100 page-header-2">
          <div className="page-wrapper">
            <div className="d-flex w-100  m-0 p-0 align-items-center justify-content-between gap-5">
              <div className="">
                <h4 className="page-title page-title-alt fw-bold">
                  Permissions
                </h4>
                <p className="page-paragraph">Employees permissions</p>
              </div>
              <div className="d-flex">
                <button
                  className="btn add-btn"
                  onClick={() => setOpenApplyPermission(true)}
                >
                  <Add /> Apply
                </button>
              </div>
            </div>
          </div>
        </section>
        {/*<!-- Page Wrapper -->*/}
        <div className="page-wrapper">
          {/*<!-- Page Content -->*/}
          <div className="content container-fluid">
            {/*<!-- Search Filter -->*/}
            <div className="grid-cat-1 filter-row">
              <div className="">
                <div className="form-group form-focus text-gray-400 d-flex align-items-center justify-content-between">
                  <select
                    className="form-control floating"
                    value={theQueryParams.status}
                    name="status"
                    onChange={(e) => onChangeInput(e)}
                  >
                    <option selected value="">
                      Select Status
                    </option>
                    {openDetails?.uniqueStatus?.map((name) => (
                      <option value={name}>{name}</option>
                    ))}
                  </select>
                  <div className=" pr-2">
                    <DownArrowIcon color="#6c757d" />
                  </div>
                </div>
              </div>

              <div className="search-date cal-icon">
                <RsDateRangePicker
                  placement="bottomEnd"
                  onClean={(event, picker) => clearDateRange(event, picker)}
                  onOk={(event, picker) => filterByDateRange(event, picker)}
                  placeholder="Select From and To Date"
                />
              </div>
            </div>
            {/*<!-- /Search Filter -->*/}
            {allPermission.length > 0 ? (
              <TableComponent
                responsive
                striped
                sticky
                tableHeadsFunction={permissionTableHead}
                mainDataArray={allPermission}
                tableDataRowFunction={permissionTableBodyData}
                className="product-table text-nowrap"
              />
            ) : (
              <NoTableItem />
            )}
          </div>
          {/*<!-- /Page Content -->*/}

          <div className="my-4">
            <CustomPagination
              setQueryParams={setQueryParams}
              startIndex={returnParams.startIndex}
              endIndex={returnParams.endIndex}
              count={returnParams.count}
              limit={queryParams.limit}
              page={queryParams.page}
              dropDownFunction={dropDownFunction}
            />
          </div>

          {/*<!-- Add Leave Modal -->*/}
          {openApplyPermission && (
            <ApplyPermissionModal
              setOpenApplyPermission={setOpenApplyPermission}
              getPermission={fetchData}
              employee={employee}
            />
          )}
          {/*<!-- /Add Leave Modal -->*/}
        </div>
        {/*<!-- /Page Wrapper -->*/}
      </div>
      {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
      <div className="sidebar-overlay" data-reff=""></div>
      <ModalLoader show={loading} />
    </body>
  );
};

export default Permission;
