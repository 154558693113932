import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row, Table } from "react-bootstrap";
import Select from "react-select";
import { read, utils } from "xlsx";
import { ImportIcon } from "../icons";
import Cookies from "universal-cookie";
import { fetchActionsUtil, useCookie } from "../../utils";
import { URLS } from "../urls";
import { toast } from "react-toastify";
import ModalLoader from "../ModalLoader";

export const AddUserToBranches = ({ show, handleClose, refresh, employee }) => {
  const [isLoading, setIsLoading] = useState(false);

  // const [gradeId, setGradeId] = useState("");
  const [updatedEmployee, setUpdatedEmployee] = useState({
    branchVisibility: [],
    userId: "",
    _id: "",
    name: "",
  });
  const [branches, setBranches] = useState([]);

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const branchesData = fetchActionsUtil(`${URLS.backendBranch}/get`);

      const [branchesResult] = await Promise.all([branchesData]);

      const mapBranches = branchesResult?.branches.map((el) => ({
        value: el._id,
        label: el.name,
      }));

      setBranches(mapBranches || []);
    } catch (error) {
      console.log(error);
      toast.error("Fetching Error !!!");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      fetchData();
      setUpdatedEmployee({
        branchVisibility: employee?.branchVisibility?.map((el) => ({
          value: el,
        })),
        userId: employee?.userId,
        _id: employee?._id,
        name: `${employee?.personalInfo?.name?.first || ""} ${
          employee?.personalInfo?.name?.middle || ""
        } ${employee?.personalInfo?.name?.last || ""}`,
      });
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const saveUsers = async () => {
    if (!updatedEmployee?.branchVisibility?.length > 0) {
      return toast.error("Please select branches", {
        position: "top-right",
      });
    }

    const branchVisibility = updatedEmployee?.branchVisibility?.map(
      (el) => el?.value
    );

    const result = await fetchActionsUtil(
      `${URLS.backendUsers}/add-user-to-Branches`,
      "POST",
      "token",
      {
        ...updatedEmployee,
        branchVisibility,
      }
    );

    if (result.success) {
      toast.success("Branches saved successfully", {
        position: "top-right",
      });
      handleClose();
      refresh();
    } else {
      toast.error("Error!!!", {
        position: "top-right",
      });
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="md"
    >
      <Modal.Header closeButton>
        <Modal.Title>Add multiple branch to user</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-4">
          <div className="my-3">
            <div className="col-md-12">
              <div className="form-group">
                <label className="col-form-label">Employee name</label>

                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={updatedEmployee?.name}
                />
              </div>
            </div>
          </div>
          {/*  */}

          <div className="my-3">
            <div className="col-md-12">
              <div className="form-group">
                <label className="col-form-label">Select Branches</label>
                <Select
                  closeMenuOnSelect={true}
                  isMulti
                  isSearchable={true}
                  value={
                    branches?.length > 0
                      ? branches?.filter((d) =>
                          updatedEmployee?.branchVisibility?.find(
                            (e) => e.value === d.value
                          )
                        )
                      : []
                  }
                  onChange={(selected) => {
                    setUpdatedEmployee((el) => ({
                      ...el,
                      branchVisibility: selected,
                    }));
                  }}
                  options={branches}
                />
              </div>
            </div>
          </div>
          {/*  */}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-between my-3 mx-4">
          <div className="mx-4">
            <button className="btn btn-secondary" onClick={handleClose}>
              Cancel
            </button>
          </div>
          <div>
            <button className="btn btn-primary" onClick={saveUsers}>
              Save
            </button>
          </div>
        </div>
      </Modal.Footer>

      <ModalLoader show={isLoading} />
    </Modal>
  );
};
