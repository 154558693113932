import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import { Button, Dropdown, Modal } from "react-bootstrap";

import TableComponent from "../TableComponent";
import { URLS } from "../urls";
import { chooseAttendanceStatusColor, useCookie } from "../../utils";
import ModalLoader from "../ModalLoader";
import { ExcelIcon, ExportIcon, PDFIcon, SummaryChartIconUp } from "../icons";
import { CSVLink } from "react-csv";
import { useDownloadExcel } from "../useDownloadExcel";

import { CalendarTodayOutlined } from "@mui/icons-material";

export default function DisplaySingleModal({
  setShowDisplaySingle,
  UserId,
  selectedTimes,
  noneSaveEntries,
}) {
  const branch_id = useCookie("branch_id");

  const [showAttendance, setShowAttendence] = useState({});
  const [loading, setLoading] = useState(false);
  const [attendanceSetting, setAttendanceSetting] = useState({});
  const [attendancePercent, setAttendancePercent] = useState({
    NoSignOut: 0,
    Late: 0,
    EarlyClosure: 0,
    Absent: 0,
  });
  const [attendanceDays, setAttendanceDays] = useState({
    NoSignOut: 0,
    Late: 0,
    EarlyClosure: 0,
    Absent: 0,
  });

  const nowDate = new Date();

  const [payrollDate, setPayrollDate] = useState({
    payrollMonth: selectedTimes
      ? selectedTimes.payrollMonth
      : nowDate.getMonth() + 1,
    payrollYear: selectedTimes
      ? selectedTimes.payrollYear
      : nowDate.getFullYear(),
  });

  const [excelData, setExcelData] = useState([]);

  const CSVLinkRef = useRef(null);

  const token = "dummy token";

  // fetch excel hook
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(
    excelData,
    CSVLinkRef
  );

  useEffect(() => {
    if (noneSaveEntries?.length > 0) {
      arrangeNonSavedEntries();
    } else {
      getAttendance(UserId);
    }
  }, [UserId, noneSaveEntries]);

  useEffect(() => {
    getAttendanceSettings();
  }, []);

  const tableHead = () => {
    return (
      <thead>
        <tr>
          <th>No</th>
          <th>Employee Name </th>
          <th>Employee ID </th>
          <th>Date </th>
          <th>In-Time</th>
          <th>Out-Time</th>
          <th>Result</th>
        </tr>
      </thead>
    );
  };

  const tableBodyData = (attendance, index) => {
    return (
      <>
        <td>{index + 1}</td>
        <td>{attendance.Name}</td>
        <td>{attendance.ID}</td>

        <td className="d-flex gap-3 align-items-center">
          {" "}
          <span>
            {" "}
            <CalendarTodayOutlined fontSize="small" />{" "}
          </span>{" "}
          <p>{moment(attendance?.Date)?.format("MMMM Do YYYY")}</p>{" "}
        </td>
        <td>{attendance.in}</td>
        <td>{attendance.out}</td>
        <td>
          <p
            className={`tw-status ${chooseAttendanceStatusColor(
              attendance.result
            )}`}
          >
            {attendance.result}
          </p>
        </td>
      </>
    );
  };

  const arrangeNonSavedEntries = () => {
    const currentUser = noneSaveEntries?.filter((item) => item?.ID === UserId);
    setShowAttendence({ excellDetails: currentUser });
  };

  const getAttendance = (UserId) => {
    setLoading(true);
    const token = "dummy token";
    fetch(
      `${URLS.backendattendance}/getByUserId/${UserId}?branch_id=${branch_id}&payrollYear=${payrollDate.payrollYear}&payrollMonth=${payrollDate.payrollMonth}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        // console.log(result);
        setShowAttendence({ excellDetails: result?.attendance });

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast("Unable to get TimeSheet", {
          type: "error",
        });
      });
  };

  const search = (UserId) => {
    getAttendance(UserId);
  };

  const getAttendanceSettings = () => {
    fetch(`${URLS.backendattendance}/get-settings/${branch_id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result?.attendance) {
          setAttendanceSetting({
            ...result.attendance,
          });
        }
      })
      .catch((err) => {
        toast("Unable to get Settings", {
          type: "error",
        });
      });
  };

  useEffect(() => {
    let NoSignOut = showAttendance.excellDetails?.filter(
      (el) => el.result === "No SignOut"
    ).length;
    let Late = showAttendance.excellDetails?.filter(
      (el) => el.result === "Late"
    ).length;
    let EarlyClosure = showAttendance.excellDetails?.filter(
      (el) => el.result === "Early Closure"
    ).length;
    let Absent = showAttendance.excellDetails?.filter(
      (el) => el.result === "Absent"
    ).length;

    setAttendanceDays({
      NoSignOut,
      Late,
      EarlyClosure,
      Absent,
    });

    NoSignOut = parseFloat(attendanceSetting.NoSignOut) * NoSignOut;
    Late = parseFloat(attendanceSetting.Lateness) * Late;
    EarlyClosure = parseFloat(attendanceSetting.EarlyClosure) * EarlyClosure;
    Absent = parseFloat(attendanceSetting.Absent) * Absent;

    setAttendancePercent({
      NoSignOut,
      Late,
      EarlyClosure,
      Absent,
    });
  }, [showAttendance.excellDetails, attendanceSetting]);

  const onDownloadExcelData = async () => {
    let exData = showAttendance?.excellDetails.map((attendance, index) => [
      index + 1,
      attendance.Name,
      attendance.ID,
      moment(attendance?.Date)?.format("MM/DD/YYYY"),
      attendance.in,
      attendance.out,
      attendance.result,
    ]);

    const normalHead = [
      "No",
      "Name",
      "ID",
      "Date",
      "In-Time",
      "Out-Time",
      "Result",
    ];

    const date = `Date Prepared: ${moment(new Date()).format(
      "MMMM DD yyyy k:mm:ss z"
    )}`;

    exData = [
      // [company],
      ["User Report"],
      [date],
      [""],
      normalHead,
      ...exData,
      [""],
      ["Type", "Percentage", "Days"],
      [
        "Absent",
        `${attendancePercent.Absent}%`,
        `${attendanceDays.Absent} Days`,
      ],
      [
        "Early Closure",
        `${attendancePercent.EarlyClosure}%`,
        `${attendanceDays.EarlyClosure} Days`,
      ],
      ["Lateness", `${attendancePercent.Late}%`, `${attendanceDays.Late} Days`],
      [
        "No SignOut",
        `${attendancePercent.NoSignOut}%`,
        `${attendanceDays.NoSignOut} Days`,
      ],
    ];

    // console.log(exData);
    setExcelData(exData);
  };

  return (
    <Modal
      centered
      show={true}
      onHide={() => {
        setShowDisplaySingle();
      }}
      className="custom-modal"
      size="lg"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="form-header">
          <div className="d-flex justify-content-between align-items-baseline">
            <div>
              <p className=" text-16 light-text font-500">
                Employee ID : {UserId}
              </p>
            </div>

            <div className=" d-flex align-items-center">
              <div className="form-inline">
                {/* <label>Month</label> */}
                <select
                  className="form-control form-control-alt-day mx-2"
                  name="payrollMonth"
                  value={payrollDate.payrollMonth}
                  onChange={(e) =>
                    setPayrollDate({
                      ...payrollDate,
                      payrollMonth: e.target.value,
                    })
                  }
                >
                  {[...Array(12)].map((e, index) => (
                    <option key={index}>{index + 1}</option>
                  ))}
                </select>
                {/* <label>Year</label> */}
                <input
                  type="number"
                  style={{ width: "100px" }}
                  className="form-control form-control-alt-year mx-2"
                  value={payrollDate.payrollYear}
                  onChange={(e) =>
                    setPayrollDate({
                      ...payrollDate,
                      payrollYear: e.target.value,
                    })
                  }
                />
                <Button
                  className="mx-2 text-14 px-2"
                  onClick={() => search(UserId)}
                >
                  Search
                </Button>
              </div>
              <div>
                <CSVLink
                  className="btn print d-none"
                  filename={`User Report(${moment(new Date()).format(
                    "DD-MMM-yyyy hh:mm:ss a"
                  )}).csv`}
                  data={excelData}
                  ref={CSVLinkRef}
                />

                <Dropdown>
                  <Dropdown.Toggle
                    variant=""
                    className="print"
                    disabled={isfetchingExcel}
                    bsPrefix=""
                  >
                    <span className="mx-2 text-12">Export</span>
                    <ExportIcon fontSize="12px" color="#008000" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    popperConfig={{
                      strategy: "fixed",
                    }}
                    renderOnMount
                    className="text-center"
                  >
                    <Dropdown.Item
                      as="div"
                      onClick={onDownloadExcelData}
                      className="p-cursor"
                    >
                      Excel <ExcelIcon color="#008000" />
                    </Dropdown.Item>
                    <Dropdown.Item
                      href={`${URLS.backendattendance}/PDF/${UserId}?branch_id=${branch_id}&payrollYear=${payrollDate.payrollYear}&payrollMonth=${payrollDate.payrollMonth}`}
                      target="blank"
                      className="p-cursor text-12"
                    >
                      PDF <PDFIcon color="#ff0000" />
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-4">
          {/*<!-- JobGradeHistory Table -->*/}
          <div className="info">
            <div className="table-height">
              {showAttendance?.excellDetails?.length > 0 && (
                <TableComponent
                  responsive
                  borderless
                  striped
                  sticky
                  // hover
                  tableHeadsFunction={tableHead}
                  mainDataArray={showAttendance?.excellDetails}
                  tableDataRowFunction={tableBodyData}
                  className="product-table text-nowrap"
                />
              )}
            </div>
          </div>
          {/*<!-- Table --> */}
          <ModalLoader show={loading} />
        </div>
        <hr />
        <div className="mt-3">
          <div className="summaryNew">
            {/* First Row */}

            <div
              className="gridCont mb-3 four-col-grid"
              style={{ gridTemplateRows: "unset" }}
            >
              {/*  */}
              <div className="gridChild gridChildBorderLeftOrange">
                <div className="gridChildLeft">
                  <p className="gridChld1">
                    {attendanceDays.Absent || 0}{" "}
                    {attendanceDays.Absent > 1 ? "Days" : "Day"}
                  </p>
                  <p className="gridChld2">Absent</p>
                </div>

                <div className="gridChildRight">
                  <SummaryChartIconUp />
                  <p>%</p>
                </div>
              </div>

              {/*  */}

              <div className="gridChild gridChildBorderLeftOrange">
                <div className="gridChildLeft">
                  <p className="gridChld1">
                    {attendanceDays.EarlyClosure || 0}{" "}
                    {attendanceDays.EarlyClosure > 1 ? "Days" : "Day"}
                  </p>

                  <p className="gridChld2">Early Closure</p>
                </div>

                <div className="gridChildRight">
                  <SummaryChartIconUp />
                  <p>%</p>
                </div>
              </div>
              {/*  */}

              <div className="gridChild gridChildBorderLeftOrange">
                <div className="gridChildLeft">
                  <p className="gridChld1">
                    {attendanceDays.Late || 0}{" "}
                    {attendanceDays.Late > 1 ? "Days" : "Day"}
                  </p>
                  <p className="gridChld2">Lateness</p>
                </div>

                <div className="gridChildRight">
                  <SummaryChartIconUp />
                  <p>%</p>
                </div>
              </div>
              {/*  */}
              <div className="gridChild gridChildBorderLeftOrange">
                <div className="gridChildLeft">
                  <p className="gridChld1">
                    {attendanceDays.NoSignOut || 0}{" "}
                    {attendanceDays.NoSignOut > 1 ? "Days" : "Day"}
                  </p>
                  <p className="gridChld2">No SignOut</p>
                </div>

                <div className="gridChildRight">
                  <SummaryChartIconUp />
                  <p>%</p>
                </div>
              </div>
              {/*  */}
            </div>
            {/* Secound Row */}

            <div
              className="gridCont mb-3 four-col-grid"
              style={{ gridTemplateRows: "unset" }}
            >
              {/*  */}
              <div className="gridChild gridChildBorderLeftOrange">
                <div className="gridChildLeft">
                  <p className="gridChld1">{attendancePercent.Absent || 0} %</p>
                  <p className="gridChld2">Absent</p>
                </div>

                <div className="gridChildRight">
                  <SummaryChartIconUp />
                  <p>%</p>
                </div>
              </div>

              {/*  */}

              <div className="gridChild gridChildBorderLeftOrange">
                <div className="gridChildLeft">
                  <p className="gridChld1">
                    {attendancePercent.EarlyClosure || 0} %
                  </p>

                  <p className="gridChld2">Early Closure</p>
                </div>

                <div className="gridChildRight">
                  <SummaryChartIconUp />
                  <p>%</p>
                </div>
              </div>
              {/*  */}

              <div className="gridChild gridChildBorderLeftOrange">
                <div className="gridChildLeft">
                  <p className="gridChld1">{attendancePercent.Late || 0} %</p>
                  <p className="gridChld2">Lateness</p>
                </div>

                <div className="gridChildRight">
                  <SummaryChartIconUp />
                  <p>%</p>
                </div>
              </div>
              {/*  */}
              <div className="gridChild gridChildBorderLeftOrange">
                <div className="gridChildLeft">
                  <p className="gridChld1">
                    {attendancePercent.NoSignOut || 0} %
                  </p>
                  <p className="gridChld2">No SignOut</p>
                </div>

                <div className="gridChildRight">
                  <SummaryChartIconUp />
                  <p>%</p>
                </div>
              </div>
              {/*  */}
            </div>

            {/*  */}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
