import React, { Fragment } from "react";
import { URLS } from "../urls";
import moment from "moment";
import DatePicker from "react-datetime";
import { Form, Col, Row } from "react-bootstrap";
import Select from "react-select";
import { branch_id, fetchActionsUtil, sendFormData } from "../../utils";
import { toast } from "react-toastify";
import { Modal } from "reactstrap";

import ModalLoader from "../ModalLoader";
import { CancelIcon } from "../icons";

export default class ApplyLeaveModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonText: "Submit",
      isWaiting: false,
      isLoading: false,
      applyLeave: {
        employee: {
          id: null,
          name: "",
          image: "",
          designation: "",
        },
        leaveId: "",
        name: "",
        from: moment(new Date()).format("DD/MM/YYYY"),
        to: moment(new Date()).format("DD/MM/YYYY"),
        days: 0,
        remaining: 0,
        reason: "",
        status: "New",
        appliedDate: "",
        branch_id,
      },
      employees: [],
      mapEmployees: [],
      leaveBalances: {},
      employeeAvailableLeaves: null,
      // ----------------
      staff: "",
      departments: [],
      users: [],
      employee: {},
      supervisor: null,
      requester: null,
      leaveSettings: {},
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    if (process.env.REACT_APP_HAS_INVEX !== "true") {
      return toast.error("Please activate form module to use leave");
    }

    this.getDepartments();
    this.getLeaveSettings();
    this.setState({ isLoading: false });
  }

  getDepartments = async () => {
    // await waitFor(5000);
    if (process.env.REACT_APP_HAS_INVEX === "true") {
      let response = await fetch(`${URLS.mainSiteBackend}/users/departments`, {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        // credentials: "include",
      });

      if (!response.ok) {
        response = await response.json();
        console.log(response.message);
        throw new Error(response.message);
      }

      const { data } = await response.json();

      const res = data?.departments?.map((el) => ({
        value: el?.Department,
        label: el?.Department,
      }));

      this.setState({
        departments: [
          { value: "", label: "Select Recipient's Department" },
          ...res,
        ],
      });
    }
  };

  getLeaveSettings = async () => {
    // await waitFor(5000);

    let response = await fetch(
      `${URLS.backendLeaves}/get-settings/${branch_id}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        // credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      console.log(response.message);
      throw new Error(response.message);
    }

    const { leaveSettings } = await response.json();

    this.setState({
      leaveSettings: { ...leaveSettings },
    });
  };

  getUsersInDepartments = async (department) => {
    let response = await fetch(
      `${URLS.mainSiteBackend}/users/by-department/${department}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        // credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      console.log(response.message);
      throw new Error(response.message);
    }
    const { data } = await response.json();

    const res = data?.users?.map((el) => ({
      value: el?.Staff_ID,
      label: el?.Name,
    }));

    this.setState({
      users: [{ value: "", label: "Select Recipient" }, ...res],
    });
  };

  getSupervisors = async (id) => {
    try {
      this.setState({ isLoading: true });
      let response = await fetch(
        `${URLS.backendEmployees}/getOneEmployeeSupervisor/${id}`,
        {
          method: "GET",
          headers: {
            Accept: "Application/json",
            "Content-Type": "Application/json",
          },
          // credentials: "include",
        }
      );

      if (!response.ok) {
        response = await response.json();
        throw new Error(response.message);
      }
      const { foundSupervisor } = await response.json();

      if (foundSupervisor) {
        if (process.env.REACT_APP_HAS_INVEX === "true") {
          let response = await fetch(
            `${URLS.mainSiteBackend}/users/${foundSupervisor?.Staff_ID}`,
            {
              method: "GET",
              headers: {
                Accept: "Application/json",
                "Content-Type": "Application/json",
              },
              // credentials: "include",
            }
          );

          if (!response.ok) {
            response = await response.json();

            throw new Error(response.message);
          }

          const { data } = await response.json();

          this.setState({
            supervisor: data,
            staff: data?.Staff_ID,
          });
        }
      } else {
        this.setState({
          supervisor: null,
          staff: "",
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  static getDerivedStateFromProps(nextProps, nextState) {
    if (
      nextProps.employees !== nextState.employees ||
      nextProps.leaveBalances !== nextState.leaveBalances
    ) {
      let emp = [];
      let leaveBalances = [];
      let employees = [];
      if (nextProps.employees.length) {
        emp = nextProps.employees.map((em) => ({
          label: em?.name,
          value: em?.id,
        }));

        employees = nextProps.employees;
        // this.setState({
        //   employees: props.employees,
        //   mapEmployees: emp,
        // });
      }
      if (nextProps.leaveBalances) {
        leaveBalances = nextProps.leaveBalances;
        // this.setState({
        //   leaveBalances: props.leaveBalances,
        // });
      }

      return {
        mapEmployees: emp,
        employees,
        leaveBalances,
      };
    }

    // If no changes are needed, return null
    return null;
  }

  updateLeaveEmployee = (e) => {
    const { applyLeave, employees, leaveBalances } = this.state;
    // console.log("applyLeave  ===>", applyLeave);
    const id = e;
    applyLeave.employee = employees.find((employee) => employee.id === id);

    applyLeave.leaveId = "";
    applyLeave.name = "";
    applyLeave.remaining = "";
    applyLeave.days = "";
    applyLeave.from = moment(new Date()).format("DD/MM/YYYY");
    applyLeave.to = moment(new Date()).format("DD/MM/YYYY");

    // console.log("YOYO", leaveBalances, "id ===>", id);
    this.setState({
      applyLeave,
      employeeAvailableLeaves: leaveBalances[id],
    });
  };

  updateLeaveName = (e) => {
    const { applyLeave, employeeAvailableLeaves } = this.state;

    applyLeave.leaveId = e.target.value;
    const leaveType = employeeAvailableLeaves.find(
      (l) => l._id == applyLeave.leaveId
    );
    applyLeave.name = leaveType?.name;
    applyLeave.remaining = leaveType?.remaining;
    applyLeave.days = applyLeave?.remaining ? 1 : 0;

    applyLeave.from = moment(new Date()).format("DD/MM/YYYY");
    applyLeave.to = moment(new Date()).format("DD/MM/YYYY");
    this.setState({
      applyLeave,
    });
  };
  updateLeaveFrom = (event) => {
    const { applyLeave, leaveSettings } = this.state;

    let diff = 0;

    applyLeave.from = event.target
      ? event.target.value
      : moment(event).format("DD/MM/YYYY");

    if (!leaveSettings?.useLeaveWorkingDays) {
      diff = moment(applyLeave.to, "DD/MM/YYYY").diff(
        moment(applyLeave.from, "DD/MM/YYYY"),
        "days"
      );
    } else {
      const start = moment(applyLeave.from, "DD/MM/YYYY");
      const end = moment(applyLeave.to, "DD/MM/YYYY");

      while (start.isSameOrBefore(end)) {
        if (start.isoWeekday() !== 6 && start.isoWeekday() !== 7) {
          // exclude weekends
          diff++;
        }
        start.add(1, "days");
      }
    }

    if (diff < 0) {
      applyLeave.to = applyLeave.from;
      applyLeave.days = applyLeave.remaining ? 1 : 0;
    } else {
      applyLeave.days = diff + 1;
    }
    this.setState({
      applyLeave,
    });
  };
  updateLeaveTo = (event) => {
    const { applyLeave, leaveSettings } = this.state;
    applyLeave.to = event.target
      ? event.target.value
      : moment(event).format("DD/MM/YYYY");

    applyLeave.days = 0;

    if (!leaveSettings?.useLeaveWorkingDays) {
      applyLeave.days =
        moment(applyLeave.to, "DD/MM/YYYY").diff(
          moment(applyLeave.from, "DD/MM/YYYY"),
          "days"
        ) + 1;
    } else {
      const start = moment(applyLeave.from, "DD/MM/YYYY");
      const end = moment(applyLeave.to, "DD/MM/YYYY");
      let diff = 0;

      while (start.isSameOrBefore(end)) {
        if (start.isoWeekday() !== 6 && start.isoWeekday() !== 7) {
          // exclude weekends
          diff++;
        }
        start.add(1, "days");
      }

      applyLeave.days = diff;
    }

    this.setState({
      applyLeave,
    });
  };
  updateLeaveDays = (e) => {
    const { applyLeave } = this.state;
    applyLeave.days = e.target.value;
    this.setState({
      applyLeave,
    });
  };
  updateLeaveRemaining = (e) => {
    const { applyLeave } = this.state;
    applyLeave.remaining = e.target.value;
    this.setState({
      applyLeave,
    });
  };
  updateLeaveReason = (e) => {
    const { applyLeave } = this.state;
    applyLeave.reason = e.target.value;
    this.setState({
      applyLeave,
    });
  };

  submit = async (e) => {
    e.preventDefault();
    this.setState({ buttonText: "Submitting ...", isWaiting: true });
    const { applyLeave, employee, staff, requester } = this.state;

    if (Object.entries(employee).length < 1) {
      return toast.error("Please select an employee", {
        position: "top-right",
      });
    }

    const employeeName = employee.label;
    const employeeId = employee.value;

    let newSave = JSON.stringify(applyLeave);
    newSave = JSON.parse(newSave);
    newSave.appliedDate = new Date();
    newSave.remaining = parseInt(newSave.remaining) - parseInt(newSave.days);
    newSave.to = moment(newSave.to, "DD/MM/YYYY");
    newSave.from = moment(newSave.from, "DD/MM/YYYY");

    const requisition = {
      title: `${employeeName} Leave Application`,
      description: `${employeeName} applied for a leave that will run from ${newSave.from} to ${newSave.to}`,
      type: "Leave",
      amount: 0,
      requestto: staff,
      status: "Pending",
      regdate: moment(new Date()).format("YYYY-MM-DD"),
      requestby: requester ? requester : staff,
      vendor: "",
      comment: applyLeave.reason,
      filledFormData: "",
      loanDetails: JSON.stringify({ employeeName, employeeId }),
    };

    const formData = new FormData();
    // eslint-disable-next-line no-unused-vars
    for (const [key, value] of Object.entries(requisition)) {
      formData.append(key, value);
    }

    const token = "dummy token";

    if (process.env.REACT_APP_HAS_INVEX === "true") {
      const { data } = await sendFormData({
        url: `${URLS.mainSiteBackend}/requisition/create`,
        token,
        formData,
      });

      if (data) {
        newSave.requestid = data?.requisition?.requestid;

        const result = await fetchActionsUtil(
          `${URLS.backendApplyLeaves}/add`,
          "POST",
          token,
          {
            applyLeave: newSave,
          }
        );

        if (result.success) {
          window.$("#add_leave").modal("toggle");
          this.setState({
            buttonText: "Submit",
            isWaiting: false,
            applyLeave: {
              employee: {
                id: null,
                name: "",
                image: "",
                designation: "",
              },
              leaveId: "",
              name: "",
              from: moment(new Date()).format("DD/MM/YYYY"),
              to: moment(new Date()).format("DD/MM/YYYY"),
              days: 0,
              remaining: 0,
              reason: "",
              status: "New",
              appliedDate: "",
            },
          });
          this.props.updateAppliedLeavesList(result.applyLeave);
          toast("Leave Successfully Applied.", {
            type: "success",
            position: "top-right",
          });
        } else {
          await fetchActionsUtil(
            `${URLS.mainSiteBackend}/requisition/delete`,
            "POST",
            token,
            {
              requestid: data?.requisition?.requestid,
            }
          );

          toast.error(result?.message, {
            position: "top-right",
          });
          this.setState({ buttonText: "Submit", isWaiting: false });
        }
      } else {
        toast("Error occurred, Check the form again.", {
          type: "error",
          position: "top-right",
        });
        this.setState({ buttonText: "Submit", isWaiting: false });
      }
    } else {
      return toast.error("Please connect to Invex for Leave Approval", {
        position: "top-right",
      });
    }
  };
  validationFrom = (currentDate) => {
    return currentDate.isAfter(moment(new Date()).add(-1, "days"));
  };
  validationTo = (currentDate) => {
    const { applyLeave } = this.state;
    if (applyLeave.from) {
      return (
        currentDate.isBefore(
          moment(applyLeave.from, "DD/MM/YYYY").add(
            applyLeave.remaining,
            "days"
          )
        ) &&
        currentDate.isAfter(
          moment(applyLeave.from, "DD/MM/YYYY").add(-1, "days")
        )
      );
    } else return currentDate.isAfter(moment(new Date()).add(-1, "days"));
  };
  disableDate = (currentDate) => {
    return false;
  };

  getEmployeeFromInvex = (id) => {
    fetch(`${URLS.backendEmployees}/get-employee-from-invex-details/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          this.setState({
            requester: data?.invexStaff?.Staff_ID,
          });
        }
      });
  };

  render() {
    const {
      applyLeave,
      employeeAvailableLeaves,
      mapEmployees,
      users,
      departments,
      staff,
      isWaiting,
      supervisor,
      isLoading,
    } = this.state;

    return (
      <Modal
        className="modal-alt"
        isOpen={this.props.show}
        toggle={this.props.handleClose}
      >
        <div className="modal-header-alt">
          <div className="modal-header-title-alt">
            <h1 className=" text-dark font-600 text-20 mb-0 pb-0 ">
              Apply for leave
            </h1>
            <p className=" light-text text-14 font-400">
              Request time off for personal or professional reasons
            </p>
          </div>
          <button className="modal-close-btn" onClick={this.props.handleClose}>
            <CancelIcon />
          </button>
        </div>

        <form onSubmit={this.submit}>
          <section className="modal-body-alt">
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label className=" text-14 font-600  light-text">
                    Employee<span className="text-danger">*</span>
                  </Form.Label>
                  {mapEmployees && (
                    <Select
                      closeMenuOnSelect={true}
                      isSearchable
                      required
                      classNamePrefix="form-control"
                      value={mapEmployees?.find(
                        (el) => el.value === applyLeave?.employee?.id
                      )}
                      onChange={(selected) => {
                        if (!selected.value) return;
                        this.updateLeaveEmployee(selected.value);
                        this.setState({ employee: selected });
                        this.getSupervisors(selected.value);
                        this.getEmployeeFromInvex(selected.value);
                      }}
                      options={mapEmployees}
                    />
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label className=" text-14 font-600  light-text">
                    Leave Type <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    // value={applyLeave._id}
                    value={applyLeave.leaveId}
                    onChange={this.updateLeaveName}
                    style={{ height: "35px" }}
                    disabled={applyLeave?.employee?.id ? false : true}
                    required
                  >
                    <option value="">Select Type</option>
                    {employeeAvailableLeaves &&
                    employeeAvailableLeaves.length > 0 ? (
                      employeeAvailableLeaves.map((leaveType) => {
                        return (
                          <option value={leaveType._id}>
                            {leaveType.name}
                          </option>
                        );
                      })
                    ) : (
                      <option value="a" disabled>
                        No Leaves Available
                      </option>
                    )}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label className=" text-14 font-600  light-text">
                    From <span className="text-danger">*</span>
                  </Form.Label>
                  <div className="cal-icon">
                    <DatePicker
                      value={applyLeave.from}
                      closeOnSelect={true}
                      onChange={this.updateLeaveFrom}
                      isValidDate={
                        applyLeave?.employee?.id
                          ? this.validationFrom
                          : this.disableDate
                      }
                      required={true}
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label className=" text-14 font-600  light-text">
                    To <span className="text-danger">*</span>
                  </Form.Label>
                  <div className="cal-icon">
                    <DatePicker
                      value={applyLeave.to}
                      closeOnSelect={true}
                      onChange={this.updateLeaveTo}
                      isValidDate={
                        applyLeave?.employee?.id
                          ? this.validationTo
                          : this.disableDate
                      }
                      required={true}
                    />
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label className=" text-14 font-600  light-text">
                    Number of days <span className="text-danger">*</span>
                  </Form.Label>
                  <input
                    className="form-control"
                    disabled
                    type="number"
                    value={applyLeave.days}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label className=" text-14 font-600  light-text">
                    Remaining Leaves <span className="text-danger">*</span>
                  </Form.Label>
                  <input
                    className="form-control"
                    disabled
                    type="number"
                    value={applyLeave.remaining}
                  />
                </Form.Group>
              </Col>
            </Row>
            {/*  */}
            <div className="d-flex align-items-center my-4">
              <h4 className="pr-2">Send Request To</h4>{" "}
              <hr className="flex-grow-1 m-0" />
            </div>

            {!supervisor ? (
              <div className="row gap-3">
                <Form.Group className="col mb-3">
                  <Form.Label className=" text-14 font-600  light-text">
                    Department <span className="text-danger">*</span> :
                  </Form.Label>
                  <Select
                    classNamePrefix={`form-select-analytics`}
                    isSearchable={true}
                    placeholder="Select Recipient's Department"
                    options={departments}
                    // value={departments?.find(
                    //   (el) => el.value === queryParams.by
                    // )}
                    onChange={(selected) => {
                      this.getUsersInDepartments(selected?.value);
                    }}
                  />
                </Form.Group>

                {users.length > 0 && (
                  <Form.Group className="col mb-3">
                    <Form.Label className=" text-14 font-600  light-text">
                      Recipient <span className="text-danger">*</span> :
                    </Form.Label>
                    <Select
                      classNamePrefix={`form-select-analytics`}
                      isSearchable={true}
                      placeholder="Select Recipient"
                      options={users}
                      isRequired
                      // value={departments?.find(
                      //   (el) => el.value === queryParams.by
                      // )}
                      onChange={(selected) => {
                        this.setState({ staff: selected?.value });
                      }}
                    />
                  </Form.Group>
                )}
              </div>
            ) : (
              <div className="my-3">
                <input
                  className="form-control"
                  disabled
                  type="text"
                  value={supervisor?.Name}
                />
              </div>
            )}

            {/*  */}
            <div className="form-group">
              <label className=" text-14 font-600  light-text">
                comment (Optional)
              </label>
              <textarea
                rows="6"
                className="form-control"
                value={applyLeave.reason}
                onChange={this.updateLeaveReason}
                disabled={applyLeave?.employee?.id ? false : true}
              ></textarea>
            </div>
          </section>
          <div className="w-full align-items-center d-flex flex-wrap justify-content-between">
            <div className=""></div>
            <div className="d-flex align-items-center gap-5">
              <button
                className=" cancel-btn-alt"
                onClick={this.props.handleClose}
              >
                <p className="light-text font-600 text-14">Cancel</p>
              </button>
              {!isWaiting && (
                <button
                  className="submit-btn-alt"
                  disabled={!applyLeave.remaining}
                >
                  <p className="text-14 font-600">Submit</p>
                </button>
              )}
            </div>
          </div>
        </form>

        <ModalLoader show={isLoading} />
      </Modal>
    );
  }
}
