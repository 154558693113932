import React, { Fragment, useState, useEffect } from "react";
import { URLS } from "../urls";
import moment from "moment";
import { Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import Select from "react-select";
import { dateWithNowTime, fetchActionsUtil, useCookie } from "../../utils";
import RsDateRangePicker from "../RsDateRangePicker";

export default function ApplyPermissionModal({
  setOpenApplyPermission,
  getPermission,
  activeTab,
}) {
  const branch_id = useCookie("branch_id");

  const [state, setState] = useState({
    buttonText: "Submit",
    userId: "",
    applyDate: moment(new Date()).format("DD/MM/YYYY"),
    staff: "",
    users: [],
    comment: "",
    description: "",
    employee: {},
    employees: [],
    startDate: moment(new Date()).format("MM/DD/YYYY"),
    endDate: moment(new Date()).format("MM/DD/YYYY"),
    type: "",
    mode: "General",
    reoccurring: "",
  });

  const [departments, setDepartments] = useState([]);
  const [isHoliday, setIsHoliday] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [holidayName, setHolidayName] = useState("");

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    setIsHoliday(activeTab === "holiday");
  }, [activeTab]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const departmentData = fetchActionsUtil(
        `${URLS.mainSiteBackend}/users/departments`
      );

      const employeeData = fetchActionsUtil(
        `${URLS.backendEmployees}/get/${branch_id}`
      );

      const [departmentResult, employeeResult] = await Promise.all([
        departmentData,
        employeeData,
      ]);

      const emp = employeeResult.employees?.map((em) => ({
        label: `${
          em.personalInfo.name.first ? em.personalInfo.name.first : ""
        } ${em.personalInfo.name.middle ? em.personalInfo.name.middle : ""} ${
          em.personalInfo.name.last ? em.personalInfo.name.last : ""
        }`,
        value: JSON.stringify({
          id: em._id,
          name: `${
            em.personalInfo.name.first ? em.personalInfo.name.first : ""
          } ${em.personalInfo.name.middle ? em.personalInfo.name.middle : ""} ${
            em.personalInfo.name.last ? em.personalInfo.name.last : ""
          }`,
        }),
      }));
      setState({
        ...state,
        employees: emp,
      });
      setDepartments(departmentResult?.data?.departments);
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };

  const getUsersInDepartments = async (department) => {
    let response = await fetch(
      `${URLS.mainSiteBackend}/users/by-department/${department}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        // credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    setState({
      ...state,
      users: data?.users,
    });
  };

  const onChangeInputs = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const filterByDateRange = (event, picker) => {
    setState({
      ...state,
      startDate: picker.startDate.format("MM/DD/YYYY"),
      endDate: picker.endDate.format("MM/DD/YYYY"),
    });
    picker.element.val(
      picker.startDate.format("MM/DD/YYYY") +
        " - " +
        picker.endDate.format("MM/DD/YYYY")
    );
  };

  const clearDateRange = (event, picker) => {
    setState({
      ...state,
      startDate: "",
      endDate: "",
    });

    picker.element.val("");
  };

  const submit = async (e) => {
    e.preventDefault();
    setState({ ...state, buttonText: "Submitting ..." });
    setLoading(true);

    const {
      applyDate,
      description,
      comment,
      staff,
      employee,
      startDate,
      endDate,
      type,
      mode,
      reoccurring,
    } = state;

    let employeeName = "";
    let employeeId = null;
    if (mode === "Single") {
      if (Object.entries(employee).length < 1) {
        return toast.error("Please select an employee", {
          position: "top-right",
        });
      }

      employeeName = JSON.parse(employee).name;
      employeeId = JSON.parse(employee).id;
    } else {
      employeeName = "All Employee";
    }

    const isReoccurring = reoccurring === "Yes" ? true : false;

    let newWord = null;
    if (isHoliday) {
      newWord = holidayName.toLowerCase().includes("holiday")
        ? holidayName
        : `${holidayName} holiday`;
    }

    const requisition = {
      title: isHoliday
        ? `${newWord} Request`
        : `${employeeName}'s Permission Request`,
      description,
      type: isHoliday ? "Holiday" : "Permission",
      requestto: staff,
      amount: 0,
      status: "Pending",
      regdate: moment(new Date()).format("MM/DD/YYYY"),
      requestby: staff,
      vendor: "",
      comment,
      filledFormData: "",
      loanDetails: JSON.stringify({
        employeeName,
        employeeId,
        startDate,
        endDate,
        type,
        isReoccurring,
        holidayName: newWord,
      }),
    };

    const sendBody = {
      applyDate,
      status: "Pending",
      userID: employeeId,
      staff,
      description,
      comment,
      branch_id,
      endDate: dateWithNowTime(endDate, "MM/DD/YYYY"),
      startDate: dateWithNowTime(startDate, "MM/DD/YYYY"),
      type,
      mode,
      holidayName: newWord,
      reoccurring: isReoccurring,
    };

    const formData = new FormData();
    Object.entries(requisition).forEach(([key, value]) => {
      formData.append(key, value);
    });

    const token = "dummy token";

    try {
      if (process.env.REACT_APP_HAS_INVEX === "true") {
        const res = await fetch(`${URLS.mainSiteBackend}/requisition/create`, {
          method: "POST",
          headers: {
            Authorization: `Token ${token}`,
          },
          body: formData,
        });

        if (res.ok) {
          const result = await res.json();
          const sendResult = await fetchActionsUtil(
            `${URLS.backendpermmission}`,
            "POST",
            token,
            {
              ...sendBody,
              requestid: result?.data?.requisition?.requestid,
            }
          );

          if (sendResult.success) {
            toast.success(
              `${isHoliday ? "Holiday" : "Permission"} Successfully Applied.`,
              {
                position: "top-right",
              }
            );
            getPermission();
            setState({
              ...state,
              buttonText: "Submit",
              staff: "",
              users: [],
              comment: "",
              description: "",
            });
            setLoading(false);
            setOpenApplyPermission(false);
          }
        }
      } else {
        toast.error("Please Connect to InvexERP.", {
          position: "top-right",
        });
      }
    } catch (error) {
      console.error(error);
      toast.error("An unexpected error occurred.", {
        position: "top-right",
      });
    } finally {
      setState({ ...state, buttonText: "Submit" });
      setLoading(false);
    }
  };

  return (
    <Modal
      centered
      show={true}
      onHide={() => {
        setOpenApplyPermission(false);
      }}
      className="custom-modal"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h3>Apply For {isHoliday ? "Holiday" : "Permission"}</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div>
            <form onSubmit={submit}>
              <div>
                {!isHoliday ? (
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Permission Mode <span className="text-danger">*</span> :
                    </Form.Label>
                    <select
                      className="form-control"
                      value={state?.mode}
                      name="mode"
                      onChange={onChangeInputs}
                      required
                    >
                      <option value=""></option>
                      <option value="General">General</option>
                      <option value="Single">Single</option>
                    </select>
                  </Form.Group>
                ) : (
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group form-focus">
                        <label>
                          Holiday name <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter holiday name"
                          className="form-control"
                          value={holidayName}
                          name="holidayName"
                          onChange={(e) => setHolidayName(e.target.value)}
                          required={!isHoliday}
                        />
                      </div>
                    </div>
                    <Form.Group className="col-md-6">
                      <Form.Label>
                        Choose Date <span className="text-danger">*</span>
                      </Form.Label>

                      <RsDateRangePicker
                        placement="topEnd"
                        onClean={(event, picker) =>
                          clearDateRange(event, picker)
                        }
                        onOk={(event, picker) =>
                          filterByDateRange(event, picker)
                        }
                      />
                    </Form.Group>
                  </div>
                )}
              </div>

              {!isHoliday ? (
                <div className="row">
                  {state?.mode === "Single" && (
                    <Form.Group
                      className={`mb-3 ${
                        state?.mode === "Single" ? "col-md-6" : ""
                      }`}
                    >
                      <Form.Label>
                        Employee <span className="text-danger">*</span> :
                      </Form.Label>

                      <Select
                        closeMenuOnSelect={true}
                        isSearchable
                        required
                        classNamePrefix="form-control"
                        value={state?.employees?.find(
                          (el) => el.value === state?.employee?.name
                        )}
                        onChange={(selected) => {
                          setState({ ...state, employee: selected.value });
                        }}
                        options={state?.employees}
                      />
                    </Form.Group>
                  )}

                  <Form.Group
                    className={`mb-3 ${
                      state?.mode === "Single" ? "col-md-6" : "col-12"
                    }`}
                  >
                    <Form.Label>
                      Choose Date <span className="text-danger">*</span>
                    </Form.Label>

                    <RsDateRangePicker
                      placement="topEnd"
                      onClean={(event, picker) => clearDateRange(event, picker)}
                      onOk={(event, picker) => filterByDateRange(event, picker)}
                    />
                  </Form.Group>
                </div>
              ) : null}

              <Form.Group className="mb-3">
                <Form.Label>
                  Description <span className="text-danger">*</span> :
                </Form.Label>
                <label className="border p-0 rounded pb-2 message-area d-block">
                  <Form.Control
                    name="description"
                    value={state?.description}
                    onChange={onChangeInputs}
                    as="textarea"
                    className="w-100 border-0 p-3"
                    placeholder="Enter your detail description"
                    rows={4}
                    required
                  />
                </label>
              </Form.Group>

              {!isHoliday ? (
                <Form.Group className="mb-3">
                  <Form.Label>
                    Permission Type <span className="text-danger">*</span> :
                  </Form.Label>
                  <select
                    className="form-control"
                    value={state?.type}
                    name="type"
                    onChange={onChangeInputs}
                    required={!isHoliday}
                  >
                    <option value=""></option>
                    <option value="NoSignOut">No SignOut</option>
                    <option value="Lateness">Lateness</option>
                    <option value="Absent">Absent</option>
                    <option value="EarlyClosure">Early Closure</option>
                  </select>
                </Form.Group>
              ) : (
                <Form.Group className="mb-3">
                  <Form.Label>
                    ReOccur <span className="text-danger">*</span> :
                  </Form.Label>
                  <select
                    className="form-control"
                    value={state?.reoccurring}
                    name="reoccurring"
                    onChange={onChangeInputs}
                    required={isHoliday}
                  >
                    <option value=""></option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </Form.Group>
              )}

              <div className="d-flex align-items-center my-4">
                <h4 className="pr-2">Send Request To</h4>{" "}
                <hr className="flex-grow-1 m-0" />
              </div>

              <div className="row gap-3">
                <Form.Group className="col mb-3">
                  <Form.Label>
                    Department <span className="text-danger">*</span> :
                  </Form.Label>
                  <select
                    className="form-control"
                    onChange={(e) => getUsersInDepartments(e.target.value)}
                    required
                  >
                    <option value="">Select Recipient's Department</option>
                    {departments &&
                      departments.map((el, index) => (
                        <option key={index} value={el.Department}>
                          {el.Department}
                        </option>
                      ))}
                  </select>
                </Form.Group>

                {state?.users.length > 0 && (
                  <Form.Group className="col mb-3">
                    <Form.Label>
                      Recipient <span className="text-danger">*</span> :
                    </Form.Label>
                    <select
                      name="staff"
                      className="form-control"
                      value={state?.staff}
                      onChange={onChangeInputs}
                      required
                    >
                      <option value="">Select Recipient </option>
                      {state?.users.map((el, index) => (
                        <Fragment key={index}>
                          <option value={el.Staff_ID}>{el.Name}</option>
                        </Fragment>
                      ))}
                    </select>
                  </Form.Group>
                )}
              </div>

              <Form.Group className="mb-3">
                <Form.Label>Comment :</Form.Label>
                <label className="border p-0 rounded pb-2 message-area d-block">
                  <Form.Control
                    name="comment"
                    value={state?.comment}
                    onChange={onChangeInputs}
                    as="textarea"
                    className="w-100 border-0 p-3"
                    placeholder="Enter a comment"
                    rows={4}
                  />
                </label>
              </Form.Group>
              <div className="submit-section">
                <button
                  className="btn btn-primary submit-btn"
                  disabled={isLoading}
                >
                  {state?.buttonText}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
