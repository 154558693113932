import React from "react";
import { URLS } from "../urls";

const DisplayCustomPolicy = ({
  leave,
  showMoreAssignees,
  updateCustomPolicySelectedLeave,
  showLessAssignees,
  showAllAssignees,
  updateEditCustomPolicy,
  updateDeleteCustomPolicy,
  updateLeaveAndCustom,
}) => {
  return (
    <div className="custom-policy">
      <div className="leave-header">
        <div className="title">
          <b>Custom policy</b>
        </div>
        <div>
          <button
            className="btn btn-sm add-btn"
            type="button"
            data-toggle="modal"
            data-target="#add_custom_policy"
            onClick={updateCustomPolicySelectedLeave(leave)}
            disabled={leave.isActive ? true : false}
          >
            <i className="fa fa-plus"></i> Add custom policy
          </button>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-hover table-nowrap leave-table">
          <thead>
            <tr>
              <th className="l-name">Name</th>
              <th className="l-days">Days</th>
              <th className="l-assignee">Assignee</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {leave?.customPolicies && leave?.customPolicies?.length > 0
              ? leave.customPolicies.map((lcp) => {
                  return (
                    <tr>
                      <td>{lcp.name}</td>
                      <td>{lcp.days}</td>
                      {lcp["assignees"] && lcp["assignees"].length ? (
                        lcp.assignees.length < 3 ? (
                          <td>
                            {lcp.assignees.map((assignee) => {
                              return (
                                <div
                                  style={{
                                    display: "inline-block",
                                    textAlign: "center",
                                    marginRight: "10px",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "inline",
                                    }}
                                  >
                                    <a
                                      href="Javascript:void(0);"
                                      className="avatar"
                                    >
                                      <img
                                        alt=""
                                        src={
                                          assignee.image
                                            ? URLS.backendStatic +
                                              "/" +
                                              assignee.image
                                            : "assets/img/profiles/avatar-02.jpg"
                                        }
                                      />
                                    </a>
                                  </div>
                                  <div>
                                    <a href="Javascript:void(0);">
                                      {assignee.name}
                                    </a>
                                  </div>
                                </div>
                              );
                            })}
                          </td>
                        ) : showMoreAssignees[leave?._id][lcp?._id] ? (
                          <td
                            style={{
                              whiteSpace: "normal",
                            }}
                          >
                            {lcp.assignees.map((assignee) => {
                              return (
                                <div
                                  style={{
                                    display: "inline-block",
                                    textAlign: "center",
                                    marginRight: "10px",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "inline",
                                    }}
                                  >
                                    <a
                                      href="Javascript:void(0);"
                                      className="avatar"
                                    >
                                      <img
                                        alt=""
                                        src={
                                          assignee.image
                                            ? URLS.backendStatic +
                                              "/" +
                                              assignee.image
                                            : "assets/img/profiles/avatar-02.jpg"
                                        }
                                      />
                                    </a>
                                  </div>
                                  <div>
                                    <a href="Javascript:void(0);">
                                      {assignee.name}
                                    </a>
                                  </div>
                                </div>
                              );
                            })}
                            <div
                              style={{
                                display: "inline-block",
                                textAlign: "center",
                                marginRight: "10px",
                                verticalAlign: "inherit",
                              }}
                              onClick={showLessAssignees(leave._id, lcp._id)}
                            >
                              <div
                                style={{
                                  display: "inline",
                                }}
                              >
                                <a
                                  href="Javascript:void(0);"
                                  className="avatar"
                                  style={{
                                    backgroundColor: "#eaeaea",
                                  }}
                                >
                                  <span
                                    style={{
                                      color: "#000",
                                      fontSize: "11px",
                                    }}
                                  >
                                    Less
                                  </span>
                                </a>
                              </div>
                              <div>
                                {/* <a href="Javascript:void(0);" style={{color:'#00ff00'}}>Less</a> */}
                              </div>
                            </div>
                          </td>
                        ) : (
                          <td>
                            <div
                              style={{
                                display: "inline-block",
                                textAlign: "center",
                                marginRight: "10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "inline",
                                }}
                              >
                                <a
                                  href="Javascript:void(0);"
                                  className="avatar"
                                >
                                  <img
                                    alt=""
                                    src={
                                      lcp.assignees[0].image
                                        ? URLS.backendStatic +
                                          "/" +
                                          lcp.assignees[0].image
                                        : "assets/img/profiles/avatar-02.jpg"
                                    }
                                  />
                                </a>
                              </div>
                              <div>
                                <a href="Javascript:void(0);">
                                  {lcp.assignees[0].name}
                                </a>
                              </div>
                            </div>
                            <div
                              style={{
                                display: "inline-block",
                                textAlign: "center",
                                marginRight: "10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "inline",
                                }}
                              >
                                <a
                                  href="Javascript:void(0);"
                                  className="avatar"
                                >
                                  <img
                                    alt=""
                                    src={
                                      lcp.assignees[1].image
                                        ? URLS.backendStatic +
                                          "/" +
                                          lcp.assignees[1].image
                                        : "assets/img/profiles/avatar-02.jpg"
                                    }
                                  />
                                </a>
                              </div>
                              <div>
                                <a href="Javascript:void(0);">
                                  {lcp.assignees[1].name}
                                </a>
                              </div>
                            </div>
                            <div
                              style={{
                                display: "inline-block",
                                textAlign: "center",
                                marginRight: "10px",
                                verticalAlign: "inherit",
                              }}
                              onClick={showAllAssignees(leave._id, lcp._id)}
                            >
                              <div
                                style={{
                                  display: "inline",
                                }}
                              >
                                <a
                                  href="Javascript:void(0);"
                                  className="avatar"
                                  style={{
                                    backgroundColor: "#ff5e3a",
                                  }}
                                >
                                  <span>+{lcp.assignees.length - 2}</span>
                                </a>
                              </div>
                              <div>
                                {/* <a href="Javascript:void(0);" style={{color:'#ff5e3a'}}>More</a> */}
                              </div>
                            </div>
                          </td>
                        )
                      ) : (
                        <td></td>
                      )}
                      <td className="text-right">
                        <div className="dropdown dropdown-action">
                          <a
                            aria-expanded="false"
                            data-toggle="dropdown"
                            className="action-icon dropdown-toggle"
                            href="Javascript:void(0);"
                          >
                            <i className="material-icons">more_vert</i>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <a
                              href="Javascript:void(0);"
                              className="dropdown-item"
                              data-toggle="modal"
                              data-target="#edit_custom_policy"
                              onClick={updateEditCustomPolicy(lcp)}
                            >
                              <i className="fa fa-pencil m-r-5"></i> Edit
                            </a>
                            <a
                              href="Javascript:void(0);"
                              className="dropdown-item"
                              data-toggle="modal"
                              data-target="#add_leave_allowance"
                              onClick={() => {
                                updateLeaveAndCustom({ leave, lcp });
                              }}
                            >
                              <i className="fa fa-plus m-r-5"></i> Add Allowance
                            </a>

                            <a
                              href="Javascript:void(0);"
                              className="dropdown-item"
                              data-toggle="modal"
                              data-target="#delete_custom_policy"
                              onClick={updateDeleteCustomPolicy(lcp)}
                            >
                              <i className="fa fa-trash-o m-r-5"></i> Delete
                            </a>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })
              : ""}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DisplayCustomPolicy;
