import React, { useEffect, useState } from "react";

import { Button, Form, Modal, Dropdown } from "react-bootstrap";
import ModalLoader from "../ModalLoader";
import { toast } from "react-toastify";
import { URLS } from "../urls";
import { checkStartDate, fetchActionsUtil, useCookie } from "../../utils";
import Cookies from "universal-cookie";
import moment from "moment";
import Papa from "papaparse";
import { CloudIcon, ExcelIcon, ImportIcon, MobileArrowDown } from "../icons";
import { Close } from "@mui/icons-material";

export default function AddAttendanceModal({
  setShowUploadModal,
  selectedTimesheetToEdit,
  setSelectedTimesheetToEdit,
  showAttendance,
  updatesetPayrollDate,
  setshowSaveButton,
  setNoneSaveEntries,
}) {
  const cookies = new Cookies();
  const userId = cookies.get("userId");
  const nowDate = new Date();

  const branch_id = useCookie("branch_id");

  const [payrollDate, setPayrollDate] = useState({
    payrollMonth: selectedTimesheetToEdit
      ? selectedTimesheetToEdit.payrollMonth
      : nowDate.getMonth() + 1,
    payrollYear: selectedTimesheetToEdit
      ? selectedTimesheetToEdit.payrollYear
      : nowDate.getFullYear(),
  });
  const [loading, setLoading] = useState(false);
  const [CheckTotal, setCheckTotal] = useState(0);
  const [permission, setShowPermission] = useState([]);
  const [holiday, setShowHoliday] = useState([]);

  const [excellDetails, setExcellDetails] = useState([]);
  const [attendanceSetting, setAttendanceSetting] = useState({});
  const [attendanceText, setAttendanceText] = useState("Import Attendance");
  const [allGetDayOff, setAllGetDayOff] = useState([]);
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  const fetchData = async () => {
    try {
      const permissionData = fetchActionsUtil(
        `${URLS.backendpermmission}/by-branch/${branch_id}?status=Approved`
      );

      const attendanceData = fetchActionsUtil(
        `${URLS.backendattendance}/get-settings/${branch_id}`
      );

      const dayOffData = fetchActionsUtil(
        `${URLS.backendDayOff}/by-branch/${branch_id}?status=Approved`
      );

      const employeeData = fetchActionsUtil(
        `${URLS.backendEmployees}/get/${branch_id}`
      );

      const [permissionResult, attendanceResult, dayOffResult, employeeResult] =
        await Promise.all([
          permissionData,
          attendanceData,
          dayOffData,
          employeeData,
        ]);

      const holidays = permissionResult?.permission?.filter(
        (el) => el.holidayName !== null && el.holidayName !== undefined
      );
      const permit = permissionResult?.permission?.filter(
        (el) => el.holidayName === null || el.holidayName === undefined
      );

      setShowHoliday(holidays);
      setShowPermission(permit);
      setAttendanceSetting({
        ...attendanceResult.attendance,
      });
      setAllGetDayOff(dayOffResult?.dayOff);
      setEmployees(employeeResult.employees);
    } catch (error) {
      console.log({ error });
    }
  };

  const uploadTimeSheet = (e) => {
    e.preventDefault(e);
    // setLoading(true);
    // const formData = new FormData(e.target);
    const values = {
      branch_id,
      payrollMonth: payrollDate.payrollMonth,
      payrollYear: payrollDate.payrollYear,
      employeeId: userId,
      excellDetails,
      uploadType: attendanceText,
    };
    showAttendance(values);
    setshowSaveButton(true);
    updatesetPayrollDate({
      payrollYear: parseInt(payrollDate.payrollYear),
      payrollMonth: parseInt(payrollDate.payrollMonth),
    });
    setNoneSaveEntries(excellDetails);
    setShowUploadModal(false);
  };

  const currentMonthDates = (date, format) => {
    return new Array(moment(date, format).daysInMonth())
      .fill(null)
      .map((x, i) => moment(date, format).startOf("month").add(i, "days"));
  };

  const checkBetweenDates = ({
    date,
    mainFormat,
    btwFormat1,
    btwFormat2,
    startDate,
    endDate,
    format,
    outFormat,
  }) => {
    return moment(moment(date, mainFormat).format(format), outFormat).isBetween(
      moment(moment(startDate, btwFormat1).format(format), outFormat),
      moment(moment(endDate, btwFormat2).format(format), outFormat),
      null,
      []
    );
  };

  const GeneralPermission = permission.filter((el) => el?.mode === "General");

  const checkForPermission = ({ staffDetails, el, checkType }) => {
    const userPermission = permission.filter(
      (user) => user?.userID?.Staff_ID === staffDetails?.ID
    );

    let absentPermitted = null;

    // absentPermitted = userPermission.find(
    //   (ab) =>
    //     moment(
    //       moment(el.date, "MM/DD/YYYY").format("MM/DD/YYYY"),
    //       "MM/DD/YYYY"
    //     ).isBetween(
    //       moment(
    //         moment(ab.startDate, "YYYY-MM-DD").format("MM/DD/YYYY"),
    //         "MM/DD/YYYY"
    //       ),
    //       moment(
    //         moment(ab.endDate, "YYYY-MM-DD").format("MM/DD/YYYY"),
    //         "MM/DD/YYYY"
    //       ),
    //       null,
    //       []
    //     ) && ab.type === checkType
    // );

    absentPermitted = userPermission.find(
      (ab) =>
        checkBetweenDates({
          date: el.date,
          mainFormat: "MM/DD/YYYY",
          btwFormat1: "YYYY-MM-DD",
          btwFormat2: "YYYY-MM-DD",
          startDate: ab.startDate,
          endDate: ab.endDate,
          format: "MM/DD/YYYY",
          outFormat: "MM/DD/YYYY",
        }) && ab.type === checkType
    );

    if (!absentPermitted) {
      // absentPermitted = GeneralPermission.find(
      //   (ab) =>
      //     moment(
      //       moment(el.date, "MM/DD/YYYY").format("MM/DD/YYYY"),
      //       "MM/DD/YYYY"
      //     ).isBetween(
      //       moment(
      //         moment(ab.startDate, "YYYY-MM-DD").format("MM/DD/YYYY"),
      //         "MM/DD/YYYY"
      //       ),
      //       moment(
      //         moment(ab.endDate, "YYYY-MM-DD").format("MM/DD/YYYY"),
      //         "MM/DD/YYYY"
      //       ),
      //       null,
      //       []
      //     ) && ab.type === checkType
      // );
      absentPermitted = GeneralPermission.find(
        (ab) =>
          checkBetweenDates({
            date: el.date,
            mainFormat: "MM/DD/YYYY",
            btwFormat1: "YYYY-MM-DD",
            btwFormat2: "YYYY-MM-DD",
            startDate: ab.startDate,
            endDate: ab.endDate,
            format: "MM/DD/YYYY",
            outFormat: "MM/DD/YYYY",
          }) && ab.type === checkType
      );
    }

    return absentPermitted;
  };

  const checkForHoliday = ({ date }) => {
    const foundHoliday = holiday.find((ab) =>
      checkBetweenDates({
        date,
        mainFormat: "MM/DD/YYYY",
        btwFormat1: "YYYY-MM-DD",
        btwFormat2: "YYYY-MM-DD",
        startDate: ab.startDate,
        endDate: ab.endDate,
        format: "MM/DD/YYYY",
        outFormat: "MM/DD/YYYY",
      })
    );

    return foundHoliday;
  };

  const checkForLeave = ({ staffDetails, el, leave }) => {
    const userLeave = leave.filter(
      (user) => user?.employee?.id?.Staff_ID === staffDetails?.ID
    );

    // const onLeave = userLeave.find((ab) =>
    //   moment(
    //     moment(el.date, "MM/DD/YYYY").format("MM/DD/YYYY"),
    //     "MM/DD/YYYY"
    //   ).isBetween(
    //     moment(
    //       moment(ab.from, "YYYY-MM-DD").format("MM/DD/YYYY"),
    //       "MM/DD/YYYY"
    //     ),
    //     moment(moment(ab.to, "YYYY-MM-DD").format("MM/DD/YYYY"), "MM/DD/YYYY"),
    //     null,
    //     []
    //   )
    // );

    const onLeave = userLeave.find((ab) =>
      checkBetweenDates({
        date: el.date,
        mainFormat: "MM/DD/YYYY",
        btwFormat1: "YYYY-MM-DD",
        btwFormat2: "YYYY-MM-DD",
        startDate: ab.from,
        endDate: ab.to,
        format: "MM/DD/YYYY",
        outFormat: "MM/DD/YYYY",
      })
    );

    return onLeave;
  };

  const getUserDayOff = (id) => {
    let usersAllowance;

    // Find the selected user
    const user = employees.find((el) => el?.jobInfo?.employeeId === id);
    const jobInfo = user?.jobInfo;

    allGetDayOff.forEach((el) => {
      if (
        el["applySelected"] === "Employees" &&
        el["selectedData"]?.includes(user?.userId)
      ) {
        usersAllowance = { ...el };
      } else if (
        el["applySelected"] === "Job Grade" &&
        el["selectedData"]?.includes(jobInfo?.grade?._id)
      ) {
        usersAllowance = { ...el };
      } else if (el["applySelected"] === "All") {
        usersAllowance = { ...el };
      } else if (
        el["applySelected"] === "Department" &&
        el["selectedData"]?.includes(jobInfo?.department?._id)
      ) {
        usersAllowance = { ...el };
      }
    });

    return usersAllowance;
  };

  const checkDayOff = ({ id, date }) => {
    const user = getUserDayOff(id);

    let dayOffDate = false;

    const foundDate = user?.dateRanges.find((ab) =>
      checkBetweenDates({
        date,
        mainFormat: "YYYY-MM-DD",
        btwFormat1: "YYYY-MM-DD",
        btwFormat2: "YYYY-MM-DD",
        startDate: ab.startDate,
        endDate: ab.endDate,
        format: "MM/DD/YYYY",
        outFormat: "MM/DD/YYYY",
      })
    );

    if (foundDate) {
      dayOffDate = true;
    }

    return dayOffDate;
  };

  const checkForMoreDetails = ({
    foundDate,
    isLeave,
    members,
    notAStaff,
    staffDetails,
    el,
    dayOffDate,
    isHoliday,
  }) => {
    if (foundDate) {
      if (isHoliday) {
        members.push({
          ...foundDate,
          result: isHoliday?.holidayName || "Holiday",
        });
      } else if (isLeave) {
        members.push({
          ...foundDate,
          result: "OnLeave",
        });
      } else if (notAStaff) {
        members.push({
          ...staffDetails[0],
          in: "--",
          out: "--",
          result: "Not a staff",
          Date: moment(el.date.toString(), "MM/DD/YYYY"),
        });
      } else if (dayOffDate) {
        members.push({
          ...staffDetails[0],
          in: "--",
          out: "--",
          result: "Day Off",
          Date: moment(el.date.toString(), "MM/DD/YYYY"),
        });
      } else {
        if (foundDate.result === "No SignOut") {
          if (
            checkForPermission({
              staffDetails: staffDetails[0],
              el,
              checkType: "NoSignOut",
            })
          ) {
            members.push({
              ...foundDate,
              result: "Permitted",
            });
          } else {
            members.push({ ...foundDate });
          }
        } else if (foundDate.result === "Late") {
          if (
            checkForPermission({
              staffDetails: staffDetails[0],
              el,
              checkType: "Lateness",
            })
          ) {
            members.push({
              ...foundDate,
              result: "Permitted",
            });
          } else {
            members.push({ ...foundDate });
          }
        } else if (foundDate.result === "Early Closure") {
          if (
            checkForPermission({
              staffDetails: staffDetails[0],
              el,
              checkType: "EarlyClosure",
            })
          ) {
            members.push({
              ...foundDate,
              result: "Permitted",
            });
          } else {
            members.push({ ...foundDate });
          }
        } else {
          members.push({ ...foundDate });
        }
      }
    } else if (
      el.day.toString() === "Sunday" ||
      el.day.toString() === "Saturday"
    ) {
      members.push({
        ...staffDetails[0],
        in: "--",
        out: "--",
        result: "Weekend",
        Date: moment(el.date.toString(), "MM/DD/YYYY"),
      });
    } else {
      if (isHoliday) {
        members.push({
          ...staffDetails[0],
          in: "--",
          out: "--",
          result: isHoliday?.holidayName || "Holiday",
          Date: moment(el.date.toString(), "MM/DD/YYYY"),
        });
      } else if (isLeave) {
        members.push({
          ...staffDetails[0],
          in: "--",
          out: "--",
          result: "OnLeave",
          Date: moment(el.date.toString(), "MM/DD/YYYY"),
        });
      } else if (notAStaff) {
        members.push({
          ...staffDetails[0],
          in: "--",
          out: "--",
          result: "Not a staff",
          Date: moment(el.date.toString(), "MM/DD/YYYY"),
        });
      } else if (dayOffDate) {
        members.push({
          ...staffDetails[0],
          in: "--",
          out: "--",
          result: "Day Off",
          Date: moment(el.date.toString(), "MM/DD/YYYY"),
        });
      } else {
        if (
          checkForPermission({
            staffDetails: staffDetails[0],
            el,
            checkType: "Absent",
          })
        ) {
          members.push({
            ...staffDetails[0],
            in: "--",
            out: "--",
            result: "Permitted",
            Date: moment(el.date.toString(), "MM/DD/YYYY"),
          });
        } else {
          members.push({
            ...staffDetails[0],
            in: "--",
            out: "--",
            result: "Absent",
            Date: moment(el.date.toString(), "MM/DD/YYYY"),
          });
        }
      }
    }
  };

  const manipulateUserAttendanceData = ({ staffForMonths, leave }) => {
    let curDate = currentMonthDates(
      `${payrollDate.payrollMonth}/1/${payrollDate.payrollYear}`,
      "MM/DD/YYYY"
    );

    curDate = curDate.map((el) => ({
      date: el.format("M/D/YYYY"),
      day: el.format("dddd"),
    }));

    const uniqueIds = [...new Set(staffForMonths.map((el) => el.WorkId))];

    const data = uniqueIds?.map((el) => {
      let staffs = staffForMonths.filter((s) => s.WorkId === el);

      let uniqueDates = [...new Set(staffs.map((el) => el.Date))];

      uniqueDates = uniqueDates.map((uniqueDate) => {
        const dataForDay = staffs
          .filter((staff) => staff.Date === uniqueDate)
          .sort((a, b) => moment(a.Time, "HH:mm") - moment(b.Time, "HH:mm"));

        //HH:mm:ss was there before it was changed to HH:mm for hydroFerric

        //   Check for No checkout
        if (dataForDay.length === 1) {
          return {
            in: dataForDay[0]?.Time,
            out: "--",
            Name: dataForDay[0]?.User,
            ID: dataForDay[0]?.WorkId,
            Date: moment(dataForDay[0]?.Date, "MM/DD/YYYY"),
            result: "No SignOut",
            startDate: dataForDay[0]?.startDate,
          };
        }
        //   Check for Early Closure
        else if (
          moment(dataForDay[1]?.Time, "HH:mm").isBefore(
            moment(attendanceSetting?.closingTime, "HH:mm")
          )
        ) {
          return {
            in: dataForDay[0]?.Time,
            out: dataForDay[1]?.Time,
            Name: dataForDay[0]?.User,
            ID: dataForDay[0]?.WorkId,
            Date: moment(dataForDay[0]?.Date, "MM/DD/YYYY"),
            result: "Early Closure",
            startDate: dataForDay[0]?.startDate,
          };
        }
        //   Check for Lateness
        else if (
          moment(attendanceSetting?.resumeTime, "HH:mm").isBefore(
            moment(dataForDay[0]?.Time, "HH:mm")
          )
        ) {
          return {
            in: dataForDay[0]?.Time,
            out: dataForDay[1]?.Time,
            Name: dataForDay[0]?.User,
            ID: dataForDay[0]?.WorkId,
            Date: moment(dataForDay[0]?.Date, "MM/DD/YYYY"),
            result: "Late",
            startDate: dataForDay[0]?.startDate,
          };
        } else if (dataForDay[0]?.Time === "" && dataForDay[1]?.Time === "") {
          return {
            in: "--",
            out: "--",
            Name: dataForDay[0]?.User,
            ID: dataForDay[0]?.WorkId,
            Date: moment(dataForDay[0]?.Date, "MM/DD/YYYY"),
            result: "Absent",
            startDate: dataForDay[0]?.startDate,
          };
        } else {
          return {
            in: dataForDay[0]?.Time,
            out: dataForDay[1]?.Time,
            Name: dataForDay[0]?.User,
            ID: dataForDay[0]?.WorkId,
            Date: moment(dataForDay[0]?.Date, "MM/DD/YYYY"),
            result: "Good",
            startDate: dataForDay[0]?.startDate,
          };
        }
      });

      return { ...uniqueDates };
    });

    const members = [];

    // Check the dates of the month and find weekend and their absents
    curDate.forEach((el) => {
      data.forEach((staffs) => {
        const staffDetails = Object.values(staffs);

        const foundDate = staffDetails?.find(
          (sd) => sd?.Date?.format("M/D/YYYY") === el?.date?.toString()
        );

        const isLeave = checkForLeave({
          staffDetails: staffDetails[0],
          el,
          leave,
        });

        const notAStaff = checkStartDate({
          date: moment(el?.date, "MM/DD/YYYY").format("DD/MM/YYYY"),
          startDate: staffDetails[0]?.startDate,
        });

        const dayOffDate = checkDayOff({
          id: staffDetails[0]?.ID,
          date: moment(el?.date, "MM/DD/YYYY").format("YYYY-MM-DD"),
        });

        const isHoliday = checkForHoliday({ date: el.date });

        checkForMoreDetails({
          foundDate,
          isLeave,
          members,
          notAStaff,
          staffDetails,
          el,
          dayOffDate,
          isHoliday,
        });
      });
    });

    setCheckTotal(members.length);
    setExcellDetails(members);

    setLoading(false);
  };

  function handleFile({ eventData: e, chooseDate }) {
    setLoading(true);

    const file = e?.target?.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = async function ({ target }) {
        try {
          const { leave } = await fetchActionsUtil(
            `${URLS.backendApplyLeaves}/dynamic-by-months/${branch_id}?date=${payrollDate.payrollYear}-${payrollDate.payrollMonth}-1`
          );

          const { employees } = await fetchActionsUtil(
            `${URLS.backendEmployees}/get/${branch_id}`
          );

          const parsedData = [];
          Papa.parse(target?.result, {
            header: true,
            step: function (row) {
              parsedData.push(row.data);
            },
            complete: function () {
              const staffForMonths = parsedData?.filter((el) =>
                moment(
                  moment(el.Date, "MM/DD/YYYY").format("MM/DD/YYYY"),
                  "MM/DD/YYYY"
                ).isSame(
                  moment(
                    moment(
                      chooseDate ? new Date(chooseDate) : new Date()
                    ).format("MM/DD/YYYY"),
                    "MM/DD/YYYY"
                  ),
                  "month"
                )
              );

              const uniqueIds = [
                ...new Set(
                  staffForMonths?.map((el) => `${el.WorkId}-${el.Date}`)
                ),
              ];

              let allEmployees = [];

              uniqueIds.forEach((id) => {
                const uniqueEmployees = staffForMonths
                  .filter(
                    (employee) => `${employee.WorkId}-${employee.Date}` === id
                  )
                  .sort(
                    (a, b) => moment(a.Time, "HH:mm") - moment(b.Time, "HH:mm")
                  )
                  .filter((element, i, arr) => i === 0 || i === arr.length - 1)
                  .map((item) => {
                    const user = employees?.find(
                      (em) => em.Staff_ID === item.WorkId
                    );

                    const userName = user?.personalInfo?.name;
                    const startDate = user?.jobInfo?.startDate;

                    const name = `${userName?.first ? userName?.first : ""} ${
                      userName?.middle ? userName?.middle : ""
                    } ${userName?.last ? userName?.last : ""}`;

                    return {
                      ...item,
                      User: name,
                      startDate,
                    };
                  });

                allEmployees.push(uniqueEmployees);
              });

              allEmployees = allEmployees.flat(1);

              manipulateUserAttendanceData({
                staffForMonths: allEmployees,
                leave,
              });
            },
          });
        } catch (err) {
          console.log(err);
        }
      };
      reader.readAsText(file);
      e.target.value = "";
    }
  }

  const getFromInvexAndMobile = async ({ mobile }) => {
    setLoading(true);

    let attendace;

    if (mobile) {
      const data = await fetchActionsUtil(
        `${URLS.backendattendance}/get-attendance-from-mobile-app?branchId=${branch_id}&month=${payrollDate.payrollMonth}&year=${payrollDate.payrollYear}`
      );

      attendace = data?.attendace;
    } else {
      const current = moment(
        `${payrollDate.payrollYear}-${payrollDate.payrollMonth}-1`,
        "YYYY-MM-DD"
      ).daysInMonth();

      const month =
        `${payrollDate.payrollMonth}`.split("").length > 1
          ? `${payrollDate.payrollMonth}`
          : `0${payrollDate.payrollMonth}`;

      const startDate = `${payrollDate.payrollYear}/${month}`;

      const endDate = `${payrollDate.payrollYear}/${payrollDate.payrollMonth}/${current}`;

      const data = await fetchActionsUtil(
        `${URLS.mainSiteBackend}/users/attendance/log?startDate=${startDate}&endDate=${endDate}`
      );

      attendace = data?.attendace;
    }
    // console.log({ attendace });

    const { employees } = await fetchActionsUtil(
      `${URLS.backendEmployees}/get/${branch_id}`
    );

    const { leave } = await fetchActionsUtil(
      `${URLS.backendApplyLeaves}/dynamic-by-months/${branch_id}?date=${payrollDate.payrollYear}-${payrollDate.payrollMonth}-1`
    );

    const umquiIds = [
      ...new Set(attendace?.map((el) => `${el.Employee_ID}-${el.Access_Date}`)),
    ];

    const cleanedData = umquiIds
      .map((el) => {
        const uniqueEmployees = attendace
          ?.filter((ft) => el === `${ft.Employee_ID}-${ft.Access_Date}`)
          // .sort((p1, p2) => (p1.id < p2.id ? -1 : p1.id > p2.id ? 1 : 0))
          .sort(
            (a, b) =>
              moment(a.Access_Date_and_Time, "YYYY-MM-DD HH:mm").format(
                "HH:mm"
              ) -
              moment(b.Access_Date_and_Time, "YYYY-MM-DD HH:mm").format("HH:mm")
          )
          .filter((ele, i, arr) => i === 0 || i === arr.length - 1)
          .map((ele) => {
            const user = employees?.find(
              (em) => em.Staff_ID === ele?.Employee_ID
            );
            const userName = user?.personalInfo?.name;
            const startDate = user?.jobInfo?.startDate;

            const name = `${userName?.first ? userName?.first : ""} ${
              userName?.middle ? userName?.middle : ""
            } ${userName?.last ? userName?.last : ""}`;

            return {
              User: name,
              WorkId: ele?.Employee_ID,
              CardNo: "NULL",
              Date: moment(ele?.Access_Date, "YYYY-MM-DD").format("MM/DD/YYYY"),
              Time: moment(
                ele?.Access_Date_and_Time,
                "YYYY-MM-DD HH:mm"
              ).format("H:mm"),
              "IN/OUT": "IN",
              EventCode: "30038",
              startDate,
            };
          });

        return uniqueEmployees;
      })
      .flat(1);
    manipulateUserAttendanceData({ staffForMonths: cleanedData, leave });
  };

  const cancelRequest = (e) => {
    e.preventDefault(e);
    setPayrollDate({
      payrollMonth: nowDate.getMonth() + 1,
      payrollYear: nowDate.getFullYear(),
    });
    setExcellDetails([]);
    setAttendanceText("Import Attendance");
    setCheckTotal(0);
  };

  return (
    <>
      <Modal
        className="custom-modal"
        show={true}
        centered
        onHide={() => {
          if (setSelectedTimesheetToEdit) setSelectedTimesheetToEdit();
          setShowUploadModal(false);
        }}
      >
        <Form className="">
          {/* <Modal.Header>
            <Modal.Title>Import Attendance</Modal.Title>
          </Modal.Header> */}
          <div className="d-flex align-items-center px-5 pt-5 justify-content-between gap-4">
            <h5 className="page-title page-title-alt m-0 p-0">
              Import Attendance
            </h5>
            <div
              className="btn-close-alt"
              onClick={() => {
                if (setSelectedTimesheetToEdit) setSelectedTimesheetToEdit();
                setShowUploadModal(false);
              }}
            >
              <Close />
            </div>
          </div>
          <Modal.Body>
            <input name="employeeId" type="hidden" value={userId} />
            <input name="branch_id" type="hidden" value={branch_id} />

            <div className="d-flex justify-content-center">
              <div className="d-flex ">
                <div className="form-inline">
                  <label className="ml-2 text-16 font-600  light-text mb-2">
                    Month
                  </label>
                  <select
                    className="form-control  form-control-alt mx-2"
                    name="payrollMonth"
                    value={payrollDate.payrollMonth}
                    onChange={(e) =>
                      setPayrollDate({
                        payrollMonth: e.target.value,
                        payrollYear: payrollDate.payrollYear,
                      })
                    }
                  >
                    {[...Array(12)].map((e, index) => (
                      <option key={index}>{index + 1}</option>
                    ))}
                  </select>
                </div>
                <div className="form-inline">
                  <label className="ml-2 text-16 font-600  light-text mb-2">
                    Year
                  </label>
                  <input
                    type="number"
                    name="payrollYear"
                    style={{ width: "100px" }}
                    className="form-control form-control-alt"
                    value={payrollDate.payrollYear}
                    onChange={(e) =>
                      setPayrollDate({
                        payrollMonth: payrollDate.payrollMonth,
                        payrollYear: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>

            <div className="w-full d-flex mr-2 ml-2 mt-4 align-items-center justify-content-between">
              <Dropdown className="d-flex  align-items-center gap-3 ">
                <Dropdown.Toggle variant="primary" bsPrefix="">
                  <ImportIcon color="#fff" /> {attendanceText}
                </Dropdown.Toggle>

                <Dropdown.Menu
                  popperConfig={{
                    strategy: "fixed",
                  }}
                  renderOnMount
                  className=""
                >
                  <Dropdown.Item as="label" className="p-cursor" title="Import">
                    <input
                      type="file"
                      className="d-none"
                      onChange={(e) => {
                        handleFile({
                          eventData: e,
                          chooseDate: `${payrollDate.payrollMonth}/1/${payrollDate.payrollYear}`,
                        });
                        setAttendanceText("Attendance From Excel");
                      }}
                      accept=".csv"
                    />
                    <ExcelIcon color="#008000" />{" "}
                    <span className="mx-2">Upload from Excel</span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    as="div"
                    onClick={() => {
                      getFromInvexAndMobile({ mobile: false });
                      setAttendanceText("Attendance From Cloud");
                    }}
                    className="p-cursor"
                  >
                    <CloudIcon color="#2463ae" />{" "}
                    <span className="mx-2">Upload from Cloud</span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    as="div"
                    onClick={() => {
                      getFromInvexAndMobile({ mobile: true });
                      setAttendanceText("Attendance From Mobile");
                    }}
                    className="p-cursor"
                  >
                    <MobileArrowDown color="#2463ae" />{" "}
                    <span className="mx-2"> Upload from Mobile App</span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <div className="dark-text fw-bold ">
                <p>{`${CheckTotal} Rows`}</p>
              </div>
            </div>
            <hr />
            <div className="d-flex align-items-center gap-4 ml-2 mt-6">
              {excellDetails.length > 0 && (
                <Button onClick={(e) => uploadTimeSheet(e)}>View</Button>
              )}

              <button
                className="btn btn-secondary btn-secondary-alt"
                onClick={(e) => cancelRequest(e)}
              >
                Cancel
              </button>
            </div>
          </Modal.Body>
          <Modal.Footer className="text-left"></Modal.Footer>
        </Form>
      </Modal>
      <ModalLoader show={loading} />
    </>
  );
}
