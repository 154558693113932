/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useMemo } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import currency from "currency.js";
import { CSVLink } from "react-csv";
import {
  Dropdown,
  Popover,
  OverlayTrigger,
  Button,
  Table,
  Form,
} from "react-bootstrap";

import { Helmet } from "react-helmet";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import {
  calculateAttendance,
  calculateInvexRetainment,
  calculateWorkDays,
  fetchActionsUtil,
  isEnterKey,
  taxComputation,
  useCookie,
  stringifyQueryString,
} from "../../utils";
import { DownArrowIcon, SearchIcon, SummaryChartIconUp } from "../icons";
import { Row } from "reactstrap";
import { URLS } from "../urls";
import { toast } from "react-toastify";
import moment from "moment";
import { SummaryCloseIcon } from "../icons";
import { allMonths } from "../../utils";
import { DialogModal } from "../DialogModal";
import { InforModal } from "../InforModal";
import ImportPaylistFromExcel from "../editEmployeeJobGrade/ImportPaylistFromExcel";
import {
  totalUniqueNum,
  checkDateYear,
  calculateMultiPayRollSettings,
  getGrossPayments,
  calculateLoan,
  converArrayToObject,
} from "../../utils";
import ModalLoader from "../ModalLoader";
import { ExcelIcon, ExportIcon } from "../icons";
import { useDownloadExcel } from "../useDownloadExcel";
import TableComponent from "../TableComponent";
import { parseInt, toNumber, upperFirst } from "lodash";
import { UseQueryData } from "../UseQueryData";
import CustomPagination from "../Paginations";

export const SalarySetting = ({ setting, placement }) => {
  setting = {
    ...setting,
    ...converArrayToObject(setting["customSettings"], "status", "name"),
  };
  const salaryItems = useMemo(() => {
    let items = [];
    if (!setting) return items;
    for (const key in setting) {
      if (setting[key] === true && String(key) !== "enabled")
        items = [...items, upperFirst(key)];
    }
    items = [...items, ...setting?.allSettings?.map((el) => el.name)];
    return items;
  }, [setting]);

  const checkDates = () => {
    let data = null;
    let text = "Maturity period (from joined date)";
    if (setting?.paymentPeriod === "CustomDate") {
      const { endDate, startDate } = setting?.dateRange;
      text = "Maturity period from";

      data = `${startDate} - ${endDate}`;
    } else if (setting?.paymentPeriod === "All") {
      data = "Anytime";
    } else {
      data = setting?.paymentPeriod;
    }
    return { data, text };
  };

  return (
    <OverlayTrigger
      trigger="click"
      rootClose
      placement={placement || "bottom"}
      overlay={
        <Popover id={setting?.name}>
          <Popover.Title as="h3" className="text-capitalize">
            {setting?.name}
          </Popover.Title>
          <Popover.Content as={"div"}>
            <Table size="sm" borderless>
              <tr>
                <td className="font-weight-bold text-nowrap">Calculate by:</td>
                <td>{setting?.paymentType || "..."}</td>
              </tr>
              <tr>
                <td colSpan={2}>
                  {setting?.paymentType === "Percentage"
                    ? `${setting?.percentage}% of ${salaryItems
                        .map((el) => el)
                        .join(", ")
                        .replace(/,(?!.*,)/gim, " and")}`
                    : setting?.percentage === "Static"
                    ? "Fixed"
                    : ` ${currency(setting?.percentage, {
                        symbol: "",
                      }).format()} flat rate`}
                </td>
              </tr>

              <tr>
                <td className="font-weight-bold">Apply to:</td>
                <td>{setting?.DeductType || "..."}</td>
              </tr>

              {setting?.paymentPeriod && (
                <tr>
                  <td className="font-weight-bold ">{checkDates().text}:</td>
                  <td className="text-nowrap">{checkDates().data || "..."}</td>
                </tr>
              )}
            </Table>
          </Popover.Content>
        </Popover>
      }
    >
      <Button
        className="d-inline p-0 border-0  px-2  rounded-circle  d-relative"
        size="sm"
        style={{ transform: `scale(0.7)` }}
      >
        <i className="fa fa-info"></i>
      </Button>
    </OverlayTrigger>
  );
};

function CreateSalary(props) {
  const branch_id = useCookie("branch_id");
  const date = new Date();
  const [payrollDate, setPayrollDate] = useState({
    payrollMonth: date.getMonth() + 1,
    payrollYear: date.getFullYear(),
  });

  // ------------------ Filter Search -------------------//
  const initialQueryParams = {
    limit: 20,
    page: 1,
    id: "",
    name: "",
    category: "",
    type: "All",
    department: "",
    grades: "",
    sortBy: "id",
    sortOrder: "asc",
  };
  const { queryParams, theQueryParams, onChangeInput, setQueryParams } =
    UseQueryData({
      initialQueryParams,
      timer: 1500,
    });
  // ------------------ Filter Search -------------------//

  const [normalEmployees, setNormalEmployees] = useState([]);
  const [deductionSettings, setDeductionSettings] = useState([]);
  const [bonusSettings, setBonusSettings] = useState([]);
  const [allownaceSettings, setAllowanceSettings] = useState([]);
  const [benefitSettings, setBenefitSettings] = useState([]);
  const [leaveSetup, setLeaveSetup] = useState({});
  const [pendingRetirement, setPendingRetirement] = useState([]);

  const [totalGross, setTotalGross] = useState(0);
  const [totalNet, setTotalNet] = useState(0);
  const [totalDeduct, setTotalDeduct] = useState(0);
  const [btnSummryActive, setBtnSummaryActive] = useState(false);
  const [dialog] = useState("send-payroll");
  const [openDialog, setOpenDialog] = useState(false);
  const [openInforDialog, setOpenInforDialog] = useState(false);
  const [useExcel, setUseExcel] = useState("");
  const [theexcelData, settheexcelData] = useState([]);
  const [btnText, setBtnText] = useState("Process Payroll");
  const [totalbonus, setTotalbonus] = useState(0);
  const [totalallowance, setTotalallowance] = useState(0);
  const [totalbenefit, setTotalbenefit] = useState(0);
  const [isSendLoading, setIsSendLoading] = useState(false);
  const [returnParams, setReturnParams] = useState({});
  const [theLocations, settheLocations] = useState([]);
  const [theContracts, settheContracts] = useState([]);
  const [loanSettings, setloanSettings] = useState([]);
  const [companySettings, setcompanySettings] = useState({});
  const [IsRuning, setIsRuning] = useState(false);
  const [IsFetching, setsFetching] = useState(true);
  const [customHeaders, setCustomHeaders] = useState(true);
  const [settingHeaders, setSettingsHeaders] = useState({});
  const [attendanceSetting, setAttendanceSetting] = useState({});
  const [AllowanceGroupName, setAllowanceGroupName] = useState([]);
  const [BenefitGroupName, setBenefitGroupName] = useState([]);
  const [BonusGroupName, setBonusGroupName] = useState([]);
  const [DeductionGroupName, setDeductionGroupName] = useState([]);
  const [payrollSetting, setPayrollSetting] = useState([]);
  const [terminations, setTerminations] = useState([]);
  const [suspensions, setSuspensions] = useState([]);
  const [excelName, setExcelName] = useState("Salary Computation");
  const [searchItems, setSearchItems] = useState([]);

  // let isRendered = useRef(false);

  const [excelData, setExcelData] = useState([]);

  const CSVLinkRef = useRef(null);

  const token = "dummy token";

  // fetch excel hook
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(
    excelData,
    CSVLinkRef
  );

  useEffect(() => {
    if (IsRuning) {
      getEmployees();
    }

    return () => {};
  }, [useExcel, theexcelData, IsRuning, payrollDate, queryParams]);

  useEffect(() => {
    // Set Total Gross, Net and deductions
    setTotalGross(() =>
      normalEmployees.reduce((p, c) => currency(p).add(c.ogGrossPay), 0)
    );
    setTotalNet(() =>
      normalEmployees.reduce((p, c) => currency(p).add(c.netPay), 0)
    );
    setTotalDeduct(() =>
      normalEmployees.reduce((p, c) => currency(p).add(c.totalDeduction), 0)
    );
    setTotalbonus(() =>
      normalEmployees.reduce((p, c) => currency(p).add(c.totalBonus), 0)
    );
    setTotalallowance(() =>
      normalEmployees.reduce((p, c) => currency(p).add(c.totalAllowances), 0)
    );
    setTotalbenefit(() =>
      normalEmployees.reduce((p, c) => currency(p).add(c.totalBenefits), 0)
    );
  }, [normalEmployees]);

  useEffect(() => {
    getData();
  }, []);

  const getByDate = async () => {
    try {
      const overtimeData = fetchActionsUtil(
        `${URLS.backendOvertime}/by-month?month=${payrollDate.payrollMonth}&year=${payrollDate.payrollYear}&branch_id=${branch_id}`
      );

      const leaveData = fetchActionsUtil(
        `${URLS.backendApplyLeaves}/dynamic-by-months/${branch_id}?date=${payrollDate.payrollYear}-${payrollDate.payrollMonth}-1`
      );

      const overtimeExtraData = await fetchActionsUtil(
        `${URLS.backendOvertime}/overtimesExtras?branch_id=${branch_id}&month=${payrollDate.payrollMonth}&year=${payrollDate.payrollYear}`
      );

      const attendanceData = await fetchActionsUtil(
        `${URLS.backendattendance}/get/${branch_id}?payrollYear=${payrollDate.payrollYear}&payrollMonth=${payrollDate.payrollMonth}`
      );

      const [
        overtimeResult,
        leaveResult,
        attendanceResult,
        overtimeExtraResult,
      ] = await Promise.all([
        overtimeData,
        leaveData,
        attendanceData,
        overtimeExtraData,
      ]);

      return {
        overtimeExtra: overtimeExtraResult?.overtimes,
        leaveSettings: leaveResult?.leave,
        overSettings: overtimeResult?.overtimes,
        attendance: attendanceResult?.attendance,
      };
    } catch (error) {
      console.log(error);
    }
  };

  const getData = async () => {
    try {
      const deductionData = fetchActionsUtil(
        `${URLS.backendDeductionSettings}/enabled`
      );

      const bonusData = fetchActionsUtil(
        `${URLS.backendBonusSettings}/enabled`
      );

      const allowanceData = fetchActionsUtil(
        `${URLS.backendAllowance}/enabled`
      );

      const benefitData = fetchActionsUtil(`${URLS.backendBenefit}/enabled`);

      const loanData = fetchActionsUtil(`${URLS.backendLoan}`);

      const locationsData = fetchActionsUtil(`${URLS.backendLocations}/get`);

      const contractsData = fetchActionsUtil(`${URLS.backendContracts}/get`);

      const salarySettingsData = fetchActionsUtil(
        `${URLS.backendsalarySetting}/get/${branch_id}`
      );

      const settingsData = fetchActionsUtil(
        `${URLS.backendCompanySettings}/get-settings`,
        "POST"
      );

      const allowanceGroupData = fetchActionsUtil(
        `${URLS.backendAllowance}/all-group?branch_id=${branch_id}`
      );

      const benefitGroupData = fetchActionsUtil(
        `${URLS.backendBenefit}/all-group?branch_id=${branch_id}`
      );

      const bonusGroupData = fetchActionsUtil(
        `${URLS.backendBonusSettings}/all-group?branch_id=${branch_id}`
      );

      const deductionGroupData = fetchActionsUtil(
        `${URLS.backendDeductionSettings}/all-group?branch_id=${branch_id}`
      );

      const payrollSettingData = fetchActionsUtil(
        `${URLS.backendPayroll}/get-settings/${branch_id}`
      );

      const terminationsData = fetchActionsUtil(
        `${URLS.backendTermination}/get-terminations/${branch_id}`
      );

      const suspensionsData = fetchActionsUtil(
        `${URLS.backendSuspension}/get/${branch_id}`
      );

      const attendanceData = fetchActionsUtil(
        `${URLS.backendattendance}/get-settings/${branch_id}`
      );

      const levelSettingData = fetchActionsUtil(
        `${URLS.backendLeaves}/get-settings/${branch_id}`
      );

      const gradesData = fetchActionsUtil(
        `${URLS.backendJobGrades}/get/${branch_id}`,
        "GET",
        token
      );

      const departmentsData = fetchActionsUtil(
        `${URLS.backendDepartments}/get/${branch_id}`,
        "GET",
        token
      );

      const categoryData = fetchActionsUtil(
        `${URLS.backendJobCategory}/get/${branch_id}`,
        "GET",
        token
      );

      const [
        deductionResult,
        bonusResult,
        allowanceResult,
        benefitResult,
        loanResult,
        locationsResult,
        contractsResult,
        settingsResult,
        salarySettingsResult,
        allowanceGroupResult,
        benefitGroupResult,
        bonusGroupResult,
        deductionGroupResult,
        payrollSettingResult,
        terminationsResult,
        suspensionsResult,
        attendanceResult,
        levelSettingResult,
        gradesResult,
        departmentsResult,
        categoryResult,
      ] = await Promise.all([
        deductionData,
        bonusData,
        allowanceData,
        benefitData,
        loanData,
        locationsData,
        contractsData,
        settingsData,
        salarySettingsData,
        allowanceGroupData,
        benefitGroupData,
        bonusGroupData,
        deductionGroupData,
        payrollSettingData,
        terminationsData,
        suspensionsData,
        attendanceData,
        levelSettingData,
        gradesData,
        departmentsData,
        categoryData,
      ]);

      // ---------- Salary Settings  ------------
      const items = {};
      Object.entries(salarySettingsResult?.jobGrades[0]).forEach((el) => {
        if (el[1] > 0) {
          items[`${el[0]}`] = true;
        }
      });

      const salarySettings = salarySettingsResult?.jobGrades[0]?.custom?.map(
        (el) => el.name
      );
      // ---------- Salary Settings  ------------

      const grades = gradesResult?.jobGrades?.map((el) => ({
        value: el?._id,
        label: el?.name,
      }));
      const department = departmentsResult?.departments?.map((el) => ({
        value: el?._id,
        label: el?.name,
      }));

      const category = categoryResult?.jobCategories?.map((el) => ({
        value: el?._id,
        label: el?.name,
      }));

      setSearchItems({
        grades,
        department,
        category,
      });

      setcompanySettings({
        ...settingsResult?.settings,
        extras: settingsResult?.settings?.extras === "true" ? true : false,
        customWorkingDays:
          settingsResult?.settings?.customWorkingDays === "true" ? true : false,
      });

      setSuspensions(suspensionsResult?.suspensions);
      setTerminations(terminationsResult?.terminations);
      setPayrollSetting(payrollSettingResult?.settings);

      setBenefitGroupName(benefitGroupResult?.allowance);
      setAllowanceGroupName(allowanceGroupResult?.allowance);
      setBonusGroupName(bonusGroupResult?.allowance);
      setDeductionGroupName(deductionGroupResult?.allowance);

      setSettingsHeaders(items);
      setCustomHeaders(salarySettings);

      setAllowanceSettings(allowanceResult?.allowance);
      setBenefitSettings(benefitResult?.benefit);
      setDeductionSettings(deductionResult?.deductionSettings);
      setBonusSettings(bonusResult?.bonusSettings);
      settheLocations(locationsResult?.locations);
      settheContracts(contractsResult?.contracts);
      setloanSettings(loanResult?.loan);

      setAttendanceSetting({
        ...attendanceResult?.attendance,
      });

      setLeaveSetup(levelSettingResult?.leaveSettings);

      if (process.env.REACT_APP_HAS_INVEX === "true") {
        const retairement = await fetch(
          `${URLS.mainSiteBackend}/users/salary/pending-retirement?month=${payrollDate.payrollMonth}&year=${payrollDate.payrollYear}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Token ${token}`,
            },
          }
        );

        const { retairment } = await retairement.json();
        setPendingRetirement(retairment);
      }

      setIsRuning(true);
    } catch (error) {
      console.log(error);
    }
  };

  const getEmployees = async () => {
    try {
      // check if its getting from excel or database
      let mainEmployees;
      setsFetching(true);

      if (useExcel !== "Use Timesheet") {
        let employeesResult = await fetchActionsUtil(
          `${
            URLS.backendPayroll
          }/get-employees/${branch_id}?${stringifyQueryString(
            queryParams
          )}&date=${payrollDate?.payrollYear}-${payrollDate?.payrollMonth}-1`
        );

        const { employees, count, endIndex, startIndex } = employeesResult;

        mainEmployees = employees;
        setReturnParams({
          count,
          endIndex,
          startIndex,
        });
      } else {
        mainEmployees = theexcelData;
      }

      mainEmployees = await calculateEmployeePayroll(mainEmployees);

      setsFetching(false);
      setNormalEmployees(mainEmployees);
    } catch (err) {
      console.log(err);
      setsFetching(false);
    }
  };

  const calculateEmployeePayroll = async (mainEmployees) => {
    const { overtimeExtra, leaveSettings, overSettings, attendance } =
      await getByDate();

    const normalEmployees = mainEmployees?.map((employee) => {
      const custom =
        employee?.jobInfo?.usergrade?.custom?.length > 0
          ? employee?.jobInfo?.usergrade?.custom
          : customHeaders?.map((el) => ({
              name: el,
              value: 0,
            }));

      employee = {
        ...employee.personalInfo,
        ...employee.jobInfo,
        ...converArrayToObject(
          employee?.jobInfo?.usergrade?.custom
            ? employee?.jobInfo?.usergrade?.custom
            : [],
          "value",
          "name",
          true
        ),
        custom,
        ...employee?.jobInfo?.usergrade?.salary,
        userId: employee.userId,
        bankDetails: employee.bankDetails ?? {},
        gradeName: employee.jobInfo.grade.name,
        emps_id: employee._id,
        totalDaysWorked: employee?.totalDaysWorked,
      };

      const StaffTermination = terminations.find(
        (em) => em?.employeeId === employee?.userId
      );

      const StaffSuspension = suspensions.find(
        (em) => em?.employeeId === employee?.userId
      );

      //Calculate The worked Days
      let current = moment(
        `1/${payrollDate.payrollMonth}/${payrollDate.payrollYear}`,
        "DD/MM/YYYY"
      ).daysInMonth();

      if (companySettings?.customWorkingDays) {
        current = companySettings?.workingDays;
      } else {
        companySettings.workingDays = current;
      }

      if (useExcel !== "Use Timesheet") {
        if (employee?.startDate) {
          current = calculateWorkDays({
            date: employee?.startDate,
            payrollDate,
            current,
          });
        }

        if (StaffTermination) {
          current = calculateWorkDays({
            date: StaffTermination?.terminationDate,
            payrollDate,
            current,
            workedDays: true,
          });
        }

        if (StaffSuspension) {
          if (StaffSuspension.type === "Payment") {
            current = calculateWorkDays({
              date: StaffSuspension?.suspensionDate,
              payrollDate,
              current,
              workedDays: true,
            });
          } else {
            current = 0;
          }
        }

        employee.totalDaysWorked = current;
      }

      let userExtras = overtimeExtra.find(
        (el) => el?.employeeId === employee?.employeeId
      );

      if (!userExtras) {
        userExtras = {
          DaysCommitted: employee.totalDaysWorked,
          DayOn: 0,
          TransitDays: 0,
          OverStayDays: 0,
          ArrearsDays: 0,
          employeeId: employee?.employeeId,
          month: payrollDate.payrollMonth,
          year: payrollDate.payrollYear,
          branch_id,
        };
      } else {
        userExtras = {
          ...userExtras,
          DaysCommitted: employee.totalDaysWorked,
          month: payrollDate.payrollMonth,
          year: payrollDate.payrollYear,
          branch_id,
        };
      }

      employee.usedlocation = theLocations?.find(
        (el) => el._id === employee?.workLocation
      )?.name;

      employee.usedlocation = employee.usedlocation
        ? employee.usedlocation
        : employee?.workLocation?.name;

      employee.usedcontract = theContracts?.find(
        (el) => el._id === employee?.contractType
      )?.contractName;

      const retaire = pendingRetirement?.find(
        (el) => el?.Staff_ID === employee?.employeeId
      );

      const allNums = totalUniqueNum(
        benefitSettings,
        allownaceSettings,
        bonusSettings,
        deductionSettings
      );

      let allPREV = [];
      let benes = [];
      let allow = [];
      let bon = [];
      let deduct = [];

      const data = getGrossPayments({
        employee,
        settings: companySettings,
        useExcel,
      });

      for (const key of allNums) {
        const be = calculateMultiPayRollSettings({
          data: benefitSettings.filter((el) => el.level === key),
          employee,
          pre: allPREV,
          settings: companySettings,
          useExcel,
          totalGross: data?.grossPay,
          payrollDate,
        });

        benes = [...benes, ...be.otherData];
        allPREV = [...allPREV, ...be.otherData];

        const allo = calculateMultiPayRollSettings({
          data: allownaceSettings.filter((el) => el.level === key),
          employee,
          pre: allPREV,
          settings: companySettings,
          useExcel,
          overSettings,
          leaves: leaveSettings,
          totalGross: data?.grossPay,
          payrollDate,
          leaveSettings: leaveSetup,
        });

        allow = [...allow, ...allo.otherData];
        allPREV = [...allPREV, ...allo.otherData];

        const bons = calculateMultiPayRollSettings({
          data: bonusSettings.filter((el) => el.level === key),
          employee,
          pre: allPREV,
          settings: companySettings,
          useExcel,
          totalGross: data?.grossPay,
          payrollDate,
        });

        bon = [...bon, ...bons.otherData];
        allPREV = [...allPREV, ...bons.otherData];

        const dedt = calculateMultiPayRollSettings({
          data: deductionSettings.filter((el) => el.level === key),
          employee,
          pre: allPREV,
          settings: companySettings,
          useExcel,
          totalGross: data?.grossPay,
          payrollDate,
        });

        deduct = [...deduct, ...dedt.otherData];
        allPREV = [...allPREV, ...dedt.otherData];
      }

      const benefits = {
        totalSum: benes.reduce((c, p) => c + p.total, 0),
        otherData: benes,
      };

      const allowances = {
        totalSum: allow.reduce((c, p) => c + p.total, 0),
        otherData: allow,
      };

      const bonus = {
        totalSum: bon.reduce((c, p) => c + p.total, 0),
        otherData: bon,
      };

      let penCom = deduct.find((el) => el.slug === "company-pension");
      let penTotal = deduct.find((el) => el.slug === "pension");

      penCom = penCom ? penCom?.total : 0;
      penTotal = penTotal ? penTotal?.total : 0;
      penTotal = penCom + penTotal;

      const deductions = {
        totalSum: deduct.reduce((c, p) => c + p.total, 0) - penCom,
        otherData: deduct,
      };

      //---------------- Calculate Loan -----------------------------------------------
      const employeeLoan = loanSettings.filter(
        (el) => el?.userID?._id === employee?.emps_id && el?.Term !== undefined
      );

      let empLoan = {};

      if (
        employeeLoan[0]?.payments?.length > 0 &&
        employeeLoan[0]?.loanmotion !== "Pause" &&
        employeeLoan[0]?.Term === "Annual" &&
        checkDateYear(employeeLoan[0]?.paidDate)
      ) {
        empLoan = calculateLoan(employeeLoan, employee);
      } else if (
        employeeLoan[0]?.payments?.length > 0 &&
        employeeLoan[0]?.loanmotion !== "Pause" &&
        employeeLoan[0]?.Term !== "Annual"
      ) {
        empLoan = calculateLoan(employeeLoan, employee);
      }

      //--------------------- Get the deducted loan --------------------------
      const loanDeduct = parseFloat(
        empLoan?.deductAmount ? empLoan?.deductAmount : 0
      );

      //---------------------- Calculate all deductions ------------------------
      let allTotalDeduction =
        parseFloat(deductions.totalSum) + parseFloat(loanDeduct);

      //-------------------------- Calculate all income --------------------------
      const income =
        parseFloat(bonus.totalSum) +
        parseFloat(allowances.totalSum) +
        parseFloat(benefits.totalSum) +
        parseFloat(data.ogGrossPay);

      //------------------------------- Calculate Net pay ------------------------
      let netPay = parseFloat(income) - parseFloat(allTotalDeduction);

      //----------------------------------- Calculate Attendance ---------------------
      const attendanceData = calculateAttendance({
        attendance,
        employee,
        attendanceSetting,
        grossPay: data.ogGrossPay,
        netPay,
      });

      allTotalDeduction =
        parseFloat(allTotalDeduction) + parseFloat(attendanceData.total);

      netPay = parseFloat(netPay) - parseFloat(attendanceData.total);

      // -------------------------------- Calculate Payee Tax Normal -------------------------
      let totalExemption = 0;

      if (payrollSetting?.exemptionDeductions?.length > 0) {
        totalExemption = deductions?.otherData?.filter(({ _id }) =>
          payrollSetting?.exemptionDeductions?.find((ele) => _id === ele)
        );

        totalExemption = totalExemption?.reduce((c, p) => c + p.total, 0);
      }

      let CRAGrossPurpose = data.grossPay - toNumber(totalExemption.toFixed(2));

      CRAGrossPurpose = CRAGrossPurpose === data.grossPay ? 0 : CRAGrossPurpose;

      const CRAGrossPurposePercentage =
        payrollSetting?.grossForCraPurposePercentage
          ? toNumber(
              (
                (payrollSetting?.grossForCraPurposePercentage / 100) *
                CRAGrossPurpose
              ).toFixed(2)
            )
          : 0;

      const personalAllowance = payrollSetting?.personalAllowance
        ? toNumber((payrollSetting?.personalAllowance / 12).toFixed(2))
        : 0;

      const totalRelief = toNumber(
        (CRAGrossPurposePercentage + personalAllowance).toFixed(2)
      );

      let chargeableIncome = toNumber(
        (data.grossPay - totalRelief - totalExemption).toFixed(2)
      );

      chargeableIncome =
        chargeableIncome === data.grossPay ? 0 : chargeableIncome;

      const computation = payrollSetting?.taxRateAsPerMonthlyComputation.sort(
        (a, b) => a.ratePercentage - b.ratePercentage
      );

      const { taxRateArray, taxRateAsPerMonthlyTotal } = taxComputation({
        computation,
        chargeableIncome,
      });

      const checkPayeeTax = deductions?.otherData?.filter(
        (ele) => ele.taxLaw === true
      );

      if (checkPayeeTax.length > 0) {
        deductions.otherData = deductions?.otherData?.map((el) => {
          if (el.taxLaw === true) {
            el = { ...el, total: taxRateAsPerMonthlyTotal };
          }
          return el;
        });

        deductions.totalSum =
          parseFloat(deductions.totalSum) +
          parseFloat(taxRateAsPerMonthlyTotal);

        allTotalDeduction =
          parseFloat(allTotalDeduction) + parseFloat(taxRateAsPerMonthlyTotal);

        netPay = parseFloat(netPay) - parseFloat(taxRateAsPerMonthlyTotal);
      }

      //-------------------------- Calculate retainment (Always the last )----------------------------
      const { remainingRetairment, retairefee } = calculateInvexRetainment({
        retaire,
        netPay,
      });

      netPay = parseFloat(netPay) - parseFloat(remainingRetairment);

      return {
        ...data.employee,
        totalExemption,
        CRAGrossPurpose,
        CRAGrossPurposePercentage,
        personalAllowance,
        chargeableIncome,
        taxRateArray,
        taxRateAsPerMonthlyTotal,
        totalRelief,
        loanDeduct,
        attendanceDetails: attendanceData.data,
        attendanceTotal: attendanceData.total,
        totalDaysWorked: employee?.totalDaysWorked,
        empLoan,
        ogGrossPay: data.ogGrossPay,
        grossPay: data.grossPay,
        otherDeductions: 0,
        bonuses: 0,
        totalDeduction: deductions.totalSum,
        ogNetPay: netPay,
        netPay,
        deductions: deductions.otherData,
        totalBonus: bonus.totalSum,
        totalAllowances: allowances.totalSum,
        totalBenefits: benefits.totalSum,
        bonus: bonus.otherData,
        allowances: allowances.otherData,
        benefits: benefits.otherData,
        AllowanceGroupName,
        BenefitGroupName,
        BonusGroupName,
        DeductionGroupName,
        debitAccountNo: "--",
        debitBankCode: "--",
        creditAccountNo: employee.bankDetails?.accountNumber,
        creditBankCode: employee.bankDetails?.bankCode,
        allTotalDeduction:
          parseFloat(allTotalDeduction) + parseFloat(retairefee),
        numProration:
          parseFloat(employee?.totalDaysWorked) /
          parseFloat(companySettings?.workingDays),
        useExcel,
        penTotal,
        retairefee,
        remainingRetairment,
        retaire,
        customHeaders,
        employeeType: theQueryParams.type,
        userExtras,
      };
    });

    return normalEmployees;
  };

  const calculateData = ({ e, employeeId }) => {
    if (isEnterKey(e)) {
      //  const newValue = e.target.value ? parseFloat(e.target.value) : 0;
      const index = normalEmployees.findIndex(
        (employee) => employee.employeeId === employeeId
      );
      const employee = normalEmployees[index];
      // const { ogGrossPay, bonuses, otherDeductions } = employee;
      const { netPay, otherDeductions, allTotalDeduction } = employee;

      // let deductionSum = 0;
      // const calculatedDeductions = deductionSettings.map((deduction) => {
      //   let sum = 0;
      //   for (let key in deduction) {
      //     if (key !== "enabled" && deduction[key] === true) {
      //       sum += employee[key];
      //     }
      //   }
      //   const total = ((deduction.percentage / 100) * sum).toFixed(2);
      //   deductionSum += parseFloat(total);

      //   return {
      //     ...deduction,
      //     total: parseFloat(total),
      //   };
      // });

      // const grossPay = ogGrossPay + bonuses;
      normalEmployees[index] = {
        ...employee,
        netPay: parseFloat(netPay) - parseFloat(otherDeductions),
        allTotalDeduction:
          parseFloat(allTotalDeduction) + parseFloat(otherDeductions),
        // grossPay,
        // deductions: calculatedDeductions,
        // otherDeductions,
        // totalDeduction: deductionSum + otherDeductions,
        // netPay: grossPay - (deductionSum + otherDeductions),
      };

      setNormalEmployees([...normalEmployees]);
      // e.target.blur();
    }
  };

  const setOtherDeductions = ({ e, employeeId }) => {
    // const newValue = e.target.value ? parseFloat(e.target.value) : 0;
    // console.log(e);
    const newValue = isNaN(parseInt(e)) ? 0 : parseInt(e);

    const index = normalEmployees.findIndex(
      (employee) => employee.employeeId === employeeId
    );
    const employee = normalEmployees[index];

    normalEmployees[index] = {
      ...employee,
      otherDeductions: newValue,
    };

    setNormalEmployees([...normalEmployees]);
  };

  // const setBonuses = ({ e, employeeId }) => {
  //   const newValue = e.target.value ? parseFloat(e.target.value) : 0;
  //   const index = normalEmployees.findIndex(
  //     (employee) => employee.employeeId === employeeId
  //   );
  //   const employee = normalEmployees[index];

  //   normalEmployees[index] = {
  //     ...employee,
  //     bonuses: newValue,
  //   };

  //   setNormalEmployees([...normalEmployees]);
  // };

  const setRemark = ({ e, employeeId }) => {
    const index = normalEmployees.findIndex(
      (employee) => employee.employeeId === employeeId
    );
    const employee = normalEmployees[index];

    normalEmployees[index] = {
      ...employee,
      remark: e.target.value,
    };
    setNormalEmployees([...normalEmployees]);
  };

  const savePayroll = async () => {
    try {
      setBtnText("Please wait...");
      setIsSendLoading(true);

      const { type, sortBy, sortOrder } = theQueryParams;

      const fullQuery = { type, sortBy, sortOrder };

      let mainEmployees;

      if (useExcel !== "Use Timesheet") {
        let employeesResult = await fetchActionsUtil(
          `${
            URLS.backendPayroll
          }/get-employees/${branch_id}?${stringifyQueryString(
            fullQuery
          )}&date=${payrollDate?.payrollYear}-${payrollDate?.payrollMonth}-1`
        );

        const { employees } = employeesResult;

        mainEmployees = employees;
      } else {
        mainEmployees = theexcelData;
      }

      mainEmployees = await calculateEmployeePayroll(mainEmployees);

      let formData = new FormData();

      let nE = mainEmployees.map((employee) => {
        return {
          ...employee,
          payrollMonth: payrollDate.payrollMonth,
          payrollYear: payrollDate.payrollYear,
          debitAccountNo: props.settings.debitAccountNumber,
          debitBankCode: props.settings.debitBankCode,
          type: "normal",
          settingHeaders,
        };
      });
      setOpenDialog(false);
      formData.append(
        "payload",
        JSON.stringify({
          payroll: [...nE],
          payrollMonth: payrollDate.payrollMonth,
          payrollYear: payrollDate.payrollYear,
          companySettings,
          branch_id,
          employeeType: theQueryParams.type,
        })
      );

      const response = await fetch(
        `${URLS.backendPayroll}/save-payroll-by-branch`,
        {
          method: "POST",
          body: formData,
        }
      );
      const result = await response.json();
      setIsSendLoading(false);
      if (result.success) {
        toast("payroll created", {
          type: "success",
          position: "top-right",
        });
        setOpenInforDialog(true);
      } else {
        toast(result.message, {
          type: "error",
          position: "top-right",
        });
      }
    } catch (err) {
      toast("payroll deleted", {
        type: "error",
      });
    } finally {
      setBtnText("Process");
      setIsSendLoading(false);
    }
  };

  const onDownloadExcelData = async () => {
    setExcelName("Salary Computation");
    let exData = normalEmployees.map((employee, index) => [
      index + 1,
      employee.name.first + " " + employee.name.last,
      employee.employeeId,
      useExcel === "Use Timesheet" ? employee?.sfa_id : "",
      employee.gradeName,

      companySettings?.extras
        ? employee?.userExtras?.DaysCommitted === ""
          ? 0
          : employee?.userExtras?.DaysCommitted
        : "",

      companySettings?.extras
        ? employee?.userExtras?.DayOn === ""
          ? 0
          : employee?.userExtras?.DayOn
        : "",

      companySettings?.extras
        ? employee?.userExtras?.TransitDays === ""
          ? 0
          : employee?.userExtras?.TransitDays
        : "",

      companySettings?.extras
        ? employee?.userExtras?.OverStayDays === ""
          ? 0
          : employee?.userExtras?.OverStayDays
        : "",

      companySettings?.extras
        ? employee?.userExtras?.ArrearsDays === ""
          ? 0
          : employee?.userExtras?.ArrearsDays
        : "",

      employee.usedlocation,
      employee.usedcontract,
      useExcel === "Use Timesheet" ? employee?.totalDaysWorked : "",
      useExcel === "Use Timesheet"
        ? parseFloat(employee?.numProration).toFixed(2)
        : "",
      currency(employee.grossPay, {
        symbol: "",
      }).format(),
      currency(employee.basic, {
        symbol: "",
      }).format(),
      currency(employee.housing, {
        symbol: "",
      }).format(),
      currency(employee.transport, {
        symbol: "",
      }).format(),
      currency(employee.medical, {
        symbol: "",
      }).format(),
      currency(employee.utility, {
        symbol: "",
      }).format(),
      currency(employee.entertainment, {
        symbol: "",
      }).format(),
      currency(employee.dressing, {
        symbol: "",
      }).format(),
      ...employee?.custom?.map((el) =>
        currency(el?.value, {
          symbol: "",
        }).format()
      ),
      currency(employee.ogGrossPay, {
        symbol: "",
      }).format(),

      ...deductionSettings
        .filter((el) => !el.groupName)
        .map((e, i) => {
          return currency(
            employee.deductions.find((el) => el.slug === e.slug)?.total,
            {
              symbol: "",
            }
          ).format();
        }),

      ...employee.DeductionGroupName.map((e, i) =>
        currency(
          employee.deductions
            ?.filter((el) => el.groupName === e)
            .map((el) => el.total)
            .reduce((a, b) => a + b, 0),
          {
            symbol: "",
          }
        ).format()
      ),

      ...bonusSettings
        .filter((el) => !el.groupName)
        .map((e, i) => {
          return currency(
            employee.bonus.find((el) => el.slug === e.slug)?.total,
            {
              symbol: "",
            }
          ).format();
        }),

      ...employee.BonusGroupName.map((e, i) =>
        currency(
          employee.bonus
            ?.filter((el) => el.groupName === e)
            .map((el) => el.total)
            .reduce((a, b) => a + b, 0),
          {
            symbol: "",
          }
        ).format()
      ),

      ...allownaceSettings
        .filter((el) => !el.groupName)
        .map((e, i) => {
          return currency(
            employee.allowances.find((el) => el.slug === e.slug)?.total,
            {
              symbol: "",
            }
          ).format();
        }),

      ...employee.AllowanceGroupName.map((e, i) =>
        currency(
          employee.allowances
            ?.filter((el) => el.groupName === e)
            .map((el) => el.total)
            .reduce((a, b) => a + b, 0),
          {
            symbol: "",
          }
        ).format()
      ),

      ...benefitSettings
        .filter((el) => !el.groupName)
        .map((e, i) => {
          return currency(
            employee.benefits.find((el) => el.slug === e.slug)?.total,
            {
              symbol: "",
            }
          ).format();
        }),

      ...employee.BenefitGroupName.map((e, i) =>
        currency(
          employee.benefits
            ?.filter((el) => el.groupName === e)
            .map((el) => el.total)
            .reduce((a, b) => a + b, 0),
          {
            symbol: "",
          }
        ).format()
      ),

      employee.loanDeduct
        ? currency(employee.loanDeduct, {
            symbol: "",
          }).format()
        : 0,
      pendingRetirement.length > 0 && employee.remainingRetairment
        ? currency(employee.remainingRetairment, {
            symbol: "",
          }).format()
        : 0,
      currency(employee.attendanceTotal, {
        symbol: "",
      }).format(),
      currency(employee.allTotalDeduction, {
        symbol: "",
      }).format(),
      currency(employee.totalBonus, {
        symbol: "",
      }).format(),
      currency(employee.totalAllowances, {
        symbol: "",
      }).format(),
      currency(employee.totalBenefits, {
        symbol: "",
      }).format(),
      currency(employee.netPay, {
        symbol: "",
      }).format(),
    ]);

    const normalHead = [
      "No",
      "Name",
      "Employees ID",
      "SFA ID",
      "Job Grade",
      companySettings?.extras ? "Days Committed" : "",
      companySettings?.extras ? "Days On" : "",
      companySettings?.extras ? "Transit Days" : "",
      companySettings?.extras ? "Overstay Days" : "",
      companySettings?.extras ? "Arrears Days" : "",
      "Location",
      "LSP",
      "Days Worked",
      "Proration",
      "Gross Salary",
      "Basic",
      "Housing",
      "Transport",
      "Medical",
      "Utility",
      "Entertainment",
      "Dressing",
      ...customHeaders.map((el) => el),
      "Earned Gross Salary",

      ...deductionSettings
        .filter((el) => !el.groupName)
        .map((setting, index) => {
          return setting.name;
        }),

      ...DeductionGroupName.map((setting, index) => setting),

      ...bonusSettings
        .filter((el) => !el.groupName)
        .map((setting, index) => {
          return setting.name;
        }),

      ...BonusGroupName.map((setting, index) => setting),

      ...allownaceSettings
        .filter((el) => !el.groupName)
        .map((setting, index) => {
          return setting.name;
        }),

      ...AllowanceGroupName.map((setting, index) => setting),

      ...benefitSettings
        .filter((el) => !el.groupName)
        .map((setting, index) => {
          return setting.name;
        }),

      ...BenefitGroupName.map((setting, index) => setting),

      "Loan Deductions",
      "IOU Deductions",
      "Attendance Deduction",
      "Total Deductions",
      "Total Bonus",
      "Total Allowance",
      "Total Benefit",
      "Net Pay",
    ];

    const date = `Date Prepared: ${moment(new Date()).format(
      "MMMM DD yyyy k:mm:ss z"
    )}`;

    exData = [
      // [company],
      ["Payroll Report"],
      [date],
      [""],
      normalHead,
      ...exData,
      [""],
      ["Payroll Summary"],
      [
        "Total Earned Gross",
        currency(totalGross, {
          symbol: "",
        }).format(),
      ],
      [
        "Total Allowance",
        currency(totalallowance, {
          symbol: "",
        }).format(),
      ],
      [
        "Total Benefit",
        currency(totalbenefit, {
          symbol: "",
        }).format(),
      ],
      [
        "Total Bouns",
        currency(totalbonus, {
          symbol: "",
        }).format(),
      ],

      [
        "Total Deduction",
        currency(totalDeduct, {
          symbol: "",
        }).format(),
      ],
      [
        "Total Net Amount",
        currency(totalNet, {
          symbol: "",
        }).format(),
      ],
    ];

    // console.log(exData);
    setExcelData(exData);
  };

  const onDownloadExcelPayeeTax = async () => {
    setExcelName("Paye Tax Computation");

    let taxNames = [];

    const computation = payrollSetting?.taxRateAsPerMonthlyComputation.sort(
      (a, b) => a.ratePercentage - b.ratePercentage
    );

    let exData = normalEmployees.map((employee, index) => {
      let totalExemption = [];
      if (payrollSetting?.exemptionDeductions?.length > 0) {
        totalExemption = employee?.deductions?.filter(({ _id }) =>
          payrollSetting?.exemptionDeductions?.find((ele) => _id === ele)
        );

        totalExemption = totalExemption?.map((el) => ({
          amount: el?.total,
          name: el?.groupName,
        }));

        taxNames = totalExemption;
      }

      const computationTax = employee?.taxRateArray?.sort(
        (a, b) => a.percent - b.percent
      );

      // console.log({ taxNames, totalExemption });

      return [
        index + 1,
        employee.name.first + " " + employee.name.last,
        employee.employeeId,
        useExcel === "Use Timesheet" ? employee?.sfa_id : "",
        employee.gradeName,
        currency(employee.grossPay, {
          symbol: "",
        }).format(),
        currency(employee.basic, {
          symbol: "",
        }).format(),
        currency(employee.housing, {
          symbol: "",
        }).format(),
        currency(employee.transport, {
          symbol: "",
        }).format(),
        currency(employee.medical, {
          symbol: "",
        }).format(),
        currency(employee.utility, {
          symbol: "",
        }).format(),
        currency(employee.entertainment, {
          symbol: "",
        }).format(),
        currency(employee.dressing, {
          symbol: "",
        }).format(),
        ...employee?.custom?.map((el) =>
          currency(el?.value, {
            symbol: "",
          }).format()
        ),
        currency(employee.ogGrossPay, {
          symbol: "",
        }).format(),

        ...totalExemption?.map((el) =>
          currency(el?.amount, {
            symbol: "",
          }).format()
        ),
        currency(employee?.totalExemption, {
          symbol: "",
        }).format(),
        currency(employee?.CRAGrossPurpose, {
          symbol: "",
        }).format(),
        currency(employee?.CRAGrossPurposePercentage, {
          symbol: "",
        }).format(),
        currency(employee?.personalAllowance, {
          symbol: "",
        }).format(),
        currency(employee?.totalRelief, {
          symbol: "",
        }).format(),
        currency(employee?.totalExemption, {
          symbol: "",
        }).format(),
        currency(employee?.chargeableIncome, {
          symbol: "",
        }).format(),
        ...computationTax.map((ele, i) =>
          currency(ele?.amount, {
            symbol: "",
          }).format()
        ),
        currency(employee?.taxRateAsPerMonthlyTotal, {
          symbol: "",
        }).format(),
      ];
    });

    const normalHead = [
      "No",
      "Name",
      "Employees ID",
      "SFA ID",
      "Job Grade",
      "Gross Salary",
      "Basic",
      "Housing",
      "Transport",
      "Medical",
      "Utility",
      "Entertainment",
      "Dressing",
      ...customHeaders.map((el) => el),
      "Earned Gross Salary",
      ...taxNames.map((el) => el?.name),
      "Total Exempt",
      "Gross For CRA Purpose",
      `Gross For CRA Purpose (${payrollSetting?.grossForCraPurposePercentage}%)`,
      `Personal Allowance ${payrollSetting?.personalAllowance}/PA`,
      "Total Relief",
      "Total Deductions",
      "Chargeble Income",
    ];

    const date = `Date Prepared: ${moment(new Date()).format(
      "MMMM DD yyyy k:mm:ss z"
    )}`;

    exData = [
      // [company],
      ["Paye Tax Computation"],
      [date],
      [""],
      [
        ...normalHead?.map((ele) => ""),
        ...computation.map((el) => `${el?.ratePercentage}%`),
      ],
      [
        ...normalHead,
        ...computation.map((el) =>
          currency(el?.rateAmount, {
            symbol: "",
          }).format()
        ),
        "TAX PAYABLE",
      ],
      ...exData,
      [""],
    ];

    // console.log(exData);
    setExcelData(exData);
  };

  const tableHead = () => {
    return (
      <thead className="position-sticky top-0">
        <tr>
          <th>No</th>
          <th>Name</th>
          <th>Employees ID</th>
          {useExcel === "Use Timesheet" && <th>SFA ID</th>}
          <th>Job Grade</th>
          <th>Days Committed</th>
          {companySettings?.extras ? (
            <>
              <th>Days On</th>
              <th>Transit Days</th>
              <th>Overstay Days</th>
              <th>Arrears Days</th>
            </>
          ) : null}

          <th>Location</th>
          <th>LSP</th>
          {useExcel === "Use Timesheet" && (
            <>
              {/* <th>Days Committed</th> */}
              <th>Proration</th>
            </>
          )}
          <th>Gross Salary</th>
          {Object.entries(settingHeaders).length === 0 ||
          settingHeaders?.basic ? (
            <th>Basic</th>
          ) : null}
          {Object.entries(settingHeaders).length === 0 ||
          settingHeaders?.housing ? (
            <th>Housing</th>
          ) : null}
          {Object.entries(settingHeaders).length === 0 ||
          settingHeaders?.transport ? (
            <th>Transport</th>
          ) : null}
          {Object.entries(settingHeaders).length === 0 ||
          settingHeaders?.medical ? (
            <th>Medical</th>
          ) : null}
          {Object.entries(settingHeaders).length === 0 ||
          settingHeaders?.utility ? (
            <th>Utility</th>
          ) : null}
          {Object.entries(settingHeaders).length === 0 ||
          settingHeaders?.entertainment ? (
            <th>Entertainment</th>
          ) : null}
          {Object.entries(settingHeaders).length === 0 ||
          settingHeaders?.dressing ? (
            <th>Dressing</th>
          ) : null}
          {customHeaders.map((el) => (
            <th>{el}</th>
          ))}
          <th>Earned Gross Salary</th>
          {deductionSettings
            .filter((el) => !el.groupName)
            .map((setting, index) => {
              return (
                <th className="text-capitalize" key={index}>
                  {setting.name === "company pension"
                    ? "Employer pension contribution"
                    : setting.name === "pension"
                    ? "Employee pension contribution"
                    : setting.name}
                  <SalarySetting setting={setting} />
                  <br />
                </th>
              );
            })}
          {DeductionGroupName.map((setting, index) => {
            return (
              <th className="text-capitalize" key={index}>
                {setting}
                <br />{" "}
              </th>
            );
          })}

          {bonusSettings
            .filter((el) => !el.groupName)
            .map((setting, index) => {
              return (
                <th className="text-capitalize" key={index}>
                  {setting.name} <SalarySetting setting={setting} />
                  <br />
                </th>
              );
            })}

          {BonusGroupName.map((setting, index) => {
            return (
              <th className="text-capitalize" key={index}>
                {setting}
                <br />{" "}
              </th>
            );
          })}

          {allownaceSettings
            .filter((el) => !el.groupName)
            .map((setting, index) => {
              return (
                <th className="text-capitalize" key={index}>
                  {setting.name} <SalarySetting setting={setting} />
                  <br />{" "}
                </th>
              );
            })}
          {AllowanceGroupName.map((setting, index) => {
            return (
              <th className="text-capitalize" key={index}>
                {setting}
                <br />{" "}
              </th>
            );
          })}
          {benefitSettings
            .filter((el) => !el.groupName)
            .map((setting, index) => {
              return (
                <th className="text-capitalize" key={index}>
                  {setting.name} <SalarySetting setting={setting} />
                  <br />{" "}
                </th>
              );
            })}
          {BenefitGroupName.map((setting, index) => {
            return (
              <th className="text-capitalize" key={index}>
                {setting}
                <br />{" "}
              </th>
            );
          })}
          {/* <th>Other Deductions</th> */}
          <th>Loan Deductions</th>
          {pendingRetirement?.length > 0 && <th>IOU Deductions</th>}
          <th>Attendance Deduction</th>
          <th>Total Deductions</th>
          <th>Total Bonus</th>
          <th>Total Allowance</th>
          <th>Total Benefit</th>
          <th>Remark</th>
          <th>Net Pay</th>
        </tr>
      </thead>
    );
  };

  const tableBodyData = (employee, index) => {
    return (
      <>
        <td>{returnParams?.startIndex + index + 1}</td>

        <td>{employee?.name.first + " " + employee.name.last}</td>
        <td>{employee.employeeId}</td>
        {useExcel === "Use Timesheet" && <td>{employee.sfa_id}</td>}
        <td>{employee.gradeName}</td>

        <td>
          {employee?.totalDaysWorked === "" ? 0 : employee?.totalDaysWorked}
        </td>

        {companySettings?.extras ? (
          <>
            <td>
              {employee?.userExtras?.DayOn === ""
                ? 0
                : employee?.userExtras?.DayOn}
            </td>
            <td>
              {employee?.userExtras?.TransitDays === ""
                ? 0
                : employee?.userExtras?.TransitDays}
            </td>
            <td>
              {employee?.userExtras?.OverStayDays === ""
                ? 0
                : employee?.userExtras?.OverStayDays}
            </td>
            <td>
              {employee?.userExtras?.ArrearsDays === ""
                ? 0
                : employee?.userExtras?.ArrearsDays}
            </td>
          </>
        ) : null}

        <td>{employee.usedlocation}</td>
        <td>{employee.usedcontract}</td>
        {useExcel === "Use Timesheet" && (
          <>
            {/* <td>{employee?.totalDaysWorked}</td> */}
            <td>{parseFloat(employee?.numProration).toFixed(2)}</td>
          </>
        )}
        <td>
          {currency(employee.grossPay, {
            symbol: "",
          }).format()}
        </td>
        {Object.entries(settingHeaders).length === 0 ||
        settingHeaders?.basic ? (
          <td>
            {currency(employee.basic, {
              symbol: "",
            }).format()}
          </td>
        ) : null}
        {Object.entries(settingHeaders).length === 0 ||
        settingHeaders?.housing ? (
          <td>
            {currency(employee.housing, {
              symbol: "",
            }).format()}
          </td>
        ) : null}
        {Object.entries(settingHeaders).length === 0 ||
        settingHeaders?.transport ? (
          <td>
            {currency(employee.transport, {
              symbol: "",
            }).format()}
          </td>
        ) : null}
        {Object.entries(settingHeaders).length === 0 ||
        settingHeaders?.medical ? (
          <td>
            {currency(employee.medical, {
              symbol: "",
            }).format()}
          </td>
        ) : null}
        {Object.entries(settingHeaders).length === 0 ||
        settingHeaders?.utility ? (
          <td>
            {currency(employee.utility, {
              symbol: "",
            }).format()}
          </td>
        ) : null}
        {Object.entries(settingHeaders).length === 0 ||
        settingHeaders?.entertainment ? (
          <td>
            {currency(employee.entertainment, {
              symbol: "",
            }).format()}
          </td>
        ) : null}
        {Object.entries(settingHeaders).length === 0 ||
        settingHeaders?.dressing ? (
          <td>
            {currency(employee.dressing, {
              symbol: "",
            }).format()}
          </td>
        ) : null}
        {employee?.custom?.map((el) => (
          <td>
            {currency(el?.value, {
              symbol: "",
            }).format()}
          </td>
        ))}
        {/* <td>
            <input
              value={employee.bonuses}
              onChange={(e) => {
                setBonuses({
                  e,
                  employeeId: employee.employeeId,
                });
              }}
              onKeyUp={(e) => {
                calculateData({
                  e,
                  employeeId: employee.employeeId,
                });
              }}
              className="form-control payroll-input"
            />
          </td> */}
        <td>
          {currency(employee.ogGrossPay, {
            symbol: "",
          }).format()}
        </td>
        {deductionSettings
          .filter((el) => !el.groupName)
          .map((e, i) => {
            return (
              <td key={i}>
                {currency(
                  employee.deductions.find((el) => el.slug === e.slug)?.total,
                  {
                    symbol: "",
                  }
                ).format()}
              </td>
            );
          })}
        {DeductionGroupName.map((e, i) => {
          return (
            <td key={i}>
              {currency(
                employee.deductions
                  .filter((el) => el.groupName === e)
                  .map((el) => el.total)
                  .reduce((a, b) => a + b, 0),
                {
                  symbol: "",
                }
              ).format()}
            </td>
          );
        })}

        {bonusSettings
          .filter((el) => !el.groupName)
          .map((e, i) => {
            return (
              <td key={i}>
                {currency(
                  employee.bonus.find((el) => el.slug === e.slug)?.total,
                  {
                    symbol: "",
                  }
                ).format()}
              </td>
            );
          })}
        {BonusGroupName.map((e, i) => {
          return (
            <td key={i}>
              {currency(
                employee.bonus
                  .filter((el) => el.groupName === e)
                  .map((el) => el.total)
                  .reduce((a, b) => a + b, 0),
                {
                  symbol: "",
                }
              ).format()}
            </td>
          );
        })}
        {allownaceSettings
          .filter((el) => !el.groupName)
          .map((e, i) => {
            return (
              <td key={i}>
                {currency(
                  employee.allowances.find((el) => el.slug === e.slug)?.total,
                  {
                    symbol: "",
                  }
                ).format()}
              </td>
            );
          })}
        {AllowanceGroupName.map((e, i) => {
          return (
            <td key={i}>
              {currency(
                employee.allowances
                  .filter((el) => el.groupName === e)
                  .map((el) => el.total)
                  .reduce((a, b) => a + b, 0),
                {
                  symbol: "",
                }
              ).format()}
            </td>
          );
        })}
        {benefitSettings
          .filter((el) => !el.groupName)
          .map((e, i) => {
            return (
              <td key={i}>
                {currency(
                  employee.benefits.find((el) => el.slug === e.slug)?.total,
                  {
                    symbol: "",
                  }
                ).format()}
              </td>
            );
          })}
        {BenefitGroupName.map((e, i) => {
          return (
            <td key={i}>
              {currency(
                employee.benefits
                  .filter((el) => el.groupName === e)
                  .map((el) => el.total)
                  .reduce((a, b) => a + b, 0),
                {
                  symbol: "",
                }
              ).format()}
            </td>
          );
        })}
        {/* <td>
          <input
            value={employee.otherDeductions}
            onChange={(e) => {
              setOtherDeductions({
                e,
                employeeId: employee.employeeId,
              });
            }}
            onKeyUp={(e) => {
              calculateData({
                e,
                employeeId: employee.employeeId,
              });
            }}
            className="form-control payroll-input"
          />
          <CurrencyInput
            className="form-control"
            value={employee.otherDeductions}
            allowNegativeValue={false}
            // allowDecimals={false}
            // onValueChange={(value, name) => this.updateAmount(value)}
            onValueChange={(value) => {
              setOtherDeductions({
                e: value,
                employeeId: employee.employeeId,
              });
            }}
            onKeyUp={(value) => {
              calculateData({
                e: value,
                employeeId: employee.employeeId,
              });
            }}
          />
        </td> */}
        <td>
          {employee.loanDeduct
            ? currency(employee.loanDeduct, {
                symbol: "",
              }).format()
            : 0}
        </td>
        {pendingRetirement?.length > 0 && (
          <td>
            {employee.remainingRetairment
              ? currency(employee.remainingRetairment, {
                  symbol: "",
                }).format()
              : 0}
          </td>
        )}
        <td>
          {currency(employee.attendanceTotal, {
            symbol: "",
          }).format()}
        </td>
        <td>
          {currency(employee.allTotalDeduction, {
            symbol: "",
          }).format()}
        </td>
        <td>
          {currency(employee.totalBonus, {
            symbol: "",
          }).format()}
        </td>
        <td>
          {currency(employee.totalAllowances, {
            symbol: "",
          }).format()}
        </td>
        <td>
          {currency(employee.totalBenefits, {
            symbol: "",
          }).format()}
        </td>
        <td>
          <input
            value={employee.remark ?? "..."}
            onClick={(e) => e.target.select()}
            onChange={(e) => {
              setRemark({
                e,
                employeeId: employee.employeeId,
              });
            }}
            className="form-control payroll-input"
          />
        </td>
        <td>
          {currency(employee.netPay, {
            symbol: "",
          }).format()}
        </td>
      </>
    );
  };

  const dropDownFunction = (qty) => {
    setQueryParams((el) => ({ ...el, limit: qty }));
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex, nofollow"></meta>
        <title>InvexERP</title>
      </Helmet>
      <div className="main-wrapper">
        <Topbar />
        <Sidebar />
        {/*<!-- Page Wrapper -->*/}
        <div className="page-wrapper">
          {/*<!-- Page Content -->*/}
          <div className="content container-fluid">
            {/*<!-- Page Title -->*/}
            <div className="row">
              <div className="col-sm-12 col-12 text-capitalize">
                <Breadcrumb>
                  <BreadcrumbItem>
                    <Link to={`${URLS.dashboard}`}>Dashboard</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>Process Payroll</BreadcrumbItem>
                </Breadcrumb>
              </div>
              <div className="col-sm-5 col-5"></div>
            </div>
            {/*<!-- /Page Title -->*/}
            {/*<!-- Content Starts -->*/}
            <div className="mt-3">
              <div className="d-flex justify-content-between my-4">
                <div className="d-flex">
                  <div className="form-inline">
                    {/* <label>Month</label> */}
                    <select
                      className="form-control mx-2"
                      value={payrollDate.payrollMonth}
                      onChange={(e) =>
                        setPayrollDate({
                          payrollMonth: e.target.value,
                          payrollYear: payrollDate.payrollYear,
                        })
                      }
                    >
                      {[...Array(12)].map((e, index) => (
                        <option key={index}>{index + 1}</option>
                      ))}
                    </select>
                  </div>
                  <div className="form-inline ml-3">
                    {/* <label>Year</label> */}
                    <input
                      type="number"
                      style={{ width: "100px" }}
                      className="form-control mx-2"
                      value={payrollDate.payrollYear}
                      onChange={(e) =>
                        setPayrollDate({
                          payrollMonth: payrollDate.payrollMonth,
                          payrollYear: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>

                <div className="d-flex">
                  <div className="d-flex w-100">
                    <select
                      className="form-control mx-4"
                      aria-label="Default select example"
                      value={useExcel}
                      onChange={(e) => setUseExcel(e.target.value)}
                    >
                      {["Use database", "Use Timesheet"].map((a, i) => (
                        <option key={i} value={a}>
                          {a}
                        </option>
                      ))}
                    </select>

                    <div>
                      {useExcel === "Use Timesheet" && (
                        <Dropdown>
                          <Dropdown.Toggle variant="" bsPrefix="">
                            <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                          </Dropdown.Toggle>

                          <Dropdown.Menu
                            popperConfig={{
                              strategy: "fixed",
                            }}
                            renderOnMount
                            className="text-center"
                          >
                            <Dropdown.Item as="div" className="p-cursor">
                              <ImportPaylistFromExcel
                                payrollMonth={payrollDate.payrollMonth}
                                payrollYear={payrollDate.payrollYear}
                                handleEmployeesToPay={settheexcelData}
                              />
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                    </div>
                  </div>

                  <div className="mx-4">
                    <CSVLink
                      className="btn print d-none"
                      filename={`${excelName} (${moment(new Date()).format(
                        "DD-MMM-yyyy hh:mm:ss a"
                      )}).csv`}
                      data={excelData}
                      ref={CSVLinkRef}
                    />

                    <Dropdown>
                      <Dropdown.Toggle
                        variant=""
                        className="print"
                        disabled={isfetchingExcel}
                        bsPrefix=""
                      >
                        <span className="mx-2">Export</span>
                        <ExportIcon color="#008000" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        popperConfig={{
                          strategy: "fixed",
                        }}
                        renderOnMount
                        className="text-center"
                      >
                        <Dropdown.Item
                          as="div"
                          onClick={onDownloadExcelData}
                          className="p-cursor"
                        >
                          Salary Computation <ExcelIcon color="#008000" />
                        </Dropdown.Item>
                        <Dropdown.Item
                          as="div"
                          onClick={onDownloadExcelPayeeTax}
                          className="p-cursor"
                        >
                          Paye Tax Computation
                          <ExcelIcon color="#008000" />
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  <div className="w-100">
                    <button
                      onClick={() => setBtnSummaryActive(!btnSummryActive)}
                      className="btn summaryBtn "
                    >
                      Show Summary
                    </button>
                  </div>
                </div>
              </div>

              {/* Search */}
              <div className="my-4">
                <div className="grid-cat-1 filter-row">
                  <div className="form-group">
                    <div className="search-area">
                      <SearchIcon color="#94A3B8" />
                      <Form.Control
                        placeholder="Search employee ID"
                        aria-label="Search employee ID"
                        value={theQueryParams.id}
                        name="id"
                        onChange={onChangeInput}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="search-area">
                      <SearchIcon color="#94A3B8" />
                      <Form.Control
                        placeholder="Search employee name"
                        aria-label="Search employee name"
                        value={theQueryParams.name}
                        onChange={onChangeInput}
                        name="name"
                      />
                    </div>
                  </div>
                  <div className="form-group form-focus text-gray-400 d-flex align-items-center justify-content-between">
                    <Form.Control
                      as="select"
                      value={theQueryParams.department}
                      onChange={onChangeInput}
                      name="department"
                    >
                      <option value="">Select Department</option>
                      {searchItems?.department?.length > 0
                        ? searchItems?.department.map((department) => {
                            return (
                              <option value={department.value}>
                                {department.label}
                              </option>
                            );
                          })
                        : null}
                    </Form.Control>
                    <div className=" pr-2">
                      <DownArrowIcon color="#6c757d" />
                    </div>
                  </div>
                  <div className="form-group form-focus text-gray-400 d-flex align-items-center justify-content-between">
                    <Form.Control
                      as="select"
                      value={theQueryParams.grades}
                      onChange={onChangeInput}
                      name="grades"
                    >
                      <option value="">Select Grades</option>
                      {searchItems?.grades?.length > 0
                        ? searchItems?.grades.map((grade) => {
                            return (
                              <option value={grade.value}>{grade.label}</option>
                            );
                          })
                        : null}
                    </Form.Control>
                    <div className=" pr-2">
                      <DownArrowIcon color="#6c757d" />
                    </div>
                  </div>
                  <div className="form-group form-focus text-gray-400 d-flex align-items-center justify-content-between">
                    <Form.Control
                      as="select"
                      value={theQueryParams.category}
                      onChange={onChangeInput}
                      name="category"
                    >
                      <option value="">Select category</option>
                      {searchItems?.category?.length > 0
                        ? searchItems?.category.map((categories) => {
                            return (
                              <option value={categories.value}>
                                {categories.label}
                              </option>
                            );
                          })
                        : null}
                    </Form.Control>
                    <div className=" pr-2">
                      <DownArrowIcon color="#6c757d" />
                    </div>
                  </div>
                  <div className="form-group form-focus text-gray-400 d-flex align-items-center justify-content-between">
                    <Form.Control
                      as="select"
                      value={theQueryParams.type}
                      onChange={onChangeInput}
                      name="type"
                    >
                      <option value="All">Select employee type</option>
                      <option value="staff">Staff</option>
                      <option value="contract">Contract</option>
                    </Form.Control>
                    <div className=" pr-2">
                      <DownArrowIcon color="#6c757d" />
                    </div>
                  </div>
                </div>
              </div>
              {/* //Search Area */}

              {/* Sort Area */}
              <div className="d-flex w-100 my-4">
                <div>
                  <label>Sort By : </label>
                  <Form.Control
                    as="select"
                    value={theQueryParams.sortBy}
                    onChange={onChangeInput}
                    name="sortBy"
                  >
                    <option value="">Sort By</option>
                    {[
                      { value: "id", label: "Employee ID" },
                      { value: "first", label: "First Name" },
                      { value: "last", label: "Last Name" },
                    ].map((sort) => {
                      return <option value={sort.value}>{sort.label}</option>;
                    })}
                  </Form.Control>
                </div>

                <div className="mx-4">
                  <label>Order By : </label>
                  <Form.Control
                    as="select"
                    value={theQueryParams.sortOrder}
                    onChange={onChangeInput}
                    name="sortOrder"
                  >
                    <option value="">Order By</option>
                    {[
                      { value: "asc", label: "Ascending Order" },
                      { value: "desc", label: "Descending Order" },
                    ].map((sort) => {
                      return <option value={sort.value}>{sort.label}</option>;
                    })}
                  </Form.Control>
                </div>
              </div>
              {/* //Sort Area */}

              {IsFetching ? (
                <ModalLoader show={IsFetching} />
              ) : (
                <>
                  {normalEmployees.length > 0 ? (
                    <Row className="table-height">
                      <TableComponent
                        responsive
                        striped
                        sticky
                        tableHeadsFunction={tableHead}
                        mainDataArray={normalEmployees}
                        tableDataRowFunction={tableBodyData}
                        className="product-table text-nowrap"
                      />
                    </Row>
                  ) : (
                    <div
                      className="w-100 d-flex justify-content-center align-items-center bg-white"
                      style={{ height: "250px" }}
                    >
                      <p>{`No data on ${
                        allMonths[payrollDate.payrollMonth - 1]
                      } ${payrollDate.payrollYear}`}</p>
                    </div>
                  )}
                </>
              )}

              <div className="my-4">
                <CustomPagination
                  setQueryParams={setQueryParams}
                  startIndex={returnParams.startIndex}
                  endIndex={returnParams.endIndex}
                  count={returnParams.count}
                  limit={queryParams.limit}
                  page={queryParams.page}
                  dropDownFunction={dropDownFunction}
                />
              </div>

              <div className="mt-2">
                {/* <div className="my-3">
                  <label>Send Payslip Mail</label>
                  <div
                    className="onoffswitch ml-0"
                    onClick={(e) => {
                      setSettings({
                        ...settings,
                        sendMail: !settings.sendMail,
                      });
                    }}
                  >
                    <input
                      type="checkbox"
                      name="onoffswitch"
                      className="onoffswitch-checkbox"
                      checked={settings.sendMail}
                    />
                    <label className="onoffswitch-label">
                      <span className="onoffswitch-inner"></span>
                      <span className="onoffswitch-switch"></span>
                    </label>
                  </div>
                </div> */}
                <button
                  class="btn btn-primary my-3"
                  data-toggle="modal"
                  data-target={`#${dialog}`}
                  onClick={() => setOpenDialog(true)}
                >
                  {btnText}
                </button>
              </div>

              <div
                className={
                  btnSummryActive
                    ? `summaryNew border-top open`
                    : `summaryNew border-top`
                }
              >
                <div className="row justify-content-between mt-4">
                  <div className="col">
                    <div className="my-3">
                      <div className="d-flex gap-3 align-items-center mb-4">
                        <h4 className="m-0">Payroll Summary</h4>{" "}
                        <hr className="flex-grow-1 m-0" />
                        {btnSummryActive && (
                          <button
                            onClick={() =>
                              setBtnSummaryActive(!btnSummryActive)
                            }
                            className="btn p-0"
                          >
                            <SummaryCloseIcon />
                          </button>
                        )}
                      </div>

                      <div
                        className="gridCont mb-3 three-col-grid"
                        style={{ gridTemplateRows: "unset" }}
                      >
                        {/*  */}
                        <div className="gridChild gridChildBorderLeftBlue">
                          <div className="gridChildLeft">
                            <p className="gridChld1">
                              {currency(totalGross, {
                                symbol: "",
                              }).format()}
                            </p>
                            <p className="gridChld2">Total Earned Gross</p>
                          </div>

                          <div className="gridChildRight">
                            <SummaryChartIconUp />
                            <p>5%</p>
                          </div>
                        </div>

                        {/*  */}

                        <div className="gridChild gridChildBorderLeftDarkerGreen">
                          <div className="gridChildLeft">
                            <p className="gridChld1">
                              {currency(totalallowance, {
                                symbol: "",
                              }).format()}
                            </p>
                            <p className="gridChld2">Total Allowance</p>
                          </div>

                          <div className="gridChildRight">
                            <SummaryChartIconUp />
                            <p>5%</p>
                          </div>
                        </div>
                        {/*  */}

                        <div className="gridChild gridChildBorderLeftDarkerGreen">
                          <div className="gridChildLeft">
                            <p className="gridChld1">
                              {currency(totalbenefit, {
                                symbol: "",
                              }).format()}
                            </p>
                            <p className="gridChld2">Total Benefit</p>
                          </div>

                          <div className="gridChildRight">
                            <SummaryChartIconUp />
                            <p>5%</p>
                          </div>
                        </div>
                        {/*  */}
                      </div>

                      {/* Secound Order */}

                      <div
                        className="gridCont mb-3 three-col-grid"
                        style={{ gridTemplateRows: "unset" }}
                      >
                        {/*  */}
                        <div className="gridChild gridChildBorderLeftDarkerGreen">
                          <div className="gridChildLeft">
                            <p className="gridChld1">
                              {currency(totalbonus, {
                                symbol: "",
                              }).format()}
                            </p>
                            <p className="gridChld2">Total Bouns</p>
                          </div>

                          <div className="gridChildRight">
                            <SummaryChartIconUp />
                            <p>5%</p>
                          </div>
                        </div>
                        {/*  */}

                        <div className="gridChild gridChildBorderLeftOrange">
                          <div className="gridChildLeft">
                            <p className="gridChld1">
                              {currency(totalDeduct, {
                                symbol: "",
                              }).format()}
                            </p>
                            <p className="gridChld2">Total Deduction</p>
                          </div>

                          <div className="gridChildRight">
                            <SummaryChartIconUp />
                            <p>5%</p>
                          </div>
                        </div>
                        {/*  */}

                        <div className="gridChild gridChildBorderLeftDarkerGreen">
                          <div className="gridChildLeft">
                            <p className="gridChld1">
                              {currency(totalNet, {
                                symbol: "",
                              }).format()}
                            </p>
                            <p className="gridChld2">Total Net Amount</p>
                          </div>

                          <div className="gridChildRight">
                            <SummaryChartIconUp />
                            <p>5%</p>
                          </div>
                        </div>
                        {/*  */}
                      </div>
                    </div>
                  </div>
                </div>
                <DialogModal
                  show={openDialog}
                  proceed={savePayroll}
                  handleClose={() => setOpenDialog(false)}
                  title="Process Payroll"
                  description={`Are you sure you want to process payroll for ${
                    allMonths[payrollDate.payrollMonth - 1]
                  } ${payrollDate.payrollYear}`}
                  sendTitle="Proceed"
                />
                {openInforDialog && (
                  <InforModal
                    show={openInforDialog}
                    proceed={savePayroll}
                    handleClose={() => setOpenInforDialog(false)}
                    description={`${allMonths[payrollDate.payrollMonth - 1]} ${
                      payrollDate.payrollYear
                    } process successfully`}
                    sendTitle="Salary Schedule"
                    link={`/dashboard/salary-summary?month=${payrollDate.payrollMonth}`}
                    secondLink={`/dashboard/pay-history?month=${payrollDate.payrollMonth}`}
                    secondTitle="Payroll History"
                    payrollDate={payrollDate}
                    employeeType={theQueryParams.type}
                    thirdTitle="Request for approval"
                  />
                )}
              </div>
            </div>
            {/*<!-- /Content End -->*/}
          </div>
          {/*<!-- /Page Content -->*/}
        </div>
        {/*<!-- /Page Wrapper -->*/}
      </div>
      <ModalLoader show={isSendLoading} />
      {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
      <div className="sidebar-overlay" data-reff=""></div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    User: state.api.User,
    settings: state.settingsReducer.settings,
    // deductionSettings: state.deductionSettings.deductions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CreateSalary));
