import React, { Fragment, useState, useEffect } from "react";
import { URLS } from "../urls";
import moment from "moment";
import { Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import Select from "react-select";
import { dateWithNowTime, fetchActionsUtil, useCookie } from "../../utils";
import RsDateRangePicker from "../RsDateRangePicker";

export const applyTo = [
  { label: "All", value: "All" },
  { label: "Job Grade", value: "Job Grade" },
  { label: "Selected Employees", value: "Employees" },
  { label: "Departments", value: "Department" },
];

export default function ApplyDayOffModal({ setOpenApplyDayOff, getDayOff }) {
  const branch_id = useCookie("branch_id");

  const [state, setState] = useState({
    buttonText: "Submit",
    isWaiting: false,
    userId: "",
    applyDate: moment(new Date()).format("DD/MM/YYYY"),
    staff: "",
    users: [],
    comment: "",
    description: "",
  });

  const [departments, setDepartments] = useState([]);
  const [applySelected, setApplySelected] = useState("All");
  const [gradeEmployees, setGradeEmployees] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [selectedGradeEmployees, setSelectedGradeEmployees] = useState([]);

  // Initialize state to store the multiple date ranges
  const [dateRanges, setDateRanges] = useState([]);

  useEffect(() => {
    getDepartments();
  }, []);

  const getDepartments = async () => {
    // await waitFor(5000);
    if (process.env.REACT_APP_HAS_INVEX === "true") {
      let response = await fetch(`${URLS.mainSiteBackend}/users/departments`, {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
      });

      if (!response.ok) {
        response = await response.json();

        throw new Error(response.message);
      }

      const { data } = await response.json();

      setDepartments(data?.departments);
    }
  };

  const getUsersInDepartments = async (department) => {
    let response = await fetch(
      `${URLS.mainSiteBackend}/users/by-department/${department}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        // credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    setState({
      ...state,
      users: data?.users,
    });
  };

  const onChangeInputs = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const clearDateRange = (event, picker) => {
    setState({
      ...state,
      startDate: "",
      endDate: "",
    });

    picker.element.val("");
  };

  const submit = async (e) => {
    e.preventDefault();
    setState({ ...state, buttonText: "Submitting ...", isWaiting: true });

    const { description, comment, staff } = state;
    const selectedData = selectedGradeEmployees.map((el) => el.value);
    let selectedNames = selectedGradeEmployees.map((el) => el.label);

    if (applySelected !== "All" && !selectedGradeEmployees.length > 0) {
      toast.error("Please specify where to apply the day off", {
        position: "top-right",
      });
      setState({ ...state, buttonText: "Submit", isWaiting: false });
      return;
    }

    selectedNames = selectedNames.map((name) => `${name}`).join(", ");

    const requisition = {
      title: `Day off request for ${applySelected} ${
        applySelected === "All" ? "employee" : "'s"
      } `,
      description,
      type: "DayOff",
      requestto: staff,
      amount: 0,
      status: "Pending",
      regdate: moment(new Date()).format("MM/DD/YYYY"),
      requestby: staff,
      vendor: "",
      comment,
      filledFormData: "",
      loanDetails: JSON.stringify({ selectedNames, applySelected, dateRanges }),
    };

    const sendBody = {
      status: "Pending",
      staff,
      description,
      comment,
      branch_id,
      selectedData,
      applySelected,
      dateRanges,
    };

    const formData = new FormData();
    Object.entries(requisition).forEach(([key, value]) => {
      formData.append(key, value);
    });

    const token = "dummy token";

    try {
      if (process.env.REACT_APP_HAS_INVEX === "true") {
        const res = await fetch(`${URLS.mainSiteBackend}/requisition/create`, {
          method: "POST",
          headers: {
            Authorization: `Token ${token}`,
          },
          body: formData,
        });

        if (res.ok) {
          const result = await res.json();
          const sendResult = await fetchActionsUtil(
            `${URLS.backendDayOff}`,
            "POST",
            token,
            {
              ...sendBody,
              requestid: result?.data?.requisition?.requestid,
            }
          );

          if (sendResult.success) {
            toast.success("Day off Successfully Applied.", {
              position: "top-right",
            });
            getDayOff();
            setState({
              ...state,
              buttonText: "Submit",
              isWaiting: false,
              staff: "",
              users: [],
              comment: "",
              description: "",
            });
            setOpenApplyDayOff(false);
          }
        }
      } else {
        toast.error("Please Connect to InvexERP.", {
          position: "top-right",
        });
      }
    } catch (error) {
      console.error(error);
      toast.error("An unexpected error occurred.", {
        position: "top-right",
      });
    } finally {
      setState({ ...state, buttonText: "Submit", isWaiting: false });
    }
  };

  const handleDateChange = (event, picker) => {
    if (picker.startDate && picker.endDate) {
      // Add the new date range to the dateRanges state

      setDateRanges([
        ...dateRanges,
        {
          startDate: picker.startDate.add(1, "hours"),
          endDate: picker.endDate,
        },
      ]);
    }

    picker.element.val(
      picker.startDate.format("MM/DD/YYYY") +
        " - " +
        picker.endDate.format("MM/DD/YYYY")
    );
  };

  // Function to remove a specific date range
  const removeDateRange = (index) => {
    setDateRanges(dateRanges.filter((_, i) => i !== index));
  };

  useEffect(() => {
    setIsFetching(true);
    const fetchOptions = {
      All: () => {
        setGradeEmployees([]);
        setSelectedGradeEmployees([]);
      },
      "Job Grade": async () => {
        const { jobGrades } = await fetchActionsUtil(
          `${URLS.backendJobGrades}/get/${branch_id}`
        );

        const jg = [...jobGrades].map((d) => ({
          label: d.name,
          value: d._id,
        }));
        setGradeEmployees([]);
        setGradeEmployees(jg);
        setIsFetching(false);
      },
      Employees: async () => {
        const { employees } = await fetchActionsUtil(
          `${URLS.backendEmployees}/get/${branch_id}`
        );
        const em = employees?.map((d) => ({
          label: `${
            d?.personalInfo?.name?.first ? d?.personalInfo?.name?.first : ""
          } ${
            d?.personalInfo?.name?.middle ? d?.personalInfo?.name?.middle : ""
          } ${d?.personalInfo?.name?.last ? d?.personalInfo?.name?.last : ""}`,
          value: d.userId,
        }));
        setIsFetching(false);
        setGradeEmployees([]);
        setGradeEmployees(em);
      },
      Department: async () => {
        const { departments } = await fetchActionsUtil(
          `${URLS.backendDepartments}/get/${branch_id}`
        );
        const cons = [...departments].map((d) => ({
          label: d.name,
          value: d._id,
        }));
        setGradeEmployees([]);
        setGradeEmployees(cons);
        setIsFetching(false);
      },
    };

    if (fetchOptions[applySelected]) {
      fetchOptions[applySelected]();
    }
  }, [applySelected, branch_id]);

  return (
    <Modal
      centered
      show={true}
      onHide={() => {
        setOpenApplyDayOff(false);
      }}
      className="custom-modal"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h3>Apply for day off</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div>
            <form onSubmit={submit}>
              <div className="my-3">
                <label>Apply Day off to :</label>
                <div className="row">
                  <div className="col-6">
                    <select
                      className="form-control"
                      aria-label="Default select example"
                      value={applySelected}
                      onChange={(e) => setApplySelected(e.target.value)}
                    >
                      {applyTo.map((a, i) => (
                        <option key={i} value={a.value}>
                          {a.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  {gradeEmployees.length > 0 && (
                    <div className="col-6">
                      <Select
                        closeMenuOnSelect={true}
                        isLoading={isFetching}
                        isMulti
                        isSearchable={true}
                        value={gradeEmployees.filter((d) =>
                          selectedGradeEmployees.find(
                            (e) => e.value === d.value
                          )
                        )}
                        onChange={(selected) => {
                          setSelectedGradeEmployees(selected);
                        }}
                        options={gradeEmployees}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="date-range-picker">
                <div className="date-picker-section">
                  <label>Select a New Date Range</label>
                  <RsDateRangePicker
                    placement="topStart"
                    onClean={(event, picker) => clearDateRange(event, picker)}
                    onOk={(event, picker) => handleDateChange(event, picker)}
                  />
                </div>

                {dateRanges.length > 0 ? (
                  <div className="my-3">
                    <label>Selected dates</label>
                    <div className=" flex-it selected-ranges">
                      {dateRanges.map((range, index) => (
                        <div key={index}>
                          <span>{` ${range.startDate?.format(
                            "MMMM Do YYYY"
                          )} - ${range.endDate?.format("MMMM Do YYYY")}`}</span>
                          <button onClick={() => removeDateRange(index)}>
                            Remove
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : null}
              </div>

              <Form.Group className="mb-3">
                <Form.Label>
                  Description <span className="text-danger">*</span> :
                </Form.Label>
                <label className="border p-0 rounded pb-2 message-area d-block">
                  <Form.Control
                    name="description"
                    value={state?.description}
                    onChange={onChangeInputs}
                    as="textarea"
                    className="w-100 border-0 p-3"
                    placeholder="Enter your detail description"
                    rows={4}
                    required
                  />
                </label>
              </Form.Group>

              <div className="d-flex align-items-center my-4">
                <h4 className="pr-2">Send Request To</h4>{" "}
                <hr className="flex-grow-1 m-0" />
              </div>

              <div className="row gap-3">
                <Form.Group className="col mb-3">
                  <Form.Label>
                    Department <span className="text-danger">*</span> :
                  </Form.Label>
                  <select
                    className="form-control"
                    onChange={(e) => getUsersInDepartments(e.target.value)}
                    required
                  >
                    <option value="">Select Recipient's Department</option>
                    {departments &&
                      departments.map((el, index) => (
                        <option key={index} value={el.Department}>
                          {el.Department}
                        </option>
                      ))}
                  </select>
                </Form.Group>

                {state?.users.length > 0 && (
                  <Form.Group className="col mb-3">
                    <Form.Label>
                      Recipient <span className="text-danger">*</span> :
                    </Form.Label>
                    <select
                      name="staff"
                      className="form-control"
                      value={state?.staff}
                      onChange={onChangeInputs}
                      required
                    >
                      <option value="">Select Recipient </option>
                      {state?.users.map((el, index) => (
                        <Fragment key={index}>
                          <option value={el.Staff_ID}>{el.Name}</option>
                        </Fragment>
                      ))}
                    </select>
                  </Form.Group>
                )}
              </div>

              <Form.Group className="mb-3">
                <Form.Label>Comment :</Form.Label>
                <label className="border p-0 rounded pb-2 message-area d-block">
                  <Form.Control
                    name="comment"
                    value={state?.comment}
                    onChange={onChangeInputs}
                    as="textarea"
                    className="w-100 border-0 p-3"
                    placeholder="Enter a comment"
                    rows={4}
                  />
                </label>
              </Form.Group>
              <div className="submit-section">
                <button
                  className="btn btn-primary submit-btn"
                  disabled={state?.isWaiting}
                >
                  {state?.buttonText}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
